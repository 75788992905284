import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import SavedSearchGrid from "./SavedSearchGrid";
import SavedSearchForm from "./SavedSearchForm";

import NoData from "../NoData";
import { fetchSavedSearchesAPI, selectPage, selectNoData } from "../../../app/savedSearchesSlice";

import { getPageTitle } from "../../../shared/sessionUtility";

const SavedSearches = React.memo(() => {
    const dispatch = useDispatch();
    const pageData = useSelector(selectPage);
    const noData = useSelector(selectNoData);

    useEffect(() => {
        dispatch(fetchSavedSearchesAPI());
    }, []); // eslint-disable-line

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Reports"))}</title>
            </Helmet>
            <div>
                <h1 className="line-height-md">Reports</h1>
                <SavedSearchForm />
                <div hidden={!pageData?.data?.length && noData}>
                    <SavedSearchGrid />
                </div>
                <div className="flex dl-flex-fill-height ">
                    <NoData hidden={pageData?.data?.length} savedSearch />
                </div>
            </div>
        </React.Fragment>
    );
});

export default SavedSearches;
