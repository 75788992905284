import React from "react";

import "./record-count.css";

const RecordCount = React.memo(props => {
    const selected = props.selectedCount !== 0;

    const counterText = selected ? (
        <div className="margin-bottom-xs">
            <span className="clear" />
            <span className="left no-wrap">{`${props.selectedCount} selected`}</span>
            <span className="right no-wrap">
                {props.recordCount} {props.recordName}
            </span>
        </div>
    ) : (
        <>
            <div className="margin-bottom-xs text-right">
                {props.recordCount} {props.recordName}
            </div>
            <span className="clear" />
        </>
    );

    return <div className="">{counterText}</div>;
});

export default RecordCount;
