import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { buildURL } from "../shared/Utils";
import toast from "../hooks/toast";

const [showToast] = toast();
export const retailSettingsSlice = createSlice({
    name: "retailSettings",
    initialState: {
        isLoadingRetailSettings: false,
        isLoadingPurchaserSettings: false,
        retailSettings: {},
        purchaserSettings: {},
        isAddingPurchaserSetting: false,
        isAddingRetailSetting: false,
    },
    reducers: {
        setIsLoadingRetailSettings: (state, action) => {
            state.isLoadingRetailSettings = action.payload;
        },
        setIsLoadingPurchaserSettings: (state, action) => {
            state.isLoadingRetailSettings = action.payload;
        },
        setRetailSettings: (state, action) => {
            state.retailSettings = action.payload;
        },
        setPurchaserSettings: (state, action) => {
            state.purchaserSettings = action.payload;
        },
        setIsAddingPurchaserSetting: (state, action) => {
            state.isAddingPurchaserSetting = action.payload;
        },
        setIsAddingRetailSetting: (state, action) => {
            state.isAddingRetailSetting = action.payload;
        },
    },
});

export const {
    setIsLoadingRetailSettings,
    setIsLoadingPurchaserSettings,
    setRetailSettings,
    setPurchaserSettings,
    setIsAddingPurchaserSetting,
    setIsAddingRetailSetting,
} = retailSettingsSlice.actions;
export const selectIsLoadingRetailSettings = state => {
    return state.retailSettings.isLoadingRetailSettings;
};
export const selectIsLoadingPurchaserSettings = state => {
    return state.retailSettings.isLoadingPurchaserSettings;
};
export const selectRetailSettings = state => {
    return state.retailSettings.retailSettings;
};
export const selectPurchaserSettings = state => {
    return state.retailSettings.purchaserSettings;
};
export const selectIsAddingPurchaserSetting = state => {
    return state.retailSettings.isAddingPurchaserSetting;
};
export const selectIsAddingRetailSetting = state => {
    return state.retailSettings.isAddingRetailSetting;
};
export const fetchRetailSettings = () => async (dispatch, getState) => {
    const { isLoadingRetailSettings } = getState().retailSettings;
    if (isLoadingRetailSettings) {
        return;
    }
    dispatch(setIsLoadingRetailSettings(true));

    const apiURI = buildURL(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/retail-settings`
    );
    const response = await axios.get(`${apiURI}`, { withCredentials: true });
    dispatch(setRetailSettings(response?.data?.value));
    dispatch(setIsLoadingRetailSettings(false));
};

export const fetchPurchaserSettings = () => async (dispatch, getState) => {
    const { isLoadingPurchaserSettings } = getState().retailSettings;
    if (isLoadingPurchaserSettings) {
        return;
    }
    dispatch(setIsLoadingPurchaserSettings(true));

    const apiURI = buildURL(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/retail-purchaser-settings?top=20`
    );
    const response = await axios.get(`${apiURI}`, { withCredentials: true });
    dispatch(setPurchaserSettings(response?.data?.value));
    dispatch(setIsLoadingPurchaserSettings(false));
};

export const putPurchaserSettings = payload => async dispatch => {
    dispatch(setIsAddingPurchaserSetting(true));
    await axios
        .put(
            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/retail-purchaser-settings`,
            { retailPurchaserSettings: payload },
            { withCredentials: true }
        )
        .then(response => {
            if (response.name === "AxiosError" && response.response.status === 400) {
                showToast(
                    "error",
                    response.response.data[0].message,
                    "purchaser-setting-error-toast",
                    false
                );
            }
            if (response.status === 200) {
                showToast(
                    "success",
                    `Purchaser settings updated.`,
                    "purchaser-success-toast",
                    false
                );
                dispatch(fetchPurchaserSettings());
            }
        });

    dispatch(setIsAddingPurchaserSetting(false));
};

export const putRetailSettings = payload => async dispatch => {
    dispatch(setIsAddingRetailSetting(true));

    await axios
        .put(
            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/retail-settings`,
            payload,
            { withCredentials: true }
        )
        .then(response => {
            if (response.name === "AxiosError" && response.response.status === 400) {
                showToast(
                    "error",
                    response.response.data[0].message,
                    "retail-update-settings-error-toast",
                    false
                );
            }
            if (response.status === 200) {
                showToast(
                    "success",
                    `Retail settings updated.`,
                    "retail-edit-settings-success-toast",
                    false
                );
                dispatch(fetchRetailSettings());
            }
        });

    dispatch(setIsAddingRetailSetting(false));
};
export default retailSettingsSlice.reducer;
