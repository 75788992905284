import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SLoader, SPagination } from "@avalara/skylab-react";
import { gridHeader, DataGrid } from "@certcapture/react-components";
import {
    fetchCustomerCustomFields,
    selectPage,
    selectIsLoading,
    setPageToNull,
    selectSortColumn,
    setSortColumn,
} from "../../../../app/customerCustomFieldSlice";
import DeleteCustomersCustomFieldDialog from "./DeleteCustomersCustomField";
import AddEditCustomerCustomFieldsDialog from "./AddEditCustomerCustomFieldsDialog";
import { isEligibleUser } from "../../../../shared/sessionUtility";
import featureFlag from "../../../../featureToggler/featureFlag";
import FeatureToggler from "../../../../featureToggler/FeatureToggler";

function CustomersCustomFieldsGrid(props) {
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const page = useSelector(selectPage, shallowEqual);
    const gridData = page.data;
    const pageData = page.paginate;
    const isLoading = useSelector(selectIsLoading);
    const sortColumn = useSelector(selectSortColumn);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [customerCustomField, setCustomerCustomField] = useState({});
    const isUserEligibleEditCustomersCustomField = !dispatch(
        isEligibleUser(featureFlag.settings.editCustomersCustomField)
    );
    const isUserEligibleDeleteCustomersCustomField = !dispatch(
        isEligibleUser(featureFlag.settings.deleteCustomersCustomField)
    );

    useEffect(() => {
        dispatch(fetchCustomerCustomFields());
        return () => {
            dispatch(setPageToNull([]));
        };
    }, [dispatch]);

    async function sortFunction(e) {
        setSubmitting(true);
        const column = e.detail.id;
        const order = sortColumn[0] === column ? !sortColumn[1] : true;
        dispatch(setSortColumn([column, order]));
        await dispatch(fetchCustomerCustomFields(pageData.rowsPerPage, null, pageData));
        setSubmitting(false);
    }

    const columns = [
        gridHeader(
            "Name",
            "fieldName",
            value => value,
            null,
            "",
            null,
            null,
            sortFunction,
            "fieldName"
        ),
        gridHeader("Type", "type", value => value, null, "", null, null, sortFunction, "type"),
        gridHeader("Values", "possibleValues", value => value, "text-center", "text-center", null),
        gridHeader(
            "",
            "isEditable",
            (value, row) => (
                <FeatureToggler category="settings" id="editCustomersCustomField">
                    <button
                        className="link font-bold"
                        disabled={isUserEligibleEditCustomersCustomField}
                        onClick={() => {
                            props.setAddOrEdit("Edit");
                            setCustomerCustomField(row);
                            props.setOpenAddUpdateDialog(true);
                        }}>
                        Edit
                    </button>
                </FeatureToggler>
            ),
            "text-center",
            "text-center"
        ),
        gridHeader(
            "",
            "inUse",
            (value, row) => (
                <FeatureToggler category="settings" id="deleteCustomersCustomField">
                    <button
                        className="link font-bold"
                        disabled={isUserEligibleDeleteCustomersCustomField}
                        onClick={() => {
                            setCustomerCustomField(row);
                            setIsDeleteDialogOpen(true);
                        }}>
                        Delete
                    </button>
                </FeatureToggler>
            ),
            "text-center",
            "text-center"
        ),
    ];

    const getRowKey = row => {
        return row.id;
    };

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && pageData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            dispatch(
                fetchCustomerCustomFields(e.detail.rowsPerPage, e.detail.startIndex, e.detail)
            );
            setSubmitting(false);
        }
    };

    const table =
        isLoading || submitting ? (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        ) : (
            <>
                <div className="margin-bottom-xs text-right">
                    {pageData.totalRecords} custom fields for customers
                </div>
                <span className="clear" />
                <s-table-container>
                    <DataGrid
                        columns={columns}
                        rows={gridData}
                        loading={isLoading}
                        getKey={getRowKey}
                        inContainer
                        sortColumn={sortColumn}
                    />
                </s-table-container>
            </>
        );

    return !isLoading && pageData.totalRecords === 0 ? (
        <div className="text-center flex dl-flex-fill-height dl-flex-center">
            <h1 className="text-color-no-data">No Custom fields are found</h1>
        </div>
    ) : (
        <React.Fragment>
            {table}
            {pageData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={pageData.rowsPerPage}
                    totalRecords={pageData.totalRecords}
                    startIndex={pageData.startIndex}
                    onS-paginate={e => {
                        handlePaginate(e);
                    }}
                />
            ) : null}

            {isDeleteDialogOpen ? (
                <DeleteCustomersCustomFieldDialog
                    isDeleteDialogOpen
                    setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                    customerCustomField={customerCustomField}
                />
            ) : null}
            {props.openAddUpdateDialog ? (
                <AddEditCustomerCustomFieldsDialog
                    addOrEdit={props.addOrEdit}
                    customerCustomField={customerCustomField}
                    openAddUpdateDialog={props.openAddUpdateDialog}
                    setOpenAddUpdateDialog={props.setOpenAddUpdateDialog}
                />
            ) : null}
        </React.Fragment>
    );
}

export default CustomersCustomFieldsGrid;
