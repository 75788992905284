import React, { useEffect, useState } from "react";
import { SCol, SLoader, SRow, SBox, SIcon } from "@avalara/skylab-react";
import { toLower } from "lodash";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
    selectIsLoading,
    fetchVendorPackageContent,
    selectPackageContent,
    selectPackageInfo,
    fetchVendorPackageById,
} from "../../../app/vendorPackagesSlice";
import { selectSession } from "../../../app/sessionSlice";
import "./vendorPackages.css";
import toast from "../../../hooks/toast";
import VendorPackageValidationError from "./VendorPackageValidationError";
import PackageCertDeleteDialog from "./PackageCertDeleteDialog";
import { errorTag, noErrorTag } from "../../../shared/Utils";

function VendorPackagesContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const isLoading = useSelector(selectIsLoading);
    const packageContent = useSelector(selectPackageContent);
    const packageInfo = useSelector(selectPackageInfo);

    const [pckNameError, setPckNameError] = useState("");
    const [pckDescError, setPckDescError] = useState("");
    const [packageName, setPackageName] = useState("");
    const [packageDesc, setPackageDesc] = useState("");
    const [expandedCon, setExpandedCon] = useState([]);
    const [openPkCertDelDiag, setOpenPkCertDelDiag] = useState(false);
    const [certToDelete, setCertToDelete] = useState("");
    const session = useSelector(selectSession);
    const companyId = session.activeCompany.id;
    const [showToast] = toast();

    useEffect(() => {
        dispatch(fetchVendorPackageById(params.id));
    }, [params.id]); // eslint-disable-line

    useEffect(() => {
        dispatch(fetchVendorPackageContent(params.id));
    }, [params.id]); // eslint-disable-line

    useEffect(() => {
        setPackageDesc(packageInfo.packageDescription);
        setPackageName(packageInfo.packageName);
    }, [packageInfo]); // eslint-disable-line

    const reloadHandler = () => {
        // dispatch(fetchVendorPackageContent(params.id));
        window.location.reload();
    };

    const validateData = () => {
        let valid = true;
        if (!packageName || packageName === "") {
            setPckNameError("error");
            valid = false;
        }
        if (!packageDesc || packageDesc === "") {
            setPckDescError("error");
            valid = false;
        }
        return valid;
    };

    const endHandler = async () => {
        if (validateData()) {
            const urlPath = `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/companies/${companyId}/vendor-packages/${params.id}`;
            const putData = {
                packageName,
                packageDescription: packageDesc,
            };
            await axios
                .put(urlPath, putData, { withCredentials: true })

                .then(() => {
                    showToast("success", "Certificate package saved");
                    navigate("/search/vendor-packages");
                });
        }
    };

    const deleteContent = cntId => {
        setCertToDelete(cntId);
        setOpenPkCertDelDiag(true);
    };

    const moreContent = cnId => {
        if (!expandedCon.includes(`pack-${cnId}`)) {
            setExpandedCon([...expandedCon, `pack-${cnId}`]);
        }
    };

    const lessContent = cnId => {
        setExpandedCon(prevState => prevState.filter(prevItem => prevItem !== `pack-${cnId}`));
    };

    const handleText = (type, value) => {
        if (type === "name") {
            setPckNameError("");
            setPackageName(value);
        } else {
            setPckDescError("");
            setPackageDesc(value);
        }
    };

    const navHandler = () => {
        navigate(`/search/vendor-packages/add-certificates/${params.id}`);
    };

    const backHandler = () => {
        navigate("/search/vendor-packages");
    };

    let vendorPackageDOM = "";
    if (isLoading) {
        vendorPackageDOM = (
            <div>
                <SRow>
                    <SCol className="inline">
                        <SLoader loading={isLoading} className="large" aria-live="polite" />
                    </SCol>
                </SRow>
            </div>
        );
    } else if (!isLoading && packageContent.length >= 1) {
        vendorPackageDOM = (
            <div>
                <h1> Package details</h1>
                <SRow>
                    <button
                        type="button"
                        className="inline ghost-blue margin-bottom-lg margin-top-xs"
                        onClick={navHandler}>
                        <SIcon
                            name="plus"
                            className="margin-right-xs"
                            aria-label="Add certificates"
                        />
                        <span className="button-liner"> Add certificates </span>
                    </button>
                </SRow>
                <SRow>
                    <SCol span="6" className="margin-bottom-md">
                        <label htmlFor="edit-pack-name" className="required">
                            Package name
                        </label>
                        <input
                            name="edit-pack-name"
                            id="edit-pack-name"
                            type="text"
                            className={pckNameError === "error" ? errorTag : noErrorTag}
                            defaultValue={packageInfo.packageName}
                            onInput={e => handleText("name", e.target.value)}
                        />
                        {pckNameError === "error" ? (
                            <VendorPackageValidationError fieldName="Package name" />
                        ) : null}
                    </SCol>
                </SRow>

                <SRow>
                    <SCol span="6" className="margin-bottom-xl">
                        <label htmlFor="edit-pack-desc" className="required">
                            Package description
                        </label>
                        <input
                            name="edit-pack-desc"
                            id="edit-pack-desc"
                            type="text"
                            className={pckDescError === "error" ? errorTag : noErrorTag}
                            defaultValue={packageInfo.packageDescription}
                            onInput={e => handleText("desc", e.target.value)}
                        />
                        {pckDescError === "error" ? (
                            <VendorPackageValidationError fieldName="Package description" />
                        ) : null}
                    </SCol>
                </SRow>

                {packageContent?.map(content => {
                    const isMore = content.fields.length > 3;
                    const styling = !isMore ? "margin-bottom-lg" : "";
                    const renderCnt =
                        isMore && !expandedCon.includes(`pack-${content.contentId}`)
                            ? content.fields.slice(0, 3)
                            : content.fields;
                    return (
                        <SRow key={uuidv4()} className="margin-bottom-lg">
                            <SCol span="8">
                                <SBox key={uuidv4()} className="pad-right-none pad-left-none">
                                    <div>
                                        <div className="margin-right-lg">
                                            <SRow>
                                                <SCol span="4">
                                                    <span className="text-lg margin-left-md">
                                                        {content.regionName}{" "}
                                                        {toLower(content.taxCodeName)}
                                                    </span>
                                                </SCol>
                                                <SCol span="auto">
                                                    <span className="float-right margin-top-xs">
                                                        <button className="secondary" disabled>
                                                            Edit
                                                        </button>
                                                        <button
                                                            className="secondary"
                                                            onClick={() =>
                                                                deleteContent(content.contentId)
                                                            }>
                                                            Remove this certificate
                                                        </button>
                                                    </span>
                                                </SCol>
                                            </SRow>
                                        </div>
                                        <hr />
                                        <div className="margin-top-sm margin-left-md margin-top-sm margin-bottom-xs">
                                            <b>
                                                {content.formName !== "" && content.formName
                                                    ? content.formName
                                                    : "Static document"}
                                            </b>
                                        </div>
                                        <div className={styling}>
                                            {renderCnt.length >= 1 ? (
                                                renderCnt.map(val => {
                                                    return (
                                                        <div
                                                            key={uuidv4()}
                                                            className="margin-left-md">
                                                            <SRow key={uuidv4()}>
                                                                <SCol>
                                                                    <b> {val.fieldName} </b>
                                                                </SCol>
                                                                <SCol>{val.fieldValue}</SCol>
                                                            </SRow>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <span className="margin-left-md">
                                                    There are no fillable form fields on this
                                                    document
                                                </span>
                                            )}
                                        </div>

                                        {isMore ? (
                                            <button
                                                className="link margin-top-md font-bold margin-left-md margin-bottom-lg"
                                                onClick={
                                                    !expandedCon.includes(
                                                        `pack-${content.contentId}`
                                                    )
                                                        ? () => {
                                                              moreContent(content.contentId);
                                                          }
                                                        : () => {
                                                              lessContent(content.contentId);
                                                          }
                                                }>
                                                Show{" "}
                                                {!expandedCon.includes(`pack-${content.contentId}`)
                                                    ? "more"
                                                    : "less"}
                                                {!expandedCon.includes(
                                                    `pack-${content.contentId}`
                                                ) ? (
                                                    <SIcon
                                                        name="chevron-down"
                                                        className="pad-left-xs top-xs"
                                                        aria-hidden="true"
                                                    />
                                                ) : (
                                                    <SIcon
                                                        name="chevron-up"
                                                        className="pad-left-xs top-xs"
                                                        aria-hidden="true"
                                                    />
                                                )}
                                            </button>
                                        ) : null}
                                    </div>
                                </SBox>
                            </SCol>
                        </SRow>
                    );
                })}

                <hr className="margin-top-xl" />

                <button className="primary large margin-top-lg" onClick={endHandler}>
                    Save
                </button>

                <button className="secondary large margin-top-lg" onClick={backHandler}>
                    View all packages
                </button>

                <div>
                    <PackageCertDeleteDialog
                        reloadHdlr={reloadHandler}
                        packageCertId={certToDelete}
                        openCertDel={openPkCertDelDiag}
                        closeCertDel={setOpenPkCertDelDiag}
                    />
                </div>
            </div>
        );
    } else {
        vendorPackageDOM = (
            <div>
                <h1> Package details</h1>
                <div className="margin-top-xl pad-top-xl">
                    <div id="no-certificate-request-found">
                        <div className="font-semibold font-normal text-lg text-center">
                            <h1 className="grid-header-none">
                                This vendor package is empty or does not exist
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return vendorPackageDOM;
}
export default VendorPackagesContent;
