import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { SDialog, SRow, SCol, STag } from "@avalara/skylab-react";
import {
    selectTaxContentForms,
    selectIsContentFormDialogOpen,
    setIsContentFormDialogOpen,
    setTaxContentForm,
    setTaxTemplateTag,
    selectTaxTemplateTag,
    fetchTemplateCommunicationTaxTypesAsync,
    setCertificateCommunicationTaxTypes,
    selectMostCommonForm,
} from "../../app/certificateSlice";

function ContentFormDialog(props) {
    const dispatch = useDispatch();
    const isContentFormDialogOpen = useSelector(selectIsContentFormDialogOpen);
    const taxContentForms = useSelector(selectTaxContentForms, shallowEqual);
    const taxTemplateTag = useSelector(selectTaxTemplateTag);
    const [templateTag, setTemplateTag] = useState("");
    const [templateDescription, setTemplateDescription] = useState("");
    const mostCommonForm = useSelector(selectMostCommonForm);

    const handleFormChange = (tag, description) => {
        setTemplateTag(tag);
        setTemplateDescription(description);
    };

    const handleConfirm = async () => {
        if (!templateTag) {
            return;
        }

        dispatch(setTaxTemplateTag(templateTag));
        dispatch(setTaxContentForm(templateDescription));
        dispatch(setIsContentFormDialogOpen(false));

        dispatch(
            fetchTemplateCommunicationTaxTypesAsync(
                `?$filter=templateTag eq '${templateTag}'&$top=1000`
            )
        );
        if (props.certificateID) {
            dispatch(setCertificateCommunicationTaxTypes([]));
        }
    };

    return (
        <SDialog
            open={isContentFormDialogOpen}
            id="content-form-dialog"
            onS-dismiss={() => dispatch(setIsContentFormDialogOpen(false))}
            aria-modal="true">
            <div slot="header" id="contentForm">
                Telecommunications {props.exemptReason.toLowerCase()}
            </div>
            <div slot="body">
                <SRow>
                    <SCol span="auto">
                        <span>
                            Select the form that authorizes a tax exemption for this reason.
                        </span>
                        <span className="taxContentFormDialogHeader margin-top-sm">
                            {props.exposureZone[0].name}
                        </span>
                        <fieldset role="radiogroup">
                            {taxContentForms.map(form => (
                                <React.Fragment key={form.id}>
                                    <input
                                        onChange={() =>
                                            handleFormChange(form.tag, form.description)
                                        }
                                        type="radio"
                                        id={form.id}
                                        value={form.tag}
                                        checked={
                                            (!templateTag && form.tag === taxTemplateTag) ||
                                            templateTag === form.tag
                                        }
                                    />
                                    <label className="radioCustomLineHeight" htmlFor={form.id}>
                                        {form.description}
                                    </label>
                                    <div hidden={form.tag !== mostCommonForm}>
                                        <STag className="margin-left-lg" color="blue-light">
                                            <span slot="content">Most common</span>
                                        </STag>
                                    </div>

                                    <br hidden={form.tag === mostCommonForm} />
                                </React.Fragment>
                            ))}
                        </fieldset>
                    </SCol>
                </SRow>
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    onClick={() => {
                        dispatch(setIsContentFormDialogOpen(false));
                    }}>
                    Cancel
                </button>
                <button
                    className="primary small"
                    onClick={() => {
                        handleConfirm();
                    }}>
                    Use this form
                </button>
            </div>
        </SDialog>
    );
}

export default ContentFormDialog;
