import React, { useState } from "react";
import { Link } from "react-router-dom";
import { STag, SLoader, SPagination } from "@avalara/skylab-react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { DataGrid, gridHeader } from "@certcapture/react-components";
import { v4 as uuidv4 } from "uuid";
import {
    selectCampaign,
    selectIsCampaignCustomersLoading,
    selectCampaignCustomersPage,
    selectDetailsSortColumn,
    setDetailsSortColumn,
    fetchCampaignDetailsAPI,
} from "../../app/requestSlice";
import { RequestStatuses } from "../../shared/constants";
import { getFormattedDate } from "../../shared/Utils";
import NoData from "../search/NoData";

function CampaignGrid() {
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const loading = useSelector(selectIsCampaignCustomersLoading);
    const campaign = useSelector(selectCampaign);
    const page = useSelector(selectCampaignCustomersPage);
    const pageData = page.data;
    const paginateData = page.paginate;
    const sortColumn = useSelector(selectDetailsSortColumn, shallowEqual);

    function sortFunction(e) {
        const columnId = e.detail.id;
        const order = sortColumn[0] === columnId ? !sortColumn[1] : true;
        dispatch(setDetailsSortColumn([columnId, order]));
        dispatch(fetchCampaignDetailsAPI(campaign.id));
    }

    const mapZoneCount = customer => {
        let rowColor = "yellow-medium";
        const total = customer.zones.match(/\d+/);
        const totalZones = parseInt(total[0], 10);
        if (totalZones === 0) {
            return (
                <STag color="green-light" aria-label="No exposures found">
                    <span slot="content">No exposures found</span>
                </STag>
            );
        }
        if (customer.exposuresClosed !== 0 && customer.exposuresClosed === totalZones) {
            rowColor = "green-dark";
        }
        return (
            <STag color={rowColor}>
                <span slot="content">
                    {customer.exposuresClosed}/{totalZones}
                </span>
            </STag>
        );
    };

    const detailTable = campaignsDetails => {
        if (!campaignsDetails || campaignsDetails.length === 0) {
            return (
                <div className="text-sm-strong">
                    This customer was not a Ship-To at the start of the campaign.
                </div>
            );
        }
        return (
            <s-table-container>
                <table className="dg-data-grid nested-grid">
                    <thead>
                        <tr key={uuidv4()}>
                            <th>Region</th>
                            <th style={{ width: "200px" }}>Reason</th>
                            <th>Status</th>
                            <th>Uploaded on</th>
                            <th>Uploaded by</th>
                            {/* <th>Validated on</th>
                            <th>Validated by</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {campaignsDetails.map(x => (
                            <tr
                                id={`${x.customerId}-${x.regionId}-${x.reasonId}`}
                                key={`${x.customerId}-${x.regionId}-${x.reasonId}`}>
                                <td>{x.region}</td>
                                <td style={{ width: "200px" }}>{x.reason}</td>
                                <td>
                                    {x.certificateStatus === RequestStatuses.EXPIRED && (
                                        <STag color="gray-darker" className="tagText">
                                            <span slot="content">{x.certificateStatus}</span>
                                        </STag>
                                    )}
                                    {x.certificateStatus === RequestStatuses.VALID && (
                                        <STag color="green-dark" className="tagText">
                                            <span slot="content">{x.certificateStatus}</span>
                                        </STag>
                                    )}
                                </td>
                                <td>
                                    {getFormattedDate(x.uploadedOn) ? (
                                        getFormattedDate(x.uploadedOn)
                                    ) : (
                                        <STag color="blue-light" className="tagText">
                                            <span slot="content">Not uploaded</span>
                                        </STag>
                                    )}
                                </td>
                                <td>{x.uploadedBy}</td>
                                {/* <td>
                                    {getFormattedDate(x.validatedOn)
                                        ? getFormattedDate(x.validatedOn)
                                        : null}
                                </td>
                                <td>{x.validatedBy}</td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </s-table-container>
        );
    };

    const columns = [
        gridHeader(
            "Customer code",
            "customerNumber",
            (value, row) => (
                <Link className="font-semibold customer-link" to={`/customer/${row.id}`}>
                    {row.customerNumber}
                </Link>
            ),
            null,
            null,
            null,
            null,
            sortFunction,
            "customerNumber"
        ),
        gridHeader("Customer name", "name", (value, row) => row.name, null, "", null),
        gridHeader(
            "Certificates received",
            "completedZones",
            (value, row) => (row.zones ? <React.Fragment>{mapZoneCount(row)}</React.Fragment> : ""),
            null,
            "",
            null,
            (value, row) => detailTable(row.campaignsDetails)
        ),
    ];

    const getRowKey = row => {
        return row.id;
    };

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            await dispatch(
                fetchCampaignDetailsAPI(
                    campaign.id,
                    e.detail.rowsPerPage,
                    e.detail.currentPage,
                    false,
                    e.detail
                )
            );
            setSubmitting(false);
        }
    };

    const table =
        submitting || loading ? (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        ) : (
            <>
                <s-table-container>
                    <DataGrid
                        columns={columns}
                        rows={pageData}
                        getKey={getRowKey}
                        loading={submitting}
                        sortColumn={sortColumn}
                        rowExpander
                        inContainer
                    />
                </s-table-container>
                <NoData request="bulkRequests" />
            </>
        );

    return (
        <React.Fragment>
            {table}
            {paginateData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={paginateData.rowsPerPage}
                    totalRecords={paginateData.totalRecords}
                    startIndex={paginateData.startIndex}
                    onS-paginate={e => {
                        handlePaginate(e);
                    }}
                />
            ) : null}
        </React.Fragment>
    );
}
export default CampaignGrid;
