import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SLoader, SPagination, SIcon } from "@avalara/skylab-react";
import { gridHeader, DataGrid } from "@certcapture/react-components";
import {
    fetchInvalidReasons,
    selectPage,
    selectIsLoading,
    setIsLoading,
    setPageToNull,
    selectSortColumn,
    setSortColumn,
} from "../../../app/invalidReasonSlice";
import RecordCount from "../../search/RecordCount";
import { isEligibleUser } from "../../../shared/sessionUtility";
import featureFlag from "../../../featureToggler/featureFlag";
import DeleteInvalidReasonDialog from "./DeleteInvalidReasonDialog";
import InvalidReasonDialog from "./InvalidReasonsDialog";
import FeatureToggler from "../../../featureToggler/FeatureToggler";

export const loaderText = "Loading ...";
export const noData = "No invalid reasons found.";
export const countLabel = "invalid reasons";

function InvalidReasonsGrid(props) {
    const dispatch = useDispatch();
    const page = useSelector(selectPage, shallowEqual);
    const gridData = page.data;
    const pageData = page.paginate;
    const isLoading = useSelector(selectIsLoading);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [invalidReason, setInvalidReason] = useState({});
    const sortColumn = useSelector(selectSortColumn);
    const isEligibleUserSettingsDeleteInvalidReason = dispatch(
        isEligibleUser(featureFlag.settings.deleteInvalidReason)
    );
    const isEligibleUserSettingsEditInvalidReason = dispatch(
        isEligibleUser(featureFlag.settings.editInvalidReason)
    );

    useEffect(() => {
        dispatch(fetchInvalidReasons());
        return () => {
            dispatch(setPageToNull([]));
        };
    }, [dispatch]);

    async function sortFunction(e) {
        setIsLoading(true);
        const columnId = e.detail.id;
        const order = sortColumn[0] === columnId ? !sortColumn[1] : true;
        dispatch(setSortColumn([columnId, order]));
        dispatch(fetchInvalidReasons(null, e.detail.rowsPerPage, e.detail.startIndex));
        setIsLoading(false);
    }
    const handleEditInvalidReason = row => {
        setInvalidReason(row);
        props.setAddInvalidReasonDialog(true);
        props.setAddOrEdit("edit");
    };

    const columns = [
        gridHeader("Name", "name", value => value, null, null, null, null, sortFunction, "name"),
        gridHeader("Description", "description", value => value, null, "", null),
        gridHeader(
            "Avalara default",
            "systemCode",
            (value, row) => (row.systemCode ? <SIcon name="check" aria-hidden="true" /> : null),
            "text-center",
            "text-center",
            null
        ),
        gridHeader(
            "",
            "id",
            (value, row) => (
                <FeatureToggler category="settings" id="deleteInvalidReason">
                    <button
                        className={
                            row.systemCode
                                ? "link font-semibold"
                                : "link font-semibold color-disabled"
                        }
                        disabled={row.systemCode || !isEligibleUserSettingsDeleteInvalidReason}
                        onClick={() => {
                            setInvalidReason(row);
                            setIsDeleteDialogOpen(true);
                        }}>
                        Delete
                    </button>
                </FeatureToggler>
            ),
            "text-center",
            "text-center",
            null
        ),
        gridHeader(
            "",
            "id",
            (value, row) =>
                !row.systemCode ? (
                    <FeatureToggler category="settings" id="editInvalidReason">
                        <button
                            className="link font-semibold"
                            disabled={!isEligibleUserSettingsEditInvalidReason}
                            onClick={() => handleEditInvalidReason(row)}>
                            Edit
                        </button>
                    </FeatureToggler>
                ) : null,
            "text-center",
            "text-center",
            null
        ),
    ];

    const getRowKey = row => {
        return row.id;
    };

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && pageData.totalRecords > 0 && e.detail.flag !== "") {
            setIsLoading(true);
            dispatch(
                fetchInvalidReasons(null, e.detail.rowsPerPage, e.detail.startIndex, e.detail)
            );
            setIsLoading(false);
        }
    };

    const table = isLoading ? (
        <div className="flex dl-flex-fill-height dl-flex-center">
            <h3> {loaderText}</h3>
            <SLoader id="page-loader" className="medium" aria-live="polite" loading />
        </div>
    ) : (
        <s-table-container>
            <DataGrid
                columns={columns}
                rows={gridData}
                loading={isLoading}
                getKey={getRowKey}
                inContainer
                sortColumn={sortColumn}
            />
        </s-table-container>
    );

    return !isLoading && pageData.totalRecords === 0 ? (
        <div className="text-center flex dl-flex-fill-height dl-flex-center">
            <h1 className="text-color-no-data">{noData}</h1>
            <p className="text-color-no-data">Adjust your search and try again</p>
        </div>
    ) : (
        <React.Fragment>
            <RecordCount
                selectedCount={0}
                recordCount={pageData.totalRecords}
                recordName={countLabel}
            />
            {table}
            {pageData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={pageData.rowsPerPage}
                    totalRecords={pageData.totalRecords}
                    startIndex={pageData.startIndex}
                    onS-paginate={e => {
                        handlePaginate(e);
                    }}
                />
            ) : null}

            {isDeleteDialogOpen ? (
                <DeleteInvalidReasonDialog
                    isDeleteDialogOpen
                    setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                    invalidReason={invalidReason}
                />
            ) : null}

            {props.addInvalidReasonDialog ? (
                <InvalidReasonDialog
                    openDialog={props.addInvalidReasonDialog}
                    closeDialog={props.setAddInvalidReasonDialog}
                    addOrEdit={props.addOrEdit}
                    invalidReason={invalidReason}
                />
            ) : null}
        </React.Fragment>
    );
}

export default React.memo(InvalidReasonsGrid);
