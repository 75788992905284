import React, { useState } from "react";
import { SRow, SCol, SLoader } from "@avalara/skylab-react";
import { shallowEqual, useSelector } from "react-redux";
import toast from "../../hooks/toast";
import axios from "../../axios";
import "./assets/provision.css";
import { selectSession } from "../../app/sessionSlice";
import { ecmSubscription } from "../navigation/Subscription";
import { matchSubscriptions } from "../../shared/Utils";

const Provision = React.memo(() => {
    const session = useSelector(selectSession, shallowEqual);
    const [showToast] = toast();
    const [isLoading, showLoading] = useState(false);
    const [changeLoading, isChangeLoading] = useState(false);

    const sleep = () => new Promise(resolve => setInterval(resolve, 10000));

    const postProvisionJob = async () => {
        return axios.post(
            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION}/certificates/setup`,
            {},
            {
                withCredentials: true,
            }
        );
    };

    const getProvisionJobStatus = async () => {
        return axios.get(
            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION}/certificates/setup`,
            {
                withCredentials: true,
            }
        );
    };

    const provisionComplete = () => {
        if (!matchSubscriptions(session.subscriptions, ecmSubscription)) {
            window.location = `/promo`;
        } else {
            window.location = `/`;
        }
    };

    const checkStatusUntilCompleted = async (retries = 30) => {
        // give the job a few seconds to get kicked off, and a break in between requests
        await sleep();
        const response = await getProvisionJobStatus();
        if (response.data && response.data.status) {
            const pollerResponse = response.data.status;
            if (pollerResponse === "Provisioned") {
                showToast(
                    "success",
                    "Company/Account provisioned successfully.",
                    "provision-success"
                );
                setTimeout(() => provisionComplete(), 5000);
            } else if (retries > 0) {
                if (retries === 15) {
                    isChangeLoading(true);
                }
                checkStatusUntilCompleted(retries - 1);
            } else {
                showToast(
                    "error",
                    "There may have been some trouble scheduling or completing the provision job, please try again or reach out to support"
                );
                showLoading(false);
            }
        } else {
            showToast(
                "error",
                "Error getting provision status, please try again or reach out to support"
            );
            showLoading(false);
        }
    };

    const handlePostProvisionFunc = async () => {
        showLoading(true);
        const response = await postProvisionJob();
        if (response.data && response.data.status) {
            const pollerResponse = response.data.status;
            if (pollerResponse === "InProgress") {
                checkStatusUntilCompleted(30);
            } else if (pollerResponse === "Provisioned") {
                showToast("success", "Company/Account already provisioned.", "provision-success");
                setTimeout(() => provisionComplete(), 5000);
            } else {
                showLoading(false);
                showToast(
                    "error",
                    "Failed to queue provision job, please try again or reach out to support"
                );
            }
        } else {
            showLoading(false);
            showToast(
                "error",
                "Failed to queue provision job, please try again or reach out to support"
            );
        }
    };

    return (
        <div className="flex dl-flex-fill-height">
            <SRow>
                <SCol center className="margin-top-xl" span="6" offset="3">
                    <div className={`pad-all-md ${isLoading ? "hidden" : ""}`}>
                        <h1 className="heading">Start your exemption certificate setup</h1>
                        This process takes around 15 minutes for most customers. Take a break, find
                        another task you can finish quickly, or grab a cup of coffee and a snack. By
                        the time you get back we should have everything ready for you.
                        <hr />
                        <button className="primary" onClick={() => handlePostProvisionFunc()}>
                            Begin
                        </button>
                    </div>
                    <div className={`pad-all-lg text-center ${isLoading ? "" : "hidden"}`}>
                        <div className={changeLoading ? "hidden" : ""}>
                            <h1 className="heading">We&apos;re getting things ready for you...</h1>
                            Grab a cup of coffee while we put everything in place.
                        </div>
                        <div className={changeLoading ? "" : "hidden"}>
                            <h1 className="heading">Just putting on the finishing touches...</h1>
                            Almost ready to show your options and get you managing certificates!
                        </div>
                        <SLoader loading="true" aria-live="polite" />
                    </div>
                </SCol>
            </SRow>
        </div>
    );
});

export default Provision;
