import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { SLoader, SPagination, SIcon, STag } from "@avalara/skylab-react";
import { DataGrid, gridHeader } from "@certcapture/react-components";
import {
    fetchCampaignsAPI,
    selectPage,
    selectSortColumn,
    setSortColumn,
    selectLoading,
} from "../../../app/requestSlice";
import CoverLetterPreviewDialog from "../CoverLetterPreviewDialog";
import { fetchCoverLetterByIdAPI } from "../../../app/coverLetterSlice";
import RecordCount from "../../search/RecordCount";

function BulkRequestGrid() {
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const loading = useSelector(selectLoading);
    const page = useSelector(selectPage);
    const pageData = page.data;
    const paginateData = page.paginate;
    const sortColumn = useSelector(selectSortColumn);
    const [coverLetterId, setCoverLetterId] = useState(null);
    const [customMessage, setCustomMessage] = useState(null);
    const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);

    function sortFunction(e) {
        setSubmitting(true);
        const column = e.detail.id;
        const order = sortColumn[0] === column ? !sortColumn[1] : true;
        dispatch(setSortColumn([column, order]));
        dispatch(fetchCampaignsAPI(null, null, e.detail.rowsPerPage, e.detail.startIndex, false));
        setSubmitting(false);
    }

    const mapCustomerResponded = campaign => {
        const rowColor =
            campaign.respondedCustomersCount === campaign.customerCount
                ? "green-dark"
                : "yellow-medium";
        return (
            <STag color={rowColor}>
                <span slot="content">
                    <span>{campaign.respondedCustomersCount}</span>/ {campaign.customerCount}
                </span>
            </STag>
        );
    };

    const getCoverLetterPreview = campaign => {
        const lastCampaignRound = campaign.campaignRounds[campaign.campaignRounds.length - 1];
        setCustomMessage(lastCampaignRound.customMessage);
        setCoverLetterId(lastCampaignRound.coverLetterId);
        dispatch(fetchCoverLetterByIdAPI(lastCampaignRound.coverLetterId));
        setIsPreviewDialogOpen(true);
    };

    const columns = [
        gridHeader(
            "Request name",
            "id",
            (value, row) => <Link to={`/campaigns/${row.id}`}>{row.title}</Link>,
            null,
            "",
            null,
            null,
            sortFunction,
            "title"
        ),
        gridHeader(
            "Request date",
            "created",
            value => new Date(value).toLocaleDateString(),
            null,
            "",
            null,
            null,
            sortFunction,
            "created"
        ),
        gridHeader("Customers", "customerCount", value => value, null, "", null, null),
        gridHeader(
            "Request letter",
            "campaignRounds",
            (value, row) =>
                row.campaignRounds.length !== 0 ? (
                    <button className="link font-bold" onClick={() => getCoverLetterPreview(row)}>
                        <SIcon name="zoom-in" className="blue-icon" aria-label="Zoom-in" />
                    </button>
                ) : null,
            null,
            "",
            null,
            null
        ),
        gridHeader(
            "Requested by",
            "requester",
            value => value.name,
            null,
            "",
            null,
            null,
            sortFunction,
            "accountName"
        ),
        gridHeader(
            "Responded",
            "campaignCustomers",
            (value, row) =>
                row.customerCount > 0 ? (
                    mapCustomerResponded(row)
                ) : (
                    <STag color="blue-light" aria-label="None">
                        <span slot="content">None</span>
                    </STag>
                ),
            null,
            "",
            null,
            null
        ),
        gridHeader(
            "",
            "id",
            (value, row) => (
                <Link to={`/campaigns/${row.id}`}>
                    <button className="secondary">View Details</button>
                </Link>
            ),
            null,
            "",
            null,
            null
        ),
    ];

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            await dispatch(
                fetchCampaignsAPI(
                    null,
                    null,
                    e.detail.rowsPerPage,
                    e.detail.startIndex,
                    false,
                    e.detail
                )
            );
            setSubmitting(false);
        }
    };

    const getRowKey = useCallback(row => {
        return row.id;
    }, []);

    const table =
        submitting || loading ? (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        ) : (
            <s-table-container>
                <DataGrid
                    columns={columns}
                    rows={pageData}
                    getKey={getRowKey}
                    loading={submitting}
                    sortColumn={sortColumn}
                    inContainer
                />
            </s-table-container>
        );

    return (
        <React.Fragment>
            {paginateData.totalRecords > 0 ? (
                <div className="margin-top-sm">
                    <RecordCount
                        selectedCount={0}
                        recordCount={new Intl.NumberFormat().format(paginateData.totalRecords)}
                        recordName="requests"
                    />
                </div>
            ) : null}
            {table}
            {paginateData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={paginateData.rowsPerPage}
                    totalRecords={paginateData.totalRecords}
                    startIndex={paginateData.startIndex}
                    onS-paginate={e => {
                        handlePaginate(e);
                    }}
                />
            ) : null}
            {isPreviewDialogOpen ? (
                <CoverLetterPreviewDialog
                    setIsPreviewDialogOpen={setIsPreviewDialogOpen}
                    isPreviewDialogOpen={isPreviewDialogOpen}
                    coverLetterId={coverLetterId}
                    customMessage={customMessage}
                />
            ) : null}
        </React.Fragment>
    );
}
export default BulkRequestGrid;
