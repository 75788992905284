import React, { useState } from "react";
import { SIcon, SDialog, SBox } from "@avalara/skylab-react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import toast from "../../../hooks/toast";
import axios from "../../../axios";
import { fetchCustomerExemptionsDetails } from "../../../app/customerCertificateSlice";
import {
    setCustomer,
    getCustomerMissingCertRegion,
    getVendorMissingCertRegion,
} from "../../../app/customerSlice";

function RemoveRegionDialog(props) {
    const [showToast] = toast();
    const dispatch = useDispatch();
    const docName = "Sales and Use Tax";
    const vendorCheck = props.isVendor;
    const entityType = vendorCheck ? "vendor" : "customer";
    const [isLoadingRemoveRegion, setLoadingRemoveRegion] = useState(false);
    const deleteButton = classnames({
        primary: true,
        small: true,
        loading: isLoadingRemoveRegion,
    });
    const removeCustomersExposureZones = async (
        customerId,
        exposureZonesId,
        documentDescription
    ) => {
        const requestBody = {
            exposureZoneIds: exposureZonesId,
            documentTypeName: documentDescription,
            outgoing: vendorCheck,
        };
        return axios
            .delete(
                `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customers/${customerId}/ship-to-zones`,
                {
                    withCredentials: true,
                    data: requestBody,
                }
            )
            .catch(error => {
                return error;
            });
    };
    const removeRegion = async () => {
        setLoadingRemoveRegion(true);
        const response = await removeCustomersExposureZones(
            props.customer.id,
            [props.exposureZoneId],
            docName
        );
        if (response && response.data) {
            showToast("success", "The region has been removed successfully.");
            setLoadingRemoveRegion(false);
            props.setIsRemoveRegionDialogOpen(false);
            dispatch(fetchCustomerExemptionsDetails());
            const customerData = JSON.parse(JSON.stringify(props.customer));
            customerData.isShipTo = response.data.isShipTo;
            customerData.isBillTo = response.data.isBillTo;
            dispatch(setCustomer(customerData));
            if (entityType === "vendor") {
                dispatch(getVendorMissingCertRegion(props.customer.id));
            } else {
                dispatch(getCustomerMissingCertRegion(props.customer.id));
            }
        } else {
            showToast("warning", `Could not update ${entityType} regions.`);
        }
    };

    return (
        <SDialog
            id="RemoveRegionDialog"
            open={props.isRemoveRegionDialogOpen}
            onS-dismiss={() => {
                props.setIsRemoveRegionDialogOpen(false);
            }}
            className="remove-dialog-width"
            aria-modal="true">
            <div slot="header" id="unlinkCustomer">
                Remove a region
            </div>
            <div slot="body">
                <SBox className="yellow">
                    <div className="unlink-dialog-text">
                        <SIcon name="flag" className="medium pad-right-sm" aria-hidden="true" />
                        <span>You can&apos;t undo this action.</span>
                    </div>
                </SBox>
            </div>
            <div slot="footer" className="flex right">
                <button
                    className="secondary small"
                    onClick={() => props.setIsRemoveRegionDialogOpen(false)}>
                    No, cancel
                </button>
                <button className={deleteButton} type="button" onClick={removeRegion}>
                    Yes, remove the region
                </button>
            </div>
        </SDialog>
    );
}

export default RemoveRegionDialog;
