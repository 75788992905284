import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { buildURL } from "../shared/Utils";
import toast from "../hooks/toast";

export const productCategoriesSlice = createSlice({
    name: "productCategories",
    initialState: {
        isLoading: false,
        page: {
            data: [],
            paginate: {
                pageNumber: 0,
                startIndex: -1,
                endIndex: 0,
                rowsPerPage: 0,
                totalRecords: 0,
            },
        },
        categories: [],
        sortColumn: ["modified", true],
        noDataType: "",
        postError: "",
        avaProductCategories: [],
        isLoadingAvaProductCategories: false,
        clientProductCategories: [],
        certificateProductCategories: [],
    },
    reducers: {
        setLoading: (state, action) => {
            state.isloading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setClientProductCategories: (state, action) => {
            state.clientProductCategories = action.payload;
        },
        setCertificateProductCategories: (state, action) => {
            state.certificateProductCategories = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setNoDataType: (state, action) => {
            state.noDataType = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setAvaProductCategories: (state, action) => {
            state.avaProductCategories = action.payload;
        },
        setIsLoadingAvaProductCategories: (state, action) => {
            state.isLoadingAvaProductCategories = action.payload;
        },
    },
});

export const {
    setLoading,
    setPage,
    setPageToNull,
    setSortColumn,
    setNoDataType,
    setAvaProductCategories,
    setIsLoadingAvaProductCategories,
    setClientProductCategories,
    setCertificateProductCategories,
} = productCategoriesSlice.actions;

export const selectPage = state => {
    return state.productCategories.page;
};

export const selectSortColumn = state => {
    return state.productCategories.sortColumn;
};

export const selectIsLoading = state => {
    return state.productCategories.isloading;
};

export const selectIsLoadingAvaProductCategories = state => {
    return state.productCategories.isLoadingAvaCat;
};
export const selectNoData = state => {
    return state.productCategories.noDataType;
};

export const selectAvaProductCategories = state => {
    return state.productCategories.avaProductCategories;
};

export const selectClientProductCategories = state => {
    return state.productCategories.clientProductCategories;
};

export const selectCertificateProductCategories = state => {
    return state.productCategories.certificateProductCategories;
};

const [showToast] = toast();

export const fetchAvaTaxCategoriesAsync = () => async (dispatch, getState) => {
    const { isLoadingAvaProductCategories } = getState().exemptionMatrix;

    if (isLoadingAvaProductCategories) {
        return;
    }

    dispatch(setIsLoadingAvaProductCategories(true));

    const response = await axios.get(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/product-exemptions/categories`,
        { withCredentials: true }
    );

    if (response.data) {
        dispatch(setAvaProductCategories(response.data));
    }

    dispatch(setIsLoadingAvaProductCategories(false));
};

export const fetchClientProductCategories = () => async (dispatch, getState) => {
    const { isLoading } = getState().productCategories;

    if (isLoading) {
        return;
    }
    dispatch(setLoading(true));

    const apiURI = buildURL(
        `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/exempt-product-categories`,
        "",
        "",
        500,
        ""
    );

    const response = await axios.get(`${apiURI}`, { withCredentials: true });
    dispatch(setClientProductCategories(response.data.value));
};

export const fetchCertificateProductCategories = certId => async (dispatch, getState) => {
    const { isLoading } = getState().productCategories;

    if (isLoading) {
        return;
    }
    dispatch(setLoading(true));

    const apiURI = buildURL(
        `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/certificates/${certId}/exempt-product-categories`,
        "",
        "",
        "",
        ""
    );

    const response = await axios.get(`${apiURI}`, { withCredentials: true });
    dispatch(setCertificateProductCategories(response.data));
};
export const fetchCategoriesAsync =
    (top = 10, skip = 0, onLanding, paginateDetails) =>
    async (dispatch, getState) => {
        const { isLoading } = getState().productCategories;

        if (isLoading) {
            return;
        }
        dispatch(setLoading(true));

        const apiURI = buildURL(
            `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/exempt-product-categories`,
            "",
            "",
            top,
            skip
        );

        const response = await axios.get(`${apiURI}`, { withCredentials: true });
        if (response?.data[0]?.code) {
            onLanding ? dispatch(setNoDataType("onLanding")) : dispatch(setNoDataType("onSearch"));
            dispatch(setPageToNull());
        } else if (response?.data) {
            if (response?.data.count > 0) {
                dispatch(setNoDataType(""));
                const paginateData = paginateDetails
                    ? {
                          pageNumber: paginateDetails.currentPage,
                          startIndex: paginateDetails.startIndex,
                          endIndex: paginateDetails.endIndex,
                          rowsPerPage: paginateDetails.rowsPerPage,
                          totalRecords: response.data.count,
                      }
                    : {
                          pageNumber: 1,
                          startIndex: 0,
                          endIndex: 19,
                          rowsPerPage: 10,
                          totalRecords: response.data.count,
                      };
                dispatch(
                    setPage({
                        data: response.data.value,
                        paginate: paginateData,
                    })
                );
            } else {
                dispatch(setNoDataType(true));
            }
        } else {
            dispatch(setPageToNull([]));
        }
        dispatch(setLoading(false));
    };

export const productCategoryCertificateCountAsync = categoryName => async () => {
    const response = await axios.get(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/product-exemption-certificates-count`,
        {
            params: {
                category: categoryName,
            },
            withCredentials: true,
        }
    );

    return response.data;
};

export const deleteProductCategoryAsync = categoryName => async dispatch => {
    const response = await axios.delete(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/exempt-product-categories`,
        {
            data: {
                category: categoryName,
            },
            withCredentials: true,
        }
    );
    if (response?.data?.success) {
        showToast("success", `Category deleted successfully.`, "deleteProductCategoryToast", false);
    } else {
        showToast(
            "error",
            `It seems your category wasn't deleted. Try deleting it again.`,
            "deleteProductCategoryToast",
            false
        );
    }
    dispatch(fetchCategoriesAsync());
    return response;
};

export const updateProductCategoryAsync = putData => async dispatch => {
    dispatch(setLoading(true));
    const response = await axios.put(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/exempt-product-categories`,
        putData,
        { withCredentials: true }
    );
    if (response?.data?.success) {
        showToast("success", `Category updated.`, "updateProductCategoryToast", false);
    } else {
        showToast(
            "error",
            `It seems your changes didn't save. Try saving again.`,
            "updateProductCategoryToast",
            false
        );
    }
    dispatch(fetchCategoriesAsync());
    dispatch(setLoading(false));
    return response;
};
export default productCategoriesSlice.reducer;
