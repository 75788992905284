import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { SLoader } from "@avalara/skylab-react";
import "./retailSettings.css";
import {
    fetchRetailSettings,
    fetchPurchaserSettings,
    selectRetailSettings,
    selectPurchaserSettings,
    selectIsLoadingPurchaserSettings,
    selectIsLoadingRetailSettings,
} from "../../../../app/retailSettingsSlice";
import EditRetailSettings from "./EditRetailSettings";
import FeatureToggler from "../../../../featureToggler/FeatureToggler";
import featureFlag from "../../../../featureToggler/featureFlag";
import { isEligibleUser } from "../../../../shared/sessionUtility";
import ZoomTip from "../../../sharedComponents/ZoomTip";

const ViewRetailSettings = React.memo(() => {
    const dispatch = useDispatch();
    const retailSettings = useSelector(selectRetailSettings, shallowEqual);
    const purchaserSettings = useSelector(selectPurchaserSettings, shallowEqual);
    const isLoadingRetailSettings = useSelector(selectIsLoadingRetailSettings);
    const isLoadingPurchaserSettings = useSelector(selectIsLoadingPurchaserSettings);
    const isLoading = isLoadingRetailSettings || isLoadingPurchaserSettings;
    const [openEditSettings, setOpenEditSettings] = useState(false);

    const isUserEligibleEditRetailSettings = !dispatch(
        isEligibleUser(featureFlag.settings.editRetailLocations)
    );

    useEffect(() => {
        dispatch(fetchRetailSettings());
        dispatch(fetchPurchaserSettings());
    }, [dispatch]);

    return (
        <>
            <h3 className="margin-bottom-none">Retail certificate and purchaser settings</h3>
            <p className="margin-top-none">
                These certificate features and purchaser information settings control what your
                users can view, create and modify when using the ECM retail information at your
                retail sites.
            </p>
            {openEditSettings && <EditRetailSettings setOpenEditSettings={setOpenEditSettings} />}
            {!openEditSettings &&
                (isLoading ? (
                    <div className="flex dl-flex-fill-height dl-flex-center">
                        <h3>Loading ...</h3>
                        <SLoader id="page-loader" className="medium" aria-live="polite" loading />
                    </div>
                ) : (
                    <>
                        <FeatureToggler category="settings" id="editRetailLocations">
                            <div>
                                <button
                                    className="ghost-blue icon-leading pad-left-none font-bold"
                                    onClick={() => {
                                        setOpenEditSettings(true);
                                    }}
                                    disabled={isUserEligibleEditRetailSettings}>
                                    <s-icon aria-hidden="true" name="edit-pencil" />
                                    Make changes
                                </button>
                            </div>
                        </FeatureToggler>
                        <div className="pad-top-md">
                            <s-table-container tabindex="0">
                                <table
                                    className="table-layout-fixed fixed-column"
                                    role="presentation">
                                    <thead>
                                        <tr>
                                            <th className="gray-border" colSpan="2">
                                                Retail certificate features
                                            </th>
                                            <th className="gray-border" colSpan="4">
                                                Purchaser information collected
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="gray-border">Valid settings</th>
                                            <th className="gray-border">Invalid settings</th>
                                            <th className="gray-border col-1">Required fields</th>
                                            <th className="gray-border col-4">
                                                Preferred fields
                                                <span className="zoom-tip">
                                                    <ZoomTip
                                                        tipTitle="Preferred fields"
                                                        url="bundle/pah1663778507797/page/kxw1723815692502.html "
                                                    />
                                                </span>
                                            </th>
                                            <th className="gray-border">Optional fields</th>
                                            <th className="gray-border col-6">
                                                Editable
                                                <span className="zoom-tip">
                                                    <ZoomTip
                                                        tipTitle="Editable fields"
                                                        url="bundle/pah1663778507797/page/pyn1723815705857.html "
                                                    />
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {retailSettings && retailSettings.length > 0 ? (
                                                <>
                                                    <td className="gray-border col-1">
                                                        {retailSettings
                                                            ?.filter(
                                                                x =>
                                                                    x?.clientsRetailSettings?.[0]
                                                                        ?.value
                                                            )
                                                            .map(x => (
                                                                <p key={uuidv4()}>
                                                                    {x.readableOption}
                                                                </p>
                                                            ))}
                                                    </td>
                                                    <td className="gray-border">
                                                        {retailSettings
                                                            ?.filter(
                                                                x =>
                                                                    !x?.clientsRetailSettings?.[0]
                                                                        ?.value
                                                            )
                                                            .map(x => (
                                                                <p key={uuidv4()}>
                                                                    {x.readableOption}
                                                                </p>
                                                            ))}
                                                    </td>
                                                </>
                                            ) : null}
                                            {purchaserSettings && purchaserSettings.length > 0 ? (
                                                <>
                                                    <td className="gray-border col-1">
                                                        {purchaserSettings
                                                            ?.filter(
                                                                x =>
                                                                    x.requirementLevel ===
                                                                    "Required"
                                                            )
                                                            .map(x => (
                                                                <p key={uuidv4()}>
                                                                    {x.purchaserSettingName}
                                                                </p>
                                                            ))}
                                                    </td>
                                                    <td className="gray-border col-4">
                                                        {purchaserSettings
                                                            ?.filter(
                                                                x =>
                                                                    x.requirementLevel ===
                                                                    "Preferred"
                                                            )
                                                            .map(x => (
                                                                <p key={uuidv4()}>
                                                                    {x.purchaserSettingName}
                                                                </p>
                                                            ))}
                                                    </td>
                                                    <td className="gray-border">
                                                        {purchaserSettings
                                                            ?.filter(
                                                                x =>
                                                                    x.requirementLevel ===
                                                                    "Optional"
                                                            )
                                                            .map(x => (
                                                                <p key={uuidv4()}>
                                                                    {x.purchaserSettingName}
                                                                </p>
                                                            ))}
                                                    </td>
                                                    <td className="gray-border col-6">
                                                        {purchaserSettings
                                                            ?.filter(x => !x.disabled)
                                                            .map(x => (
                                                                <p key={uuidv4()}>
                                                                    {x.purchaserSettingName}
                                                                </p>
                                                            ))}
                                                    </td>
                                                </>
                                            ) : null}
                                        </tr>
                                    </tbody>
                                </table>
                            </s-table-container>
                        </div>
                    </>
                ))}
        </>
    );
});

export default ViewRetailSettings;
