import React, { useEffect, useState } from "react";
import { SDialog, SRow, SLoader, SAlert } from "@avalara/skylab-react";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import axios from "../../axios";

function RemoveUnRelatedRegionsDialog(props) {
    let { customerIds, regionIdName } = props;
    customerIds = JSON.parse(customerIds);
    regionIdName = JSON.parse(regionIdName);
    const {
        companyRegionSetting,
        customerRegionSetting,
        removeExposureZoneById,
        isRemoveRegionDialogOpen,
        setIsRemoveRegionDialogOpen,
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [companyRegionsToRemove, setCompanyRegionsToRemove] = useState([]);
    const [customerRegionsToRemove, setCustomerRegionsToRemove] = useState([]);
    const [disableRemoveRegionButton, setDisableRemoveRegionButton] = useState(false);

    const confirmButton = classnames({
        primary: true,
        small: true,
        loading: isLoading,
    });

    const removeRegions = async () => {
        setIsLoading(true);
        let regionsToRemove = [];
        if (companyRegionSetting) regionsToRemove = companyRegionsToRemove.map(x => x.id);
        if (customerRegionSetting && customerIds.length)
            regionsToRemove = regionsToRemove.concat(
                customerRegionsToRemove.filter(x => !regionsToRemove.includes(x.id)).map(x => x.id)
            );
        removeExposureZoneById(regionsToRemove);
        setIsLoading(false);
        setIsRemoveRegionDialogOpen(false);
    };

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const response = await axios.post(
                `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customer-company-regions`,
                { customerIds },
                { withCredentials: true }
            );

            const companyRegions = response?.data?.companyRegions;
            let companyUnRelatedRegions = [];
            if (companyRegionSetting) {
                companyUnRelatedRegions = regionIdName.filter(
                    zone => !companyRegions.includes(parseInt(zone.id, 10))
                );
                setCompanyRegionsToRemove(companyUnRelatedRegions);
            }

            const customerRegions = response?.data?.customerRegions;
            let customerUnRelatedRegions = [];
            if (customerIds.length && customerRegionSetting) {
                customerUnRelatedRegions = regionIdName.filter(
                    zone => !customerRegions.includes(parseInt(zone.id, 10))
                );
                setCustomerRegionsToRemove(customerUnRelatedRegions);
            }

            if (companyUnRelatedRegions.length === 0 && customerUnRelatedRegions.length === 0)
                setDisableRemoveRegionButton(true);
            setIsLoading(false);
        })();
    }, []);

    let companyJsx = null;
    if (companyRegionsToRemove.length) {
        companyJsx = (
            <>
                <SRow class="font-semibold pad-left-sm">
                    Regions not among the company&apos;s certificate regions
                </SRow>
                <SRow class="pad-left-xl">
                    <ul>
                        {companyRegionsToRemove.map(x => {
                            return <li key={uuidv4()}>{x.name}</li>;
                        })}
                    </ul>
                </SRow>
            </>
        );
    } else {
        companyJsx = (
            <SRow class="pad-left-sm pad-top-sm">
                <SAlert id="company-info-alert" status="info" noDismiss>
                    <div>There are no company&apos;s certificate regions to remove.</div>
                </SAlert>
            </SRow>
        );
    }
    let customerJsx = null;
    if (customerRegionsToRemove.length) {
        customerJsx = (
            <>
                <SRow class="font-semibold pad-left-sm">
                    Regions not among the customer&apos;s tax regions
                </SRow>
                <SRow class="pad-left-xl">
                    <ul>
                        {customerRegionsToRemove.map(x => {
                            return <li key={uuidv4()}>{x.name}</li>;
                        })}
                    </ul>
                </SRow>
            </>
        );
    } else {
        customerJsx = (
            <SRow class="pad-left-sm">
                <SAlert id="customer-info-alert" status="info" noDismiss>
                    <div>There are no customer&apos;s tax regions to remove.</div>
                </SAlert>
            </SRow>
        );
    }

    let body = null;
    if (isLoading) {
        body = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        );
    } else {
        body = (
            <>
                <SRow class="pad-left-sm pad-bottom-xs">
                    Confirm that you want to remove these regions from the certificate.
                </SRow>
                {customerRegionSetting ? customerJsx : null}
                {companyRegionSetting ? companyJsx : null}
            </>
        );
    }

    return (
        <SDialog
            id="remove-unrelated-regions-dialog"
            open={isRemoveRegionDialogOpen}
            onS-dismiss={() => {
                setIsRemoveRegionDialogOpen(false);
            }}
            aria-modal="true">
            <div slot="header" id="removeRegions">
                Remove these regions?
            </div>
            <div slot="body">{body}</div>
            <div slot="footer">
                <button
                    className="secondary small"
                    onClick={() => setIsRemoveRegionDialogOpen(false)}>
                    Cancel
                </button>
                <button
                    disabled={disableRemoveRegionButton}
                    className={confirmButton}
                    type="button"
                    onClick={() => removeRegions()}>
                    Yes, remove the regions
                </button>
            </div>
        </SDialog>
    );
}

export default RemoveUnRelatedRegionsDialog;
