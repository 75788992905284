import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid, gridHeader } from "@certcapture/react-components";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SPagination, SLoader } from "@avalara/skylab-react";
import { v4 as uuidv4 } from "uuid";
import RecordCount from "../RecordCount";
import VendorPackagesDeleteDialog from "./VendorPackagesDeleteDialog";

import {
    selectPage,
    selectSortColumn,
    setPageToNull,
    fetchVendorPackagesAsync,
    selectNoData,
    setSortColumn,
    selectIsLoading,
} from "../../../app/vendorPackagesSlice";

const VendorPackagesGrid = React.memo(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector(selectIsLoading);
    const noDataType = useSelector(selectNoData);
    const [submitting, setSubmitting] = useState(false);
    const page = useSelector(selectPage, shallowEqual);
    const pageData = page?.data;
    const paginateData = page.paginate;
    const sortColumn = useSelector(selectSortColumn);
    const [packageToDelete, setPackageToDelete] = useState(null);
    const [openPackageDelDialog, setOpenPackageDelDialog] = useState(false);

    useEffect(() => {
        dispatch(fetchVendorPackagesAsync(10, 0));
        return () => {
            dispatch(setPageToNull([]));
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            dispatch(
                fetchVendorPackagesAsync(e.detail.rowsPerPage, e.detail.startIndex, false, e.detail)
            );
            setSubmitting(false);
        }
    };

    async function sortFunction(e) {
        setSubmitting(true);
        const columnId = e.detail.id;
        const order = sortColumn[0] === columnId ? !sortColumn[1] : true;
        dispatch(setSortColumn([columnId, order]));
        dispatch(fetchVendorPackagesAsync(10, 0, false));
        setSubmitting(false);
    }

    const deleteHandler = packageID => {
        setPackageToDelete(packageID);
        setOpenPackageDelDialog(true);
    };

    const reloadHandler = () => {
        dispatch(fetchVendorPackagesAsync());
    };

    const columns = [
        gridHeader(
            "Package Name",
            "packageName",
            value => value,
            null,
            null,
            null,
            null,
            sortFunction,
            "packageName"
        ),
        gridHeader(
            "Package Description",
            "packageDescription",
            value => value,
            null,
            null,
            null,
            null,
            null,
            "packageDescription"
        ),
        gridHeader(
            "Last Modified",
            "modified",
            value => new Date(value).toLocaleDateString(),
            null,
            null,
            null,
            null,
            sortFunction,
            "modified"
        ),
        gridHeader(
            "",
            "",
            (value, row) => (
                <div>
                    <button
                        type="button"
                        className="ghost-blue small"
                        onClick={() => {
                            navigate(`/send-package/${row.id}`, {
                                state: { package: row },
                            });
                        }}>
                        Send
                    </button>
                    <button
                        type="button"
                        className="ghost-blue small"
                        onClick={() => {
                            navigate(`/search/vendor-packages/${row.id}`, {
                                state: { package: row },
                            });
                        }}>
                        Edit
                    </button>
                    <button
                        type="button"
                        className="ghost-blue small"
                        onClick={() => {
                            deleteHandler(row.id);
                        }}>
                        Delete
                    </button>
                </div>
            ),
            "text-right",
            "text-right",
            null
        ),
    ];

    const getRowKey = () => {
        return uuidv4();
    };

    const noDataMsg = noDataType ? "You don't have any vendor packages" : null;

    let table = "";

    if (submitting || loading) {
        table = (
            <div className="flex flex-dir-col dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="grid-loader" className="medium" aria-live="polite" loading />
            </div>
        );
    } else if (noDataType) {
        table = (
            <div className="margin-top-xl pad-top-xl">
                <div id="no-certificate-request-found">
                    <div className="font-semibold font-normal text-lg text-center">
                        <h1 className="grid-header-none">{noDataMsg}</h1>
                    </div>
                </div>
            </div>
        );
    } else
        table = (
            <div className="margin-top-sm">
                <RecordCount
                    selectedCount={0}
                    recordCount={paginateData.totalRecords}
                    recordName="certificate packages"
                />
                <s-table-container key={uuidv4()} className="margin-top-xl">
                    <DataGrid
                        columns={columns}
                        rows={pageData}
                        getKey={getRowKey}
                        loading={false}
                        sortColumn={sortColumn}
                        inContainer
                    />
                </s-table-container>
            </div>
        );

    return (
        <React.Fragment>
            {table}
            {paginateData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={paginateData.rowsPerPage}
                    totalRecords={paginateData.totalRecords}
                    startIndex={paginateData.startIndex}
                    onS-paginate={e => {
                        handlePaginate(e);
                    }}
                />
            ) : null}

            <VendorPackagesDeleteDialog
                reloadPage={reloadHandler}
                packageId={packageToDelete}
                openPaDelDiag={openPackageDelDialog}
                closePaDelDiag={setOpenPackageDelDialog}
            />
        </React.Fragment>
    );
});

export default VendorPackagesGrid;
