import React, { useEffect, useState } from "react";
import { SCol, SDialog, SIcon, SRow, SSelect } from "@avalara/skylab-react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { documentTypeNames } from "../../../../shared/constants";
import {
    AddOrUpdateExemptionReason,
    fetchEntityUseCodeMapping,
    selectEntityUseCodeMappings,
    selectIsAddOrEditLoading,
    selectIsLoadingEntityUseCodeMappings,
} from "../../../../app/exemptionReasonSlice";
import "../manageExemptionReasonsStyle.css";
import { isEligibleUser } from "../../../../shared/sessionUtility";
import featureFlag from "../../../../featureToggler/featureFlag";
import { errorTag, noErrorTag } from "../../../../shared/Utils";

function CreateUpdateExemptionReason(props) {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsAddOrEditLoading);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [entityUseCode, setEntityUseCode] = useState("");
    const [exemptionOverride, setExemptionOverride] = useState(false);
    const [nameError, setNameError] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [entityUseCodeError, setEntityUseCodeError] = useState("");
    const isLoadingEntityUseCodeMappings = useSelector(selectIsLoadingEntityUseCodeMappings);
    const entityUseCodeMappings = useSelector(selectEntityUseCodeMappings);
    const [enabled, setEnabled] = useState(false);
    const [systemCode, setSystemCode] = useState(false);

    const entityUseCodeSelectClass = classnames({
        error: entityUseCodeError,
        warning: !entityUseCodeError && entityUseCode === "no-assign",
    });
    const [inUse, setInUse] = useState(false);

    const isEligibleUserSettingsAddOrEditExemptionReason =
        dispatch(isEligibleUser(featureFlag.settings.addExemptionReason)) ||
        dispatch(isEligibleUser(featureFlag.settings.editExemptionReason));

    useEffect(() => {
        setName(props.addOrEdit === "edit" ? props.name : "");
        setDescription(props.addOrEdit === "edit" ? props.description : "");
        setExemptionOverride(props.addOrEdit === "edit" ? props.exemptionOverride : false);
        setEnabled(props.addOrEdit === "edit" ? props.enabled : false);
        setSystemCode(props.addOrEdit === "edit" ? props.systemCode : false);
        setInUse(props.addOrEdit === "edit" ? props.inUse : false);
        setEntityUseCode(props.addOrEdit === "edit" ? props.entityUseCode : "no-assign");
    }, [
        props.name,
        props.description,
        props.exemptionOverride,
        props.enabled,
        props.systemCode,
        props.inUse,
        props.entityUseCode,
    ]);

    useEffect(() => {
        dispatch(fetchEntityUseCodeMapping());
    }, []);

    const handleNameChange = value => {
        setName(value);
        if (value === "") setNameError("error");
        else setNameError("");
    };

    const handleDescriptionChange = value => {
        setDescription(value);
        if (value === "") setDescriptionError("error");
        else setDescriptionError("");
    };

    const handleCloseDialog = () => {
        setNameError("");
        setDescriptionError("");
        setEntityUseCodeError("");
        setDescriptionError("");
        setExemptionOverride(false);
        setName("");
        setDescription("");
        setEntityUseCode("");
        props.closeDialog(false);
    };

    const handleEntityUseCodeChange = value => {
        if (props.inUse && props.entityUseCode && value === "no-assign") {
            setEntityUseCodeError("error");
        } else {
            setEntityUseCodeError("");
        }
        setEntityUseCode(value);
    };

    const handleSave = async () => {
        if (!entityUseCodeError) {
            if (!name || !description) {
                if (name === "") setNameError("error");
                if (description === "") setDescriptionError("error");
            } else {
                const payload = {
                    Name: name,
                    Description: description,
                    IsOverride: exemptionOverride,
                    DocumentTypeName: documentTypeNames.salesAndUseTax,
                    Outgoing: false,
                    Enabled: enabled,
                };
                let res;
                if (props.addOrEdit === "add") {
                    res = await dispatch(
                        AddOrUpdateExemptionReason(
                            "add",
                            payload,
                            null,
                            entityUseCode,
                            props.entityUseCode
                        )
                    );
                } else {
                    res = await dispatch(
                        AddOrUpdateExemptionReason(
                            "edit",
                            payload,
                            props.taxCodeId,
                            entityUseCode,
                            props.entityUseCode
                        )
                    );
                }
                if (res) handleCloseDialog();
            }
        }
    };

    const getEntityUseCodeMappingOptionList = () => {
        const optionList = [];
        const added = [];

        entityUseCodeMappings?.forEach(element => {
            if (!added.includes(element.entityUseCode)) {
                optionList.push({
                    label: `${element.entityUseCode}`,
                    value: element.entityUseCode,
                    selected: element.entityUseCode === entityUseCode,
                });
                added.push(element.entityUseCode);
            }
        });

        optionList.push({
            label: "Don’t assign an entity use code for tax exemption purposes",
            value: "no-assign",
            selected: entityUseCode === "no-assign" || entityUseCode === "",
        });

        return optionList;
    };

    let notificationMsg = "in use.";
    if (systemCode) {
        notificationMsg = "a system exempt reason.";
    }

    return (
        <SDialog
            open={props.openDialog}
            id="create-update-exempt-reason-dialog"
            onS-dismiss={handleCloseDialog}
            className="overflow-visible"
            aria-modal="true">
            <div slot="header" id="createUpdateExemptReason" className="inline">
                {props.addOrEdit === "add" ? (
                    <span>Add an exempt reason</span>
                ) : (
                    <span>Update an exempt reason</span>
                )}
            </div>
            <div slot="body">
                {systemCode || inUse ? (
                    <>
                        <SRow>
                            <SCol>
                                The name and description of this exempt reason can&apos;t be changed
                                because the reason is {notificationMsg}
                            </SCol>
                        </SRow>
                        <SRow className="margin-top-md">
                            <SCol span="2">
                                <span className="font-bold">Exempt reason</span>
                            </SCol>
                            <SCol span="10">
                                <span>{name}</span>
                            </SCol>
                        </SRow>
                        <SRow>
                            <SCol span="2">
                                <span className="font-bold">Description</span>
                            </SCol>
                            <SCol span="10">
                                <span>{description}</span>
                            </SCol>
                        </SRow>
                    </>
                ) : (
                    <>
                        <SRow>
                            <SCol>
                                <label htmlFor="exempt-reason-name" className="required">
                                    Give this reason a name
                                </label>
                                <input
                                    id="exempt-reason-name"
                                    name="exempt-reason-name"
                                    type="text"
                                    onChange={e => handleNameChange(e.target.value)}
                                    value={name}
                                    className={nameError ? errorTag : noErrorTag}
                                />
                                <div className="input-msg">
                                    <SIcon name="alert-circle-filled" aria-hidden="true" />
                                    <span className="top-xs">Enter name</span>
                                </div>
                            </SCol>
                        </SRow>
                        <SRow className="margin-top-md">
                            <SCol>
                                <label htmlFor="exempt-reason-desc" className="required">
                                    Description
                                </label>
                                <input
                                    name="exempt-reason-desc"
                                    id="exempt-reason-desc"
                                    type="text"
                                    onChange={e => handleDescriptionChange(e.target.value)}
                                    value={description}
                                    className={descriptionError ? errorTag : noErrorTag}
                                />
                                <div className="input-msg">
                                    <SIcon name="alert-circle-filled" aria-hidden="true" />
                                    <span className="top-xs">Enter description</span>
                                </div>
                            </SCol>
                        </SRow>
                    </>
                )}
                {props.matchSubscriptions(props.session.subscriptions, props.avaTaxSubscription) ? (
                    <SRow className="margin-top-md">
                        <SCol>
                            <label htmlFor="entity-use-code" className="required">
                                Entity use code
                            </label>
                            <SSelect
                                selectionOptional
                                name="entity-use-code"
                                id="entity-use-code"
                                loading={isLoadingEntityUseCodeMappings}
                                optionsList={getEntityUseCodeMappingOptionList()}
                                onS-select={e => handleEntityUseCodeChange(e.detail.item.value)}
                                className={entityUseCodeSelectClass}
                                aria-describedby="validation-msg-id"
                                required
                            />

                            {!entityUseCodeError && entityUseCode === "no-assign" ? (
                                <div id="validation-msg-id" className="input-msg">
                                    <SIcon name="alert-triangle-filled" aria-hidden="true" />
                                    <span className="top-xs">
                                        AvaTax won&apos;t exempt transactions with this reason when
                                        no entity use code is assigned.
                                    </span>
                                </div>
                            ) : null}
                            {entityUseCodeError ? (
                                <div className="input-msg">
                                    <SIcon name="alert-circle-filled" aria-hidden="true" />
                                    <span className="top-xs">
                                        Must be assigned to an entity use code because this reason
                                        is in use.
                                    </span>
                                </div>
                            ) : null}
                        </SCol>
                    </SRow>
                ) : null}
                <SRow className="margin-top-md">
                    <SCol>
                        <input
                            id="exemption-override"
                            name="exemption-override"
                            type="checkbox"
                            aria-label="Use as an exemption override"
                            onChange={() =>
                                exemptionOverride
                                    ? setExemptionOverride(false)
                                    : setExemptionOverride(true)
                            }
                            checked={exemptionOverride}
                        />
                        <label htmlFor="exemption-override">Use as an exemption override</label>
                    </SCol>
                </SRow>
            </div>
            <div slot="footer">
                <button className="tertiary" onClick={handleCloseDialog}>
                    Cancel
                </button>
                <button
                    className={isLoading ? "primary right loading" : "primary right"}
                    onClick={handleSave}
                    disabled={!isEligibleUserSettingsAddOrEditExemptionReason}>
                    Save
                </button>
            </div>
        </SDialog>
    );
}

export default CreateUpdateExemptionReason;
