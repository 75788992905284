import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { SCol, SRow, SSelect } from "@avalara/skylab-react";
import classnames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import SelectCoverLetter from "../../sharedComponents/SelectCoverLetter";
import CustomerTypeAhead from "../../sharedComponents/CustomerTypeAhead";
import axios from "../../../axios";
import toast from "../../../hooks/toast";
import { selectCoverLetterPreview } from "../../../app/coverLetterSlice";
import { getPageTitle } from "../../../shared/sessionUtility";
import { selectSession } from "../../../app/sessionSlice";
import DeliveryTypeList from "../../certificates/exports/DeliveryTypeList";
import VendorRequestValidationError from "../../requests/vendorRequests/vendorRequestValidationError";
import { fetchSignatureData, selectSignatureData, selectSetting } from "../../../app/settingsSlice";
import MissingSignatureDialog from "./MissingSignatureDialog";

const SendPackage = React.memo(() => {
    const location = useLocation();
    const navigate = useNavigate();
    const session = useSelector(selectSession, shallowEqual);
    const dispatch = useDispatch();
    const [vendors, setVendors] = useState([]);
    const [vendorError, setVendorError] = useState("");
    const [showToast] = toast();
    const [vendorDataValidation, setVendorDataValidation] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [noEmail, setNoEmail] = useState(true);
    const coverLetter = useSelector(selectCoverLetterPreview, shallowEqual);
    const [deleteAll, setDeleteAll] = useState(false);
    const [vendorLock, setVendorLock] = useState(true);
    const setting = useSelector(selectSetting, shallowEqual);
    const sigData = useSelector(selectSignatureData, shallowEqual);
    const [sigError, setSigError] = useState(false);

    useEffect(() => {
        if (setting.id !== undefined) {
            dispatch(fetchSignatureData(setting.id));
        }
    }, [dispatch, setting.id]);

    useEffect(() => {
        if (!sigData) {
            setSigError(true);
        }
    }, [dispatch, sigData]);

    const nextButton = classnames({
        primary: true,
        large: true,
        loading: submitting,
    });

    const [deliveryType, setDeliveryType] = useState("");
    const [deliveryTypeError, setDeliveryTypeError] = useState("valid");

    const cancelButton = classnames({ tertiary: true, large: true });

    const handleDeliveryChange = detail => {
        setVendorLock(false);
        setDeleteAll(false);
        if (deliveryTypeError) {
            setDeliveryTypeError("valid");
        }
        setDeliveryType(detail.value);
    };

    const handleDeliveryRemoval = () => {
        if (deliveryTypeError) {
            setDeliveryTypeError("valid");
        }
        setVendorLock(true);
        setDeliveryType("");
        setDeleteAll(true);
    };

    const uniqueVendor = (prevVendor, newVendor) => {
        const prevVendorIds = prevVendor.map(ele => ele.id);
        return [
            ...prevVendor,
            ...newVendor.filter(vendor => prevVendorIds.indexOf(vendor.id) === -1),
        ];
    };

    const addVendor = useCallback(
        value => {
            if (!value[0].emailAddress && deliveryType === "E") {
                showToast(
                    "error",
                    `The vendor selected has no email address on file. The package will not be emailed to '${value[0].name}'.`
                );
            } else {
                setVendors(prevVendors => uniqueVendor(prevVendors, value));
                setNoEmail(false);
            }
            setVendorError("");
        },
        [deliveryType]
    );

    const removeVendor = useCallback(e => {
        setVendors(prevVendor => {
            const vendorsClone = prevVendor.slice();
            vendorsClone.splice(
                vendorsClone.findIndex(ele => ele.id === parseInt(e.value, 10)),
                1
            );
            return vendorsClone;
        });
        setNoEmail(true);
    }, []);

    const setVendorDataValidationValue = useCallback(value => {
        setVendorDataValidation(value);
    }, []);

    const validationHandler = async () => {
        let formValid = true;

        if (vendors && vendors.length === 0) {
            setVendorError("error");
            formValid = false;
        }

        if (!deliveryType || deliveryType === "") {
            setDeliveryTypeError("error");
            formValid = false;
        }

        if (vendorDataValidation) {
            formValid = false;
        }

        if (!formValid) {
            return;
        }

        setSubmitting(true);
        const filteredVendorIds = vendors.map(value => {
            return value.id;
        });

        await axios
            .post(
                `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/send-vendor-packages-tracking`,
                {
                    packageId: location.state.package.id,
                    vendorIds: filteredVendorIds,
                    status: "P",
                    deliveryType,
                    aiUserName: session.username,
                    sentByAiUserId: session.userId,
                },
                {
                    withCredentials: true,
                }
            )
            .then(async () => {
                await axios
                    .post(
                        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/vendor-packages/send-package`,
                        {
                            packageId: location.state.package.id,
                            vendorIds: filteredVendorIds,
                            coverLetterId: coverLetter.id,
                        },
                        {
                            withCredentials: true,
                        }
                    )
                    .then(() => {
                        setSubmitting(false);
                        showToast("success", "Certificate package queued successfully.");
                        navigate("/search/vendor-packages");
                    });
            });
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Send Package"))}</title>
            </Helmet>
            <SRow>
                <SCol span="11">
                    <h1 className="margin-bottom-xl">Send {location.state.package.packageName}</h1>
                </SCol>
            </SRow>
            <SRow className="margin-bottom-md">
                <SCol span="5">
                    <SelectCoverLetter label="Cover Letter" outgoing />
                </SCol>
            </SRow>
            <SRow className="margin-bottom-md">
                <SCol span="5">
                    <label htmlFor="deliveryType" className="required" id="lbl-deliveryType">
                        Delivery method
                    </label>
                    <SSelect
                        selectionOptional
                        name="deliveryType"
                        inputId="deliveryType"
                        optionsList={DeliveryTypeList}
                        noSearch
                        onS-deselect={() => handleDeliveryRemoval()}
                        onS-select={e => handleDeliveryChange(e.detail.item)}
                        className={deliveryTypeError}
                    />
                    {deliveryTypeError === "error" ? (
                        <VendorRequestValidationError fieldName="delivery method" />
                    ) : null}
                </SCol>
            </SRow>
            <SRow>
                <SCol span="5">
                    <CustomerTypeAhead
                        queueDelete={deleteAll}
                        handleAdd={addVendor}
                        handleRemove={removeVendor}
                        vendor
                        error={vendorError}
                        value={vendors}
                        setCustomerDataValidation={setVendorDataValidationValue}
                        label="Select vendors to send this package to"
                        venDisable={vendorLock}
                    />
                </SCol>
            </SRow>
            <SRow>
                <SCol span="12">
                    <hr className="margin-bottom-xl margin-top-lg" />
                    <button
                        className={nextButton}
                        disabled={submitting || noEmail}
                        onClick={() => validationHandler("Valid")}>
                        Send
                    </button>
                    <button
                        onClick={() => {
                            navigate(`/search/vendor-certificates`);
                        }}
                        className={cancelButton}>
                        Cancel
                    </button>
                </SCol>
            </SRow>
            {sigError ? <MissingSignatureDialog setSigError={setSigError} sigError /> : null}
        </React.Fragment>
    );
});
export default SendPackage;
