import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SLoader, SPagination, SIcon } from "@avalara/skylab-react";
import { gridHeader, DataGrid } from "@certcapture/react-components";
import {
    fetchRetailLocations,
    selectPage,
    selectIsLoading,
    setPageToNull,
    selectSortColumn,
    setSortColumn,
} from "../../../../app/retailLocationSlice";
import AddEditRetailLocation from "./AddEditRetailLocation";
import DeleteRetailLocationDialog from "./DeleteRetailLocationDialog";
import RetailLocationDetailDialog from "./RetailLocationDetailDialog";
import EditRetailSettingDialog from "./EditRetailSettingDialog";
import { getStatesAsync, selectStates } from "../../../../app/commonSlice";
import FeatureToggler from "../../../../featureToggler/FeatureToggler";
import featureFlag from "../../../../featureToggler/featureFlag";
import { isEligibleUser } from "../../../../shared/sessionUtility";

function RetailLocationsGrid(props) {
    const dispatch = useDispatch();
    const states = useSelector(selectStates, shallowEqual);
    const [submitting, setSubmitting] = useState(false);
    const page = useSelector(selectPage, shallowEqual);
    const gridData = page.data;
    const pageData = page.paginate;
    const isLoading = useSelector(selectIsLoading);
    const sortColumn = useSelector(selectSortColumn, shallowEqual);
    const [location, setLocation] = useState({});
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);
    const [openEditSettingsDialog, setOpenEditSettingsDialog] = useState(false);
    const [acceptedRegionList, setAcceptedRegionList] = useState([]);
    const isUserEligibleDeleteRetailLocations = !dispatch(
        isEligibleUser(featureFlag.settings.deleteRetailLocations)
    );

    async function sortFunction(e) {
        setSubmitting(true);
        const column = e.detail.id;
        const order = sortColumn[0] === column ? !sortColumn[1] : true;
        dispatch(setSortColumn([column, order]));
        await dispatch(fetchRetailLocations(pageData.rowsPerPage, null, pageData));
        setSubmitting(false);
    }
    useEffect(() => {
        dispatch(fetchRetailLocations());
        return () => {
            dispatch(setPageToNull([]));
        };
    }, [dispatch]);
    useEffect(() => {
        dispatch(getStatesAsync(false, true));
        if (location?.acceptedRegions) {
            const stateIds = location?.acceptedRegions.map(item => item.stateId);
            setAcceptedRegionList(
                states
                    ?.filter(state => stateIds.includes(parseInt(state.id, 10)))
                    .map(state => state.name)
            );
        }
    }, [dispatch, location?.acceptedRegions]);
    const columns = [
        gridHeader("Site name", "name", value => value, null, "", null, null, sortFunction, "name"),
        gridHeader("Site code", "code", value => value, null, "", null, null, null, "code"),
        gridHeader(
            "State",
            "stateId",
            value => states.filter(state => state.id === value).map(state => state.name),
            null,
            "",
            null,
            null,
            null,
            "stateId"
        ),
        gridHeader(
            "Address",
            "addressLine1",
            (value, row) => `${row.addressLine1} ${row.addressLine2}`.substring(0, 50),
            null,
            "",
            null,
            null,
            null,
            "addressLine1"
        ),
        gridHeader(
            "",
            "",
            (value, row) => (
                <button
                    className="secondary"
                    onClick={() => {
                        setLocation(row);
                        setIsDetailDialogOpen(true);
                    }}
                    aria-label={`details-${row.name}`}>
                    Details
                </button>
            ),
            "text-center details-action-col",
            "text-center details-action-col"
        ),
        gridHeader(
            "",
            "",
            (value, row) => (
                <FeatureToggler category="settings" id="deleteRetailLocations">
                    <button
                        className="link font-bold"
                        onClick={() => {
                            setLocation(row);
                            setIsDeleteDialogOpen(true);
                        }}
                        aria-label={`delete-${row.name}`}
                        disabled={isUserEligibleDeleteRetailLocations}>
                        <SIcon name="trash" aria-hidden="true" title="delete" />
                    </button>
                </FeatureToggler>
            ),

            "text-center delete-action-col",
            "text-center delete-action-col"
        ),
    ];
    const getRowKey = row => {
        return row.id;
    };
    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && pageData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            dispatch(fetchRetailLocations(e.detail.rowsPerPage, e.detail.startIndex, e.detail));
            setSubmitting(false);
        }
    };
    const table =
        isLoading || submitting ? (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        ) : (
            <>
                <div className="margin-bottom-xs text-right">
                    {pageData.totalRecords} retail locations
                </div>
                <span className="clear" />
                <s-table-container>
                    <DataGrid
                        columns={columns}
                        rows={gridData}
                        loading={isLoading}
                        getKey={getRowKey}
                        inContainer
                        sortColumn={sortColumn}
                    />
                </s-table-container>
            </>
        );

    return (
        <>
            {!isLoading && pageData.totalRecords === 0 ? (
                <div className="text-center flex dl-flex-fill-height dl-flex-center">
                    <h1 className="text-color-no-data">No Location found</h1>
                </div>
            ) : (
                <React.Fragment>
                    {table}
                    {pageData.totalRecords > 0 ? (
                        <SPagination
                            className="margin-top-md"
                            rowsPerPage={pageData.rowsPerPage}
                            totalRecords={pageData.totalRecords}
                            startIndex={pageData.startIndex}
                            onS-paginate={e => {
                                handlePaginate(e);
                            }}
                        />
                    ) : null}

                    {isDeleteDialogOpen ? (
                        <DeleteRetailLocationDialog
                            isDeleteDialogOpen
                            setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                            locationId={location?.id}
                            setLocation={setLocation}
                        />
                    ) : null}
                    {isDetailDialogOpen ? (
                        <RetailLocationDetailDialog
                            isDetailDialogOpen
                            setIsDetailDialogOpen={setIsDetailDialogOpen}
                            location={location}
                            setOpenEditSettingsDialog={setOpenEditSettingsDialog}
                            openEditSettingsDialog={openEditSettingsDialog}
                            setOpenAddUpdateDialog={props.setOpenAddUpdateDialog}
                            openAddUpdateDialog={props.openAddUpdateDialog}
                            setAddOrEdit={props.setAddOrEdit}
                            acceptedRegionList={acceptedRegionList}
                            setLocation={setLocation}
                        />
                    ) : null}
                    {openEditSettingsDialog ? (
                        <EditRetailSettingDialog
                            setOpenEditSettingsDialog={setOpenEditSettingsDialog}
                            openEditSettingsDialog={openEditSettingsDialog}
                            setIsDetailDialogOpen={setIsDetailDialogOpen}
                            location={location}
                            setLocation={setLocation}
                        />
                    ) : null}
                </React.Fragment>
            )}
            {props.openAddUpdateDialog ? (
                <AddEditRetailLocation
                    addOrEdit={props.addOrEdit}
                    location={location}
                    openAddUpdateDialog={props.openAddUpdateDialog}
                    setOpenAddUpdateDialog={props.setOpenAddUpdateDialog}
                    setLocation={setLocation}
                />
            ) : null}
        </>
    );
}

export default RetailLocationsGrid;
