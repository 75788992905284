import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import CertificateGrid from "./CertificateGrid";
import NoData from "../NoData";
import {
    fetchCertificatesSearchAPI,
    selectPage,
    selectNoData as selectNoCertificateData,
    clearCertificateFiltersState,
    setSavedSearchState,
} from "../../../app/certificateSlice";
import { getPageTitle } from "../../../shared/sessionUtility";
import CustomerQuickSearch from "../../sharedComponents/CustomerQuickSearch";

const certificates = React.memo(() => {
    const dispatch = useDispatch();
    const pageData = useSelector(selectPage);
    const location = useLocation();
    const noCertificateData = useSelector(selectNoCertificateData);
    const { searchCriteria } = location?.state ? location.state : [];

    useEffect(() => {
        if (typeof searchCriteria !== "undefined") {
            const searchObj = JSON.parse(searchCriteria);
            dispatch(clearCertificateFiltersState());
            dispatch(setSavedSearchState(searchObj));
        }
        dispatch(fetchCertificatesSearchAPI());
    }, [dispatch, searchCriteria]);

    useEffect(() => {
        dispatch(fetchCertificatesSearchAPI());
    }, []); // eslint-disable-line

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Customer certificates"))}</title>
            </Helmet>
            <div className="dl-search">
                <div className="pad-bottom-sm cx-quick-search">
                    <CustomerQuickSearch />
                </div>
                <h1 className="line-height-md">Customer certificates</h1>
                <div hidden={!pageData.data.length && noCertificateData === "onLanding"}>
                    <CertificateGrid />
                </div>
                <NoData hidden={pageData.data.length} certificate />
            </div>
        </React.Fragment>
    );
});

export default certificates;
