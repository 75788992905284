import React, { useEffect, useState } from "react";
import { SDialog, SRow, SCol, SIcon, SAlert } from "@avalara/skylab-react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import toast from "../../hooks/toast";
import {
    getSelectedColumns,
    postBulkPrintCertificateApi,
    selectIsLoadingCertificateExport,
    selectIsBulkDownloadCertificateDialogOpen,
    setIsBulkDownloadCertificateDialogOpen,
} from "../../app/certificateSlice";
import { noErrorTag } from "../../shared/Utils";

function BulkDownloadCertificateDialog(props) {
    const dispatch = useDispatch();
    const [showToast, hideToast] = toast();
    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState("");
    const certificateLoading = useSelector(selectIsLoadingCertificateExport);
    const isBulkCertificateDownloadDialogOpen = useSelector(
        selectIsBulkDownloadCertificateDialogOpen
    );
    const saveClassName = classnames({
        primary: true,
        small: true,
        loading: certificateLoading,
    });
    const certificateColumns = useSelector(getSelectedColumns, shallowEqual);
    const myDownloadsUrl = `https://${process.env.REACT_APP_DOCLOCKER_HOST}/mylockers/downloads`;
    const disableExport = props.filteredRecordCount > 500;
    const handleSubmitForm = async event => {
        event.preventDefault();
        if (title) {
            let columnList = certificateColumns.filter(column => !column.hidden);
            columnList = columnList.map(column => column.id);
            await dispatch(
                postBulkPrintCertificateApi(20, 1, columnList, title, showToast, hideToast)
            );
            setTitle("");
        } else {
            setTitleError("error");
        }
    };

    const onSDismiss = () => {
        dispatch(setIsBulkDownloadCertificateDialogOpen(false));
        if (titleError) setTitleError("");
    };

    const handleChangeInput = event => {
        setTitle(event.target.value);
        setTitleError("");
    };

    useEffect(() => {
        document.getElementById("titleTextBox")?.focus();
    });

    return (
        <SDialog
            open={isBulkCertificateDownloadDialogOpen}
            id="exportSearch-dialog"
            onS-dismiss={onSDismiss}
            aria-modal="true">
            <div slot="header" id="exportCertificateImages">
                Export certificate images
            </div>
            <div slot="body">
                {!disableExport ? (
                    <div>
                        <SRow>
                            <SCol>
                                Export certificates to download or print them in bulk. Large exports
                                may take a few minutes.
                                <br />
                                When it’s ready, we’ll add the export file to{" "}
                                <a href={myDownloadsUrl}> your downloads page.</a>
                            </SCol>
                        </SRow>
                        <SRow>
                            <SCol span="7">
                                <label htmlFor="titleTextBox" className="required">
                                    Give your export a name
                                </label>
                                <input
                                    id="titleTextBox"
                                    name="title"
                                    type="text"
                                    value={title}
                                    className={titleError || noErrorTag}
                                    onChange={e => {
                                        handleChangeInput(e);
                                    }}
                                />
                                {titleError ? (
                                    <div className="input-msg">
                                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                                        <span className="top-xs">Enter a name for Export</span>
                                    </div>
                                ) : null}
                            </SCol>
                        </SRow>
                    </div>
                ) : (
                    <>
                        <div className="pad-bottom-xs">
                            You asked to export {props.filteredRecordCount} certificate images.
                        </div>
                        <SAlert status="error" role="alert" nodismiss="">
                            <div>
                                The maximum number of images that can be exported at a time is 500.
                                <br />
                                Select a smaller number of images to export.
                            </div>
                        </SAlert>
                    </>
                )}
            </div>
            <div slot="footer">
                <button className="secondary small" onClick={onSDismiss}>
                    Cancel
                </button>
                <button
                    className={saveClassName}
                    disabled={disableExport}
                    onClick={handleSubmitForm}>
                    Export
                </button>
            </div>
        </SDialog>
    );
}

export default BulkDownloadCertificateDialog;
