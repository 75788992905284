import React, { useEffect, useCallback } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../../axios";
import { selectSetting, selectSignatureData, fetchSignatureData } from "../../../app/settingsSlice";
import toast from "../../../hooks/toast";

const PackageGenCert = React.memo(props => {
    const dispatch = useDispatch();
    const settings = useSelector(selectSetting, shallowEqual);
    const [showToast] = toast();
    const navigate = useNavigate();
    const sigData = useSelector(selectSignatureData, shallowEqual);

    useEffect(() => {
        if (settings.id) {
            dispatch(fetchSignatureData(settings.id));
        }
    }, [dispatch, settings.id]);

    function getGenCertInstance(frame, options, clientId) {
        const { GenCertClass } = window;
        return new GenCertClass(frame, options, clientId);
    }

    const deletePackage = async () => {
        await axios
            .delete(
                `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/vendor-packages/${props.packageId}`,
                { withCredentials: true }
            )
            .then(() => {
                navigate(`/search/vendor-packages/`);
            });
    };
    const initializeGenCert = useCallback(() => {
        const clientId = settings.id;
        const cxNumber = props.customerNumber;
        axios
            .post(
                `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION}/get-ecomm-token`,

                {
                    ClientId: clientId.toString(),
                    CustomerNumber: cxNumber,
                },
                {
                    withCredentials: true,
                }
            )
            .then(response => {
                const gencertFrame = document.getElementById("gencert-frame");
                const gencertObj = {};
                const defaultOptions = {
                    certCaptureURL: process.env.REACT_APP_CERTCAPTURE,
                    token: response.data.token,
                    submit_to_stack: false,
                    fill_only: true,
                    css: "ecm",
                    gencertAPI: process.env.REACT_APP_GENCERT_URL,
                    skip_signature: true,
                    customer_number: cxNumber,
                    doc_type: props.documentTypeId,
                    ship_zone: props.exposureZone,
                    tax_code: props.taxCode,
                    delivery_type: "E",
                    email_address: "placeholderemail@avalara.com",
                    cover_letter_only: false,
                    cover_letter: props.coverLetterId,
                    preview: false,
                    show_files: true,
                    package_id: props.packageId,
                    matrix_ids: `${props.matrixIDs.join(",")}`,
                    onCertSuccess: async () => {
                        const message = "Certificate package data has been successfully saved";
                        const type = "success";
                        showToast(type, message);
                        navigate(`/search/vendor-packages/${props.packageId}`);
                    },
                    onCertFailure: showError => {
                        if (typeof showError === "undefined" || showError !== false) {
                            showToast(
                                "error",
                                "Could not save your certificate package. Please try again or contact support."
                            );
                            navigate(`/search/vendor-packages`);
                        }
                    },
                    onCancel: async () => {
                        await deletePackage();
                    },
                    onManualSubmit: () => {
                        showToast(
                            "error",
                            "Your exemption matrix is set to manually collect for the region and reason for exemption selected. Please check your exemption matrix and try again."
                        );
                        navigate(`/search/vendor-packages/`);
                    },
                };

                gencertObj.myGenCert = getGenCertInstance(gencertFrame, defaultOptions, clientId);
                window.GenCert = gencertObj.myGenCert;
                if (gencertObj.myGenCert) {
                    gencertObj.myGenCert.show();
                }

                if (sigData) {
                    gencertObj.myGenCert.setSignatureData({
                        name: sigData.name,
                        title: sigData.title,
                        signature: sigData.signature,
                    });
                } else {
                    showToast("error", `Could not load signature.`, "signature-error");
                }
            });
    }, []);
    const getGenCertScript = useCallback(() => {
        const script = document.createElement("script");
        script.src = `${process.env.REACT_APP_GENCERT_URL}/js/gencert.js`;
        script.async = true;
        script.onload = () => initializeGenCert();
        document.body.appendChild(script);
    }, []);

    useEffect(() => {
        if (props.enableGencert && props.packageId !== "") {
            getGenCertScript();
        }
    }, [props.enableGencert]);

    return <div id="gencert-frame" />;
});

export default PackageGenCert;
