import React from "react";
import { SBox } from "@avalara/skylab-react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { urlToPageMap } from "../../../shared/Utils";
import addCxIcon from "./icons/add_cx.svg";
import viewCxIcon from "./icons/all_cx.svg";
import uploadIcon from "./icons/upload_cert.svg";
import requestIcon from "./icons/request_cert2.svg";
import viewCertIcon from "./icons/all_certs.svg";
import ecmReportIcon from "./icons/ecm_reports.svg";
import addVendor from "./icons/add_vendor.svg";
import vendorCert from "./icons/vendor_certificate.svg";
import retailIcon from "./icons/retail_icon.svg";
import FeatureToggler from "../../../featureToggler/FeatureToggler";
import { isEligibleUser } from "../../../shared/sessionUtility";
import featureFlag from "../../../featureToggler/featureFlag";
import "../overview.css";
import { setRedirectURL } from "../../../app/certificateSlice";

const Favorites = React.memo(() => {
    const dispatch = useDispatch();
    const isEligibleUserRetailView = dispatch(isEligibleUser(featureFlag.retail.tile));

    return (
        <SBox className="info-box">
            <h1 className="margin-left-sm"> Favorites</h1>
            <div className="make-responsive">
                <FeatureToggler category="customers" id="manageCustomer">
                    <Link to="/add-customer" className="no-link">
                        <SBox className="standard-card-first">
                            <img src={addCxIcon} alt="Add New Customer" className="icon-link" />
                            <p className="card-label-link">Add a customer</p>
                        </SBox>
                    </Link>
                </FeatureToggler>
                <FeatureToggler category="vendors" id="manageVendors">
                    <Link to="/add-vendor" className="no-link">
                        <SBox className="standard-card">
                            <img src={addVendor} className="icon-link" alt="Add Vendor" />
                            <p className="card-label-link"> Add a vendor </p>
                        </SBox>
                    </Link>
                </FeatureToggler>
                <FeatureToggler category="customers" id="manageCustomer">
                    <Link to="/search/customers" className="no-link">
                        <SBox className="standard-card">
                            <img src={viewCxIcon} className="icon-link" alt="View All Customers" />
                            <p className="card-label-link"> View all customers </p>
                        </SBox>
                    </Link>
                </FeatureToggler>
                <FeatureToggler category="vendors" id="manageVendors">
                    <Link to="/search/vendors" className="no-link">
                        <SBox className="standard-card">
                            <img src={viewCxIcon} className="icon-link" alt="View All Vendors" />
                            <p className="card-label-link"> View all vendors </p>
                        </SBox>
                    </Link>
                </FeatureToggler>
                <FeatureToggler category="reviewCertificate" id="add">
                    <Link
                        to="/review"
                        className="no-link"
                        onClick={() => {
                            dispatch(setRedirectURL(urlToPageMap.overview));
                        }}>
                        <SBox className="standard-card-first">
                            <img
                                src={uploadIcon}
                                className="icon-link add-cert"
                                alt="Add a certificate"
                            />
                            <p className="card-label-link"> Add a certificate</p>
                        </SBox>
                    </Link>
                </FeatureToggler>
                <FeatureToggler category="requestCertificates" id="campaign">
                    <Link to="/requests/create" className="no-link">
                        <SBox className="standard-card">
                            <img
                                src={requestIcon}
                                className="icon-link"
                                alt="Request Certificate"
                            />
                            <p className="card-label-link">Request certificates from customers</p>
                        </SBox>
                    </Link>
                </FeatureToggler>
                <FeatureToggler category="validatedSearchPage" id="viewValidatedCertificates">
                    <Link to="/search/certificates" className="no-link">
                        <SBox className="standard-card">
                            <img
                                src={viewCertIcon}
                                className="icon-link"
                                alt="View All Certificates"
                            />
                            <p className="card-label-link"> View all certificates </p>
                        </SBox>
                    </Link>
                </FeatureToggler>
                <FeatureToggler category="vendorCertificates" id="viewVendorCertificates">
                    <Link to="/search/vendor-certificates" className="no-link">
                        <SBox className="standard-card">
                            <img
                                src={vendorCert}
                                className="icon-link"
                                alt="View All Vendor Certificates"
                            />
                            <p className="card-label-link"> View all vendor certificates </p>
                        </SBox>
                    </Link>
                </FeatureToggler>
                <Link to="/saved-searches" className="no-link">
                    <SBox className="standard-card-first">
                        <img src={ecmReportIcon} className="icon-link" alt="Reports" />
                        <p className="card-label-link"> Reports</p>
                    </SBox>
                </Link>
                <FeatureToggler category="retail" id="tile">
                    <Link
                        to={urlToPageMap.retail}
                        className={isEligibleUserRetailView ? "no-link" : "no-link disable-link"}>
                        <SBox className="standard-card">
                            <img
                                src={retailIcon}
                                className={
                                    isEligibleUserRetailView ? "icon-link" : "icon-link disable-box"
                                }
                                alt="Retail"
                            />
                            <p
                                className={
                                    isEligibleUserRetailView
                                        ? "card-label-link"
                                        : "card-label-link disable-box"
                                }>
                                Retail
                            </p>
                        </SBox>
                    </Link>
                </FeatureToggler>
            </div>
        </SBox>
    );
});

export default Favorites;
