import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { convertFieldToString } from "../shared/Utils";

export const exemptionMatrixSlice = createSlice({
    name: "exemptionMatrix",
    initialState: {
        isLoadingMatrixExposureZones: false,
        isLoadingMatrixExemptReasons: false,
        isLoadingData: false,
        data: [],
        matrixExposureZoneData: [],
        matrixExemptReasonData: [],
    },

    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setLoadingData: (state, action) => {
            state.isLoadingData = action.payload;
        },
        setMatrixExposureZoneData: (state, action) => {
            state.matrixExposureZoneData = action.payload;
        },
        setLoadingMatrixExposureZones: (state, action) => {
            state.isLoadingMatrixExposureZones = action.payload;
        },
        setMatrixExemptReasonData: (state, action) => {
            state.matrixExemptReasonData = action.payload;
        },
        setLoadingMatrixExemptReasons: (state, action) => {
            state.isLoadingMatrixExemptReasons = action.payload;
        },
    },
});

export const {
    setData,
    setLoadingData,
    setMatrixExposureZoneData,
    setLoadingMatrixExposureZones,
    setMatrixExemptReasonData,
    setLoadingMatrixExemptReasons,
} = exemptionMatrixSlice.actions;

export const selectData = state => {
    return state.exemptionMatrix.data;
};

export const selectIsLoadingData = state => {
    return state.exemptionMatrix.isLoadingData;
};

export const selectMatrixExposureZoneData = state => {
    return state.exemptionMatrix.matrixExposureZoneData;
};

export const selectMatrixExemptReasonData = state => {
    return state.exemptionMatrix.matrixExemptReasonData;
};

export const selectIsLoadingMatrixExposureZones = state => {
    return state.exemptionMatrix.isLoadingMatrixExposureZones;
};

export const selectIsLoadingMatrixExemptReasons = state => {
    return state.exemptionMatrix.isLoadingMatrixExemptReasons;
};

export const fetchExpirationMatrixExposureZones = () => async (dispatch, getState) => {
    const { isLoadingExposureZones } = getState().exemptionMatrix;

    if (isLoadingExposureZones) {
        return;
    }

    dispatch(setLoadingMatrixExposureZones(true));

    const response = await axios.get(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/exemption-matrix/exposure-zones`,
        { withCredentials: true }
    );

    if (response.data) {
        const transformedMatrixExposureZones = convertFieldToString(response.data, null, [
            "region",
        ]);
        dispatch(setMatrixExposureZoneData(transformedMatrixExposureZones));
    }

    dispatch(setLoadingMatrixExposureZones(false));
};

export const fetchExpirationMatrixExemptReasons = exposureZoneId => async (dispatch, getState) => {
    const { isLoadingMatrixExemptReasons } = getState().exemptionMatrix;

    if (isLoadingMatrixExemptReasons) {
        return;
    }

    dispatch(setLoadingMatrixExemptReasons(true));

    const response = await axios.post(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/exemption-matrix/exempt-reasons`,
        { exposureZoneIds: [exposureZoneId] },
        { withCredentials: true }
    );

    if (response.data) {
        const transformedMatrixExemptReasons = convertFieldToString(response.data, null, [
            "exemptReason",
        ]);
        dispatch(setMatrixExemptReasonData(transformedMatrixExemptReasons));
    }

    dispatch(setLoadingMatrixExemptReasons(false));
};

export const fetchExemptionMatrix = filterString => async (dispatch, getState) => {
    const { isLoadingMatrixExemptReasons } = getState().exemptionMatrix;

    if (isLoadingMatrixExemptReasons) {
        return;
    }

    dispatch(setLoadingMatrixExemptReasons(true));

    const response = await axios.get(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/exemption-matrix${filterString}`,
        {
            withCredentials: true,
        }
    );

    if (response.data) {
        dispatch(setData(response.data.value));
    }

    dispatch(setLoadingMatrixExemptReasons(false));
};

export const fetchCodesByManyRegions = exposureZoneId => async (dispatch, getState) => {
    const { isLoadingMatrixExemptReasons } = getState().exemptionMatrix;

    if (isLoadingMatrixExemptReasons) {
        return;
    }

    dispatch(setLoadingMatrixExemptReasons(true));

    const response = await axios.get(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/exemption-matrix?$filter=Region.Id IN (${exposureZoneId})&$top=100`,
        { withCredentials: true }
    );

    if (response.data.value) {
        dispatch(setMatrixExemptReasonData(response.data.value));
    }

    dispatch(setLoadingMatrixExemptReasons(false));
};

export default exemptionMatrixSlice.reducer;
