import React from "react";
import { SRow, SCol, SIcon, SInputExtended } from "@avalara/skylab-react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    selectFormState,
    setFormState,
    fetchVendorPackagesAsync,
} from "../../../app/vendorPackagesSlice";
import FeatureToggler from "../../../featureToggler/FeatureToggler";
import featureFlag from "../../../featureToggler/featureFlag";
import { isEligibleUser } from "../../../shared/sessionUtility";

const VendorPackagesForm = React.memo(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formState = useSelector(selectFormState);
    const { packageName } = formState;
    const clearSearch = () => {
        dispatch(
            setFormState({
                ...formState,
                packageName: "",
            })
        );
        dispatch(fetchVendorPackagesAsync());
    };

    const handleInput = el => {
        dispatch(
            setFormState({
                ...formState,
                [el.detail.inputId]: el.detail.value.trim(),
            })
        );
        setTimeout(() => {
            dispatch(fetchVendorPackagesAsync());
        }, 400);
    };

    const handleSearch = async el => {
        await dispatch(
            setFormState({
                ...formState,
                [el.detail.inputId]: el.detail.value.trim(),
            })
        );
        dispatch(fetchVendorPackagesAsync());
    };

    return (
        <SRow className="pad-top-sm">
            <SCol span="3">
                <label htmlFor="packageName">Search by package name</label>
                <SInputExtended
                    inputId="packageName"
                    aria-label="Search by package name"
                    placeholder="Search by package name"
                    type="search"
                    onS-input={e => handleInput(e)}
                    onS-search={e => handleSearch(e)}
                    onS-clear={() => clearSearch()}
                    value={packageName}
                />
            </SCol>
            <FeatureToggler category="vendorCertificates" id="manageCertificatePackages">
                <SCol span="auto">
                    <button
                        className="primary float-right margin-top-lg"
                        disabled={
                            !dispatch(
                                isEligibleUser(
                                    featureFlag.vendorCertificates.manageCertificatePackages
                                )
                            )
                        }
                        onClick={() => {
                            navigate(`/search/vendor-packages/create`);
                        }}>
                        <SIcon
                            name="plus"
                            className="margin-right-sm liner-section"
                            aria-hidden="true"
                        />
                        Create a certificate package
                    </button>
                </SCol>
            </FeatureToggler>
        </SRow>
    );
});

export default VendorPackagesForm;
