import { SBox, SCol, SRow } from "@avalara/skylab-react";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { essentialsOrAbove, getPageTitle, vendorExemptions } from "../../shared/sessionUtility";
import {
    fetchOutgoingMatrixSet,
    fetchSettingsAPI,
    fetchSignatureData,
    selectIsOutgoingMatrixSet,
    selectMatrixCheckLoading,
    selectSetting,
    selectSignatureData,
    fetchDocumentTypeInfoType,
    selectDocumentTypeInfo,
    selectIsSavingSettings,
} from "../../app/settingsSlice";
import {
    fetchOverviewAPI,
    fetchOverviewMissingCertificatesAPI,
    fetchOverviewPendingCertificatesAPI,
    selectOverview,
    selectOverviewMissingCertificates,
    selectOverviewPendingCertificates,
    selectLoading,
    selectPendingCertificatesLoading,
    selectMissingCertificatesLoading,
} from "../../app/overviewSlice";
import FeatureToggler from "../../featureToggler/FeatureToggler";
import CustomerQuickSearch from "../sharedComponents/CustomerQuickSearch";
import Favorites from "./favorites/Favorites";
import ActiveCertificates from "./insights/ActiveCertificates";
import "./overview.css";
import Resources from "./resources/Resources";
import Tasks from "./tasks/Tasks";

const Overview = React.memo(() => {
    const [documentTypeId, setDocumentTypeId] = useState(0);
    const setting = useSelector(selectSetting, shallowEqual);
    const isSavingSetting = useSelector(selectIsSavingSettings);
    const sigData = useSelector(selectSignatureData, shallowEqual);
    const matrixSet = useSelector(selectIsOutgoingMatrixSet, shallowEqual);
    const dispatch = useDispatch();
    const matrixLoad = useSelector(selectMatrixCheckLoading, shallowEqual);
    const isEssentialsOrAbove = dispatch(essentialsOrAbove());
    const isvendorExemptions = dispatch(vendorExemptions());
    const documentType = useSelector(selectDocumentTypeInfo, shallowEqual);
    const overviewData = useSelector(selectOverview, shallowEqual);
    const isLoading = useSelector(selectLoading, shallowEqual);
    const isMissingCertificatesLoading = useSelector(
        selectMissingCertificatesLoading,
        shallowEqual
    );
    const isPendingCertificatesLoading = useSelector(
        selectPendingCertificatesLoading,
        shallowEqual
    );
    const overviewMissingCertificatesData = useSelector(
        selectOverviewMissingCertificates,
        shallowEqual
    );
    const overviewPendingCertificatesData = useSelector(
        selectOverviewPendingCertificates,
        shallowEqual
    );
    useEffect(() => {
        dispatch(fetchSettingsAPI);
    }, [dispatch]);

    useEffect(() => {
        if (isvendorExemptions && setting.id !== undefined) {
            dispatch(fetchSignatureData(setting.id));
            dispatch(fetchOutgoingMatrixSet());
        }
    }, [dispatch, setting.id]);

    useEffect(() => {
        dispatch(fetchDocumentTypeInfoType(false));
    }, []);

    useEffect(() => {
        if (documentType[0]) setDocumentTypeId(documentType[0].id);
    }, [documentType]);

    useEffect(() => {
        dispatch(fetchOverviewMissingCertificatesAPI());
    }, [dispatch]);

    useEffect(() => {
        if (documentTypeId > 0) {
            dispatch(fetchOverviewAPI(documentTypeId));
            dispatch(fetchOverviewPendingCertificatesAPI(documentTypeId));
        }
    }, [dispatch, documentTypeId]);
    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Overview"))}</title>
            </Helmet>
            <div className="dl-search">
                <SRow>
                    <SCol span="auto">
                        <div className="search-fit">
                            <CustomerQuickSearch />
                        </div>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol span={!isEssentialsOrAbove && isvendorExemptions ? "12" : "8"}>
                        {(isvendorExemptions && !sigData) ||
                        (isvendorExemptions && !matrixSet) ||
                        isEssentialsOrAbove ? (
                            <Tasks
                                comSig={sigData?.signature}
                                matrixSet={matrixSet}
                                matrixLoad={matrixLoad}
                                overviewData={overviewData}
                                overviewPendingCertificatesData={overviewPendingCertificatesData}
                                overviewMissingCertificatesData={overviewMissingCertificatesData}
                                isMissingCertificatesLoading={isMissingCertificatesLoading}
                                isPendingCertificatesLoading={isPendingCertificatesLoading}
                                isLoading={isLoading}
                            />
                        ) : null}
                        {!isSavingSetting ? <Favorites /> : ""}
                        <SBox className="info-box margin-top-lg">
                            <Resources caller="overview" />
                        </SBox>
                    </SCol>
                    <FeatureToggler category="overview" id="insights">
                        <SCol span="auto">
                            <div className="insights-align">
                                <h1> Insights</h1>
                                <ActiveCertificates
                                    overviewData={overviewData}
                                    overviewMissingCertificatesData={
                                        overviewMissingCertificatesData
                                    }
                                />
                            </div>
                        </SCol>
                    </FeatureToggler>
                </SRow>
            </div>
        </React.Fragment>
    );
});

export default Overview;
