import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { buildURL } from "../shared/Utils";
import toast from "../hooks/toast";

const initialState = {
    isLoading: false,
    isDeleting: false,
    isAddOrEditLoading: false,
    page: {
        data: [],
        paginate: {
            pageNumber: 0,
            startIndex: -1,
            endIndex: 0,
            rowsPerPage: 0,
            totalRecords: 0,
        },
    },
    sortColumn: ["fieldName", true],
    formState: {
        fieldName: "",
    },
};

export const certificateCustomFieldSlice = createSlice({
    name: "certificateCustomField",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setIsDeleting: (state, action) => {
            state.isDeleting = action.payload;
        },
        setIsAddOrEditLoading: (state, action) => {
            state.isAddOrEditLoading = action.payload;
        },
        setEnableLoading: (state, action) => {
            state.enableLoading = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setFormState: (state, action) => {
            state.formState = action.payload;
        },
        clearFormState: state => {
            state.formState = initialState.formState;
        },
        setIsLimitReached: (state, action) => {
            state.setIsLimitReached = action.payload;
        },
    },
});

const [showToast] = toast();
export const {
    setIsLoading,
    setPage,
    setPageToNull,
    setFormState,
    clearFormState,
    setIsDeleting,
    setIsAddOrEditLoading,
    setEnableLoading,
    setSortColumn,
    setIsLimitReached,
} = certificateCustomFieldSlice.actions;

export const selectIsLoading = state => {
    return state.certificateCustomField.isLoading;
};
export const selectPage = state => {
    return state.certificateCustomField.page;
};
export const selectFormState = state => {
    return state.certificateCustomField.formState;
};
export const selectIsDeleting = state => {
    return state.certificateCustomField.isDeleting;
};
export const selectIsAddOrEditLoading = state => {
    return state.certificateCustomField.isAddOrEditLoading;
};
export const selectEnableLoading = state => {
    return state.certificateCustomField.enableLoading;
};
export const selectSortColumn = state => {
    return state.certificateCustomField.sortColumn;
};
export const selectIsLimitReached = state => {
    return state.certificateCustomField.setIsLimitReached;
};

export const fetchCertificateCustomFields =
    (top = 100, skip, paginateDetails) =>
    async (dispatch, getState) => {
        const { isLoading, sortColumn, formState } = getState().certificateCustomField;
        const { fieldName } = formState;
        if (isLoading) {
            return;
        }
        let filterString = "";
        dispatch(setIsLoading(true));
        const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
        if (fieldName) {
            filterString = `fieldName contains '${fieldName}' `;
        }

        const apiURI = buildURL(
            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/certificate-custom-fields`,
            filterString,
            null,
            top,
            skip,
            sort
        );
        const response = await axios.get(`${apiURI}`, { withCredentials: true });

        if (response?.data?.[0]?.code?.toLowerCase() === "objectnotfound") {
            dispatch(setPageToNull());
            dispatch(setIsLoading(false));
            dispatch(setIsLimitReached(false));
            return;
        }

        if (response.data && response.data.count === "0") {
            dispatch(setPageToNull());
            dispatch(setIsLimitReached(false));
        } else {
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.pageNumber,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response.data.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 20,
                      totalRecords: response.data.count,
                  };
            dispatch(
                setPage({
                    data: response.data.value,
                    paginate: paginateData,
                })
            );
            if (paginateData.totalRecords >= 50) dispatch(setIsLimitReached(true));
            else dispatch(setIsLimitReached(false));
        }

        dispatch(setIsLoading(false));
    };

export const deleteCertificateCustomField = id => async (dispatch, getState) => {
    const { isDeleting } = getState().certificateCustomField;

    if (isDeleting) {
        return false;
    }
    let successResponse = false;
    dispatch(setIsDeleting(true));
    const response = await axios.delete(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/certificate-custom-fields/${id}`,
        { withCredentials: true }
    );
    if (response?.response?.status === 400) {
        successResponse = false;
    } else if (response?.status === 200) {
        successResponse = true;
        showToast("success", "Custom field deleted.", "deleteCertificateCustomFieldToast", false);
        dispatch(fetchCertificateCustomFields());
    }
    dispatch(setIsDeleting(false));
    return successResponse;
};

export const postCertificateCustomFields = payload => async dispatch => {
    dispatch(setIsAddOrEditLoading(true));
    let success = false;

    await axios
        .post(
            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/certificate-custom-fields`,
            payload,
            { withCredentials: true }
        )
        .then(x => {
            if (x.name === "AxiosError" && x.response.status === 400) {
                showToast(
                    "error",
                    `Custom field already exists.`,
                    "certificate-custom-field-error-toast",
                    false
                );
            }
            if (x.status === 201) {
                showToast(
                    "success",
                    `Certificate custom field added successfully.`,
                    "certificate-custom-field-success-toast",
                    false
                );
                success = true;
                dispatch(fetchCertificateCustomFields());
            }
        });
    dispatch(setIsAddOrEditLoading(false));
    return success;
};

export const putCertificateCustomFields = (payload, certificateCustomFieldId) => async dispatch => {
    dispatch(setIsAddOrEditLoading(true));
    let success = false;
    await axios
        .put(
            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/certificate-custom-fields/${certificateCustomFieldId}`,
            payload,
            { withCredentials: true }
        )
        .then(x => {
            if (x.name === "AxiosError" && x.response.status === 400) {
                showToast(
                    "error",
                    x.response.data.Message,
                    "certificate-custom-field-error-toast",
                    false
                );
            }
            if (x.status === 200) {
                if (x?.data[0]?.message) {
                    showToast(
                        "success",
                        `${x?.data[0]?.message}`,
                        "certificate-custom-field-success-toast",
                        false
                    );
                } else {
                    showToast(
                        "success",
                        `Successfully updated certificate custom field`,
                        "certificate-custom-field-success-toast",
                        false
                    );
                }
                success = true;
                dispatch(fetchCertificateCustomFields());
            }
        });
    dispatch(setIsAddOrEditLoading(false));
    return success;
};

export default certificateCustomFieldSlice.reducer;
