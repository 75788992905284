import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { selectPurchaserSettings, setPurchaserSettings } from "../../../../app/retailSettingsSlice";
import SelectRequirementLevel from "./SelectRequirementLevel";
import ZoomTip from "../../../sharedComponents/ZoomTip";

const EditPurchaserSettingsTable = React.memo(props => {
    const dispatch = useDispatch();
    const purchaserSettings = useSelector(selectPurchaserSettings, shallowEqual);
    const handlePurchaserCheckBoxChange = e => {
        props.setIsPurchaserSettingUpdated(true);
        dispatch(
            setPurchaserSettings(
                purchaserSettings.map(setting =>
                    setting.id === parseInt(e.target.id, 10)
                        ? { ...setting, disabled: !setting.disabled }
                        : setting
                )
            )
        );
    };
    const getColumnClass = requirementLevel => {
        if (requirementLevel === "Required") return "col-1 row-height";
        if (requirementLevel === "Preferred") return "col-4 row-height";
        return "row-height";
    };
    const handleRequirementChange = event => {
        props.setIsPurchaserSettingUpdated(true);
        dispatch(
            setPurchaserSettings(
                purchaserSettings.map(setting =>
                    setting.id === parseInt(event.detail.selectInputId.split("-")[1], 10)
                        ? { ...setting, requirementLevel: event.detail.item.value }
                        : setting
                )
            )
        );
    };

    return (
        <div className="row">
            <div className="column-2">
                <s-table-container tabindex="0" key={uuidv4()}>
                    <table className="retailSettings-table" role="presentation">
                        <thead>
                            <tr>
                                <th>
                                    Purchaser information
                                    <span className="zoom-tip">
                                        <ZoomTip
                                            tipTitle="Preferred fields"
                                            url="bundle/pah1663778507797/page/kxw1723815692502.html "
                                        />
                                    </span>
                                </th>
                                <th>Field status</th>
                                <th className="pad-left-editable">
                                    Editable
                                    <span className="zoom-tip">
                                        <ZoomTip
                                            tipTitle="Editable fields"
                                            url="bundle/pah1663778507797/page/pyn1723815705857.html "
                                        />
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {purchaserSettings?.map(x => (
                                <tr key={uuidv4()} className={getColumnClass(x.requirementLevel)}>
                                    <td className="pad-top-md pad-left-sm purchaser-setting-name">
                                        <label
                                            htmlFor={`select-${x.id}`}
                                            id={`lbl-${x.id}`}
                                            className="font-normal">
                                            {x.purchaserSettingName}
                                        </label>
                                    </td>
                                    <td className="purchaser-setting-requirement">
                                        <SelectRequirementLevel
                                            value={x.requirementLevel}
                                            id={`select-${x.id}`}
                                            onAdd={handleRequirementChange}
                                            label={`lbl-${x.id}`}
                                        />
                                    </td>
                                    <td>
                                        <div className="purchaser-setting-editable">
                                            <input
                                                type="checkbox"
                                                id={x.id}
                                                checked={!x.disabled}
                                                onChange={e => handlePurchaserCheckBoxChange(e)}
                                                className="unlabeled "
                                                aria-label="editable"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </s-table-container>
            </div>
        </div>
    );
});

export default EditPurchaserSettingsTable;
