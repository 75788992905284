import { SDialog, SBox, SLoader, SToggle, SRow, SCol, SIcon } from "@avalara/skylab-react";
import React, { Fragment, useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { PDFDocument, degrees } from "pdf-lib";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import axios from "axios";
import {
    selectValidationData,
    getValidationFile,
    selectIsUploadingFile,
    selectHasCertificateFile,
    selectIsSplitCertificateDialogOpen,
    setIsSplitCertificateDialogOpen,
    selectCustomerCert,
    selectIsSplittingCertificates,
    setIsSplittingCertificates,
    splitCertificateAsync,
} from "../../app/certificateSlice";
import "./splitCertificateDialog.css";
import SplitCertCustomerTypeAhead from "../sharedComponents/SplitCertCustomerTypeAhead";
import SplitCertSelectExposureZone from "../sharedComponents/SplitCertSelectExposureZone";
import SplitCertSelectExemptionReasons from "../sharedComponents/SplitCertSelectExemptionReasons";
import toast from "../../hooks/toast";
import { selectSession } from "../../app/sessionSlice";
import { urlToPageMap } from "../../shared/Utils";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

const [showToast] = toast();

function SplitCertificateDialog(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const session = useSelector(selectSession);
    const [splitData, setSplitData] = useState([
        {
            id: null,
            pageNo: 0,
            customer: [{ customerId: null, customerNumber: "", customerName: "" }],
            regionId: 0,
            reasonId: 0,
            isSplitPage: false,
            isLoadingReasons: false,
            reasonsList: [],
            rotate: null,
            isDelete: null,
        },
    ]);
    const validationData = useSelector(selectValidationData, shallowEqual);
    const isUploadingFile = useSelector(selectIsUploadingFile);
    const hasCertificateFile = useSelector(selectHasCertificateFile);
    const isSplitCertificateDialogOpen = useSelector(selectIsSplitCertificateDialogOpen);
    const certificate = useSelector(selectCustomerCert, shallowEqual);
    const [customers, setCustomers] = useState([]);
    const isSplittingCertificate = useSelector(selectIsSplittingCertificates);
    const reviewButton = classnames({
        primary: true,
        small: true,
        loading: isSplittingCertificate,
    });

    useEffect(() => {
        if (!validationData.data && props.certificateID && !isUploadingFile) {
            dispatch(getValidationFile(props.certificateID));
        }
    }, [props.certificateID, validationData, dispatch, isUploadingFile]);

    // SET CUSTOMER
    useEffect(() => {
        if (props?.customers && !isUploadingFile) setCustomers([props.customers]);
        if (certificate.customers && certificate.customers.length && !isUploadingFile) {
            setCustomers(certificate.customers);
        }
    }, [certificate.customers, isUploadingFile, props.customers]);

    const apiCall = async data => {
        if (data.every(x => x.extractedPageBase64 !== "")) {
            const certificateIDParam = props.certificateID ? props.certificateID : 0;
            await dispatch(splitCertificateAsync(certificateIDParam, data))
                .then(response => {
                    if (response === true) {
                        dispatch(setIsSplittingCertificates(false));
                        navigate(urlToPageMap["search-pending"]);
                    }
                })
                .catch(() => {
                    dispatch(setIsSplittingCertificates(false));
                });
        }
    };

    const addCustomer = (value, index) => {
        const addCustomerData =
            value.length > 0 &&
            splitData.map(item =>
                item.id === index
                    ? {
                          ...item,
                          customer: [
                              ...item.customer,
                              {
                                  customerId: value[0].id,
                                  customerName: value[0].name,
                                  customerNumber: value[0].customerNumber,
                              },
                          ],
                      }
                    : item
            );

        setSplitData(addCustomerData);
    };

    const removedCustomerList = (customerList, value) => {
        const customersClone = customerList.slice();
        customersClone.splice(
            customersClone.findIndex(ele => ele.id === parseInt(value, 10)),
            1
        );
        return customersClone;
    };

    const removeCustomer = (customerId, index) => {
        const removeCustomerData = [...splitData].map(item =>
            item.id === index
                ? {
                      ...item,
                      customer: removedCustomerList(item.customer, customerId),
                  }
                : item
        );
        setSplitData(removeCustomerData);
    };

    const handleToggle = (event, index) => {
        setSplitData(prevSplitData => {
            return prevSplitData.map(item =>
                item.id === index
                    ? {
                          ...item,
                          isSplitPage: event.detail.checked,
                      }
                    : item
            );
        });
    };

    const validateSelectedRegion = async (exposureZoneIds, index) => {
        const expZones = [];
        const response = await axios.post(
            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/companies/${session.activeCompany.id}/exemption-matrix/exempt-reasons/validation`,
            { exposureZoneIds },
            { withCredentials: true }
        );
        if (response?.data?.value) {
            const exemptionMatrix = response.data.value;
            Object.values(exemptionMatrix).forEach(element => {
                expZones.push({ value: element.exemptReason.id, label: element.exemptReason.name });
            });
            const setRegionsList = [...splitData].map(item =>
                item.id === index
                    ? {
                          ...item,
                          regionId: exposureZoneIds[0],
                          reasonsList: expZones,
                          isLoadingReasons: false,
                      }
                    : item
            );
            setSplitData(setRegionsList);
        } else if (!expZones.length) {
            showToast(
                "warning",
                "Exemption matrix does not collect for selected reason for exemption."
            );
            const clearRegionReason = [...splitData].map(item =>
                item.id === index
                    ? {
                          ...item,
                          regionId: 0,
                          reasonId: 0,
                          reasonsList: [],
                          // isLoadingReasons: false,
                      }
                    : item
            );
            setSplitData(clearRegionReason);
        }
    };

    const handleRegionAdd = async (event, index) => {
        const exposureZoneId = [];
        exposureZoneId.push(event.value);

        const addRegion = [...splitData].map(item =>
            item.id === index
                ? {
                      ...item,
                      regionId: event.value,
                      // reasonsList: event.value,
                      isLoadingReasons: true,
                  }
                : item
        );
        setSplitData(addRegion);

        await validateSelectedRegion(exposureZoneId, index);
    };

    const handleRegionRemove = async (event, index) => {
        setSplitData(
            [...splitData].map(item =>
                item.id === index
                    ? {
                          ...item,
                          regionId: 0,
                          reasonId: 0,
                          isLoadingReasons: false,
                      }
                    : item
            )
        );
    };

    const handleReasonAdd = async (event, index) => {
        setSplitData(
            [...splitData].map(item =>
                item.id === index
                    ? {
                          ...item,
                          reasonId: event.detail.item.value,
                      }
                    : item
            )
        );
    };

    const handleReasonRemove = async (event, index) => {
        setSplitData(
            [...splitData].map(item =>
                item.id === index
                    ? {
                          ...item,
                          reasonId: 0,
                      }
                    : item
            )
        );
    };

    function range(start, end) {
        const length = end - start + 1;
        return Array.from({ length }, (_, i) => start + i - 1);
    }

    const onDocumentLoadSuccess = async e => {
        const defaultSplitData = [];
        const pdfSrcDoc = await PDFDocument.load(validationData.data);
        const pdfNewDoc = await PDFDocument.create();
        const pages = await pdfNewDoc.copyPages(pdfSrcDoc, range(1, e.numPages));
        for (let i = 0; i < e.numPages; i += 1) {
            const rotationAngle = pages[i].getRotation().angle;
            defaultSplitData.push({
                id: i + 1,
                pageNo: i + 1,
                isSplitPage: false,
                customer: customers.map(x => {
                    return {
                        customerId: x.id,
                        customerName: x.name,
                        customerNumber: x?.customerNumber,
                    };
                }),
                regionId: 0,
                reasonId: 0,
                pageRange: "",
                fileName: "",
                extractedPageBase64: "",
                isLoadingReasons: false,
                reasonsList: [],
                rotate: rotationAngle,
                isDelete: false,
            });
        }
        // SET THE DEFAULT SPLIT MARKER TO THE END
        const markLastSplit = [...defaultSplitData].map(item =>
            item.id === defaultSplitData.length
                ? {
                      ...item,
                      isSplitPage: true,
                  }
                : item
        );
        setSplitData(markLastSplit);
        await pdfNewDoc.flush();
    };

    const base64Arraybuffer = async data => {
        const base64url = await new Promise(r => {
            const reader = new FileReader();
            reader.onload = () => r(reader.result);
            reader.readAsDataURL(new Blob([data]));
        });
        return base64url.split(",", 2)[1];
    };
    const extractPdfPage = async (start, end) => {
        const pdfSrcDoc = await PDFDocument.load(validationData.data);
        const pdfNewDoc = await PDFDocument.create();
        const pages = await pdfNewDoc.copyPages(pdfSrcDoc, range(start, end));
        pages.forEach((page, index) => {
            page.setRotation(degrees(splitData[start - 1 + index].rotate));
            if (!splitData[start - 1 + index].isDelete) {
                pdfNewDoc.addPage(page);
            }
        });

        if (pdfNewDoc.getPageCount() > 0) {
            const newpdf = await pdfNewDoc.save();
            const newpdfBase64 = await base64Arraybuffer(new Uint8Array(newpdf));
            return newpdfBase64.toString("base64");
        }
        return null;
    };

    const calculateSplitPageRange = async () => {
        const promises = splitData
            .filter(x => x.isSplitPage)
            .map((page, index, postData) => {
                return new Promise(resolve => {
                    let start;
                    let end;
                    if (index === 0) {
                        start = 1;
                        end = page.pageNo;
                    } else if (index === postData.length - 1 && index !== 0) {
                        start = postData[index - 1].pageNo + 1;
                        end = splitData.length;
                    } else {
                        start = postData[index - 1].pageNo + 1;
                        end = page.pageNo;
                    }
                    extractPdfPage(start, end, page.rotate).then(response => {
                        resolve({
                            ...splitData[start - 1],
                            extractedPageBase64: response,
                            fileName: `Pg${start}-${end}.pdf`,
                            pageRange: `Start ${start} - End ${end}`,
                        });
                    });
                });
            });
        Promise.allSettled(promises).then(resolve => {
            const requestbody = resolve
                .filter(result => result.status === "fulfilled")
                .map(result => {
                    return result.value;
                });
            const filteredRequestBody = requestbody.filter(x => x.extractedPageBase64 !== null);
            apiCall(filteredRequestBody);
        });
    };

    const fileLoader = (
        <SBox className="no-border">
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        </SBox>
    );

    const rotatePage = (e, index) => {
        const splitDataSlice = splitData.slice();
        splitDataSlice[index].rotate =
            splitData[index].rotate + 90 > 270 ? 0 : splitData[index].rotate + 90;
        setSplitData(splitDataSlice);
    };

    const deletePage = (e, index) => {
        const splitDataSlice = splitData.slice();
        splitDataSlice[index].isDelete = !splitDataSlice[index].isDelete;
        setSplitData(splitDataSlice);
    };

    let splitCertificateDialogDOM = null;
    if (hasCertificateFile && isSplitCertificateDialogOpen) {
        splitCertificateDialogDOM = (
            <SDialog
                className="fullScreen"
                id="splitCertificate-dialog"
                open={isSplitCertificateDialogOpen}
                onS-dismiss={() => {
                    dispatch(setIsSplitCertificateDialogOpen(false));
                }}
                aria-modal="true">
                <div slot="header" id="splitCertificate">
                    Split certificate images
                </div>
                <div slot="body">
                    <Document
                        file={validationData.data}
                        loading={fileLoader}
                        noData={fileLoader}
                        onLoadSuccess={e => onDocumentLoadSuccess(e)}>
                        {Array.from(splitData, (el, index) => (
                            <React.Fragment key={`Fragment_${index + 1}`}>
                                <div className="splitCertificateDialogWrapper">
                                    <div id={`pageDiv_${index + 1}`}>
                                        <Page
                                            className={[
                                                "margin-all-sm",
                                                "pdf-page-preview",
                                                `page_${index + 1}`,
                                            ]}
                                            key={`page_${index + 1}`}
                                            scale={props.scale ? props.scale : 1.0}
                                            pageNumber={index + 1}
                                            id={index + 1}
                                            rotate={splitData[index].rotate}
                                            isDelete={splitData[index].isDelete}
                                        />
                                        <div
                                            id="overlay"
                                            className={splitData[index].isDelete ? "overlay" : ""}
                                        />
                                        {index < splitData.length - 1 ? (
                                            <div
                                                id="toggleDiv"
                                                className="margin-top-sm toggleDivBg">
                                                <SToggle
                                                    className="margin-left-md remove-toggle-focus"
                                                    id={`toggle_${index + 1}`}
                                                    key={`toggle_${index + 1}`}
                                                    aria-label="Split certificate"
                                                    onS-toggle={e => handleToggle(e, index + 1)}
                                                    checked={el.isSplitPage}
                                                />
                                                <span
                                                    id={`toggle_${index + 1}`}
                                                    key={`span_${index + 1}`}
                                                    placeholder="Split here"
                                                    className="margin-left-xs toggle-text-line">
                                                    Split here
                                                </span>
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="split-certificate-dropdowns">
                                        <SRow className="margin-top-xs text-right">
                                            <SCol span="auto">
                                                <button
                                                    disabled={splitData[index].isDelete}
                                                    className="tertiary"
                                                    onClick={e => {
                                                        rotatePage(e, index);
                                                    }}>
                                                    <SIcon name="refresh" aria-hidden="true" />
                                                    <span className="margin-left-xs">Rotate</span>
                                                </button>
                                                <button
                                                    className="tertiary"
                                                    onClick={e => {
                                                        deletePage(e, index);
                                                    }}>
                                                    <SIcon
                                                        name={
                                                            splitData[index].isDelete
                                                                ? "undo"
                                                                : "trash"
                                                        }
                                                        aria-hidden="true"
                                                    />
                                                    <span className="margin-left-xs">
                                                        {splitData[index].isDelete
                                                            ? "Restore"
                                                            : "Delete"}
                                                    </span>
                                                </button>
                                                {index === 0 || splitData[index - 1].isSplitPage ? (
                                                    <>
                                                        <SplitCertCustomerTypeAhead
                                                            key={`typeaheadId_${index + 1}`}
                                                            typeId={`typeaheadId_${index + 1}`}
                                                            handleAdd={e =>
                                                                addCustomer(e, index + 1)
                                                            }
                                                            handleRemove={e =>
                                                                removeCustomer(e, index + 1)
                                                            }
                                                            value={el.customer}
                                                            certificateId={props.certificateID}
                                                            multiple
                                                        />
                                                        <SplitCertSelectExposureZone
                                                            inputId={`region_${index + 1}`}
                                                            key={`region_${index + 1}`}
                                                            onAdd={e =>
                                                                handleRegionAdd(e, index + 1)
                                                            }
                                                            onRemove={e =>
                                                                handleRegionRemove(e, index + 1)
                                                            }
                                                            value={el.regionId}
                                                            multiple={false}
                                                            exposureZoneDisabled={false}
                                                        />
                                                        <SplitCertSelectExemptionReasons
                                                            inputId={`reason_${index + 1}`}
                                                            key={`reason_${index + 1}`}
                                                            optionsList={el.reasonsList}
                                                            isLoading={el.isLoadingReasons}
                                                            onAdd={handleReasonAdd}
                                                            onRemove={handleReasonRemove}
                                                            index={index + 1}
                                                            value={el.reasonId}
                                                            multiple={false}
                                                            disabled={false}
                                                        />
                                                    </>
                                                ) : null}
                                            </SCol>
                                        </SRow>
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </Document>
                </div>

                <div slot="footer">
                    <button
                        className="secondary small"
                        onClick={() => dispatch(setIsSplitCertificateDialogOpen(false))}>
                        Cancel
                    </button>
                    <button
                        className={reviewButton}
                        disabled={
                            !(splitData.filter(x => x.isSplitPage).length > 1) &&
                            !splitData.some(x => x.isDelete === true)
                        }
                        onClick={() => {
                            dispatch(setIsSplittingCertificates(true));
                            calculateSplitPageRange();
                        }}>
                        Review now
                    </button>
                </div>
            </SDialog>
        );
    }

    return splitCertificateDialogDOM;
}

export default SplitCertificateDialog;
