import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SCol, SInputExtended } from "@avalara/skylab-react";
import {
    selectPendingFormState,
    setPendingState,
    fetchPendingCertificatesSearchAPI,
} from "../../../app/certificateSlice";
import "./pending.css";

const PendingSearchForm = React.memo(props => {
    const dispatch = useDispatch();
    const pendingFormState = useSelector(selectPendingFormState);
    const { nameOrCodeOrFileOrId } = pendingFormState;

    const handleSubmitForm = () => {
        dispatch(fetchPendingCertificatesSearchAPI());
        props.setSelectedRows([]);
        props.setDisableDelete(true);
        props.setRowCount(0);
    };

    const clearSearch = async () => {
        await dispatch(
            setPendingState({
                ...pendingFormState,
                nameOrCodeOrFileOrId: "",
            })
        );
        handleSubmitForm();
    };

    const handleInput = el => {
        dispatch(
            setPendingState({
                ...pendingFormState,
                [el.detail.inputId]: el.detail.value.trim(),
            })
        );
        setTimeout(() => {
            handleSubmitForm();
        }, 400);
    };

    const handleSearch = async el => {
        await dispatch(
            setPendingState({
                ...pendingFormState,
                [el.detail.inputId]: el.detail.value.trim(),
            })
        );
        handleSubmitForm();
    };

    const certificateDOM = (
        <SCol span="md-6 xl-6 5" className="pad-bottom-none">
            <label htmlFor="nameOrCodeOrFileOrId">
                Search by Certificate ID, customer name, customer code, or file name
            </label>
            <SInputExtended
                inputId="nameOrCodeOrFileOrId"
                aria-label="search"
                type="search"
                onS-input={e => handleInput(e)}
                onS-search={e => handleSearch(e)}
                onS-clear={() => clearSearch()}
                value={nameOrCodeOrFileOrId}
            />
        </SCol>
    );

    return certificateDOM;
});
export default PendingSearchForm;
