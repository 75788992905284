import { useDispatch, useSelector } from "react-redux";
import featureFlag from "./featureFlag";
import { validateSubscriptions } from "../shared/sessionUtility";
import { validateSettings, selectIsSavingSettings } from "../app/settingsSlice";

const FeatureToggler = props => {
    const { category, id, children, firstChild, secondChild } = props;
    const feature =
        featureFlag[category] && featureFlag[category][id] ? featureFlag[category][id] : null;
    let dom = secondChild || null;

    const isSavingSetting = useSelector(selectIsSavingSettings);

    const dispatch = useDispatch();
    // check for subscription and tier
    const isValidated = dispatch(validateSubscriptions(feature));

    const isSettingsEnabled =
        !Object.hasOwn(feature, "setting") ||
        (!isSavingSetting && dispatch(validateSettings(feature?.setting)));

    let isHiddenEnv = false;
    if (feature != null && Object.hasOwn(feature, "env") && feature.env) {
        isHiddenEnv = !feature.env.includes(process.env.REACT_APP_ENV);
    }
    if (!isHiddenEnv && isValidated && isSettingsEnabled) {
        dom = firstChild || children;
    }
    return dom;
};

export default FeatureToggler;
