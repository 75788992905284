import { DataGrid, gridHeader } from "@certcapture/react-components";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { SLoader, SPagination, STag } from "@avalara/skylab-react";
import { v4 as uuidv4 } from "uuid";
import {
    fetchCertificateRequestFormsAPI,
    selectIsLoadingCertificateRequestForm,
    selectNoDataType,
    selectPage,
    selectRequestingCertificateFormsDialogOpen,
    selectSortColumn,
    setPageToNull,
    setRequestingCertificateFormsDialogOpen,
    setSortColumn,
} from "../../../../app/settingsSlice";

import { isEligibleUser } from "../../../../shared/sessionUtility";
import featureFlag from "../../../../featureToggler/featureFlag";
import expirationTimeFrames from "./ExpirationTimeFrames";
import RemoveConfirmationDialog from "./RemoveConfirmationDialog";
import RequestingCertificateFormsDialog from "./RequestingCertificateFormsDialog";

const CertificateRequestFormsGrid = React.memo(() => {
    const dispatch = useDispatch();
    const loading = useSelector(selectIsLoadingCertificateRequestForm);
    const noDataType = useSelector(selectNoDataType);
    const [submitting, setSubmitting] = useState(false);
    const page = useSelector(selectPage, shallowEqual);
    const pageData = page?.data;
    const paginateData = page.paginate;
    const certificateFormsDialogOpen = useSelector(selectRequestingCertificateFormsDialogOpen);
    const [certificateForm, setCertificateForm] = useState({});
    const sortColumn = useSelector(selectSortColumn);
    const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
    const isEligibleUserAddMatrix = dispatch(
        isEligibleUser(featureFlag.settings.requestingCertficateExemptionMatrix)
    );

    useEffect(() => {
        dispatch(fetchCertificateRequestFormsAPI(false));
        return () => {
            dispatch(setPageToNull([]));
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getExpirationTimeFrame = timeFrame => {
        const expirationTimeFrame = expirationTimeFrames.find(x => x.id === timeFrame);

        if (expirationTimeFrame) return expirationTimeFrame.label;

        return timeFrame;
    };

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            dispatch(
                fetchCertificateRequestFormsAPI(
                    false,
                    false,
                    e.detail.rowsPerPage,
                    e.detail.startIndex,
                    false,
                    e.detail
                )
            );
            setSubmitting(false);
        }
    };

    async function sortFunction(e) {
        setSubmitting(true);
        const columnId = e.detail.id;
        const order = sortColumn[0] === columnId ? !sortColumn[1] : true;
        dispatch(setSortColumn([columnId, order]));
        dispatch(fetchCertificateRequestFormsAPI(false));
        setSubmitting(false);
    }

    const columns = [
        gridHeader(
            "Region",
            "regionName",
            value => value,
            null,
            null,
            null,
            null,
            sortFunction,
            "region.name"
        ),
        gridHeader("Reason for exemption", "exemptReasonName", value => value, null, "", null),
        gridHeader(
            "Form",
            "formName",
            value => (value === null || value === undefined ? "Manual Submission Required" : value),
            null,
            "",
            null
        ),
        gridHeader(
            "Expiration timeframe",
            "timeLength",
            value => getExpirationTimeFrame(value),
            null,
            "",
            null
        ),
        gridHeader(
            "Avalara defaults?",
            "setToDefault",
            value =>
                value ? (
                    <div>
                        <STag id={`tag-${uuidv4()}`} color="blue-light" className="text-ellipsis">
                            <span slot="content">Yes</span>
                        </STag>
                    </div>
                ) : (
                    <div>
                        <STag id={`tag-${uuidv4()}`} className="text-ellipsis">
                            <span slot="content">No</span>
                        </STag>
                    </div>
                ),
            "text-center",
            "text-center",
            null
        ),
        gridHeader(
            "",
            "",
            (value, row) => (
                <button
                    type="button"
                    className="secondary small"
                    onClick={() => {
                        setCertificateForm(row);
                        dispatch(setRequestingCertificateFormsDialogOpen(true));
                    }}>
                    Details
                </button>
            ),
            "text-left",
            "text-left",
            null
        ),
    ];

    columns.push(
        gridHeader(
            "",
            "",
            (value, row) => (
                <button
                    className="link"
                    disabled={!isEligibleUserAddMatrix}
                    onClick={() => {
                        setIsRemoveDialogOpen(true);
                        setCertificateForm(row);
                    }}>
                    Remove
                </button>
            ),
            "text-left",
            "text-left",
            null
        )
    );

    const getRowKey = () => {
        return uuidv4();
    };

    let noDataMsg = "";
    switch (noDataType) {
        case "onLanding":
            noDataMsg = (
                <div className=" margin-all-xl">
                    <div id="no-data-landing-header" className="text-center color-gray-dark">
                        <div className="font-semibold font-normal text-lg">No forms found</div>
                        <div className="text-sm">You have no certificate requests.</div>
                    </div>
                </div>
            );
            break;
        case "onSearch":
            noDataMsg = (
                <div className=" margin-all-xl">
                    <div id="no-certificate-request-found" className="text-center color-gray-dark">
                        <div className="font-semibold font-normal text-lg">No forms found</div>
                        <div>Adjust your search or add a region and reason.</div>
                    </div>
                </div>
            );
            break;
        default:
            noDataMsg = null;
    }
    let table = null;
    if (submitting || loading) {
        table = (
            <div className="flex flex-dir-col dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="grid-loader" className="medium" aria-live="polite" loading />
            </div>
        );
    } else if (noDataType) {
        table = noDataMsg;
    } else {
        table = (
            <s-table-container key={uuidv4()} class="margin-top-xl">
                <DataGrid
                    columns={columns}
                    rows={pageData}
                    getKey={getRowKey}
                    loading={loading}
                    sortColumn={sortColumn}
                    inContainer
                />
            </s-table-container>
        );
    }

    return (
        <React.Fragment>
            {table}
            {paginateData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={paginateData.rowsPerPage}
                    totalRecords={paginateData.totalRecords}
                    startIndex={paginateData.startIndex}
                    onS-paginate={e => {
                        handlePaginate(e);
                    }}
                />
            ) : null}

            {certificateFormsDialogOpen ? (
                <RequestingCertificateFormsDialog
                    certificateFormsDialogOpen={certificateFormsDialogOpen}
                    certificateForm={certificateForm}
                    outgoing
                />
            ) : null}

            {isRemoveDialogOpen ? (
                <RemoveConfirmationDialog
                    setIsRemoveConfirmationDialogOpen={setIsRemoveDialogOpen}
                    isRemoveRegionReason={isRemoveDialogOpen}
                    certificateForm={certificateForm}
                />
            ) : null}
        </React.Fragment>
    );
});

export default CertificateRequestFormsGrid;
