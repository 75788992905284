import React, { useState } from "react";
import { SDialog } from "@avalara/skylab-react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import {
    selectIsDeleting,
    deleteCustomerCustomField,
} from "../../../../app/customerCustomFieldSlice";

const DeleteCustomersCustomFieldDialog = React.memo(props => {
    const dialogName = "DeleteCustomersCustomFieldDialog";
    const dispatch = useDispatch();
    const isDeleting = useSelector(selectIsDeleting);
    const [isInUse, setIsInUse] = useState(false);
    const deleteButton = classnames({
        primary: true,
        small: true,
        loading: isDeleting,
    });

    const handleDelete = async event => {
        event.preventDefault();
        const response = await dispatch(deleteCustomerCustomField(props.customerCustomField.id));
        setIsInUse(!response);
        props.setIsDeleteDialogOpen(!response);
    };

    return (
        <SDialog
            id={dialogName}
            open={props.isDeleteDialogOpen}
            onS-dismiss={() => props.setIsDeleteDialogOpen(false)}
            aria-modal="true">
            <div slot="header" id="deleteCustomField">
                {isInUse
                    ? "Can't delete this custom field."
                    : "Are you sure you want to delete this custom field?"}
            </div>
            <div slot="body">
                {isInUse ? (
                    <>
                        <div>
                            This custom field is being used for one or more customers in this
                            company. You must remove
                        </div>
                        <div>
                            values in this custom field from all customers before it can be deleted.
                        </div>
                    </>
                ) : (
                    "You can't undo this action, but you can recreate this customer field later."
                )}
            </div>
            <div slot="footer">
                {isInUse ? (
                    <button
                        className="secondary small"
                        onClick={() => props.setIsDeleteDialogOpen(false)}>
                        Back
                    </button>
                ) : (
                    <>
                        <button
                            className="secondary small"
                            onClick={() => props.setIsDeleteDialogOpen(false)}>
                            Cancel
                        </button>
                        <button className={deleteButton} onClick={handleDelete}>
                            Yes, I&apos;m sure
                        </button>
                    </>
                )}
            </div>
        </SDialog>
    );
});

export default DeleteCustomersCustomFieldDialog;
