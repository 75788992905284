import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { buildURL } from "../shared/Utils";
import toast from "../hooks/toast";

const initialState = {
    isLoading: false,
    isDeleting: false,
    isAddOrEditLoading: false,
    enableLoading: "",
    page: {
        data: [],
        paginate: {
            pageNumber: 0,
            startIndex: -1,
            endIndex: 0,
            rowsPerPage: 0,
            totalRecords: 0,
        },
    },
    sortColumn: ["fieldName", true],
    formState: {
        fieldName: "",
    },
};

export const customerCustomFieldSlice = createSlice({
    name: "customerCustomField",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setIsDeleting: (state, action) => {
            state.isDeleting = action.payload;
        },
        setIsAddOrEditLoading: (state, action) => {
            state.isAddOrEditLoading = action.payload;
        },
        setEnableLoading: (state, action) => {
            state.enableLoading = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setFormState: (state, action) => {
            state.formState = action.payload;
        },
        clearFormState: state => {
            state.formState = initialState.formState;
        },
        setIsLimitReached: (state, action) => {
            state.setIsLimitReached = action.payload;
        },
    },
});

const [showToast] = toast();
export const {
    setIsLoading,
    setPage,
    setPageToNull,
    setFormState,
    clearFormState,
    setIsDeleting,
    setIsAddOrEditLoading,
    setEnableLoading,
    setSortColumn,
    setIsLimitReached,
} = customerCustomFieldSlice.actions;

export const selectIsLoading = state => {
    return state.customerCustomField.isLoading;
};
export const selectPage = state => {
    return state.customerCustomField.page;
};
export const selectFormState = state => {
    return state.customerCustomField.formState;
};
export const selectIsDeleting = state => {
    return state.customerCustomField.isDeleting;
};
export const selectIsAddOrEditLoading = state => {
    return state.customerCustomField.isAddOrEditLoading;
};
export const selectEnableLoading = state => {
    return state.customerCustomField.enableLoading;
};
export const selectSortColumn = state => {
    return state.customerCustomField.sortColumn;
};
export const selectIsLimitReached = state => {
    return state.customerCustomField.setIsLimitReached;
};

export const fetchCustomerCustomFields =
    (top = 100, skip, paginateDetails) =>
    async (dispatch, getState) => {
        const { isLoading, sortColumn, formState } = getState().customerCustomField;
        const { fieldName } = formState;

        if (isLoading) {
            return;
        }
        let filterString = "";
        dispatch(setIsLoading(true));
        const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
        if (fieldName) {
            filterString = `fieldName contains '${formState.fieldName}'`;
        }

        const apiURI = buildURL(
            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customer-custom-fields`,
            filterString,
            null,
            top,
            skip,
            sort
        );
        const response = await axios.get(`${apiURI}`, { withCredentials: true });

        if (response?.data?.[0]?.code?.toLowerCase() === "objectnotfound") {
            dispatch(setPageToNull());
            dispatch(setIsLoading(false));
            dispatch(setIsLimitReached(false));
            return;
        }

        if (response.data && response.data.count === "0") {
            dispatch(setPageToNull());
            dispatch(setIsLimitReached(false));
        } else {
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.pageNumber,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response.data.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 20,
                      totalRecords: response.data.count,
                  };
            dispatch(
                setPage({
                    data: response.data.value,
                    paginate: paginateData,
                })
            );
            if (paginateData.totalRecords >= 50) dispatch(setIsLimitReached(true));
            else dispatch(setIsLimitReached(false));
        }

        dispatch(setIsLoading(false));
    };

export const deleteCustomerCustomField = id => async (dispatch, getState) => {
    const { isDeleting } = getState().customerCustomField;
    if (isDeleting) {
        return false;
    }
    dispatch(setIsDeleting(true));
    const response = await axios.delete(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customer-custom-fields/${id}`,
        { withCredentials: true }
    );
    if (response?.data?.success) {
        showToast("success", "Custom field deleted", "deleteCustomerCustomFieldToast", false);
        dispatch(fetchCustomerCustomFields());
    }
    dispatch(setIsDeleting(false));
    return response?.data?.success;
};

export const AddOrUpdateCustomerCustomFields =
    (addOrEdit, payload, customerCustomFieldId) => async dispatch => {
        dispatch(setIsAddOrEditLoading(true));
        let success = false;
        if (addOrEdit === "Add") {
            await axios
                .post(
                    `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customer-custom-fields`,
                    payload,
                    { withCredentials: true }
                )
                .then(x => {
                    if (!x.data.success && x.status === 200) {
                        showToast(
                            "error",
                            `Custom field already exists.`,
                            "custom-field-error-toast",
                            false
                        );
                    }
                    if (x.status === 200 && x.data.success) {
                        showToast(
                            "success",
                            `Custom field added.`,
                            "custom-field-success-toast",
                            false
                        );
                        success = true;
                        dispatch(fetchCustomerCustomFields());
                    }
                });
        } else {
            await axios
                .put(
                    `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customer-custom-fields/${customerCustomFieldId}`,
                    payload,
                    { withCredentials: true }
                )
                .then(x => {
                    if (x.name === "AxiosError" && x.response.status === 400) {
                        showToast(
                            "error",
                            `Custom field updated.`,
                            "custom-field-error-toast",
                            false
                        );
                    }
                    if (x.status === 200) {
                        if (x?.data[0]?.message) {
                            showToast(
                                "error",
                                `${x?.data[0]?.message}`,
                                "custom-field-error-toast",
                                false
                            );
                        } else {
                            showToast(
                                "success",
                                `Successfully updated customer custom field`,
                                "custom-field-success-toast",
                                false
                            );
                        }
                        success = true;
                        dispatch(fetchCustomerCustomFields());
                    }
                });
        }
        dispatch(setIsAddOrEditLoading(false));
        return success;
    };

export default customerCustomFieldSlice.reducer;
