import React, { useEffect } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";
import { checkSelectComponentProps } from "../../shared/Utils";
import { selectSession } from "../../app/sessionSlice";

import {
    getExemptionReasonsAsync,
    selectExemptionReasons,
    selectIsLoadingExemptionReasons,
} from "../../app/certificateSlice";

const selectModifiedExemptionReasons = createSelector(
    selectExemptionReasons,
    (_, props) => props,
    (reasons, props) => {
        return reasons.map(reason => {
            const selectOption = {
                label: reason.name,
                value: reason.id,
                selected: false,
            };
            if (
                props.multiple &&
                (props?.value?.includes(reason.name) || props?.value?.includes(reason.id))
            ) {
                selectOption.selected = true;
            } else if (reason.name === props?.value || reason.id === props?.value) {
                selectOption.selected = true;
            }
            return selectOption;
        });
    }
);

function SelectExemptionReasons(props, ref) {
    const [getCurrentId, getNextId] = useId();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingExemptionReasons);
    const exemptionReasons = useSelector(
        state => selectModifiedExemptionReasons(state, props),
        shallowEqual
    );
    if (props?.isAll && !props.value) {
        exemptionReasons.unshift({ label: "All", value: "-1", selected: true });
    }

    const session = useSelector(selectSession, shallowEqual);
    checkSelectComponentProps("SelectExemptionReasons", props);

    useEffect(() => {
        if (session.activeCompany.id !== null) {
            dispatch(
                getExemptionReasonsAsync(
                    '?$filter=DenotesExemption eq true AND DocumentType.Name eq "Sales and Use Tax" AND Enabled eq true'
                )
            );
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <label htmlFor={getNextId()} id="lbl-exemptionReason">
                Reason for exemption
            </label>
            <SSelect
                name="exemptionReason"
                inputId={getCurrentId()}
                ref={ref}
                selectionOptional
                optionsList={exemptionReasons}
                multiple={props.multiple}
                loading={isLoading}
                className={props.error ? props.error : ""}
                onS-select={e => props.onAdd(e.detail.item)}
                onS-deselect={e => props.onRemove(e.detail.item)}
                onS-close={e => (props.onClose ? props.onClose(e) : null)}
                disabled={props.disabled ? "disabled" : null}
                value={props?.value}
            />
        </React.Fragment>
    );
}

export default React.forwardRef(SelectExemptionReasons);
