import React from "react";
import { SIcon } from "@avalara/skylab-react";

const VendorRequestValidationError = React.memo(props => {
    return (
        <div className="input-msg">
            <SIcon name="alert-circle-filled" aria-hidden="true" />
            <span className="top-xs">Enter a {props.fieldName}</span>
        </div>
    );
});

export default VendorRequestValidationError;
