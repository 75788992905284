import React, { useState, useEffect, useCallback, useMemo } from "react";
import { SRow, SCol, SIcon, SDatepicker, SAlert, SSelect, SBox } from "@avalara/skylab-react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
    selectExposureZones,
    selectExemptionMatrixValidation,
    selectIsInvalidReasonDialogOpen,
    incrementValidationStateCount,
} from "../../app/certificateSlice";
import {
    HttpStatusCode,
    TaxNumberTypeList,
    AllowedTaxNumberTypeList,
    AlertType,
    TaxIdAlertReason,
} from "../../shared/constants";
import toast from "../../hooks/toast";
import axiosInstanceWithoutInterceptor from "../../axiosWithoutInterceptor";
import getValidTaxIdForState from "./GetValidTaxIdForState";
import { selectSetting } from "../../app/settingsSlice";
import { errorTag, removeSpaces } from "../../shared/Utils";
import ZoomTip from "../sharedComponents/ZoomTip";

const Multi = React.memo(props => {
    const dispatch = useDispatch();
    const {
        updatePostData,
        clearPostData,
        zoneId,
        zoneName,
        certificateId,
        effectiveDate,
        existingExpirationDate,
        exemptReason,
        submitted,
        autoValidationPredictionsData,
        certStatus,
        existingEffectiveDate,
        existingExpectedTaxCodeName,
        existingExposureZoneName,
        isExistingZone,
        setInvalidExpirationDate,
        taxValidationCode,
        taxValidationMessage,
        avsPredictedZoneInitial,
        isMulti,
    } = props;
    const autoValidationPredictions = autoValidationPredictionsData
        ? JSON.parse(autoValidationPredictionsData)
        : undefined;

    const isInvalidReasonDialogOpen = useSelector(selectIsInvalidReasonDialogOpen);
    const currentDate = new Date().toISOString().slice(0, 10);
    const expirationDateMapping = useMemo(() => {
        return {
            NVR: "9999-12-31",
            IMM: currentDate,
            EOM: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
                .toISOString()
                .slice(0, 10),
            EOY: new Date(new Date().getFullYear(), 11, 31).toLocaleDateString("fr-CA"),
            OVR: null,
        };
    }, [currentDate]);
    const matrixValue = useSelector(selectExemptionMatrixValidation, shallowEqual).filter(
        matrix => matrix.region.name === zoneName && matrix.exemptReason.name === exemptReason
    )[0];
    const matrixTimeLength = matrixValue?.timeLength;
    const matrixTaxCodeFormat = matrixValue?.taxCodeFormat?.format;
    const matrixTaxCodeRegex = matrixValue?.taxCodeFormat?.regex;
    const matrixEnableStateVerification = matrixValue?.taxCodeFormat?.enableStateVerification;
    const [expirationDate, setExpirationDate] = useState(
        existingExpirationDate.length > 0 ? existingExpirationDate : expirationDateMapping.EOY
    );
    const settings = useSelector(selectSetting, shallowEqual);
    const { requireTaxId } = settings;
    const [requireTaxPayerId] = useState(requireTaxId);
    const [taxNumberType, setTaxNumberType] = useState(null);
    const [taxId, setTaxID] = useState("");
    const [taxIdRegex, setTaxIdRegex] = useState([/.{1,}/]);
    const [taxIdFormat, setTaxIdFormat] = useState("");
    const [taxIdStatus, setTaxIdStatus] = useState({
        code: 0,
        state: "",
        reason: "",
        message: "",
    });
    const [dateOverride, setDateOverride] = useState(false);
    const [expirationDateError, setExpirationDateError] = useState("");
    const [taxNumberTypeError, setTaxNumberTypeError] = useState("");
    const dateErrorMsg = "Enter a date";
    const taxNumberTypeErrorMsg = "Enter a tax ID type";
    const invalidDateErrorMsg = "Enter the date in MM/DD/YYYY format";
    const region = useSelector(selectExposureZones, shallowEqual);
    const [validating, setValidating] = useState(false);
    const [submitForValidation, setSubmitForValidation] = useState(false);
    const [showToast] = toast();
    const getExpirationDate = timeLength => {
        let calculateDate = new Date();
        let lastDayOfMonth = new Date(calculateDate.getFullYear(), calculateDate.getMonth() + 1, 0);
        let lastDayOfYear = new Date(calculateDate.getFullYear(), 11, 31);

        if (effectiveDate) {
            calculateDate = new Date(effectiveDate.replace(/-/g, "/"));
            lastDayOfMonth = new Date(calculateDate.getFullYear(), calculateDate.getMonth() + 1, 0);
            lastDayOfYear = new Date(calculateDate.getFullYear(), 11, 31);
        }
        if (effectiveDate && currentDate !== effectiveDate) {
            expirationDateMapping.EOM = lastDayOfMonth.toISOString().slice(0, 10);
            expirationDateMapping.EOY = lastDayOfYear.toISOString().slice(0, 10);
            expirationDateMapping.IMM = effectiveDate;
        }
        if (expirationDateMapping[timeLength] || timeLength === "OVR") {
            return expirationDateMapping[timeLength];
        }

        const regexTest1 = /(\d+)\s(EOM|EOY)\+(.*)/;
        const regexTest2 = /(\d+)\s(months|days)\+?(.*)/;
        const regexMatch1 = timeLength.match(regexTest1);
        const regexMatch2 = timeLength.match(regexTest2);
        let expirationEntry;
        let incrementValue;
        let dateType;
        let endOfType = null;

        if (regexMatch1) {
            [expirationEntry, incrementValue, endOfType, dateType] = regexMatch1;
        } else if (regexMatch2) {
            [expirationEntry, incrementValue, dateType, endOfType] = regexMatch2; // eslint-disable-line no-unused-vars
        }
        incrementValue = parseInt(incrementValue, 10);
        const targetDate = new Date(calculateDate.getFullYear(), calculateDate.getMonth() + 1, 1);

        if (regexMatch1) {
            if (endOfType === "EOM") {
                if (dateType === "months") {
                    targetDate.setMonth(targetDate.getMonth() + incrementValue);
                    targetDate.setDate(0);
                    if (lastDayOfMonth.getDate() > targetDate.getDate()) {
                        return targetDate.toISOString().slice(0, 10);
                    }
                    lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + incrementValue);
                } else {
                    lastDayOfMonth.setDate(lastDayOfMonth.getDate() + incrementValue);
                }
                return lastDayOfMonth.toISOString().slice(0, 10);
            }
            if (endOfType === "EOY") {
                // eslint-disable-next-line no-unused-expressions
                dateType === "months"
                    ? lastDayOfYear.setMonth(lastDayOfYear.getMonth() + incrementValue)
                    : lastDayOfYear.setDate(lastDayOfYear.getDate() + incrementValue);
                return lastDayOfYear.toISOString().slice(0, 10);
            }
        }

        if (regexMatch2) {
            if (dateType === "months") {
                targetDate.setMonth(targetDate.getMonth() + incrementValue);
                targetDate.setDate(0);
                if (calculateDate.getDate() > targetDate.getDate()) {
                    calculateDate = new Date(
                        targetDate.getFullYear(),
                        targetDate.getMonth(),
                        targetDate.getDate()
                    );
                } else {
                    calculateDate.setMonth(calculateDate.getMonth() + incrementValue);
                }
            } else {
                calculateDate.setDate(calculateDate.getDate() + incrementValue);
            }

            if (!endOfType) {
                return calculateDate.toISOString().slice(0, 10);
            }

            if (endOfType === "EOM") {
                const monthEndDate = new Date(
                    calculateDate.getFullYear(),
                    calculateDate.getMonth() + 1,
                    0
                );
                return monthEndDate.toISOString().slice(0, 10);
            }
            if (endOfType === "EOY") {
                const yearEndDate = new Date(calculateDate.getFullYear(), 11, 31);
                return yearEndDate.toISOString().slice(0, 10);
            }
        }

        return calculateDate.toISOString().slice(0, 10);
    };
    const expirationDateFromMatrix = matrixTimeLength && getExpirationDate(matrixTimeLength);

    const setExpirationDateFromMatrix = useCallback(() => {
        let expiration = null;
        if (matrixTimeLength) {
            expiration = expirationDateFromMatrix;
            setExpirationDate(expirationDateFromMatrix);
            updatePostData(zoneId, "expirationDate", expiration);
        }
        if (expiration) {
            setExpirationDateError("");
        } else {
            setExpirationDate("");
            setExpirationDateError(dateErrorMsg);
        }
    }, [matrixTimeLength, updatePostData, zoneId, expirationDateFromMatrix]);

    // set expiration date as per matrix timelength when region-reason combination changed or effective date changed
    useEffect(() => {
        if (
            (autoValidationPredictions?.state?.toUpperCase() !== zoneName.toUpperCase() ||
                autoValidationPredictions.expirationDate === undefined) &&
            (existingEffectiveDate?.slice(0, 10) !== effectiveDate ||
                existingExpectedTaxCodeName !== exemptReason ||
                (existingExposureZoneName !== zoneName && !isExistingZone))
        ) {
            setExpirationDateFromMatrix();
        }
    }, [
        existingEffectiveDate,
        existingExpectedTaxCodeName,
        existingExposureZoneName,
        exemptReason,
        zoneName,
        effectiveDate,
        setExpirationDateFromMatrix,
        JSON.stringify(autoValidationPredictions),
        isExistingZone,
    ]);

    // when reason is changed then reset tax validation status
    useEffect(() => {
        if (existingExpectedTaxCodeName !== exemptReason) {
            setTaxIdStatus({
                code: 0,
                state: "",
                reason: "",
                message: "",
            });
        }
    }, [existingExpectedTaxCodeName, exemptReason]);

    // set expiration date and tax id as per AVS
    useEffect(() => {
        if (autoValidationPredictions?.state?.toUpperCase() === zoneName.toUpperCase()) {
            if (props.clearPostData) {
                clearPostData();
            }
            if (autoValidationPredictions.expirationDate) {
                const predictedExpiration = autoValidationPredictions.expirationDate;
                setExpirationDate(predictedExpiration);
                updatePostData(zoneId, "expirationDate", predictedExpiration);
                if (predictedExpiration < effectiveDate) {
                    setExpirationDateError("Must be on or after the effective date");
                }
            }
        }
        if (autoValidationPredictions?.taxIds) {
            let predictedTaxId = null;
            if (isMulti) {
                predictedTaxId = autoValidationPredictions.taxIds.filter(
                    x => x.state === avsPredictedZoneInitial
                );
            }
            predictedTaxId = predictedTaxId
                ? predictedTaxId[0]?.value
                : autoValidationPredictions.taxIds[0].value;
            setTaxID(predictedTaxId);
            updatePostData(zoneId, "taxNumber", predictedTaxId);
        }
    }, [JSON.stringify(autoValidationPredictions)]); // eslint-disable-line

    const handleTaxIdTypeChange = useCallback(
        e => {
            const value = e?.detail?.item?.label;
            updatePostData(zoneId, "taxNumberType", value);
            setTaxNumberType(value);
        },
        [updatePostData, zoneId]
    );
    const handleTaxIdTypeRemove = () => {
        updatePostData(zoneId, "taxNumberType", null);
        setTaxNumberType(null);
        setTaxNumberTypeError(taxNumberTypeErrorMsg);
    };
    // change event: set taxId on input change
    const handleInputChange = useCallback(
        e => {
            const value = e.target?.value;
            updatePostData(zoneId, "taxNumber", value);
            if (value.trim() === "" && requireTaxPayerId === true) {
                setTaxIdStatus({
                    code: 0,
                    state: AlertType.Error,
                    reason: TaxIdAlertReason.RegexTest,
                    message: "Enter the Taxpayer ID",
                });
            } else
                setTaxIdStatus({
                    code: 0,
                    state: "",
                    reason: "",
                    message: "",
                });

            setTaxID(value.trim());
        },
        [updatePostData, zoneId]
    );

    // set postData obj on change of taxValidationStatus
    useEffect(() => {
        updatePostData(zoneId, "taxValidation", {
            code: taxIdStatus.code,
            message: taxIdStatus.message,
        });
    }, [taxIdStatus.message, zoneId, taxIdStatus.code, updatePostData]);

    // sets taxId on basis of incoming certificate tax number
    useEffect(() => {
        if (props.taxNumber !== "") {
            setTaxID(prevTaxId => {
                if (prevTaxId !== props.taxNumber.trim()) {
                    return props.taxNumber.trim();
                }
                return prevTaxId;
            });
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if (!props?.taxNumberType || props?.taxNumberType === "") {
            updatePostData(zoneId, "taxNumberType", "Tax ID Number");
            setTaxNumberType("Tax ID Number");
        } else if (AllowedTaxNumberTypeList.includes(props?.taxNumberType)) {
            setTaxNumberType(props.taxNumberType);
        } else {
            setTaxNumberType(null);
            setTaxNumberTypeError(taxNumberTypeErrorMsg);
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if (
            (certStatus === "Invalid" || certStatus === "Pending") &&
            taxIdStatus.message === "Enter the Taxpayer ID" &&
            isInvalidReasonDialogOpen === true
        ) {
            setTaxIdStatus({
                code: 0,
                state: "",
                reason: "",
                message: "",
            });
        }
    }, [taxIdStatus.message, certStatus, isInvalidReasonDialogOpen]);

    useEffect(() => {
        if (!exemptReason) {
            setExpirationDateError("");
        }
    }, [exemptReason]);

    // validation when status is Invalid
    useEffect(() => {
        if (certStatus === "Invalid" || certStatus === "Pending") {
            if (taxId?.trim() === "") {
                setTaxIdStatus({
                    code: 0,
                    state: "",
                    reason: "",
                    message: "",
                });
            }
            if (expirationDate) {
                setExpirationDateError("");
            }
            if (taxNumberType === null) {
                setTaxNumberTypeError("");
            }
        } else {
            // eslint-disable-next-line
            if (!expirationDate && submitted) {
                setExpirationDateError(dateErrorMsg);
            } else if (
                autoValidationPredictions?.state?.toUpperCase() === zoneName.toUpperCase() &&
                autoValidationPredictions.expirationDate &&
                autoValidationPredictions.expirationDate > effectiveDate
            ) {
                setExpirationDateError("");
            } else if (expirationDate && expirationDateError === dateErrorMsg) {
                setExpirationDateError("");
            } else if (expirationDate < effectiveDate) {
                setExpirationDateError("Must be on or after the effective date");
            }
            if (!taxId?.trim() && requireTaxPayerId === true) {
                setTaxIdStatus({
                    code: 0,
                    state: AlertType.Error,
                    reason: TaxIdAlertReason.RegexTest,
                    message: "Enter the Taxpayer ID",
                });
            } else {
                setTaxIdStatus(prevTaxStatus => {
                    if (prevTaxStatus.message === "Enter the Taxpayer ID")
                        return { code: 0, state: "", reason: "", message: "" };
                    return prevTaxStatus;
                });
            }
        }
    }, [
        certStatus,
        taxId,
        expirationDate,
        effectiveDate,
        submitted,
        expirationDateError,
        JSON.stringify(autoValidationPredictions),
        zoneName,
    ]);

    const setStateOnCodeChange = useCallback(code => {
        if (HttpStatusCode.BadRequest === code || HttpStatusCode.NotFound === code)
            return AlertType.Warning;
        if (HttpStatusCode.Success === code) return AlertType.Success;
        if (HttpStatusCode.NotImplemented === code) return AlertType.Info;
        return "";
    }, []);

    // set status on basis of incoming certificate tax validation code and message
    useEffect(() => {
        if (taxValidationCode && taxValidationMessage) {
            setTaxIdStatus({
                code: taxValidationCode,
                state: setStateOnCodeChange(taxValidationCode),
                reason: TaxIdAlertReason.Idvalidation,
                message: taxValidationMessage,
            });
        }
    }, [taxValidationCode, taxValidationMessage, setStateOnCodeChange]);

    // event when exiration date is changed
    const handleDateChange = e => {
        const date = e?.detail?.value || null;
        if (e.detail.value) {
            setInvalidExpirationDate(false);
            setExpirationDate(e.detail.value);
            if (e.detail.value === undefined) {
                setExpirationDateError(dateErrorMsg);
            } else if (e.detail.value < effectiveDate) {
                setExpirationDateError("Must be on or after the effective date");
            } else if (expirationDateError !== "") {
                setExpirationDateError("");
            }
        } else if (e?.detail?.invalidInput) {
            setInvalidExpirationDate(true);
            setExpirationDateError(invalidDateErrorMsg);
        } else {
            setInvalidExpirationDate(false);
            setExpirationDate("");
        }

        updatePostData(zoneId, "expirationDate", date);
    };

    // API call to validate taxId online and set validationStatus
    const getTaxIdValidation = async taxIdValue => {
        const filterString = `?exposureZoneId=${zoneId}&exemptReasonId=${matrixValue.exemptReason.id}&taxId=${taxIdValue}`;
        setValidating(true);
        await axiosInstanceWithoutInterceptor
            .get(
                `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/auto-validate-taxid${filterString}`,
                { withCredentials: true }
            )
            .then(res => {
                const { message } = res?.data || "";
                const { code } = res?.data || {};
                if (code !== 0) {
                    if (code === HttpStatusCode.Success) {
                        setTaxIdStatus({
                            code,
                            state: setStateOnCodeChange(HttpStatusCode.Success),
                            reason: TaxIdAlertReason.Idvalidation,
                            message: `${zoneName} says taxpayer ID ${taxId} is valid`,
                        });
                    } else if (code === HttpStatusCode.BadRequest) {
                        setTaxIdStatus({
                            code: HttpStatusCode.BadRequest,
                            state: setStateOnCodeChange(HttpStatusCode.BadRequest),
                            reason: TaxIdAlertReason.RegexTest,
                            message: message.toString(),
                        });
                    } else if (code === HttpStatusCode.NotFound) {
                        setTaxIdStatus({
                            code: HttpStatusCode.NotFound,
                            state: setStateOnCodeChange(HttpStatusCode.NotFound),
                            reason: TaxIdAlertReason.Idvalidation,
                            message: message.toString(),
                        });
                    } else if (code === HttpStatusCode.NotImplemented) {
                        setTaxIdStatus({
                            code: HttpStatusCode.NotImplemented,
                            state: setStateOnCodeChange(HttpStatusCode.NotImplemented),
                            reason: TaxIdAlertReason.Idvalidation,
                            message: message.toString(),
                        });
                    } else {
                        setTaxIdStatus({
                            code,
                            state: AlertType.Error,
                            reason: TaxIdAlertReason.Idvalidation,
                            message: "We can't verify IDs right now. Try again in a moment",
                        });
                    }
                }
            })
            .catch(() => {
                showToast(
                    "error",
                    "There was a problem calling the tax validation service. Please try again later."
                );
            });
        setValidating(false);
    };
    const TaxNumberType = TaxNumberTypeList.map(element => {
        const selectOption = {
            label: element.label,
            value: element.label,
            selected: false,
        };
        if (!props?.taxNumberType || props?.taxNumberType === "") {
            if (selectOption.label === taxNumberType) selectOption.selected = true;
        } else if (props?.taxNumberType && element.label?.includes(props.taxNumberType)) {
            selectOption.selected = true;
        }
        return selectOption;
    });
    // validate taxId against API if regex test passed
    const validateTaxId = async () => {
        setSubmitForValidation(true);
        const testRegex = taxIdRegex
            .map(reg => reg.test(taxId.trim()))
            .findIndex(val => val === true);

        const trimmedValue = taxId.trim();
        if (trimmedValue === "") {
            setTaxIdStatus({
                code: 0,
                state: AlertType.Error,
                reason: TaxIdAlertReason.RegexTest,
                message: "Enter the Taxpayer ID",
            });
        } else if (
            matrixTaxCodeRegex === null ||
            matrixTaxCodeRegex?.length === 0 ||
            matrixTaxCodeRegex === "None" ||
            matrixTaxCodeRegex === undefined
        ) {
            setTaxIdStatus({
                code: HttpStatusCode.NotImplemented,
                state: AlertType.Info,
                reason: TaxIdAlertReason.ValidationNotSupported,
                message: `To verify a ${zoneName} taxpayer ID, check the state's website`,
            });
        } else if (testRegex === -1) {
            setTaxIdStatus({
                code: HttpStatusCode.BadRequest,
                state: AlertType.Warning,
                reason: TaxIdAlertReason.RegexTest,
                message: `${zoneName} taxpayer IDs should look like {${taxIdFormat}}`,
            });
        } else if (
            trimmedValue.length &&
            testRegex !== -1 &&
            matrixEnableStateVerification === false
        ) {
            setTaxIdStatus({
                code: HttpStatusCode.NotImplemented,
                state: AlertType.Info,
                reason: TaxIdAlertReason.RegexTest,
                message: `The format of taxpayer ID is correct, but ${zoneName} ID's can't be validated online. Check with the state to confirm it.`,
            });
        } else if (testRegex !== -1 && matrixEnableStateVerification === true) {
            const state = region.filter(obj => {
                return obj.name === zoneName;
            });
            const stateCode = state[0]?.state?.initials;
            const formattedId = getValidTaxIdForState(stateCode, taxId);
            await getTaxIdValidation(formattedId);
        }
        dispatch(incrementValidationStateCount());
    };

    // set taxIdRegex and taxIdFormat as per matrix data
    useEffect(() => {
        if (
            matrixTaxCodeRegex &&
            matrixTaxCodeRegex !== "None" &&
            !matrixTaxCodeRegex?.includes("/^No\\s*specific\\s*format\\s*required[.]*$")
        ) {
            let taxIdAllowedRegex = matrixTaxCodeRegex;
            const regexOptions = taxIdAllowedRegex.slice(taxIdAllowedRegex.lastIndexOf("/") + 1);
            taxIdAllowedRegex = taxIdAllowedRegex.slice(0, taxIdAllowedRegex.lastIndexOf("/"));
            taxIdAllowedRegex = taxIdAllowedRegex
                .replace(/\//, "")
                .replace(/\^/, "")
                .replace(/\$/, "");
            taxIdAllowedRegex = taxIdAllowedRegex.includes(",") //eslint-disable-line
                ? taxIdAllowedRegex.split(",")
                : taxIdAllowedRegex.includes("or")
                ? taxIdAllowedRegex.split("or")
                : [taxIdAllowedRegex];
            const regexForTaxId = taxIdAllowedRegex.map(
                reg => new RegExp(`^${reg.trim()}$`, regexOptions)
            );
            setTaxIdRegex(regexForTaxId);
            setTaxIdFormat(matrixTaxCodeFormat);
        } else {
            setTaxIdRegex([/.{1,}/]);
            setTaxIdFormat("");
        }
        if (props?.validateAllIds) {
            validateTaxId();
        }
    }, [matrixTaxCodeFormat, matrixTaxCodeRegex, props?.validateAllIds]);
    // reset expiration date to default
    const resetToDefault = async () => {
        setInvalidExpirationDate(false);
        setExpirationDateFromMatrix();
        setDateOverride(false);
    };

    return (
        <SBox>
            <SCol span="12">
                <SRow className="pad-left-sm">
                    <label className="text-sm-strong">{zoneName}</label>
                    {certificateId ? (
                        <span className="pad-left-xs">certificate ID {certificateId}</span>
                    ) : null}
                </SRow>
                <SRow>
                    <SCol span="6" className="pad-left-sm">
                        <label
                            htmlFor={`select-type-id-${certificateId}`}
                            id={`lbl-type-id-${certificateId}`}>
                            Type of ID
                        </label>
                        <SSelect
                            name={`select-type-id-${certificateId}`}
                            inputId={`select-type-id-${certificateId}`}
                            optionsList={TaxNumberType}
                            noSearch
                            selectionOptional
                            value={props.taxNumberType}
                            onS-select={handleTaxIdTypeChange}
                            onS-deselect={handleTaxIdTypeRemove}
                            disabled={props?.disableMulti ? "disabled" : null}
                        />
                        {submitted && taxNumberTypeError ? (
                            <div>
                                <SIcon name="alert-circle-filled" aria-hidden="true" />
                                <span className="top-xs">{taxNumberTypeError}</span>
                            </div>
                        ) : null}
                    </SCol>
                    <SCol span="6" className="pad-right-md">
                        <fieldset>
                            <label htmlFor={`taxId-${removeSpaces(zoneName)}`}>
                                State-issued ID number
                            </label>
                            <input
                                id={`taxId-${removeSpaces(zoneName)}`}
                                name={`taxId-${removeSpaces(zoneName)}`}
                                type="text"
                                required
                                value={taxId}
                                disabled={props?.disableMulti === true}
                                className={
                                    submitted ||
                                    submitForValidation ||
                                    (!submitted &&
                                        taxIdStatus.code !== 0 &&
                                        !(
                                            taxIdStatus.state === "success" &&
                                            taxIdStatus.reason === "RegexTest"
                                        ))
                                        ? taxIdStatus.state
                                        : "fs-exclude"
                                }
                                onChange={handleInputChange}
                            />
                        </fieldset>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol span="12" className="pad-left-sm">
                        <button
                            id="validate-tax-id-btn"
                            title="Validate Tax ID"
                            onClick={validateTaxId}
                            disabled={props?.disableMulti === true}
                            className="link inline font-semibold">
                            <span className="md-left-sm">Verify taxpayer ID</span>
                        </button>
                        <span className="zoom-tip">
                            <ZoomTip
                                tipTitle="validatemessage"
                                url="bundle/pah1663778507797/page/kke1720800087894.html"
                            />
                        </span>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol span="12" className="pad-left-sm">
                        {validating ? (
                            <span className="text-sm-strong">
                                <i>Verifying the ID number...</i>
                            </span>
                        ) : null}
                    </SCol>
                </SRow>
                {(submitted || submitForValidation || (!submitted && taxIdStatus.code !== 0)) &&
                !(taxIdStatus.state === "success" && taxIdStatus.reason === "RegexTest") &&
                !validating ? (
                    <SRow>
                        <SCol span="12" className="pad-left-sm">
                            {taxIdStatus.reason === "OnlineValidationNotSupported" ? ( // eslint-disable-line
                                <span className="zoom-tip">
                                    {taxIdStatus.message}
                                    <ZoomTip
                                        tipTitle="taxvalidation"
                                        url="bundle/pah1663778507797/page/kke1720800087894.html"
                                    />
                                </span>
                            ) : taxIdStatus.state !== "" ? (
                                <SAlert
                                    noDismiss
                                    status={
                                        taxIdStatus.state === "info" // eslint-disable-line
                                            ? "info"
                                            : taxIdStatus.state === "warning" // eslint-disable-line
                                            ? "warning"
                                            : taxIdStatus.state === "error" // eslint-disable-line
                                            ? "error"
                                            : taxIdStatus.state === "success" &&
                                              taxIdStatus.reason === "IdValidation"
                                            ? "success"
                                            : ""
                                    }
                                    role={
                                        ["error", "warning"].includes(taxIdStatus.state)
                                            ? "alert"
                                            : "status"
                                    }>
                                    <span className="top-xs" style={{ overflowWrap: "anywhere" }}>
                                        {taxIdStatus.message}
                                    </span>
                                </SAlert>
                            ) : null}
                        </SCol>
                    </SRow>
                ) : null}
                <SRow>
                    <SCol span="12" className="pad-left-sm">
                        {expirationDate === expirationDateMapping.NVR && !dateOverride ? (
                            <SAlert
                                status="info"
                                role="status"
                                className="validation-alert margin-top-none"
                                noDismiss>
                                <div>
                                    <span>Certificate doesn&apos;t expire </span>
                                    <span className="right">
                                        <button
                                            className="link inline font-semibold text-right margin-left-xs"
                                            disabled={props?.disableMulti === true || validating}
                                            onClick={() => setDateOverride(true)}>
                                            Assign an expiration date
                                        </button>
                                    </span>
                                </div>
                            </SAlert>
                        ) : (
                            <SRow>
                                <SCol span="12">
                                    <SRow>
                                        <SCol span="2" className="pad-bottom-none">
                                            <label htmlFor={`expired-date-input-${certificateId}`}>
                                                Expiration
                                            </label>
                                        </SCol>
                                    </SRow>
                                    <SRow>
                                        <SCol span="10" className="inline-flex">
                                            <SDatepicker
                                                inputid={`expired-date-input-${certificateId}`}
                                                inputname="date"
                                                value={expirationDate}
                                                disabled={
                                                    props?.disabledExpirationDate
                                                        ? "disabled"
                                                        : null
                                                }
                                                className={
                                                    submitted && expirationDateError
                                                        ? errorTag
                                                        : "expirationDate"
                                                }
                                                onS-select={e => {
                                                    handleDateChange(e);
                                                }}
                                                onS-error={e => {
                                                    handleDateChange(e);
                                                }}
                                                onS-deselect={e => {
                                                    handleDateChange(e);
                                                }}
                                            />
                                            <span className="pad-left-sm pad-top-xs">
                                                <button
                                                    className="link font-semibold pad-bottom-sm"
                                                    onClick={resetToDefault}
                                                    disabled={
                                                        props?.disabledExpirationDate
                                                            ? "disabled"
                                                            : null
                                                    }>
                                                    Reset expiration to the default for this form.
                                                </button>
                                            </span>
                                        </SCol>
                                    </SRow>
                                    {submitted && expirationDateError ? (
                                        <div className="input-msg">
                                            <SIcon name="alert-circle-filled" aria-hidden="true" />
                                            <span>{expirationDateError}</span>
                                        </div>
                                    ) : null}
                                </SCol>
                            </SRow>
                        )}
                    </SCol>
                </SRow>
            </SCol>
        </SBox>
    );
});

export default Multi;
