import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SSelect, SRow, SCol, SIcon } from "@avalara/skylab-react";
import axios from "../../axios";

const SplitCertCustomerTypeAhead = React.memo(props => {
    //    const dispatch = useDispatch();
    const [customers, setCustomers] = useState([]);

    const fetchTypeAhead = async value => {
        const response = await axios.get(
            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customers/typeahead?$filter=name contains '${value}' or customerNumber contains '${value}' and isVendor eq false &$orderBy=name ASC&$top=10`,
            {
                withCredentials: true,
            }
        );
        return response.data;
    };

    // This will open up the customers details in new tab when clicked on the Drop down Chip
    useEffect(() => {
        const asyncSelect = document.getElementById(`async-select-${props.typeId}`);
        function customerLink(event) {
            if (
                event &&
                event.path &&
                event.path.length >= 1 &&
                event.path[1].nodeName === "S-CHIP"
            ) {
                const idRegex = event.path[1].id.match(/-(.*)-/);
                if (idRegex && idRegex.length >= 1) {
                    const customerId = parseInt(idRegex[1], 10);
                    const idIndex = customers.findIndex(element => element.id === customerId);
                    if (idIndex !== -1) {
                        window.open(`${window.location.origin}/customer/${customerId}`, "_blank");
                    }
                }
            }
        }
        asyncSelect.addEventListener("click", customerLink);
        return () => {
            asyncSelect.removeEventListener("click", customerLink);
        };
    }, [JSON.stringify(customers)]); // eslint-disable-line

    useEffect(() => {
        const asyncSelect = document.getElementById(`async-select-${props.typeId}`);
        if (
            props?.value[0]?.customerId &&
            (!customers.length || props.value.length > customers.length)
        ) {
            setCustomers(props.value);
            let selectedResults = {};
            selectedResults = props.value.map(element => {
                return {
                    label: `${element.name ? element.name : element.customerName} : ${
                        element.customerNumber
                    }`,
                    value: `${element.id || element.customerId}`,
                    selected: true,
                };
            });
            asyncSelect.optionsList = selectedResults;
        }
    }, [JSON.stringify(props.value), customers.length]); // eslint-disable-line

    useEffect(() => {
        const uniqueCustomer = (prevCustomer, newCustomer) => {
            if (!prevCustomer.length) return newCustomer;
            if (!newCustomer.length) return prevCustomer;

            const prevCustomerIds = prevCustomer.map(ele => ele.id);
            return [
                ...prevCustomer,
                ...newCustomer.filter(cust => prevCustomerIds.indexOf(cust.id) === -1),
            ];
        };

        async function typeAhead(e) {
            const asyncSelect = document.getElementById(`async-select-${props.typeId}`);
            let typeAheadResults = {};
            if (
                e.detail.inputValue !== "" &&
                e.detail.inputValue.length > 1 &&
                !asyncSelect.getAttribute("loading")
            ) {
                asyncSelect.setAttribute("loading", "");
                // loading = true;
                const results = await fetchTypeAhead(e.detail.inputValue);
                if (results) {
                    typeAheadResults = results.map(element => {
                        return {
                            label: `${element.name ? element.name : element.customerName} : ${
                                element.customerNumber
                            }`,
                            value: `${element.id}`,
                        };
                    });
                    asyncSelect.optionsList = typeAheadResults;
                    setCustomers(uniqueCustomer(customers, results));
                }
                asyncSelect.removeAttribute("loading");
            }
        }
        const asyncSelect = document.getElementById(`async-select-${props.typeId}`);
        asyncSelect.addEventListener("s-input", typeAhead);
        return () => {
            asyncSelect.removeEventListener("s-input", typeAhead);
        };
    }, [JSON.stringify(customers)]); // eslint-disable-line

    return (
        <React.Fragment>
            <SRow>
                <SCol span="8" className="pad-bottom-none">
                    <label htmlFor={`async-select-${props.typeId}`} id="lbl-Customers">
                        Customers
                    </label>
                </SCol>
            </SRow>
            <SRow>
                <SCol>
                    <SSelect
                        name={`async-select-${props.typeId}`}
                        id={`async-select-${props.typeId}`}
                        key={`async-select-${props.typeId}`}
                        inputid={`async-select-inputs-${props.typeId}`}
                        className={props.error ? props.error : "text-as-link"}
                        async
                        autofocus
                        multiple={props.multiple}
                        placeholder="Search customers..."
                        onS-select={e => {
                            props.handleAdd(
                                customers.filter(cust => cust.id == e.detail.item.value)
                            ); /* eslint eqeqeq: 0 */
                        }}
                        onS-deselect={e => {
                            props.handleRemove(e.detail.item);
                        }}
                        disabled={props?.disabled ? true : false} // eslint-disable-line
                    />
                    <div className="input-msg" hidden={!props?.customerError}>
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        <span className="top-xs">Enter a customer</span>
                    </div>
                </SCol>
            </SRow>
        </React.Fragment>
    );
});

export default SplitCertCustomerTypeAhead;
