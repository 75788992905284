import React, { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import { SLoader, SPagination } from "@avalara/skylab-react";
import { gridHeader, DataGrid } from "@certcapture/react-components";
import { v4 as uuidv4 } from "uuid";
import DeleteSavedSearchDialog from "./DeleteSavedSearchDialog";
import { isEligibleUser } from "../../../shared/sessionUtility";
import featureFlag from "../../../featureToggler/featureFlag";

import {
    selectPage,
    selectIsLoading,
    selectSortColumn,
    setSortColumn,
    fetchSavedSearchesAPI,
    setIsVendor,
} from "../../../app/savedSearchesSlice";
import RecordCount from "../RecordCount";
import { nextDays, previousDays } from "../../../shared/Utils";

function SavedSearchGrid() {
    const dispatch = useDispatch();
    const loading = useSelector(selectIsLoading);
    const [submitting, setSubmitting] = useState(false);
    const [savedSearchIdToDelete, setSavedSearchIdToDelete] = useState("");
    const page = useSelector(selectPage, shallowEqual);
    const pageData = page.data;
    const paginateData = page.paginate;
    const sortColumn = useSelector(selectSortColumn);
    const currentdate = new Date().toISOString().slice(0, 10);
    function sortFunction(e) {
        setSubmitting(true);
        const column = e.detail.id;
        const order = sortColumn[0] === column ? !sortColumn[1] : true;
        dispatch(setSortColumn([column, order]));
        dispatch(fetchSavedSearchesAPI(e.detail.rowsPerPage, e.detail.startIndex, false));
        setSubmitting(false);
    }
    const isEligibleUserReportsViewVendorReports = dispatch(
        isEligibleUser(featureFlag.reports.viewVendorReports)
    );

    useEffect(() => {
        dispatch(setIsVendor(isEligibleUserReportsViewVendorReports));
    }, []); // eslint-disable-line

    const columns = [
        gridHeader("Name", "title", value => value, null, "", null, null, sortFunction, "title"),
        gridHeader("Created by", "cannedSearch", value => value, null, ""),
        gridHeader(
            "Report type",
            "searchType",
            value => value,
            null,
            "",
            null,
            null,
            sortFunction,
            "searchType"
        ),
        gridHeader(
            "",
            "",
            (value, row) => (
                <>
                    {row.searchType === "Certificate" ? (
                        <Link
                            key={uuidv4()}
                            to="/saved/search/certificates"
                            state={{
                                searchCriteria: row.criteria
                                    .replace("Last30days", previousDays(30))
                                    .replace("Next30days", nextDays(30))
                                    .replace("today", currentdate),
                            }}>
                            <button type="button" className="secondary small">
                                Run report
                            </button>
                        </Link>
                    ) : null}
                    {row.searchType === "Customer" ? (
                        <Link
                            key={uuidv4()}
                            to="/saved/search/customers"
                            state={{
                                searchCriteria: row.criteria
                                    .replace("Last30days", previousDays(30))
                                    .replace("Next30days", nextDays(30))
                                    .replace("today", currentdate),
                            }}>
                            <button type="button" className="secondary small">
                                Run report
                            </button>
                        </Link>
                    ) : null}
                    {row.searchType === "Vendor" ? (
                        <Link
                            key={uuidv4()}
                            to="/saved/search/Vendors"
                            state={{
                                searchCriteria: row.criteria
                                    .replace("Last30days", previousDays(30))
                                    .replace("Next30days", nextDays(30))
                                    .replace("today", currentdate),
                            }}>
                            <button type="button" className="secondary small">
                                Run report
                            </button>
                        </Link>
                    ) : null}
                    {row.searchType === "Vendor-Certificate" ? (
                        <Link
                            key={uuidv4()}
                            to="/saved/search/vendor-certificates"
                            state={{
                                searchCriteria: row.criteria
                                    .replace("Last30days", previousDays(30))
                                    .replace("Next30days", nextDays(30))
                                    .replace("today", currentdate),
                            }}>
                            <button type="button" className="secondary small">
                                Run report
                            </button>
                        </Link>
                    ) : null}
                    <button
                        className="ghost-blue small pad-left-lg"
                        onClick={() => setSavedSearchIdToDelete(row.id)}
                        disabled={row.cannedSearch !== "User"}>
                        Delete
                    </button>
                </>
            ),
            "text-right",
            "text-right",
            null
        ),
    ];

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            dispatch(
                fetchSavedSearchesAPI(e.detail.rowsPerPage, e.detail.startIndex, false, e.detail)
            );
            setSubmitting(false);
        }
    };
    const getRowKey = useCallback(() => {
        return uuidv4();
    }, []);

    const table =
        submitting || loading ? (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="grid-loader" className="medium" aria-live="polite" loading />
            </div>
        ) : (
            <>
                <RecordCount
                    selectedCount={0}
                    recordCount={paginateData.totalRecords}
                    recordName="reports"
                />
                <s-table-container key={uuidv4()}>
                    <DataGrid
                        columns={columns}
                        rows={pageData}
                        getKey={getRowKey}
                        inContainer
                        sortColumn={sortColumn}
                    />
                </s-table-container>
            </>
        );

    return (
        <React.Fragment>
            {table}
            {paginateData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={paginateData.rowsPerPage}
                    totalRecords={paginateData.totalRecords}
                    startIndex={paginateData.startIndex}
                    onS-paginate={e => {
                        handlePaginate(e);
                    }}
                />
            ) : null}

            <DeleteSavedSearchDialog
                savedSearchIdToDelete={savedSearchIdToDelete}
                setSavedSearchIdToDelete={setSavedSearchIdToDelete}
            />
        </React.Fragment>
    );
}
export default SavedSearchGrid;
