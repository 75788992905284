import { SAlert, SDialog } from "@avalara/skylab-react";
import classnames from "classnames";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedCertificateIds } from "../../app/bulkUpdateSlice";
import {
    deleteCertificatesAsync,
    fetchCertificatesSearchAPI,
    getSearchedCertificates,
    selectIsDeletingCert,
} from "../../app/certificateSlice";
import toast from "../../hooks/toast";

function BulkDeleteCertificateDialog(props) {
    const dialogName = "bulkDeleteCertificateDialog";
    const isDeletingCertificate = useSelector(selectIsDeletingCert);
    const dispatch = useDispatch();

    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [wholeCertificateDelete, setWholeCertificateDelete] = useState(false);
    const [noMultiCertificate, setNoMultiCertificate] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const deleteButton = classnames({
        primary: true,
        small: true,
        loading: isLoadingDelete,
    });
    const [showToast] = toast();

    const deleteCertificate = async () => {
        const page = dispatch(getSearchedCertificates());
        const certificateIds = props?.useFilterCriteriaForDelete
            ? []
            : dispatch(getSelectedCertificateIds());
        const deleteMultiple =
            (Array.isArray(certificateIds) && certificateIds.length > 1) || props?.totalRecords > 1;
        const paginateData = page.paginate;
        if (certificateIds.length > 0 || props?.useFilterCriteriaForDelete) {
            setIsLoadingDelete(true);
            dispatch(
                deleteCertificatesAsync(
                    certificateIds,
                    deleteMultiple,
                    showToast,
                    null,
                    props?.useFilterCriteriaForDelete
                )
            ).then(() => {
                setWholeCertificateDelete(false);
                setNoMultiCertificate(false);
                setConfirmDelete(false);
                props.setIsBulkDeleteCertificateDialogOpen(false);
                dispatch(fetchCertificatesSearchAPI(paginateData.rowsPerPage, 1, false));
                setIsLoadingDelete(false);
            });
        }
    };

    const handleCheckboxChange = setter => {
        return event => {
            setter(event.target.checked);
        };
    };

    const dismissDialog = () => {
        setWholeCertificateDelete(false);
        setNoMultiCertificate(false);
        setConfirmDelete(false);
        props.setIsBulkDeleteCertificateDialogOpen(false);
    };

    return (
        <SDialog
            id={dialogName}
            open={props.isBulkDeleteCertificateDialogOpen}
            onS-dismiss={() => {
                dismissDialog();
            }}
            aria-modal="true">
            <div slot="header" id="bulkDeleteCertificate">
                Delete {props.totalRecords} certificates?
            </div>
            <div slot="body">
                <SAlert
                    id="certificateDelete-alert"
                    status="warning"
                    role="alert"
                    noDismiss
                    className="margin-bottom-md">
                    <strong className="block margin-bottom-md">
                        I understand that when a certificate is deleted...
                    </strong>
                    <input
                        id="whole-certificate-delete"
                        name="whole-certificate-delete"
                        type="checkbox"
                        aria-label="Both the certificate data and the certificate image are deleted"
                        checked={wholeCertificateDelete}
                        onChange={handleCheckboxChange(setWholeCertificateDelete)}
                    />
                    <label
                        htmlFor="whole-certificate-delete"
                        className="margin-top-xs margin-bottom-md block">
                        Both the certificate data and the certificate image are deleted
                    </label>
                    <input
                        id="no-multi-certificate"
                        name="no-multi-certificate"
                        type="checkbox"
                        aria-label="Multi-Jurisdictional certificates will NOT be deleted"
                        checked={noMultiCertificate}
                        onChange={handleCheckboxChange(setNoMultiCertificate)}
                    />
                    <label
                        htmlFor="no-multi-certificate"
                        className="margin-top-xs margin-bottom-md block">
                        Multi-Jurisdictional certificates will NOT be deleted
                    </label>
                    <input
                        id="confirm-delete"
                        name="confirm-delete"
                        type="checkbox"
                        aria-label="A deleted certificate is gone for good"
                        checked={confirmDelete}
                        onChange={handleCheckboxChange(setConfirmDelete)}
                    />
                    <label htmlFor="confirm-delete" className="margin-top-xs margin-bottom-md">
                        A deleted certificate is gone for good
                    </label>
                </SAlert>
                <span>Deleting certificates can take a while.</span>
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    disabled={isDeletingCertificate}
                    onClick={() => dismissDialog()}>
                    Cancel
                </button>
                <button
                    className={deleteButton}
                    disabled={!wholeCertificateDelete || !noMultiCertificate || !confirmDelete}
                    type="button"
                    onClick={() => deleteCertificate()}>
                    Yes, delete
                </button>
            </div>
        </SDialog>
    );
}

export default BulkDeleteCertificateDialog;
