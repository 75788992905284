import { SCol, SIcon, SLoader, SRow } from "@avalara/skylab-react";
import classnames from "classnames";
import React, { useState, useCallback } from "react";
import { pdfjs } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import { PDFDocument, degrees } from "pdf-lib";
import {
    selectIsAttachingFile,
    selectIsPostingCert,
    replaceFileAsync,
    selectValidationData,
    selectHasCertificateFile,
    selectIsUploadingFile,
    selectIsLoadingValidationData,
    setIsUploadDialogOpen,
    setIsSplitCertificateDialogOpen,
    selectIsSplitCertificateDialogOpen,
    selectIsUploadDialogOpen,
    selectIsScanningFVS,
    selectPassedFVS,
} from "../../app/certificateSlice";
import { selectSession } from "../../app/sessionSlice";
import { isEligibleUser } from "../../shared/sessionUtility";
import DeleteCertificateFileDialog from "../sharedDialogs/DeleteCertificateFileDialog";
import SupplementalFiles from "./SupplementalFiles";
import ValidationFileRender from "./ValidationFileRender";
import AutoValidationResult from "./AutoValidation";
import FileUploaderDialog from "../sharedDialogs/FileUploaderDialog";
import SplitCertificateDialog from "../sharedDialogs/SplitCertificateDialog";
import FeatureToggler from "../../featureToggler/FeatureToggler";
import featureFlag from "../../featureToggler/featureFlag";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

const pdfTypeString = "data:application/pdf;base64";

function ValidationFileColumn(props) {
    const dispatch = useDispatch();
    const { certificateID, disabledBasedOnMulti, onReview } = props;
    const [scale, setScale] = useState(1.6);
    const IsUploadDialogOpen = useSelector(selectIsUploadDialogOpen);
    const isSplitCertificateDialogOpen = useSelector(selectIsSplitCertificateDialogOpen);
    const [isDeleteCertificateFileDialogOpen, setIsDeleteCertificateFileDialogOpen] =
        useState(false);
    const session = useSelector(selectSession);
    const attachingFile = useSelector(selectIsAttachingFile);
    const postingCert = useSelector(selectIsPostingCert);
    const validationData = useSelector(selectValidationData);
    const fileTypePDF = validationData.fileType.includes(pdfTypeString);
    const isUploadingFile = useSelector(selectIsUploadingFile);
    const hasCertificateFile = useSelector(selectHasCertificateFile);
    const isLoadingValidationData = useSelector(selectIsLoadingValidationData);
    const fileRendererClass = classnames({
        "margin-top-sm": true,
        "file-container": true,
        "fit-content": scale === 1.6,
        "file-container-border": isLoadingValidationData || hasCertificateFile || isUploadingFile,
    });
    const [numberPages, setNumberPages] = useState(null);
    const splitCertClassnames = classnames({
        hidden: !onReview,
        tertiary: true,
        inline: true,
    });
    const [isSupplementalAttachment, setIsSupplementalAttachment] = useState(false);
    const isScanningFVS = useSelector(selectIsScanningFVS);
    const passedFVS = useSelector(selectPassedFVS);
    const isEligibleUserManageFileFlag = dispatch(
        isEligibleUser(
            onReview
                ? featureFlag.reviewCertificate.upload
                : featureFlag.validatedCertificate.upload
        )
    );

    const isEligibleUserManageAttachmentsFlag = dispatch(
        isEligibleUser(
            onReview
                ? featureFlag.reviewCertificate.attachments
                : featureFlag.validatedCertificate.attachments
        )
    );

    function range(start, end) {
        const length = end - start + 1;
        return Array.from({ length }, (_, i) => start + i - 1);
    }

    const rotatePDF = useCallback(async () => {
        const pdfSrcDoc = await PDFDocument.load(validationData.data);
        const pdfNewDoc = await PDFDocument.create();
        const pages = await pdfNewDoc.copyPages(pdfSrcDoc, range(1, pdfSrcDoc.getPageCount()));
        const rotateAngle = pages[0].getRotation().angle;
        pages.forEach(page => {
            page.setRotation(degrees(Number(rotateAngle + 90 > 270 ? 0 : rotateAngle + 90)));
            pdfNewDoc.addPage(page);
        });
        const fileUnit8Array = await pdfNewDoc.save();
        const fileBlob = new Blob([fileUnit8Array], { type: "application/pdf" });
        return fileBlob;
    }, [validationData.data]);

    const rotateImage = useCallback(async () => {
        await rotatePDF().then(response => {
            const formData = new FormData();
            const file = new File([response], validationData.fileName);
            formData.append("File", file);
            dispatch(replaceFileAsync(certificateID, formData, true));
        });
    }, [certificateID, dispatch, rotatePDF, validationData.fileName]);

    const openFVSDialog = isSupplemental => {
        setIsSupplementalAttachment(isSupplemental);
        dispatch(setIsUploadDialogOpen(true));
    };

    return (
        <>
            <SRow>
                <SCol span="auto">
                    <AutoValidationResult />
                </SCol>
            </SRow>
            <SRow>
                {hasCertificateFile || isLoadingValidationData || isUploadingFile ? (
                    <SCol span="auto">
                        {fileTypePDF ? (
                            <React.Fragment>
                                <button
                                    className="tertiary inline"
                                    disabled={postingCert}
                                    onClick={() => setScale(scale + 0.2)}>
                                    <SIcon name="zoom-in" aria-label="zoom-in" />
                                </button>
                                <button
                                    className="tertiary inline"
                                    disabled={postingCert || scale === 0.2000000000000003}
                                    onClick={() => setScale(scale - 0.2)}>
                                    <SIcon name="zoom-out" aria-label="zoom-out" />
                                </button>
                            </React.Fragment>
                        ) : null}
                        {certificateID && !isScanningFVS && passedFVS ? (
                            <button
                                className="tertiary inline"
                                onClick={rotateImage}
                                disabled={
                                    postingCert || isUploadingFile || !isEligibleUserManageFileFlag
                                }>
                                <SIcon name="refresh" aria-label="refresh" />
                            </button>
                        ) : null}
                        {certificateID ? (
                            <React.Fragment>
                                <a
                                    className="button tertiary margin-left-sm"
                                    disabled={postingCert}
                                    hidden={!certificateID}
                                    href={`//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/companies/${session.activeCompany.id}/certificates/${certificateID}/certificate-file`}>
                                    <SIcon name="download" aria-label="download" />
                                </a>
                                <button
                                    className="tertiary inline"
                                    disabled={
                                        postingCert ||
                                        disabledBasedOnMulti ||
                                        !isEligibleUserManageFileFlag ||
                                        props.isOutgoing
                                    }
                                    onClick={() => {
                                        openFVSDialog(false);
                                    }}>
                                    <SIcon name="upload" aria-label="Replace image" />
                                    <span className="margin-left-xs">Replace image</span>
                                </button>
                            </React.Fragment>
                        ) : null}

                        {fileTypePDF &&
                        numberPages &&
                        numberPages > 1 &&
                        !isScanningFVS &&
                        passedFVS ? (
                            <button
                                className={splitCertClassnames}
                                disabled={
                                    postingCert ||
                                    disabledBasedOnMulti ||
                                    !isEligibleUserManageFileFlag
                                }
                                onClick={() => {
                                    dispatch(setIsSplitCertificateDialogOpen(true));
                                }}>
                                <SIcon name="network" aria-label="Split image" />
                                <span className="margin-left-xs">Split image</span>
                            </button>
                        ) : null}
                        {certificateID ? (
                            <button
                                className="tertiary inline"
                                disabled={
                                    postingCert ||
                                    disabledBasedOnMulti ||
                                    props.isOutgoing ||
                                    !isEligibleUserManageFileFlag
                                }
                                hidden={!certificateID}
                                onClick={() => setIsDeleteCertificateFileDialogOpen(true)}>
                                <SIcon name="trash" aria-label="Delete image" />
                                <span className="margin-left-xs">Delete image</span>
                            </button>
                        ) : null}
                    </SCol>
                ) : null}
                <FeatureToggler
                    category={props.onReview ? "reviewCertificate" : "validatedCertificate"}
                    id="attachments">
                    <SCol span="2" className="flex justify-content-end">
                        {!attachingFile ? (
                            <button
                                className="link inline font-semibold"
                                disabled={
                                    props.isOutgoing ||
                                    !isEligibleUserManageAttachmentsFlag ||
                                    props.showRevokedAlert
                                }
                                onClick={() => {
                                    openFVSDialog(true);
                                }}>
                                <SIcon
                                    className="top-sm ghost link"
                                    name="attach"
                                    aria-label="Attach a file"
                                />
                                <span className="md-left-md">Attach a file</span>
                            </button>
                        ) : (
                            <SLoader loading aria-live="polite" />
                        )}
                    </SCol>
                </FeatureToggler>
            </SRow>
            <SRow>
                <SCol span="12">
                    <>
                        <div className={fileRendererClass}>
                            <ValidationFileRender
                                certificateID={certificateID}
                                poll={false}
                                scale={scale}
                                PdfPageCount={setNumberPages}
                                isOutgoing={props.isOutgoing}
                                isUploaderDisabled={props.isUploaderDisabled}
                                showRevokedAlert={props.showRevokedAlert}
                            />
                        </div>
                        <FeatureToggler category="validatedCertificate" id="attachments">
                            <SupplementalFiles
                                certificateID={certificateID}
                                onReview={props.onReview}
                            />
                        </FeatureToggler>
                    </>
                </SCol>
            </SRow>
            {/* <DeleteCertificateDialog
                    isDeleteCertificateDialogOpen={isDeleteCertificateDialogOpen}
                    setIsDeleteCertificateDialogOpen={setIsDeleteCertificateDialogOpen}
                    certificateIds={certificateID}
                    handleParentDelete={deleteCertificate}
                /> */}
            <DeleteCertificateFileDialog
                isDeleteCertificateFileDialogOpen={isDeleteCertificateFileDialogOpen}
                setIsDeleteCertificateFileDialogOpen={setIsDeleteCertificateFileDialogOpen}
                certificateID={certificateID}
            />
            <FeatureToggler category="reviewCertificate" id="split">
                {isSplitCertificateDialogOpen && onReview && !postingCert ? (
                    <SplitCertificateDialog
                        certificateID={certificateID}
                        customers={props.customers}
                    />
                ) : null}
            </FeatureToggler>
            {(isSupplementalAttachment || IsUploadDialogOpen) && (
                <FileUploaderDialog
                    certificateID={props.certificateID}
                    isReplace={!isSupplementalAttachment}
                    isSupplementalAttachment={isSupplementalAttachment}
                    isUploaderDisabled={props.isUploaderDisabled || props.showRevokedAlert}
                    title={`${
                        isSupplementalAttachment ? `Upload an attachment` : `Replace a certificate`
                    } `}
                    showRevokedAlert={props.showRevokedAlert}
                />
            )}
        </>
    );
}

export default React.memo(ValidationFileColumn);
