import React, { useCallback, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SAlert, SSelect, SRow, SCol, SIcon } from "@avalara/skylab-react";
import axios from "../../axios";
import { fixSearchStringForPostgres } from "../../shared/Utils";
import { getCountriesWithStateCountAsync, selectCountriesStateCount } from "../../app/commonSlice";
import EditCustomeDialog from "../sharedDialogs/EditCustomeDialog";
import AddCustomerDialog from "../sharedDialogs/AddCustomerDialog";
import FeatureToggler from "../../featureToggler/FeatureToggler";
import CustomerInfoPanel from "./CustomerInfoPanel";
import { isEligibleUser } from "../../shared/sessionUtility";
import featureFlag from "../../featureToggler/featureFlag";

const CustomerTypeAhead = React.memo(props => {
    const dispatch = useDispatch();
    const ref = useRef(null);
    const venRef = useRef(null);
    const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
    const [multiCustomerDialogOpen, setMultiCustomerDialogOpen] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [isCustomerDataMissing, setIsCustomerDataMissing] = useState(false);
    const countries = useSelector(selectCountriesStateCount, shallowEqual);
    const [customersMissingData, setCustomersMissingData] = useState([]);
    const [focusedCustomer, setFocusedCustomer] = useState(null);
    const isEligibleUserCustomersManageCustomer = dispatch(
        isEligibleUser(featureFlag.customers.manageCustomer)
    );
    const { setCustomerDataValidation, handleAdd, handleRemove } = props;
    useEffect(() => {
        if (setCustomerDataValidation) setCustomerDataValidation(isCustomerDataMissing);
    }, [isCustomerDataMissing, setCustomerDataValidation]);

    useEffect(() => {
        if (props.queueDelete) venRef.current.deselectAll();
    }, [props.queueDelete]);

    const fetchTypeAhead = async value => {
        const updatedValue = fixSearchStringForPostgres(value);
        let typeAheadURL = `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customers/typeahead?$filter=name contains '${updatedValue}' or customerNumber contains '${updatedValue}' and isVendor eq false  &$orderBy=name ASC&$top=10`;

        if (props.vendor) {
            typeAheadURL = `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customers/typeahead?$filter=customerNumber contains '${updatedValue}' or name contains '${updatedValue}' and isVendor eq true &$orderBy=name ASC&$top=10`;
        }
        const response = await axios.get(typeAheadURL, {
            withCredentials: true,
        });
        return response.data;
    };
    useEffect(() => {
        const asyncSelect = document.getElementById("async-select");
        function customerLink(event) {
            if (event?.target?.parentElement?.id) {
                const idRegex = event.target.parentElement.id.match(/-(.*)-/);
                if (idRegex && idRegex.length >= 1) {
                    const customerId = parseInt(idRegex[1], 10);
                    const idIndex = customers.findIndex(element => element.id === customerId);
                    if (idIndex > -1) {
                        setFocusedCustomer(customers[idIndex]);
                    }
                }
            }
        }
        asyncSelect.addEventListener("click", customerLink);
        return () => {
            asyncSelect.removeEventListener("click", customerLink);
        };
    }, [JSON.stringify(customers)]); // eslint-disable-line

    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                setFocusedCustomer(null);
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    const isAddressZipInvalid = customer => {
        if (customer.addressLine1.length > 50) return true;
        const zip = customer.zip ? customer.zip : customer.zipCode;
        if (customer.country?.name === "United States" && (zip.length < 5 || zip.length > 10))
            return true;
        if (customer.country?.name === "Canada" && (zip.length < 6 || zip.length > 10)) return true;
        return false;
    };
    const validationCustomers = () => {
        setIsCustomerDataMissing(false);
        if (props?.value?.length > 0) {
            dispatch(getCountriesWithStateCountAsync());
            const customerValidate = props?.value
                .map(customer => {
                    if (
                        !customer.customerNumber ||
                        (!customer.name && !customer.customerName) ||
                        !customer.country ||
                        !customer.addressLine1 ||
                        !customer.city ||
                        (!customer.zip && !customer.zipCode) ||
                        isAddressZipInvalid(customer) ||
                        (countries?.length > 0 &&
                            customer.country &&
                            countries.filter(
                                x => x.name === customer.country.name && x.stateCount > 0
                            ).length > 0 &&
                            !customer.state)
                    ) {
                        return customer;
                    }
                    return null;
                })
                .filter(c => c);
            if (customerValidate?.length > 0) {
                setIsCustomerDataMissing(true);
                setCustomersMissingData(customerValidate);
            }
        }
    };

    useEffect(() => {
        setIsCustomerDataMissing(false);
        if (props?.value?.length > 0 && !props.fromSingleRequests) {
            validationCustomers();
        }
    }, [props?.value]); // eslint-disable-line

    useEffect(() => {
        setFocusedCustomer(null);
        const asyncSelect = document.getElementById("async-select");
        if (props.value && (!customers.length || props.value.length > customers.length)) {
            setCustomers(props.value);
            let selectedResults = {};
            selectedResults = props.value.map(element => {
                return {
                    label: `${element.name ? element.name : element.customerName} : ${
                        element.customerNumber
                    }`,
                    value: `${element.id}`,
                    selected: true,
                };
            });
            asyncSelect.optionsList = selectedResults;
        }
    }, [JSON.stringify(props.value), customers.length]); // eslint-disable-line

    useEffect(() => {
        const uniqueCustomer = (prevCustomer, newCustomer) => {
            if (!prevCustomer.length) return newCustomer;
            if (!newCustomer.length) return prevCustomer;

            const prevCustomerIds = prevCustomer.map(ele => ele.id);
            return [
                ...prevCustomer,
                ...newCustomer.filter(cust => prevCustomerIds.indexOf(cust.id) === -1),
            ];
        };

        async function typeAhead(e) {
            const asyncSelect = document.getElementById("async-select");
            let typeAheadResults = {};
            if (
                e.detail.inputValue !== "" &&
                e.detail.inputValue.length > 1 &&
                !asyncSelect.getAttribute("loading")
            ) {
                asyncSelect.setAttribute("loading", "");
                // loading = true;
                const results = await fetchTypeAhead(e.detail.inputValue);
                if (results) {
                    typeAheadResults = results.map(element => {
                        return {
                            label: `${element.name ? element.name : element.customerName} : ${
                                element.customerNumber
                            }`,
                            value: `${element.id}`,
                        };
                    });
                    asyncSelect.optionsList = typeAheadResults;
                    setCustomers(uniqueCustomer(customers, results));
                }
                asyncSelect.removeAttribute("loading");
            }
        }
        const asyncSelect = document.getElementById("async-select");
        asyncSelect.addEventListener("s-input", typeAhead);
        return () => {
            asyncSelect.removeEventListener("s-input", typeAhead);
        };
    }, [JSON.stringify(customers)]); // eslint-disable-line

    const onEditUpdateCustomers = useCallback(
        updatedCustomers => {
            const removeCustomer = updatedCustomers.map(c => ({
                value: c.id,
            }));
            removeCustomer.forEach(customer => {
                handleRemove(customer);
            });
            handleAdd(updatedCustomers);
        },
        [handleAdd, handleRemove]
    );
    const disabledAttr = props?.disabled || props?.venDisable ? { disabled: true } : {};
    return (
        <React.Fragment>
            <SRow>
                <SCol span="8" className="pad-bottom-none">
                    <label id="lbl-async-select" htmlFor="async-select-input">
                        {props.label}
                    </label>
                </SCol>
                <FeatureToggler category="customers" id="manageCustomer">
                    <SCol span="4" className="pad-bottom-none">
                        {props.addNewCustomer && (
                            <span className="right">
                                <button
                                    className="link text-label"
                                    disabled={
                                        props?.disabled ||
                                        !!props.vendor ||
                                        !isEligibleUserCustomersManageCustomer
                                    } // eslint-disable-line
                                    onClick={() => {
                                        setCustomerDialogOpen(true);
                                    }}>
                                    {!props.vendor ? "Create a customer" : "Create a vendor"}
                                </button>
                            </span>
                        )}
                    </SCol>
                </FeatureToggler>
            </SRow>
            <SRow>
                <SCol className="pad-bottom-none">
                    <SSelect
                        id="async-select"
                        inputid="async-select-input"
                        ref={venRef}
                        className={props.error ? props.error : "text-as-link"}
                        async
                        multiple={!props.fromSingleRequests}
                        placeholder={!props.vendor ? "Search customers..." : "Search vendors..."}
                        onS-select={e => {
                            props.handleAdd(
                                customers.filter(cust => cust.id == e.detail.item.value)
                            ); /* eslint eqeqeq: 0 */
                        }}
                        onS-deselect={e => {
                            props.handleRemove(e.detail.item);
                        }}
                        {...disabledAttr}
                    />
                    <div className="input-msg" hidden={!props?.customerError}>
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        <span className="top-xs">Enter a customer</span>
                    </div>
                    {focusedCustomer && (
                        <CustomerInfoPanel
                            focusedCustomer={focusedCustomer}
                            setFocusedCustomer={setFocusedCustomer}
                        />
                    )}
                </SCol>
            </SRow>
            <SRow>
                {isCustomerDataMissing && (
                    <SCol>
                        <SAlert status="error" role="alert" nodismiss>
                            <SRow>
                                <SCol>
                                    One or more customer record is missing some information needed
                                    in order for their transaction to be exempt.
                                    <button
                                        className="no-border pad-all-xs fix-it-link"
                                        onClick={() => {
                                            setMultiCustomerDialogOpen(true);
                                        }}>
                                        Fix it now
                                    </button>
                                </SCol>
                            </SRow>
                        </SAlert>
                    </SCol>
                )}
                {multiCustomerDialogOpen ? (
                    <EditCustomeDialog
                        customerDialogOpen={multiCustomerDialogOpen}
                        setCustomerDialogOpen={setMultiCustomerDialogOpen}
                        customers={customersMissingData}
                        onEditUpdateCustomers={onEditUpdateCustomers}
                        setIsCustomerDataMissing={setIsCustomerDataMissing}
                    />
                ) : null}
            </SRow>
            {customerDialogOpen ? (
                <AddCustomerDialog
                    customerDialogOpen={customerDialogOpen}
                    setCustomerDialogOpen={setCustomerDialogOpen}
                    handleAddCustomer={props?.handleAdd}
                />
            ) : null}
        </React.Fragment>
    );
});

export default CustomerTypeAhead;
