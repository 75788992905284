import React, { useState } from "react";
import { AuiUploader, SLoader } from "@avalara/skylab-react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { getProduct } from "../../app/sessionSlice";
import { toBase64, matchSubscriptions } from "../../shared/Utils";
import { getSessionAccessToken } from "../../shared/sessionUtility";
import {
    setIsUploadDialogOpen,
    uploadFileAsync,
    selectIsUploadDialogEllipsesMenuOpen,
    setIsUploadDialogEllipsesMenuOpen,
    attachSupplementalFile,
    replaceFileAsync,
    setValidationData,
    setHasCertificateFile,
    autoValidateFileAsync,
    setIsScanningFVS,
    setPassedFVS,
    setFvsValidationData,
} from "../../app/certificateSlice";
import { saveCompanyLogo, selectSetting } from "../../app/settingsSlice";
import toast from "../../hooks/toast";
import featureFlag from "../../featureToggler/featureFlag";
import { logger } from "../../shared/Logger";

function FVSUploader(props) {
    const dispatch = useDispatch();
    const isUploadDialogEllipsesMenuOpen = useSelector(selectIsUploadDialogEllipsesMenuOpen);
    const [showToast] = toast();
    const auiUploaderRef = React.createRef();
    const [uploadCertificateEvent, setUploadCertificateEvent] = useState("");
    const setting = useSelector(selectSetting, shallowEqual);
    const getSubscription = useSelector(getProduct, shallowEqual);
    const isDisabled = props.isUploaderDisabled || props.showRevokedAlert || false;
    let maxRetries = 3;
    let domId = "aui-uploader";

    if (props.isSupplementalAttachment) {
        domId = domId.concat("-", "supplemental-attachment");
    }

    const resetAuiRef = () => {
        if (auiUploaderRef.current) {
            auiUploaderRef.current.reset();
        } else {
            const uploader = document.getElementById(domId);
            if (uploader) {
                uploader.reset();
            }
        }
    };

    const closeDialog = () => {
        if (isUploadDialogEllipsesMenuOpen) {
            dispatch(setIsUploadDialogEllipsesMenuOpen(false));
        } else {
            dispatch(setIsUploadDialogOpen(false));
        }
    };

    const createNewCertificateAndFile = async event => {
        if (event.detail.files[0]) {
            const fileObj = event.detail.files[0];
            const FileName = event.detail.files[0].name;
            const fileData = {
                FileName,
                SubmitToStack: true,
                Status: { name: "PENDING" },
            };
            const base64Result = await toBase64(fileObj);
            const [fileType, data] = base64Result.split(","); // eslint-disable-line no-unused-vars

            if (fileType.includes("pdf")) {
                fileData.Pdf = data;
            } else {
                fileData.pages = [data];
            }
            dispatch(setHasCertificateFile(true));

            dispatch(
                setValidationData({ fileType, data: base64Result, fileName: fileData.FileName })
            );

            if (
                setting.useAvs &&
                matchSubscriptions(featureFlag.fvsUploader.avs.subscription, getSubscription)
            ) {
                const uploadData = new FormData();
                uploadData.append("File", fileObj, FileName);
                uploadData.append("avsId", uuidv4());
                dispatch(autoValidateFileAsync(uploadData));
            }
        }
    };

    const uploadFile = async (event, fileDetails) => {
        if (fileDetails) {
            const uploadData = {
                Key: fileDetails.key,
                FileName: fileDetails.name,
                ValidationId: fileDetails.validationId,
            };
            // If file is logo
            if (props.isLogo) {
                dispatch(saveCompanyLogo(uploadData));
            }

            // If file is Supplemental Attachment
            if (props.isSupplementalAttachment) {
                await dispatch(attachSupplementalFile(props.certificateID, uploadData));
                showToast("success", `${event.detail.files[0].name} is attached.`);
            }

            // If need to replace a file
            if (
                isUploadDialogEllipsesMenuOpen ||
                (props.isReplace && !props?.isSupplementalAttachment)
            ) {
                await dispatch(
                    replaceFileAsync(
                        props.certificateID,
                        uploadData,
                        !isUploadDialogEllipsesMenuOpen,
                        showToast
                    )
                );
            }

            // If just upload
            if (
                !isUploadDialogEllipsesMenuOpen &&
                !props.isSupplementalAttachment &&
                !props.isLogo &&
                !props.isReplace
            ) {
                await dispatch(uploadFileAsync(props.certificateID, uploadData, true, showToast));
            }
        }
        // eslint-disable-next-line
        event.detail.value = null;
    };

    function handleValidateEvent(e) {
        dispatch(setIsScanningFVS(false));
        const detail = e?.detail || {};
        const { filesPassedValidation, filesFailedValidation } = detail;
        if (filesPassedValidation?.length > 0) {
            dispatch(setPassedFVS(true));

            if (props.displayOn === "certificateDetails" && !props.isSupplementalAttachment) {
                dispatch(
                    setFvsValidationData({
                        Key: filesPassedValidation[0].key,
                        FileName: filesPassedValidation[0].name,
                        ValidationId: filesPassedValidation[0].validationId,
                    })
                );
            }

            if (props.displayOn !== "certificateDetails" || Number.isInteger(props.certificateID)) {
                uploadFile(uploadCertificateEvent, filesPassedValidation[0]);
                resetAuiRef();
                closeDialog();
            }
        }
        if (filesFailedValidation?.length > 0) {
            showToast("error", `${filesFailedValidation[0].message}`);
            resetAuiRef();
            closeDialog();
            dispatch(setPassedFVS(false));
        }
    }

    async function handleErrorEvent(e) {
        if (maxRetries > 0) {
            maxRetries -= 1;
            resetAuiRef();
            await logger(3, 4, e.detail);
        }
    }

    function handleAttachEvent(event) {
        if (event.detail.files[0]) {
            const FileName = event.detail.files[0].name;
            const fileNameExtension = FileName.slice(FileName.lastIndexOf(".") + 1);

            if (props.isLogo) {
                if (
                    !fileNameExtension.toLowerCase().includes("jpg") &&
                    !fileNameExtension.toLowerCase().includes("png") &&
                    !fileNameExtension.toLowerCase().includes("gif")
                ) {
                    showToast("error", "File type not allowed.");
                    resetAuiRef();
                    closeDialog();
                    return;
                }
            } else if (
                !fileNameExtension.toLowerCase().includes("pdf") &&
                !fileNameExtension.toLowerCase().includes("jpg") &&
                !fileNameExtension.toLowerCase().includes("jpeg") &&
                !fileNameExtension.toLowerCase().includes("png") &&
                !fileNameExtension.toLowerCase().includes("tif") &&
                !fileNameExtension.toLowerCase().includes("tiff") &&
                !fileNameExtension.toLowerCase().includes("gif")
            ) {
                showToast("error", "File type not allowed.");
                resetAuiRef();
                closeDialog();
                return;
            }

            setUploadCertificateEvent(event);
            if (props.displayOn === "certificateDetails") {
                dispatch(setIsScanningFVS(true));
                createNewCertificateAndFile(event);
            }
        }
    }
    return (
        <AuiUploader
            id={domId}
            ref={auiUploaderRef}
            callbackUrl="/"
            multiple={false}
            stagingInlineCta={false}
            ctaText="Upload a file"
            className="aui-uploader-review"
            disabled={isDisabled}
            accept={
                props?.isLogo
                    ? `image/jpeg, image/png, image/gif`
                    : `application/pdf, image/jpeg, image/png, image/tiff, image/gif`
            }
            stagingExternalCta={false}
            configurationId={process.env.REACT_APP_FVS_CONFIGURATIONID}
            requestCurrentAccessToken={getSessionAccessToken}
            onS-attach={e => {
                handleAttachEvent(e);
            }}
            retries={3}
            onS-validate={e => {
                handleValidateEvent(e);
            }}
            onS-error={e => {
                handleErrorEvent(e);
            }}>
            <div slot="guidelines">
                <div>
                    {props?.isLogo
                        ? `Upload your logo file`
                        : `Supported file formats include .pdf,.gif,.jpeg, and .png`}
                </div>
            </div>
            <div slot="loading" className="flex flex-dir-col align-items-center pad-all-xl">
                <SLoader loading className="margin-bottom-sm" aria-live="polite" />
                <h3 className="margin-all-none">We&apos;re importing the file</h3>
                <p className="margin-all-none">This might take a while.</p>
            </div>
            <div slot="disabled-content">
                <h3 className="margin-all-none">
                    {props?.showRevokedAlert
                        ? `Cannot upload a revoked certificate`
                        : `Not authorized to upload a certificate`}
                </h3>
            </div>
        </AuiUploader>
    );
}

export default FVSUploader;
