import { createSelector, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { convertFieldToString } from "../shared/Utils";

export const commonSlice = createSlice({
    name: "common",
    initialState: {
        isLoadingStates: false,
        isLoadingItems: false,
        isLoadingLocations: false,
        isLoadingCostCenter: false,
        items: [],
        locations: [],
        states: [],
        countriesStateCount: [],
        costCenters: [],
    },

    reducers: {
        setLoadingStates: (state, action) => {
            state.isLoadingStates = action.payload;
        },
        setIsLoadingItems: (state, action) => {
            state.isLoadingItems = action.payload;
        },
        setIsLoadingLocations: (state, action) => {
            state.isLoadingLocations = action.payload;
        },
        setStates: (state, action) => {
            state.states = action.payload;
        },
        setCountriesWithStateCount: (state, action) => {
            state.countriesStateCount = action.payload;
        },
        setItems: (state, action) => {
            state.items = action.payload;
        },
        setLocations: (state, action) => {
            state.locations = action.payload;
        },
        setCostCenters: (state, action) => {
            state.costCenters = action.payload;
        },
        setIsLoadingCostCenters: (state, action) => {
            state.isLoadingCostCenter = action.payload;
        },
    },
});

export const {
    setLoadingStates,
    setIsLoadingItems,
    setIsLoadingLocations,
    setItems,
    setLocations,
    setStates,
    setCountriesWithStateCount,
    setCostCenters,
    setIsLoadingCostCenters,
} = commonSlice.actions;

export const selectIsLoadingStates = state => {
    return state.common.isLoadingStates;
};
export const selectIsLoadingItems = state => {
    return state.common.isLoadingItems;
};
export const selectIsLoadingLocations = state => {
    return state.common.isLoadingLocations;
};

export const selectStates = state => {
    return state.common.states;
};
export const selectCountriesStateCount = createSelector(
    state => state.common,
    common => {
        if (localStorage.getItem("countriesStateCount")) {
            return JSON.parse(localStorage.getItem("countriesStateCount"));
        }
        return common.countriesStateCount;
    }
);

export const selectItems = state => {
    return state.common.items;
};
export const selectLocations = state => {
    return state.common.locations;
};

export const getStoreValues = (objectPath, localStoragePath) => (dispatch, getState) => {
    const keys = objectPath?.split(".");
    const store = getState();
    let value = store[keys[0]];
    for (let i = 1; i < keys?.length; i += 1) {
        value = value[keys[i]];
    }
    if (
        value?.length === 0 &&
        localStoragePath &&
        localStorage.getItem(localStoragePath)?.length > 0
    )
        return localStorage.getItem(localStoragePath);
    return value;
};

export const selectCostCenters = state => {
    return state.common.costCenters;
};

export const selectIsLoadingCostCenters = state => {
    return state.common.isLoadingCostCenter;
};

export const getCountriesWithStateCountAsync = () => async (dispatch, getState) => {
    const { certificate } = getState();

    if (certificate?.countriesStateCount?.length > 0) {
        return false;
    }
    if (localStorage.getItem("countriesStateCount")) {
        return false;
    }

    const response = await axios.get(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/countries-with-states?$top=300&$orderBy=id`,
        {
            withCredentials: true,
        }
    );

    const allCountries = response?.data?.value;
    if (allCountries) {
        dispatch(setCountriesWithStateCount(allCountries));
    } else {
        dispatch(setCountriesWithStateCount([]));
    }

    localStorage.setItem("countriesStateCount", JSON.stringify(allCountries));
    return true;
};

export const getStatesAsync =
    (stateBasedOnUS, allStates = false) =>
    async (dispatch, getState) => {
        const { common } = getState();
        if (common.isLoadingStates) {
            return false;
        }

        dispatch(setLoadingStates(true));
        let response = null;
        if (stateBasedOnUS) {
            response = await axios.get(
                `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/states?$filter=Country.Name eq 'United States'&$orderBy=name ASC&$top=200`,
                {
                    withCredentials: true,
                }
            );
        } else if (allStates) {
            response = await axios.get(
                `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/states?$orderBy=name ASC&$top=500`,
                {
                    withCredentials: true,
                }
            );
        } else
            response = await axios.get(
                `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/states?$filter=country.id in (1,2)&$orderBy=name ASC&$top=500`,
                {
                    withCredentials: true,
                }
            );

        if (response && response.data && response.data.value) {
            const transformedStates = convertFieldToString(response.data.value, "id");
            dispatch(setStates(transformedStates));
        }
        dispatch(setLoadingStates(false));
        return true;
    };

export const getItemsAsync = () => async (dispatch, getState) => {
    const { common } = getState();

    if (common.isLoadingItems) {
        return;
    }
    dispatch(setIsLoadingItems(true));

    const response = await axios.get(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/items?$orderBy=itemCode ASC&$top=1500`,
        {
            withCredentials: true,
        }
    );

    const allItems = convertFieldToString(response?.data?.value, "id");
    if (allItems) {
        dispatch(setItems(allItems));
    } else {
        dispatch(setItems([]));
    }
    dispatch(setIsLoadingItems(false));
};

export const getLocationAsync = () => async (dispatch, getState) => {
    const { common } = getState();

    if (common.isLoadingLocations) {
        return;
    }
    dispatch(setIsLoadingLocations(true));

    const response = await axios.get(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/locations?$orderBy=locationCode ASC&$top=1500`,
        {
            withCredentials: true,
        }
    );
    const allLocations = convertFieldToString(response?.data?.value, "id");

    if (allLocations) {
        dispatch(setLocations(allLocations));
    } else {
        dispatch(setLocations([]));
    }
    dispatch(setIsLoadingLocations(false));
};

export const getCostCentersAsync = () => async (dispatch, getState) => {
    const { common } = getState();

    if (common.isLoadingCostCenter) {
        return;
    }
    dispatch(setIsLoadingCostCenters(true));

    const response = await axios.get(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/costcenters?$orderBy=costCenterCode ASC&$top=1500`,
        {
            withCredentials: true,
        }
    );
    const allCostCenters = convertFieldToString(response?.data?.value, "costCenterId");
    if (allCostCenters) {
        dispatch(setCostCenters(allCostCenters));
    } else {
        dispatch(setCostCenters([]));
    }
    dispatch(setIsLoadingCostCenters(false));
};

export default commonSlice.reducer;
