import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SLoader, SPagination, SIcon, SRowActions } from "@avalara/skylab-react";
import { gridHeader, DataGrid } from "@certcapture/react-components";
import { v4 as uuidv4 } from "uuid";
import {
    fetchExemptionReasons,
    selectPage,
    selectIsLoading,
    setIsLoading,
    setPageToNull,
    EnableDisableExemptionReason,
} from "../../../app/exemptionReasonSlice";
import CreateUpdateExemptionReasonDialog from "./ManageExemptionReasonsDialogs/CreateUpdateExemptionReason";
import RecordCount from "../../search/RecordCount";
import DeleteExemptReasonDialog from "./ManageExemptionReasonsDialogs/DeleteExemptionReason";
import { documentTypeNames } from "../../../shared/constants";
import { isEligibleUser } from "../../../shared/sessionUtility";
import { avaTaxSubscription } from "../../navigation/Subscription";
import { selectSession } from "../../../app/sessionSlice";
import { matchSubscriptions, lowerCaseAllExceptFirst } from "../../../shared/Utils";
import featureFlag from "../../../featureToggler/featureFlag";

function ExemptionReasonsGrid(props) {
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [override, setOverride] = useState(false);
    const [taxCodeId, setTaxCodeId] = useState();
    const [enabled, setEnabled] = useState(false);
    const [systemCode, setSystemCode] = useState(false);
    const [inUse, setInUse] = useState(false);
    const [entityUseCode, setEntityUseCode] = useState("");
    const page = useSelector(selectPage, shallowEqual);
    const gridData = page.data;
    const pageData = page.paginate;
    const isLoading = useSelector(selectIsLoading);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [exemptReason, setExemptReason] = useState({});
    const session = useSelector(selectSession);

    const isEligibleUserSettingsEditExemptionReason = dispatch(
        isEligibleUser(featureFlag.settings.editExemptionReason)
    );
    const isEligibleUserSettingsDeleteExemptionReason = dispatch(
        isEligibleUser(featureFlag.settings.deleteExemptionReason)
    );
    const isEligibleUserSettingsDisableExemptionReason = dispatch(
        isEligibleUser(featureFlag.settings.disableExemptionReason)
    );

    useEffect(() => {
        dispatch(fetchExemptionReasons());
        return () => {
            dispatch(setPageToNull([]));
        };
    }, [dispatch]);

    const getEntityUseCode = (row, setForEdit = false) => {
        let returnVal = "";
        if (row.externalUseCode) {
            Object.values(row.externalUseCode).forEach(externalUseCode => {
                if (externalUseCode.externalApiDefinitionId === row.externalApiDefinitionId) {
                    if (setForEdit) {
                        setEntityUseCode(externalUseCode.apiTaxcode);
                    } else {
                        returnVal = `${externalUseCode.apiTaxcode}`;
                    }
                }
            });
        }
        return returnVal;
    };

    const handleEditExemptReason = row => {
        props.setOpenAddUpdateDialog(true);
        props.setAddOrEdit("edit");
        setName(row.name);
        setDescription(row.description);
        setOverride(row.markActual);
        setTaxCodeId(row.id);
        setEnabled(row.enabled);
        setSystemCode(row.systemCode);
        setInUse(row.inUse);
        getEntityUseCode(row, true);
    };

    const handleEnableDisable = async row => {
        const payload = {
            Name: row.name,
            Description: row.description,
            IsOverride: row.markActual,
            DocumentTypeName: documentTypeNames.salesAndUseTax,
            Outgoing: false,
            Enabled: !row.enabled,
        };
        await dispatch(EnableDisableExemptionReason(payload, row.id));
    };

    const ellipsisItems = row => {
        const itemList = [];
        const featureFlagKey = "settings";
        const actions = {
            editExemptionReason: "Edit",
            disableExemptionReason: row.enabled ? "Disable" : "Enable",
            deleteExemptionReason: "Delete",
        };

        Object.entries(actions).forEach(([key, value]) => {
            let feature = null;
            if (featureFlag[featureFlagKey] && featureFlag[featureFlagKey][key]) {
                feature = featureFlag[featureFlagKey][key];
            }

            if (feature !== null) {
                let item = {};

                if (key === "editExemptionReason") {
                    item = {
                        label: value,
                        classList: row.systemCode
                            ? ["link font-semibold"]
                            : ["link font-semibold color-disabled"],
                        id: `menu-${uuidv4()}-${value}`,
                        disabled: !isEligibleUserSettingsEditExemptionReason,
                    };
                } else if (key === "disableExemptionReason") {
                    item = {
                        label: value,
                        classList: ["link font-semibold"],
                        id: `menu-${uuidv4()}-${value}`,
                        disabled: !isEligibleUserSettingsDisableExemptionReason,
                    };
                } else if (key === "deleteExemptionReason") {
                    item = {
                        label: value,
                        classList: row.systemCode
                            ? ["link font-semibold"]
                            : ["link font-semibold color-disabled"],
                        id: `menu-${uuidv4()}-${value}`,
                        disabled:
                            row.systemCode ||
                            row.inUse ||
                            !isEligibleUserSettingsDeleteExemptionReason,
                    };
                }

                if (Object.keys(item).length > 0) {
                    itemList.push(item);
                }
            }
        });

        return itemList;
    };

    let columns = [
        gridHeader("Name", "name", value => lowerCaseAllExceptFirst(value), null, ""),
        gridHeader("Description", "description", value => value, null, "", null),
    ];

    if (matchSubscriptions(session.subscriptions, avaTaxSubscription)) {
        columns = [
            ...columns,
            gridHeader(
                "Entity use code",
                "code",
                (value, row) => getEntityUseCode(row),
                "text-center",
                "text-center",
                null
            ),
        ];
    }

    columns = [
        ...columns,
        gridHeader(
            "Is override",
            "markActual",
            (value, row) =>
                row.markActual ? <SIcon name="check" aria-hidden="true" /> : <SIcon />,
            "text-center",
            "text-center",
            null
        ),
        gridHeader(
            "Enabled",
            "enabled",
            (value, row) => (row.enabled ? <SIcon name="check" aria-hidden="true" /> : <SIcon />),
            "text-center",
            "text-center",
            null
        ),
        gridHeader(
            "In use",
            "inUse",
            (value, row) => (row.inUse ? <SIcon name="check" aria-hidden="true" /> : <SIcon />),
            "text-center",
            "text-center",
            null
        ),
        gridHeader(
            "",
            "",
            (value, row) => (
                <SRowActions
                    key={uuidv4()}
                    menuId={`menu-${uuidv4()}`}
                    className="row-actions"
                    actionItems={ellipsisItems(row)}
                    collapsed
                    onS-select={e => {
                        if (e.detail.id.includes("Edit")) {
                            handleEditExemptReason(row);
                        }

                        if (e.detail.id.includes("Enable") || e.detail.id.includes("Disable")) {
                            if (isEligibleUserSettingsDisableExemptionReason) {
                                handleEnableDisable(row);
                            }
                        }

                        if (e.detail.id.includes("Delete")) {
                            if (isEligibleUserSettingsDeleteExemptionReason) {
                                setExemptReason(row);
                                setIsDeleteDialogOpen(true);
                            }
                        }
                    }}
                />
            ),
            "text-left",
            "text-left"
        ),
    ];

    const getRowKey = row => {
        return row.id;
    };

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && pageData.totalRecords > 0 && e.detail.flag !== "") {
            setIsLoading(true);
            dispatch(
                fetchExemptionReasons(e.detail, null, e.detail.rowsPerPage, e.detail.startIndex)
            );
            setIsLoading(false);
        }
    };

    const table = isLoading ? (
        <div className="flex dl-flex-fill-height dl-flex-center">
            <h3>Loading ...</h3>
            <SLoader id="page-loader" className="medium" aria-live="polite" loading />
        </div>
    ) : (
        <s-table-container>
            <DataGrid
                columns={columns}
                rows={gridData}
                loading={isLoading}
                getKey={getRowKey}
                inContainer
            />
        </s-table-container>
    );

    return !isLoading && pageData.totalRecords === 0 ? (
        <div className="text-center flex dl-flex-fill-height dl-flex-center">
            <h1 className="text-color-no-data">No exempt reasons found</h1>
            <p className="text-color-no-data">Adjust your search and try again</p>
        </div>
    ) : (
        <React.Fragment>
            <RecordCount
                selectedCount={0}
                recordCount={pageData.totalRecords}
                recordName="exempt reasons"
            />
            {table}
            {pageData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={pageData.rowsPerPage}
                    totalRecords={pageData.totalRecords}
                    startIndex={pageData.startIndex}
                    onS-paginate={e => {
                        handlePaginate(e);
                    }}
                />
            ) : null}

            {isDeleteDialogOpen ? (
                <DeleteExemptReasonDialog
                    isDeleteDialogOpen
                    setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                    exemptReason={exemptReason}
                />
            ) : null}
            {props.openAddUpdateDialog ? (
                <CreateUpdateExemptionReasonDialog
                    openDialog={props.openAddUpdateDialog}
                    closeDialog={props.setOpenAddUpdateDialog}
                    addOrEdit={props.addOrEdit}
                    name={name}
                    description={description}
                    exemptionOverride={override}
                    taxCodeId={taxCodeId}
                    session={session}
                    matchSubscriptions={matchSubscriptions}
                    avaTaxSubscription={avaTaxSubscription}
                    enabled={enabled}
                    systemCode={systemCode}
                    inUse={inUse}
                    entityUseCode={entityUseCode}
                />
            ) : null}
        </React.Fragment>
    );
}

export default ExemptionReasonsGrid;
