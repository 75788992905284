import React, { useCallback, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { gridHeader, DataGrid } from "@certcapture/react-components";
import { SLoader, SPagination, SIcon } from "@avalara/skylab-react";
import {
    selectPage,
    selectLoading,
    selectSortColumn,
    setSortColumn,
    fetchBulkUploadCertificates,
    selectIsDeleting,
} from "../../../app/bulkUploadSlice";
import BulkUploadCertificateViewDialog from "../../sharedDialogs/BulkUploadCertificateViewDialog";
import BulkUploadStatus from "../../sharedComponents/BulkUploadStatus";
import DeleteCertificatesZipDialog from "./DeleteCertificatesZipDialog";

function BulkUploadCertificatesGrid() {
    const dispatch = useDispatch();
    const page = useSelector(selectPage, shallowEqual);
    const pageData = page.data;
    const paginateData = page.paginate;
    const sortColumn = useSelector(selectSortColumn, shallowEqual);
    const loading = useSelector(selectLoading);
    const [isBulkUploadDialogOpen, setIsBulkUploadDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [currentParentId, setCurrentParentId] = useState();
    const [fileStatus, setFileStatus] = useState();
    const isDeleting = useSelector(selectIsDeleting);

    const openBulkUploadDialog = row => {
        setIsBulkUploadDialogOpen(true);
        setCurrentParentId(row.id);
        setFileStatus(row.status);
    };

    const getRowKey = useCallback(row => {
        return row.id;
    }, []);

    async function sortFunction(e) {
        const columnId = e.detail.id;
        const order = sortColumn[0] === columnId ? !sortColumn[1] : true;
        dispatch(setSortColumn([columnId, order]));
        dispatch(fetchBulkUploadCertificates("", null, e.detail.rowsPerPage, e.detail.startIndex));
    }

    const deleteFile = row => {
        setIsDeleteDialogOpen(true);
        setCurrentParentId(row?.id);
    };

    const columns = [
        gridHeader(
            "Upload file Id",
            "id",
            (value, row) => (
                <div>
                    <button className="link" onClick={() => openBulkUploadDialog(row)}>
                        {value}
                    </button>
                </div>
            ),
            null,
            "",
            null,
            null,
            sortFunction,
            "id"
        ),
        gridHeader("File name", "filename", value => value, null, ""),
        gridHeader(
            "Created",
            "created",
            value => (value ? new Date(value).toLocaleDateString() : ""),
            null,
            "",
            null,
            null,
            sortFunction,
            "created"
        ),
        gridHeader("Uploaded by", "uploadedBy", value => value, null, ""),
        gridHeader(
            "Processing status",
            "status",
            (value, row) => <BulkUploadStatus data={row} />,
            null,
            ""
        ),
        gridHeader(
            "",
            "",
            (value, row) =>
                row.status !== "Deleted" && (
                    <button
                        className={`link ${
                            currentParentId === row?.id && isDeleting ? "loading" : ""
                        }`}
                        onClick={() => deleteFile(row)}
                        aria-label={`delete- ${row?.id}`}>
                        <SIcon name="trash" aria-hidden="true" title="Delete" />
                    </button>
                ),
            "text-center",
            "text-center"
        ),
    ];

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            await dispatch(
                fetchBulkUploadCertificates(
                    "",
                    null,
                    e.detail.rowsPerPage,
                    e.detail.startIndex,
                    e.detail
                )
            );
        }
    };

    let table = null;
    if (loading)
        table = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        );
    else
        table =
            pageData.length > 0 ? (
                <s-table-container id="zip-upload-table">
                    <DataGrid
                        columns={columns}
                        rows={pageData}
                        getKey={getRowKey}
                        inContainer
                        sortColumn={sortColumn}
                    />
                </s-table-container>
            ) : (
                <h2>No ZIP files found </h2>
            );

    return (
        <React.Fragment>
            {table}
            {paginateData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={paginateData.rowsPerPage}
                    totalRecords={paginateData.totalRecords}
                    startIndex={paginateData.startIndex}
                    onS-paginate={e => {
                        handlePaginate(e);
                    }}
                />
            ) : null}

            {isBulkUploadDialogOpen ? (
                <BulkUploadCertificateViewDialog
                    isBulkUploadDialogOpen={isBulkUploadDialogOpen}
                    parentId={currentParentId}
                    fileStatus={fileStatus}
                    setIsBulkUploadDialogOpen={setIsBulkUploadDialogOpen}
                />
            ) : null}

            {isDeleteDialogOpen ? (
                <DeleteCertificatesZipDialog
                    isDeleteDialogOpen={isDeleteDialogOpen}
                    setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                    parentId={currentParentId}
                />
            ) : null}
        </React.Fragment>
    );
}
export default BulkUploadCertificatesGrid;
