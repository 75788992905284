import React, { useState, useEffect, useRef } from "react";
import { SBox, SInputExtended, SLoader } from "@avalara/skylab-react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { escapeRegExp } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
    quickSearch,
    selectData,
    selectIsLoading,
    clearQuickSearch,
} from "../../app/CustomerQuickSearchSlice";
import "./customerQuickSearch.css";

const CustomerQuickSearch = React.memo(() => {
    const dispatch = useDispatch();
    const quickSearchResults = useSelector(selectData);
    const isLoading = useSelector(selectIsLoading);
    const [showResult, setShowResult] = useState(false);
    const [value, setValue] = useState("");
    const firstRender = useRef(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (value.length >= 1) {
                dispatch(quickSearch(value));
            } else {
                setShowResult(false);
                setValue("");
                dispatch(clearQuickSearch());
            }
        }, 400);
        return () => clearTimeout(timeoutId);
    }, [dispatch, value]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
        }
        if (!quickSearchResults.length) {
            setShowResult(false);
        } else if (quickSearchResults.length) {
            setShowResult(true);
        }
    }, [quickSearchResults]);

    function generateHighlight(fieldName) {
        let finalForm = "";
        if (fieldName && fieldName.length >= 1) {
            let subidx = 0;
            const escpStr = escapeRegExp(value);
            const regex = new RegExp(`(${escpStr})`, "gi");
            const textArray = fieldName.split(regex);
            finalForm = textArray.map(str => {
                if (regex.test(str)) {
                    subidx += 1;
                    return <b key={subidx}>{str}</b>;
                }
                return str;
            });
        }
        return finalForm;
    }
    return (
        <div className="boxStyle">
            <label htmlFor="quick-search-input">
                Search by customer name, customer code or address
            </label>
            <SInputExtended
                autofocus
                inputId="quick-search-input"
                type="search"
                value={value}
                onS-clear={() => {
                    setShowResult(false);
                    setValue("");
                }}
                onS-input={e => {
                    setValue(e.detail.value);
                    setShowResult(true);
                }}
                onS-search={e => {
                    setValue(e.detail.value);
                    setShowResult(true);
                }}
            />
            {showResult && !isLoading && value.length >= 1 ? (
                <SBox className="results">
                    {quickSearchResults.map(result => {
                        const linkVal = result.isVendor
                            ? `/vendor/${result.id}`
                            : `/customer/${result.id}`;
                        return (
                            <Link
                                key={uuidv4()}
                                to={linkVal}
                                className="link-item text-default font-normal">
                                <ul type="none" className="margin-bottom-sm selected">
                                    <li>
                                        <span className="text-blue-medium margin-right-xs">
                                            {generateHighlight(result.name)}
                                        </span>
                                        <span> {generateHighlight(result.customerNumber)}</span>
                                    </li>
                                    <li>
                                        {result.addressLine1 ? (
                                            <span>{generateHighlight(result.addressLine1)}, </span>
                                        ) : null}

                                        {result.city ? (
                                            <span>{generateHighlight(result.city)}, </span>
                                        ) : null}

                                        {result.state?.initials ? (
                                            <span>
                                                {result.state.initials} {"  "}
                                            </span>
                                        ) : null}

                                        {result.zip ? <span>{result.zip} </span> : null}
                                    </li>
                                </ul>
                            </Link>
                        );
                    })}
                </SBox>
            ) : null}
            {!showResult && !firstRender.current && !isLoading && value.length >= 1 ? (
                <SBox className="results">
                    <span>No results found</span>
                </SBox>
            ) : null}

            {isLoading ? (
                <SBox className="results">
                    <span>
                        <SLoader className="small" aria-live="polite" loading />
                    </span>
                </SBox>
            ) : null}
        </div>
    );
});

export default CustomerQuickSearch;
