import React, { useRef, useState, useCallback, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import { SDialog, SRow, SCol, SLoader } from "@avalara/skylab-react";
import axios from "../../../axios";
import SelectExposureZone from "../../sharedComponents/SelectExposureZone";
import toast from "../../../hooks/toast";
import {
    selectCustomerShipToZones,
    setLoadingCustomerShipToZones,
    selectIsLoadingCustomerShipToZones,
    setCustomerShipToZones,
    setCustomer,
    getCustomerMissingCertRegion,
    getVendorMissingCertRegion,
} from "../../../app/customerSlice";
import { selectIsLoadingExposureZones } from "../../../app/certificateSlice";
import {
    setIsAddRegionDialogOpen,
    fetchCustomerExemptionsDetails,
} from "../../../app/customerCertificateSlice";
import "../customer.css";
import FeatureToggler from "../../../featureToggler/FeatureToggler";

function CustomerAddRegionDialog(props) {
    const dispatch = useDispatch();
    const vendorCheck = props.isVendor;
    const taxRegionsRef = useRef(null);
    const customerSelectedShipToZones = useSelector(selectCustomerShipToZones, shallowEqual);
    const [customerShipToZones, setCustomersShipToZones] = useState(customerSelectedShipToZones);
    const [deselectedClicked, setDeselectedClicked] = useState(false);
    const [formUpdated, setFormUpdated] = useState(false);
    const isLoadingCustomerShipToZones = useSelector(
        selectIsLoadingCustomerShipToZones,
        shallowEqual
    );
    const isLoadingExposureZone = useSelector(selectIsLoadingExposureZones);
    const docName = "Sales and Use Tax";
    const saveButton = classnames({
        primary: true,
        loading: isLoadingCustomerShipToZones,
    });
    const [showToast] = toast();
    const labelString = vendorCheck
        ? "Select regions where you purchase from this vendor. Regions where this company's certificates exempt purchases can't be removed."
        : "Select regions where you expect exemptions certificates from this customer. Regions where the customer has an exemption certificate can't be removed.";
    const entityType = vendorCheck ? "vendor" : "customer";
    const closeDialog = () => {
        dispatch(setIsAddRegionDialogOpen(false));
    };
    let CustomerDOM = null;
    const handleAddShipToZone = value => {
        let items = value;
        if (!Array.isArray(value)) {
            items = [{ id: value.value, name: value.label }];
        }
        setCustomersShipToZones([...customerShipToZones, ...items]);
        dispatch(setLoadingCustomerShipToZones(false));
        setFormUpdated(true);
        setDeselectedClicked(false);
    };

    useEffect(() => {
        if (customerSelectedShipToZones.length > 0) {
            setCustomerShipToZones(customerSelectedShipToZones);
        }
    }, [customerSelectedShipToZones.length > 0]);

    const handleRemoveShipToZone = useCallback(
        (eventDetail, isDeselectAll) => {
            const { value } = eventDetail;
            if (isDeselectAll) {
                setCustomersShipToZones([]);
            } else {
                setCustomersShipToZones(customerShipToZones.filter(option => option.id !== value));
            }
            dispatch(setLoadingCustomerShipToZones(false));
            setFormUpdated(true);
        },
        [customerShipToZones]
    );

    const addCustomersExposureZones = async (customerId, exposureZonesId, documentDescription) => {
        const requestBody = {
            exposureZoneIds: exposureZonesId,
            documentTypeName: documentDescription,
            outgoing: vendorCheck,
        };
        return axios
            .post(
                `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customers/${customerId}/ship-to-zones`,
                requestBody,
                {
                    withCredentials: true,
                }
            )
            .catch(error => {
                return error;
            });
    };

    const removeCustomersExposureZones = async (
        customerId,
        exposureZonesId,
        documentDescription
    ) => {
        const requestBody = {
            exposureZoneIds: exposureZonesId,
            documentTypeName: documentDescription,
            outgoing: vendorCheck,
        };
        return axios
            .delete(
                `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customers/${customerId}/ship-to-zones`,
                {
                    withCredentials: true,
                    data: requestBody,
                }
            )
            .catch(error => {
                return error;
            });
    };

    const updateCustomersExposureZones = async (customerId, shipToStates) => {
        let response;
        const shipStateIds = shipToStates.map(shipState => shipState.id);
        const customerShipStateIds = customerSelectedShipToZones.map(shipState => shipState.id);
        const addRequestBody = shipStateIds.filter(val => !customerShipStateIds.includes(val));
        if (addRequestBody.length !== 0) {
            response = await addCustomersExposureZones(customerId, addRequestBody, docName);
        }
        const removeRequestBody = customerShipStateIds.filter(val => !shipStateIds.includes(val));
        if (removeRequestBody.length !== 0) {
            response = await removeCustomersExposureZones(customerId, removeRequestBody, docName);
        }
        if (removeRequestBody.length === 0 && addRequestBody.length === 0) {
            dispatch(setLoadingCustomerShipToZones(false));
            setFormUpdated(false);
            showToast("warning", "There are no changes.");
        }
        return response;
    };
    const addTaxRegion = async () => {
        dispatch(setLoadingCustomerShipToZones(true));
        const response = await updateCustomersExposureZones(props.customer.id, customerShipToZones);
        if (response && !response.data) {
            showToast("warning", `Could not update ${entityType} regions.`);
            return;
        }
        if (response && response.data) {
            dispatch(setCustomerShipToZones(customerShipToZones));
            showToast("success", `Tax region added.`);
            dispatch(setLoadingCustomerShipToZones(false));
            dispatch(setIsAddRegionDialogOpen(false));
            dispatch(fetchCustomerExemptionsDetails());
            const customerData = JSON.parse(JSON.stringify(props.customer));
            customerData.isShipTo = response.data.isShipTo;
            customerData.isBillTo = response.data.isBillTo;
            dispatch(setCustomer(customerData));
            if (entityType === "vendor") {
                dispatch(getVendorMissingCertRegion(props.customer.id));
            } else {
                dispatch(getCustomerMissingCertRegion(props.customer.id));
            }
        }
    };

    if (isLoadingExposureZone && isLoadingCustomerShipToZones) {
        CustomerDOM = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h1>Loading ...</h1>
                <SLoader loading="true" aria-live="polite" />
            </div>
        );
    } else {
        CustomerDOM = (
            <SRow>
                <SCol>
                    <FeatureToggler category="customers" id="taxRegions">
                        <SelectExposureZone
                            label={labelString}
                            id="shipToZones"
                            name="shipToZones"
                            onAdd={handleAddShipToZone}
                            onRemove={handleRemoveShipToZone}
                            value={customerShipToZones}
                            multiple
                            exposureZoneDisabled={false}
                            disabled={false}
                            loading={isLoadingExposureZone && isLoadingCustomerShipToZones}
                            ref={taxRegionsRef}
                            externalDeselectedClicked={deselectedClicked}
                            isVendor={vendorCheck}
                        />
                    </FeatureToggler>
                </SCol>
            </SRow>
        );
    }

    return (
        <SDialog
            id="customerAddRegionDialog"
            open={props.isAddRegionDialogOpen}
            onS-dismiss={closeDialog}
            className="region-dialog-width">
            <div slot="header" id="taxregions">
                Region
            </div>
            <div slot="body" className="region-dialog-body">
                {CustomerDOM}
            </div>
            <div slot="footer">
                <button
                    className={saveButton}
                    disabled={!formUpdated}
                    type="button"
                    onClick={addTaxRegion}>
                    Save
                </button>
                <button className="secondary" onClick={closeDialog}>
                    Cancel
                </button>
            </div>
        </SDialog>
    );
}
export default CustomerAddRegionDialog;
