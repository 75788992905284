import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { buildURL } from "../shared/Utils";

export const certificateHistorySlice = createSlice({
    name: "certificateHistory",
    initialState: {
        isloading: false,
        page: {
            data: [],
            paginate: {
                pageNumber: 0,
                startIndex: -1,
                endIndex: 0,
                rowsPerPage: 0,
                totalRecords: 0,
            },
        },
        sortColumn: "created DESC",
    },

    reducers: {
        setLoading: (state, action) => {
            state.isloading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setNoDataType: (state, action) => {
            state.noDataType = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
    },
});

export const { setLoading, setPage, setPageToNull, setSortColumn, setNoDataType } =
    certificateHistorySlice.actions;

export const selectPage = state => {
    return state.certificateHistory.page;
};

export const selectSortColumn = state => {
    return state.certificateHistory.sortColumn;
};

export const selectIsLoading = state => {
    return state.certificateHistory.isLoading;
};

export const fetchCertificateHistoryAPI =
    (certificateID, include, top, skip, onLanding, paginateDetails) =>
    async (dispatch, getState) => {
        const { isloading, sortColumn } = getState().certificateHistory;
        if (isloading) {
            return;
        }
        dispatch(setLoading(true));
        const apiURI = buildURL(
            `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/certificates/${certificateID}/certificate-history`,
            "",
            include,
            top,
            skip,
            sortColumn
        );

        const response = await axios.get(`${apiURI}`, { withCredentials: true });
        if (response.data && response.data.count === "0") {
            onLanding ? dispatch(setNoDataType("onLanding")) : dispatch(setNoDataType("onSearch"));
            dispatch(setPageToNull());
        } else {
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.currentPage,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response.data.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 20,
                      totalRecords: response.data.count,
                  };
            dispatch(
                setPage({
                    data: response.data.value,
                    paginate: paginateData,
                })
            );
        }

        dispatch(setLoading(false));
    };

export default certificateHistorySlice.reducer;
