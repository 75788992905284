import React, { useEffect, useState, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { SCol, SDialog, SIcon, SLoader, SRow, SSelect, STabs } from "@avalara/skylab-react";
import { v4 as uuidv4 } from "uuid";
import { useId } from "@certcapture/react-components";
import classnames from "classnames";
import { lowerCaseAllExceptFirst } from "../../shared/Utils";
import axios from "../../axios";

import {
    setCertificateCommunicationTaxTypes,
    selectIsTaxTypeDialogOpen,
    setIsTaxTypeDialogOpen,
    selectCertificateCommunicationTaxTypes,
    selectIsLoadingTaxContentForms,
} from "../../app/certificateSlice";

const TaxTypeDialog = React.memo(props => {
    const dispatch = useDispatch();
    const isTaxTypeDialogOpen = useSelector(selectIsTaxTypeDialogOpen);
    const [communicationTaxTypes, setCommunicationTaxTypes] = useState({});
    const [isLoadingCommunicationTaxTypes, setIsLoadingCommunicationTaxTypes] = useState(false);
    const isLoadingTaxContentForms = useSelector(selectIsLoadingTaxContentForms);

    const certificateCommunicationTaxTypes = useSelector(
        selectCertificateCommunicationTaxTypes,
        shallowEqual
    );

    const [filteredCommunicationTaxTypes, setFilteredCommunicationTaxTypes] = useState({});
    const [showCommunications, setShowCommunications] = useState(true);
    const [showSales, setShowSales] = useState(false);
    const [showUse, setShowUse] = useState(false);
    const [currentTab, setCurrentTab] = useState("Communications");
    const [noResultsText, setNoResultsText] = useState("");
    const [transformedToSelect, setTransformedToSelect] = useState(false);
    const [currentRegionName, setCurrentRegionName] = useState(props.exposureZoneName);
    const [currentRegionID, setCurrentRegionID] = useState(props.exposureZoneId);
    const [getCurrentId] = useId();
    const spanClass = classnames({ hidden: transformedToSelect === true, block: true });
    const labelClass = classnames({ "font-bold": !transformedToSelect });
    let editHtml = [];
    const editLabels = [];
    let viewHtml = [];
    const { isMulti } = props;
    let currentLabel = "";
    let currentTransformLabel = "";

    const filterCommunicationTaxTypes = useCallback((filterKey, regionID) => {
        const filtered = certificateCommunicationTaxTypes.filter(
            element =>
                element.rateType.includes(filterKey) &&
                element.exposureZoneId === parseInt(regionID, 10)
        );

        setFilteredCommunicationTaxTypes(filtered);
    }, []);

    const fetchCommunicationTaxTypes = async (tab, regionID, filterTaxTypes = false) => {
        setIsLoadingCommunicationTaxTypes(true);
        const taxTypes = {};
        const filterString = `?$filter=exposureZoneId eq ${regionID} AND communicationTaxTypesCategory.rateType contains '${tab}'&$top=1000`;
        const response = await axios.get(
            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/communication-tax-types${filterString}`,
            {
                withCredentials: true,
            }
        );

        if (response && response.data) {
            Object.values(response.data.value).forEach(element => {
                if (
                    typeof taxTypes[element.communicationTaxTypesCategory.rateType] === "undefined"
                ) {
                    taxTypes[element.communicationTaxTypesCategory.rateType] = [
                        {
                            id: element.id.toString(),
                            codeId: element.afcCode.code,
                            code: element.afcCode.name,
                            rateType: element.communicationTaxTypesCategory.rateType,
                            exposureZoneId: element.exposureZoneId,
                        },
                    ];
                } else {
                    taxTypes[element.communicationTaxTypesCategory.rateType].push({
                        id: element.id.toString(),
                        codeId: element.afcCode.code,
                        code: element.afcCode.name,
                        rateType: element.communicationTaxTypesCategory.rateType,
                        exposureZoneId: element.exposureZoneId,
                    });
                }
            });

            const ordered = Object.keys(taxTypes)
                .sort()
                .reduce((obj, key) => {
                    const returnObj = obj;
                    returnObj[key] = taxTypes[key];
                    return returnObj;
                }, {});

            setCommunicationTaxTypes(ordered);

            if (filterTaxTypes && !transformedToSelect) {
                filterCommunicationTaxTypes(currentTab, regionID);
            }
        }

        setIsLoadingCommunicationTaxTypes(false);
        return true;
    };

    useEffect(() => {
        filterCommunicationTaxTypes("Communications", props.exposureZoneId);
    }, [filterCommunicationTaxTypes, props.exposureZoneId]);

    const transformTaxTypes = () => {
        fetchCommunicationTaxTypes(currentTab, currentRegionID);
        setTransformedToSelect(true);
    };

    const tabItems = [
        {
            id: "communications",
            label: "Communications",
            tabPanelId: "tabpanel-communications",
            selected: showCommunications,
        },
        {
            id: "sales",
            label: "Sales",
            tabPanelId: "tabpanel-sales",
            selected: showSales,
        },
        {
            id: "use",
            label: "Use",
            tabPanelId: "tabpanel-use",
            selected: showUse,
        },
    ];

    const navigateTo = to => {
        if (to.includes("communications") && !showCommunications) {
            const tab = "Communications";
            if (transformedToSelect) {
                fetchCommunicationTaxTypes(tab, currentRegionID, true);
            } else {
                filterCommunicationTaxTypes(tab, currentRegionID);
            }
            setShowSales(false);
            setShowUse(false);
            setShowCommunications(true);
            setNoResultsText(to);
            setCurrentTab(tab);
        } else if (to.includes("sales") && !showSales) {
            const tab = "Sales";
            if (transformedToSelect) {
                fetchCommunicationTaxTypes(tab, currentRegionID, true);
            } else {
                filterCommunicationTaxTypes(tab, currentRegionID);
            }
            setShowCommunications(false);
            setShowUse(false);
            setShowSales(true);
            setNoResultsText(to);
            setCurrentTab(tab);
        } else if (to.includes("use") && !showUse) {
            const tab = "Use";
            if (transformedToSelect) {
                fetchCommunicationTaxTypes(tab, currentRegionID, true);
            } else {
                filterCommunicationTaxTypes(tab, currentRegionID);
            }
            setShowCommunications(false);
            setShowSales(false);
            setShowUse(true);
            setNoResultsText(to);
            setCurrentTab(tab);
        }
    };

    const getSelected = taxTypeId => {
        let selected = false;

        Object.values(certificateCommunicationTaxTypes).forEach(element => {
            if (element.id === taxTypeId.toString()) {
                selected = true;
            }
            return false;
        });
        return selected;
    };

    const getTaxTypeDisplayLabel = element => {
        return `${element.code} - ${element.codeId}`;
    };

    const transformLowerCaseLabel = str => {
        const newArr = str.split(" ");
        newArr[1] = newArr[1].charAt(0).toUpperCase() + newArr[1].slice(1);
        return newArr.join(" ");
    };

    const getCategoryOptionList = (parent, communicationTaxType) => {
        const optionList = communicationTaxType.map(element => {
            const selectOption = {
                label: getTaxTypeDisplayLabel(element),
                value: element.id.toString(),
                selected: getSelected(element.id),
                parent,
            };
            return selectOption;
        });
        return optionList;
    };

    const updatePostData = (e, add) => {
        if (add) {
            const taxTypeCategory = transformLowerCaseLabel(e.detail.item.parent);
            const communicationTaxTypeRecord = communicationTaxTypes[taxTypeCategory].find(
                o => o.id === e.detail.item.value
            );

            const newArray = [...certificateCommunicationTaxTypes, communicationTaxTypeRecord];

            const byRateType = newArray.slice(0);
            byRateType.sort((a, b) => {
                const x = a.rateType.toLowerCase();
                const y = b.rateType.toLowerCase();
                // eslint-disable-next-line no-nested-ternary
                return x < y ? -1 : x > y ? 1 : 0;
            });

            dispatch(setCertificateCommunicationTaxTypes(byRateType));
        } else if (!e.detail.isDeselectAll) {
            const filtered = certificateCommunicationTaxTypes.filter(f => {
                return f.id !== e.detail.item.value;
            });

            dispatch(setCertificateCommunicationTaxTypes(filtered));
        } else {
            const filtered = certificateCommunicationTaxTypes.filter(element => {
                return (
                    lowerCaseAllExceptFirst(element.rateType) !== e.detail.item.parent ||
                    element.exposureZoneId !== currentRegionID
                );
            });
            dispatch(setCertificateCommunicationTaxTypes(filtered));
        }
    };

    const handleTaxRegionChange = el => {
        setCurrentRegionName(el.detail.item.label);
        setCurrentRegionID(el.detail.item.value);
        fetchCommunicationTaxTypes(currentTab, el.detail.item.value, true);
    };

    return (
        <SDialog
            open={isTaxTypeDialogOpen}
            id="taxType-dialog"
            className="tax-type-min-width"
            onS-dismiss={() => dispatch(setIsTaxTypeDialogOpen(false))}
            aria-modal="true">
            <div slot="header" id="dialog-title">
                Taxes exempted by this form
            </div>
            <div slot="body">
                <SRow>
                    <SCol span="auto">
                        <SRow hidden={!isMulti}>
                            <SCol>
                                <label id="tax-region-id" htmlFor="tax-region-select">
                                    Tax region
                                </label>
                                <SSelect
                                    name="tax-region-select"
                                    inputId="tax-region-select"
                                    className="margin-bottom-md"
                                    onS-select={e => handleTaxRegionChange(e)}
                                    optionsList={props.getTaxTypeRegionOptionList(
                                        currentRegionName
                                    )}
                                />
                            </SCol>
                        </SRow>

                        {!transformedToSelect ? (
                            <React.Fragment>
                                {!isMulti ? (
                                    <span className="taxContentFormDialogHeader margin-bottom-md">
                                        {props.exposureZoneName}
                                    </span>
                                ) : null}
                                <button
                                    onClick={() => transformTaxTypes()}
                                    className="link small-link">
                                    <SIcon
                                        className="margin-right-xs"
                                        name="edit-pencil"
                                        aria-hidden="true"
                                        onclick={() => transformTaxTypes()}
                                    />
                                    Edit
                                </button>
                            </React.Fragment>
                        ) : null}
                    </SCol>
                </SRow>
                <SRow>
                    <SCol>
                        <STabs tabItems={tabItems} onS-select={e => navigateTo(e.detail.id)} />
                    </SCol>
                </SRow>

                <SRow hidden={!isLoadingCommunicationTaxTypes && !isLoadingTaxContentForms}>
                    <div className="flex dl-flex-fill-height dl-flex-center">
                        <h3>Loading ...</h3>
                        <SLoader id="page-loader" className="medium" aria-live="polite" loading />
                    </div>
                </SRow>

                <SRow
                    hidden={
                        isLoadingCommunicationTaxTypes ||
                        isLoadingTaxContentForms ||
                        transformedToSelect
                    }>
                    <SCol>
                        {filteredCommunicationTaxTypes.length > 0 ? (
                            filteredCommunicationTaxTypes.map(element => {
                                const { rateType } = element;
                                if (lowerCaseAllExceptFirst(rateType) !== currentLabel) {
                                    currentLabel = lowerCaseAllExceptFirst(rateType);
                                    viewHtml.push(
                                        <React.Fragment key={uuidv4()}>
                                            <label className={labelClass}>{currentLabel}</label>
                                        </React.Fragment>
                                    );
                                }

                                viewHtml.push(
                                    <span key={uuidv4()} className={spanClass}>
                                        {getTaxTypeDisplayLabel(element)}
                                    </span>
                                );

                                const returnMap = viewHtml;
                                viewHtml = [];
                                return returnMap;
                            })
                        ) : (
                            <div className="margin-top-xs">
                                <span className="font-semibold line-height-sm">
                                    There aren&apos;t any {noResultsText} taxes being exempted.
                                </span>
                            </div>
                        )}
                    </SCol>
                </SRow>

                <SRow
                    hidden={
                        !transformedToSelect ||
                        isLoadingCommunicationTaxTypes ||
                        isLoadingTaxContentForms
                    }>
                    <SCol>
                        {Object.keys(communicationTaxTypes).length > 0 ? (
                            Object.keys(communicationTaxTypes).map(key => {
                                const currentKey = lowerCaseAllExceptFirst(key);
                                if (
                                    currentKey !== currentTransformLabel &&
                                    !editLabels.includes(currentKey)
                                ) {
                                    currentTransformLabel = currentKey;
                                    editLabels.push(currentKey);
                                    editHtml.push(
                                        <label key={uuidv4()} className={labelClass}>
                                            {currentTransformLabel}
                                        </label>
                                    );

                                    editHtml.push(
                                        <SSelect
                                            multiple
                                            key={getCurrentId()}
                                            inputId={getCurrentId()}
                                            className="category-select margin-bottom-md"
                                            optionsList={getCategoryOptionList(
                                                currentTransformLabel,
                                                communicationTaxTypes[key]
                                            )}
                                            onS-select={e => updatePostData(e, true)}
                                            onS-deselect={e => updatePostData(e, false)}
                                        />
                                    );
                                }

                                const returnMap = editHtml;
                                editHtml = [];
                                return returnMap;
                            })
                        ) : (
                            <div className="margin-top-xs">
                                <span className="font-semibold line-height-sm">
                                    There aren&apos;t any {noResultsText} taxes being exempted.
                                </span>
                            </div>
                        )}
                    </SCol>
                </SRow>
            </div>
            <div slot="footer">
                {transformedToSelect ? (
                    <React.Fragment>
                        <button
                            className="secondary small"
                            onClick={() => {
                                dispatch(setIsTaxTypeDialogOpen(false));
                            }}>
                            Cancel
                        </button>
                        <button
                            onClick={() => {
                                dispatch(setIsTaxTypeDialogOpen(false));
                            }}
                            className="primary small">
                            Save
                        </button>
                    </React.Fragment>
                ) : (
                    <button
                        className="secondary small"
                        onClick={() => {
                            dispatch(setIsTaxTypeDialogOpen(false));
                        }}>
                        Close
                    </button>
                )}
            </div>
        </SDialog>
    );
});

export default React.memo(TaxTypeDialog);
