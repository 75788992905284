import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { roleKeyValue } from "../shared/constants";

const initialState = {
    isLoading: false,
    isPutPostLoading: false,
    isCompanyLoading: false,
    userInfo: {
        subjectId: null,
        userName: null,
        firstName: null,
        lastName: null,
        email: null,
        isActive: null,
        userRoles: null,
        userCompanyLocations: [],
    },
    isAllCompany: false,
    companyInfo: [],
    staticGridData: [],
    isEditClicked: false,
    companiesSelectedCount: 0,
};

const roleManagementSlice = createSlice({
    name: "roleManagement",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsPutPostLoading: (state, action) => {
            state.isPutPostLoading = action.payload;
        },
        setIsCompanyLoading: (state, action) => {
            state.isCompanyLoading = action.payload;
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
        setCompanyInfo: (state, action) => {
            state.companyInfo = action.payload;
        },
        setIsAllCompany: (state, action) => {
            state.isAllCompany = action.payload;
        },
        setStaticGridData: (state, action) => {
            state.staticGridData = action.payload;
        },
        setIsEditClicked: (state, action) => {
            state.isEditClicked = action.payload;
        },
        setCompaniesSelectedCount: (state, action) => {
            state.companiesSelectedCount = action.payload;
        },
    },
});

export const {
    setIsLoading,
    setIsPutPostLoading,
    setUserInfo,
    setCompanyInfo,
    setIsAllCompany,
    setStaticGridData,
    setIsEditClicked,
    setCompaniesSelectedCount,
    setIsCompanyLoading,
} = roleManagementSlice.actions;

export const selectIsCompanyLoading = state => {
    return state.roleManagement.isCompanyLoading;
};

export const selectCompaniesSelectedCount = state => {
    return state.roleManagement.companiesSelectedCount;
};

export const selectIsEditClicked = state => {
    return state.roleManagement.isEditClicked;
};

export const selectStaticGridData = state => {
    return state.roleManagement.staticGridData;
};

export const selectUserInfoRole = state => {
    return state.roleManagement.userInfo.userRoles;
};

export const selectUserLocationsInfo = state => {
    return state.roleManagement.userInfo.userCompanyLocations;
};

export const selectIsPutPostLoading = state => {
    return state.roleManagement.isPutPostLoading;
};

export const selectIsLoading = state => {
    return state.roleManagement.isLoading;
};

export const selectUserInfo = state => {
    return state.roleManagement.userInfo;
};

export const selectCompanyInfo = state => {
    return state.roleManagement.companyInfo;
};

export const selectIsAllCompany = state => {
    return state.roleManagement.isAllCompany;
};

export const selectSubjectId = state => {
    return state.roleManagement.userInfo.subjectId;
};

const formStaticGridData = (userRoles, userCompanyLocations, dispatch) => {
    let gridData = [];
    if (userRoles[0].isAllCompany) {
        const locationsList = userCompanyLocations
            ?.map(userCompanyLocation => userCompanyLocation.locations)[0]
            ?.map(x => x.name);
        gridData = [
            {
                name: "All companies",
                service: userRoles[0].serviceName,
                role: userRoles[0].displayRoleName,
                location: locationsList?.join(", "),
            },
        ];
    } else if (userRoles.length === 1) {
        const locationsList = userCompanyLocations
            ?.map(userCompanyLocation => userCompanyLocation.locations)[0]
            ?.map(x => x.name);
        gridData = userRoles[0].companyIds.map(element => {
            return {
                name: element.name,
                service: userRoles[0].serviceName,
                role: userRoles[0].displayRoleName,
                location: locationsList?.join(", "),
            };
        });
    } else {
        userRoles.forEach(userRole => {
            userRole.companyIds.forEach(company => {
                gridData.push({
                    name: company.name,
                    service: userRole.serviceName,
                    role: userRole.displayRoleName,
                    location: userCompanyLocations
                        .filter(userCompanyLocation => {
                            if (userCompanyLocation.companyId === company.id) return true;
                            return false;
                        })[0]
                        ?.locations?.map(x => x.name)
                        .join(", "),
                });
            });
        });
    }
    dispatch(setStaticGridData(gridData));
};

export const getUserInfo = userId => async (dispatch, getState) => {
    const { isLoading } = getState().roleManagement;
    if (isLoading) return;
    dispatch(setIsLoading(true));
    await axios
        .get(
            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/user-role/${userId}`,
            { withCredentials: true }
        )
        .then(response => {
            if (response.status === 200) {
                const { userRoles, userCompanyLocations } = response.data;
                if (userRoles) {
                    dispatch(setIsAllCompany(userRoles[0].isAllCompany));
                    formStaticGridData(userRoles, userCompanyLocations, dispatch);
                }
                const locations = userCompanyLocations
                    ? userCompanyLocations.map(userCompanyLocation => ({
                          ...userCompanyLocation,
                          locationIds: userCompanyLocation.locationIds.map(locationId =>
                              locationId.toString()
                          ),
                      }))
                    : [];

                dispatch(setUserInfo({ ...response.data, userCompanyLocations: locations }));
            }
        });
    dispatch(setIsLoading(false));
};

export const getAccountCompanies = userId => async (dispatch, getState) => {
    const { isCompanyLoading, userInfo } = getState().roleManagement;
    if (isCompanyLoading) return;
    dispatch(setIsCompanyLoading(true));
    await axios
        .get(
            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/user-role/user-entitlements-company/${userId}`,
            { withCredentials: true }
        )
        .then(response => {
            if (response.status === 200 && response.data?.value) {
                let data = response.data.value;
                const roleData = userInfo.userRoles;
                const idRoleMap = new Map();
                if (roleData !== null && !roleData[0].isAllCompany) {
                    roleData.forEach(x => {
                        x.companyIds.forEach(y => {
                            idRoleMap.set(y.id, x.role);
                        });
                    });
                }
                data = data.map(element => ({
                    ...element,
                    isSelected: idRoleMap.get(element.id) !== undefined,
                    role: idRoleMap.get(element.id) ? idRoleMap.get(element.id) : "cem_admin",
                }));
                dispatch(setCompanyInfo(data));
                dispatch(setCompaniesSelectedCount(data?.filter(x => x?.isSelected)?.length));
            } else {
                dispatch(setCompanyInfo([]));
            }
        })
        .catch();
    dispatch(setIsCompanyLoading(false));
};

export const postUserRoleInfo =
    (subjectId, userId, roleInfo, locationInfo, showToast) => async (dispatch, getState) => {
        const { isPutPostLoading } = getState().roleManagement;
        if (isPutPostLoading) return;
        dispatch(setIsPutPostLoading(true));
        await axios
            .post(
                `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/user-role?subjectId=${subjectId}&userId=${userId}`,
                {
                    userRoles: roleInfo,
                    userCompanyLocations: locationInfo,
                },
                { withCredentials: true }
            )
            .then(response => {
                if (response.status === 200) {
                    if (roleInfo[0].isAllCompany) {
                        showToast(
                            "success",
                            `Applied ${roleKeyValue[roleInfo[0].role]} to all companies`,
                            "create-role-success",
                            false
                        );
                    } else {
                        showToast(
                            "success",
                            `Successfully applied roles`,
                            "create-role-success",
                            false
                        );
                    }
                }
            });
        dispatch(getUserInfo(userId));
        dispatch(setIsPutPostLoading(false));
    };

export const putUserRoleInfo =
    (subjectId, userId, userRoles, userCompanyLocations, showToast) =>
    async (dispatch, getState) => {
        const { isPutPostLoading } = getState().roleManagement;

        if (isPutPostLoading) return;
        dispatch(setIsPutPostLoading(true));
        await axios
            .put(
                `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/user-role?subjectId=${subjectId}&userId=${userId}`,
                {
                    userRoles,
                    userCompanyLocations,
                },
                { withCredentials: true }
            )
            .then(response => {
                if (response.status === 200) {
                    if (userRoles[0].isAllCompany) {
                        showToast(
                            "success",
                            `Applied ${roleKeyValue[userRoles[0].role]} to all companies`,
                            "update-role-success",
                            false
                        );
                    } else {
                        showToast(
                            "success",
                            `Successfully applied roles`,
                            "update-role-success",
                            false
                        );
                    }
                }
            });
        dispatch(getUserInfo(userId));
        dispatch(setIsPutPostLoading(false));
    };

export default roleManagementSlice.reducer;
