import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SRow, SCol, SDialog, SLoader } from "@avalara/skylab-react";
import "./previewDialog.css";
import { Document, Page, pdfjs } from "react-pdf";
import { getFormattedDate } from "../../shared/Utils";
import { selectSession } from "../../app/sessionSlice";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

function PreviewSupplFileDialogue(props) {
    const [pdfFile, setPdfFile] = useState("");
    const [imageSrc, setImageSrc] = useState("");
    const [numberPages, setNumberPages] = useState(null);
    const session = useSelector(selectSession);
    const [loaded, setLoaded] = useState(false);
    const [fileType, setFileType] = useState("");
    useEffect(() => {
        if (props.currFile.mimeType === "application/pdf") {
            setFileType("pdf");
            setPdfFile({
                url: `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/companies/${session.activeCompany.id}/certificates/${props.certificateID}/certificate-comments/${props.currFile.id}/file`,
                withCredentials: true,
            });
        } else if (props.currFile.mimeType) {
            setFileType("img");
            setImageSrc(
                `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/companies/${session.activeCompany.id}/certificates/${props.certificateID}/certificate-comments/${props.currFile.id}/file`
            );
        }
    }, [props.certificateID, session.activeCompany.id, props.currFile]);
    function onDocumentLoadSuccess({ numPages }) {
        setNumberPages(numPages);
    }
    const onDocumentLoadError = () => {
        setImageSrc(
            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/companies/${session.activeCompany.id}/certificates/${props.certificateID}/certificate-comments/${props.currFile.id}/file`
        );
    };

    const onLoad = () => {
        setLoaded(true);
    };
    const onError = () => {
        setLoaded(true);
        setImageSrc(`/assets/document_notfound.jpg`);
    };
    const closeDialog = () => {
        props.setIsPreviewSupplementalDialogOpen(false);
        setNumberPages(null);
        setLoaded(false);
    };
    return props.isPreviewSupplementalDialogOpen ? (
        <SDialog
            id="previewSupply-dialog"
            open={props.isPreviewSupplementalDialogOpen}
            noDismiss
            onS-dismiss={() => {
                props.setIsPreviewSupplementalDialogOpen(false);
            }}
            aria-modal="true">
            <div slot="header" id="previewSupply">
                Attachment
            </div>

            <div slot="body" className="preview-modal-body">
                {fileType === "pdf" ? (
                    <Document
                        file={pdfFile}
                        onLoadSuccess={e => onDocumentLoadSuccess(e)}
                        onLoadError={onDocumentLoadError}>
                        {Array.from(new Array(numberPages), (el, index) => (
                            <Page
                                className="pdf-border"
                                key={`page_${index + 1}`}
                                scale={1.4}
                                pageNumber={index + 1}
                            />
                        ))}
                    </Document>
                ) : null}
                {fileType === "img" ? (
                    <SRow>
                        {!loaded && (
                            <SCol className="flex dl-flex-fill-height dl-flex-center">
                                <h3>Loading ...</h3>
                                <SLoader
                                    id="page-loader"
                                    className="medium"
                                    aria-live="polite"
                                    loading
                                />
                            </SCol>
                        )}

                        <SCol span="auto">
                            <div>
                                <img
                                    alt="supplemental-file-preview"
                                    src={imageSrc}
                                    className="image-border"
                                    onLoad={() => onLoad()}
                                    onError={e => {
                                        onError(e);
                                    }}
                                    hidden={!loaded}
                                    width="100%"
                                />
                            </div>
                        </SCol>
                    </SRow>
                ) : null}
            </div>
            <div slot="footer" className="block">
                <SRow>
                    <SCol span="9" className="align-start">
                        <span className="pad-right-xs"> {props.currFile.entry}</span>
                        <span className="auto">
                            {getFormattedDate(props.currFile.created)} by{" "}
                            {props.currFile.accountName}
                        </span>
                    </SCol>
                    <SCol span="3" className="flex justify-content-end">
                        <button className="primary inline" onClick={() => closeDialog()}>
                            Cancel
                        </button>
                    </SCol>
                </SRow>
            </div>
        </SDialog>
    ) : null;
}

export default PreviewSupplFileDialogue;
