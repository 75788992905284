import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../axios";

const initialState = {
    openCustomerAddOrEdit: false,
    openCustomerDetails: false,
    isLoading: false,
    page: {
        data: [],
        paginate: {
            pageNumber: 0,
            startIndex: -1,
            endIndex: 0,
            rowsPerPage: 20,
            totalRecords: 0,
        },
    },
    customerFiltersState: {
        nameOrCode: "",
        phoneNumber: "",
        emailAddress: "",
        address: "",
        city: "",
        zip: "",
        certificateIds: null,
        customerCodes: null,
        exposureZones: null,
        customerRegions: null,
        isRetailSearch: true,
    },
    sortColumn: ["id", true],
    noDataType: "",
    isCustomerCountAPICompleted: 0,
    isLoadingCustomerCount: false,
    selectedLocation: null,
    isLoadingCompanyLocations: false,
    companyLocations: null,
    colSpan: "md-10 lg-7 xl-7 12",
};

export const retailSlice = createSlice({
    name: "retail",
    initialState,
    reducers: {
        setOpenCustomerAddOrEdit: (state, action) => {
            state.openCustomerAddOrEdit = action.payload;
        },
        setOpenCustomerDetails: (state, action) => {
            state.openCustomerDetails = action.payload;
        },
        setCustomerFiltersState: (state, action) => {
            state.customerFiltersState = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: (state, action) => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: action?.payload || initialState.page.rowsPerPage,
                    totalRecords: 0,
                },
            };
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setNoDataType: (state, action) => {
            state.noDataType = action.payload;
        },
        incrementCustomerCountAPICompleted: state => {
            state.isCustomerCountAPICompleted += 1;
        },
        decrementCustomerCountAPICompleted: state => {
            state.isCustomerCountAPICompleted -= 1;
        },
        setIsLoadingCustomerCount: (state, action) => {
            state.isLoadingCustomerCount = action.payload;
        },
        setSelectedLocation: (state, action) => {
            state.selectedLocation = action.payload;
        },
        resetCustomerFiltersState: state => {
            state.customerFiltersState = initialState.customerFiltersState;
        },
        setCompanyLocations: (state, action) => {
            state.companyLocations = action.payload;
        },
        setIsLoadingCompanyLocations: (state, action) => {
            state.isLoadingCompanyLocations = action.payload;
        },
        setColSpan: (state, action) => {
            state.colSpan = action.payload;
        },
    },
});

export const {
    setOpenCustomerAddOrEdit,
    setOpenCustomerDetails,
    setPageToNull,
    setSortColumn,
    setPage,
    setIsLoading,
    setCustomerFiltersState,
    setNoDataType,
    incrementCustomerCountAPICompleted,
    decrementCustomerCountAPICompleted,
    setIsLoadingCustomerCount,
    setSelectedLocation,
    resetCustomerFiltersState,
    setCompanyLocations,
    setIsLoadingCompanyLocations,
    setColSpan,
} = retailSlice.actions;

export const selectIsLoading = state => {
    return state.retail.isLoading;
};
export const selectOpenCustomerAddOrEdit = state => {
    return state.retail.openCustomerAddOrEdit;
};
export const selectOpenCustomerDetails = state => {
    return state.retail.openCustomerDetails;
};
export const selectPage = state => {
    return state.retail.page;
};

export const selectNoData = state => {
    return state.retail.noDataType;
};

export const selectSortColumn = state => {
    return state.retail.sortColumn;
};

export const selectSelectedLocation = state => {
    return state.retail.selectedLocation;
};

export const selectIsLoadingCompanyLocation = state => {
    return state.retail.isLoadingCompanyLocations;
};

export const selectCompanyLocation = state => {
    return state.retail.companyLocations;
};

export const selectColSpan = state => {
    return state.retail.colSpan;
};

export const fetchRetailCustomers =
    (paginateDetails, onLanding = false, pageSize = 20, pageNumber = 0) =>
    async (dispatch, getState) => {
        const { isLoading, sortColumn, customerFiltersState } = getState().retail;
        if (isLoading) {
            return;
        }
        dispatch(setIsLoading(true));

        const request = {
            ...customerFiltersState,
            // locationId: parseInt(selectedLocation?.value, 10) ?? 0,
            pageSize,
            pageNumber,
            orderBy: sortColumn[0],
            orderByDirection: sortColumn[1] ? "DESC" : "ASC",
        };
        axiosInstance
            .post(
                `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customers/search`,
                request,
                { withCredentials: true }
            )
            .then(async response => {
                if (!response.data) {
                    onLanding
                        ? dispatch(setNoDataType("onLanding"))
                        : dispatch(setNoDataType("onSearch"));
                    dispatch(setPageToNull());
                    dispatch(setIsLoading(false));
                } else {
                    dispatch(setNoDataType(""));
                    const { page, isCustomerCountAPICompleted } = getState().retail;
                    const pageData = { ...page.paginate };
                    const paginateData = paginateDetails
                        ? {
                              pageNumber,
                              startIndex: paginateDetails.startIndex,
                              endIndex: paginateDetails.endIndex,
                              rowsPerPage: paginateDetails.rowsPerPage,
                              totalRecords: pageData.totalRecords,
                              indeterminate: false,
                          }
                        : {
                              pageNumber,
                              startIndex: 0,
                              endIndex: 19,
                              rowsPerPage: pageSize,
                              totalRecords:
                                  isCustomerCountAPICompleted === 0 ? pageData.totalRecords : 20,
                              indeterminate: !(isCustomerCountAPICompleted === 0),
                          };
                    dispatch(
                        setPage({
                            data: response.data.value,
                            paginate: paginateData,
                        })
                    );
                    dispatch(setIsLoading(false));
                }
            });

        if (!paginateDetails) {
            dispatch(setIsLoadingCustomerCount(true));
            dispatch(incrementCustomerCountAPICompleted());
            axiosInstance
                .post(
                    `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customers/search-count`,
                    request,
                    { withCredentials: true }
                )
                .then(res => {
                    dispatch(decrementCustomerCountAPICompleted());
                    const { page } = getState().retail;
                    const pageData = { ...page.paginate };
                    pageData.totalRecords = res?.data;
                    pageData.indeterminate = false;
                    dispatch(
                        setPage({
                            data: page.data,
                            paginate: pageData,
                        })
                    );
                    dispatch(setIsLoadingCustomerCount(false));
                });
        }
    };

export const fetchCompanyLocations = () => async (dispatch, getState) => {
    const { isLoadingCompanyLocations } = getState().retail;

    if (isLoadingCompanyLocations) return;
    dispatch(setIsLoadingCompanyLocations(true));
    const response = await axiosInstance.get(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/retail-locations`,
        {
            withCredentials: true,
        }
    );
    if (response?.data?.value) {
        dispatch(setCompanyLocations(response.data.value));
    }
    dispatch(setIsLoadingCompanyLocations(false));
};

export default retailSlice.reducer;
