/* eslint-disable */
function zoominWidget(session) {
    let widgetId = 16;
    if (session.subscriptions.includes("ECMPro")) {
        widgetId = 17;
    }
    if (session.subscriptions.includes("ECMPremium")) {
        widgetId = 18;
    }
    
    (function (w, d, s, o, f, js, fjs) {
        w['InProductHelp'] = o; w[o] = w[o] ||
            function () { (w[o].q = w[o].q || []).push(arguments) }; js = d.createElement(s),
            fjs = d.getElementsByTagName(s)[0]; js.id = o; js.src = f; js.async = 1;
        fjs.parentNode.insertBefore(js, fjs);
        }(window, document, 'script', 'iph',
        'https://iph.zoominsoftware.io/widget.js')); iph('init',
            {
            "id": widgetId, 
            "host": "knowledge-be.avalara.com", 
            "widgetSettings": {
                "container": "zoom-in-widget-container", 
                "mode": "fixed", 
                "primaryColor": "#0c7bab", 
                "showTopicActions": true,
                "showInfoButton": false,
                "footerLinks": [
                {
                    "url": "https://knowledge.avalara.com",
                    "label": "Knowledge Center"
                },
                {
                    "url":"https://training.avalara.com",
                    "label":"Avalara University"
                }
                ]
            }
        });
};

export default zoominWidget;