import { SBox, SCol, SDatepicker, SIcon, SLoader, SRow, SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";
import classnames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SyncErrorDialog from "../dialogs/SyncErrorDialog";
import {
    fetchExemptionMatrixReasonValidationApi,
    fetchRegionIdsByDataSetIdAPI,
    fetchValidationCertificateByIdAPI,
    getTaxCodeValidation,
    selectAutoValidationData,
    selectCertificateStatuses,
    selectCustomerCert,
    selectExemptionMatrixValidation,
    selectExposureZones,
    selectIsCertPresent,
    selectLoading,
    selectRedirectURL,
    selectRegionIds,
    selectValidationData,
    setCertificate,
    setRegionIds,
    selectIsRegionValid,
    setIsRegionValid,
    setIsTaxcodeMap,
    selectRegionMessage,
    selectTaxcodeMap,
    selectIsAutoValidatingFile,
    getAutoValidationResults,
    selectIsScanningFVS,
    setIsScanningFVS,
    selectPassedFVS,
    setIsCertificateUpdated,
    clearAutoValidation,
    selectHasCertificateFile,
    setIsInvalidReasonDialogOpen,
    selectEscalationLog,
    setEscalationLog,
    selectFvsValidationData,
    setComments,
    setSupplementalFiles,
    selectComments,
    selectSupplementalFiles,
    getCustomFieldFromSlice,
    selectIsLoadingTaxContentForms,
    fetchTaxContentFormsAsync,
    selectTaxContentForm,
    setIsContentFormDialogOpen,
    selectIsContentFormDialogOpen,
    setTaxContentForm,
    setIsTaxTypeDialogOpen,
    selectIsTaxTypeDialogOpen,
    selectTaxTemplateTag,
    setTaxTemplateTag,
    setCertificateCommunicationTaxTypes,
    selectCertificateCommunicationTaxTypes,
    selectValidationStateCount,
    setValidationStateCount,
} from "../../../app/certificateSlice";
import {
    fetchCertificateRequestsAPI,
    getCertificateRequest,
    selectSetting,
} from "../../../app/settingsSlice";

import { getProduct } from "../../../app/sessionSlice";
import { getPageTitle, isEligibleUser } from "../../../shared/sessionUtility";
import axios from "../../../axios";
import toast from "../../../hooks/toast";
import {
    getFormattedDate,
    matchSubscriptions,
    updateSelectedAttribute,
    urlToPageMap,
} from "../../../shared/Utils";
import BusinessTypeOptions from "../../certificates/exports/BusinessTypeOptions";
import Coverage from "../../sharedComponents/Coverage";
import CustomerTypeAhead from "../../sharedComponents/CustomerTypeAhead";
import SelectExemptionReasonBasedOnRegion from "../../sharedComponents/SelectExemptionReasonBasedOnRegion";
import SelectExposureZone from "../../sharedComponents/SelectExposureZone";
import DeleteCertificateDialog from "../../sharedDialogs/DeleteCertificateDialog";
import CertificateComments from "../CertificateComments";
import CertificateCustomField from "../../sharedComponents/CertificateCustomField";
import Multi from "../Multi";
import "../validation.css";
import ValidationFileColumn from "../ValidationFileColumn";
import FeatureToggler from "../../../featureToggler/FeatureToggler";
import featureFlag from "../../../featureToggler/featureFlag";
import InvalidReasonDialog from "../InvalidReasonDialog";
import CertificateLabel from "../../sharedComponents/CertificateLabel";
import ContentFormDialog from "../ContentFormDialog";
import TaxTypeDialog from "../TaxTypeDialog";
import RemoveUnRelatedRegionsDialog from "../RemoveUnRelatedRegionsDialog";
import { invalidReasonDialogID, PendingStatuses } from "../../../shared/constants";
import { getStoreValues } from "../../../app/commonSlice";
import CertificateProductCategories from "../../sharedComponents/CertificateProductCategories";

const [showToast] = toast();

const ValidationDetails = React.memo(() => {
    const dispatch = useDispatch();
    const certificateCustomFieldsRef = useRef();
    const coverageRef = useRef();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const isLoading = useSelector(selectLoading);
    const certificate = useSelector(selectCustomerCert, shallowEqual);
    const isCertPresent = useSelector(selectIsCertPresent);
    const validationData = useSelector(selectValidationData, shallowEqual);
    const fvsValidationData = useSelector(selectFvsValidationData, shallowEqual);
    const getProductInfo = useSelector(getProduct);
    const autoValidationData = useSelector(selectAutoValidationData, shallowEqual);
    const setting = useSelector(selectSetting, shallowEqual);
    const hasCertificateFile = useSelector(selectHasCertificateFile);
    const [exemptReason, setExemptReason] = useState("");
    const [exposureZone, setExposureZone] = useState([]);
    const [customers, setCustomers] = useState([]);
    const escalationLog = useSelector(selectEscalationLog, shallowEqual);
    const [PoNumber, setPoNumber] = useState("");
    const [single, setIsSingle] = useState(false);
    const [postData, setPostData] = useState({});
    const [effectiveDate, setEffectiveDate] = useState(new Date().toISOString().slice(0, 10));
    const [effectiveDateError, setEffectiveDateError] = useState("");
    const [exemptReasonError, setExemptReasonError] = useState("");
    const [customerError, setCustomerError] = useState("");
    const [regionError, setRegionError] = useState("");
    const certificateStatuses = useSelector(selectCertificateStatuses, shallowEqual);
    const exposureZones = useSelector(selectExposureZones, shallowEqual);
    const exemptionMatrixList = useSelector(selectExemptionMatrixValidation, shallowEqual);
    const [logEntry, setLogEntry] = useState("");
    const [isSyncErrorDialogOpen, setIsSyncErrorDialogOpen] = useState(false);
    const clearRef = useRef(null);
    const [certificateID, setCertificateID] = useState(Number.parseInt(params?.certificateID, 10));
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [invalidReasons, setInvalidReasons] = useState([]);
    const dateErrorMsg = "Enter an effective date";
    const invalidDateErrorMsg = "Enter the date in MM/DD/YYYY format";
    const currentDate = new Date();
    const [invalidEffectiveDate, setInvalidEffectiveDate] = useState(false);
    const [futureEffectiveDate, setFutureEffectiveDate] = useState(false);
    const saveCertificateButton = classnames({
        primary: true,
        large: true,
        loading: submitting,
    });
    const secondaryButton = classnames({ secondary: true, large: true });
    const cancelButton = classnames({ tertiary: true, large: true });
    const [certificateStatus, setCertificateStatus] = useState({
        id: certificateStatuses.filter(x => x.name === "PENDING")[0]?.id || 1,
        status: "Pending",
    });
    const [businessNumber, setBusinessNumber] = useState(null);
    const [businessNumberType, setBusinessNumberType] = useState("");
    const [businessTypeError, setBusinessTypeError] = useState("");
    const [businessTypeDescription, setBusinessTypeDescription] = useState("");
    const [showBusinessTypeDescription, setShowBusinessTypeDescription] = useState(false);
    const [businessTypeDescriptionError, setBusinessTypeDescriptionError] = useState("");
    const [getCurrentId, getNextId] = useId();
    const [showExemptReasonDescription, setShowExemptReasonDescription] = useState(false);
    const [exemptReasonDescription, setExemptReasonDescription] = useState("");
    const [exemptReasonDescriptionError, setExemptReasonDescriptionError] = useState("");
    const redirectURL = useSelector(selectRedirectURL);
    const isRegionValid = useSelector(selectIsRegionValid, shallowEqual);
    const regionMessage = useSelector(selectRegionMessage, shallowEqual);
    const isTaxcodeMap = useSelector(selectTaxcodeMap, shallowEqual);
    const isAutoValidatingFile = useSelector(selectIsAutoValidatingFile);
    const isScanningFVS = useSelector(selectIsScanningFVS);
    const passedFVS = useSelector(selectPassedFVS);
    const [exemptReasonErrorMessage, setexemptReasonErrorMessage] = useState("");
    const regionIds = useSelector(selectRegionIds, shallowEqual);
    const [isDeleteCertificateDialogOpen, setIsDeleteCertificateDialogOpen] = useState(false);
    const [isRemoveRegionDialogOpen, setIsRemoveRegionDialogOpen] = useState(false);
    const [customerDataValidation, setCustomerDataValidation] = useState(false);
    const [invalidExpirationDate, setInvalidExpirationDate] = useState(false);
    const [isSSTregion, setIsSSTregion] = useState(false);
    const comments = useSelector(selectComments, shallowEqual);
    const fileAttachments = useSelector(selectSupplementalFiles, shallowEqual);
    const [labelPostData, setLabelPostData] = useState();
    const [areZonesPredicted, setAreZonesPredicted] = useState(false);
    const errorTag = "error";
    const noErrorTag = "no-error";
    const isLoadingTaxContentForms = useSelector(selectIsLoadingTaxContentForms);
    const taxContentForm = useSelector(selectTaxContentForm);
    const taxTemplateTag = useSelector(selectTaxTemplateTag);
    const isContentFormDialogOpen = useSelector(selectIsContentFormDialogOpen);
    const isTaxTypeDialogOpen = useSelector(selectIsTaxTypeDialogOpen);
    const [validateAllIds, setValidateAllIds] = useState(false);
    const validationStateCount = useSelector(selectValidationStateCount);
    const validatingAllIds = validateAllIds && validationStateCount < exposureZone.length;
    const [removedCategories, setRemovedCategories] = useState([]);
    const [addedCategories, setAddedCategories] = useState([]);
    const certOnly = matchSubscriptions(
        featureFlag.afcContent.associateTaxTypeData.subscription,
        getProductInfo
    );

    const certificateCommunicationTaxTypes = useSelector(
        selectCertificateCommunicationTaxTypes,
        shallowEqual
    );

    const showRegionRemoveButton = setting.applyCustomerExposure || setting.removeNonNexusExposure;

    const isEligibleUserValidatedCertificateSaveAsValid = dispatch(
        isEligibleUser(featureFlag.validatedCertificate.saveAsValid)
    );
    const isEligibleUserReviewCertificateValidate = dispatch(
        isEligibleUser(featureFlag.reviewCertificate.validate)
    );
    const isEligibleUserReviewCertificateDelete = dispatch(
        isEligibleUser(featureFlag.reviewCertificate.delete)
    );
    const isEligibleUserReviewCertificateAdd = dispatch(
        isEligibleUser(featureFlag.reviewCertificate.add)
    );

    const categoriesHandler = category => {
        setAddedCategories([...addedCategories, category]);
    };
    const removeCategoryHandler = category => {
        setRemovedCategories([...removedCategories, category]);
    };

    const clearExemptReasonDescription = () => {
        setExemptReasonDescription("");
        setShowExemptReasonDescription(false);
        setExemptReasonDescriptionError("");
    };
    const setCustomerDataValidationValue = useCallback(value => {
        setCustomerDataValidation(value);
    }, []);

    const validationFileColumnComponent = useMemo(
        () => <ValidationFileColumn certificateID={certificateID} onReview customers={customers} />,
        [certificateID, JSON.stringify(customers)] // eslint-disable-line
    );

    useEffect(() => {
        if (params?.certificateID)
            setCertificateID(prevCertificateId => {
                if (!prevCertificateId) return Number.parseInt(params?.certificateID, 10);
                return prevCertificateId;
            });
    }, [params?.certificateID]);

    useEffect(() => {
        if (!validatingAllIds) {
            setValidateAllIds(false);
            dispatch(setValidationStateCount(0));
        }
    }, [validatingAllIds]);

    const handleReasonChange = useCallback((el, calledOnRender) => {
        setExemptReason(prevExemptReason => {
            const reason = typeof el === "object" ? el.detail.item.value : el;
            // do not reset if the exempt reason was not changed
            if (reason === prevExemptReason && !calledOnRender) return prevExemptReason;
            setExemptReasonError("");
            if (reason === "OTHER/CUSTOM") {
                setShowExemptReasonDescription(true);
            } else if (!calledOnRender) {
                clearExemptReasonDescription();
            }
            return reason;
        });
    }, []);

    const updatePostData = useCallback(
        (zone, key, value) => {
            setPostData(prevState => ({
                ...prevState,
                [zone]: { ...prevState[zone], [key]: value },
            }));
            dispatch(setIsCertificateUpdated(true));
        },
        [dispatch]
    );

    const updateCertificateLabelPostData = useCallback(async (e, add, selectRef) => {
        let value = null;
        const allSelectedValues = await selectRef.current.getValue().then(x => {
            return x;
        });
        if (e.detail.isDeselectAll) {
            setLabelPostData([]);
        } else if (add) {
            value = allSelectedValues;
        } else {
            value = allSelectedValues?.filter(item => {
                return item !== e.detail.item.value;
            });
        }
        setLabelPostData(value);
    }, []);

    const clearPostData = useCallback(() => {
        setPostData({});
        dispatch(setIsCertificateUpdated(false));
    }, [dispatch]);

    const uniqueCustomer = (prevCustomer, newCustomer) => {
        const prevCustomerIds = prevCustomer.map(ele => ele.id);
        return [
            ...prevCustomer,
            ...newCustomer.filter(customer => prevCustomerIds.indexOf(customer.id) === -1),
        ];
    };

    const setBusinessTypeData = (businessType, avsOtherBusinessTypeValue) => {
        const businessTypeOption = BusinessTypeOptions.find(
            x => x.value.toUpperCase() === businessType.toUpperCase()
        );
        let businessNum;
        if (businessTypeOption) businessNum = businessTypeOption?.number;
        if (businessTypeOption && businessType !== "Other") {
            updateSelectedAttribute(BusinessTypeOptions, businessTypeOption.value);
            setBusinessNumber(businessNum);
            setBusinessNumberType(businessTypeOption.value);
            setShowBusinessTypeDescription(false);
        } else {
            setShowBusinessTypeDescription(true);
            if (avsOtherBusinessTypeValue) setBusinessTypeDescription(avsOtherBusinessTypeValue);
            else setBusinessTypeDescription(businessType);
            setBusinessNumber(businessNum);
            setBusinessNumberType("Other");
            updateSelectedAttribute(BusinessTypeOptions, "Other");
        }
    };

    const exemptionMatrixAPI = useCallback(
        ids => {
            dispatch(
                fetchExemptionMatrixReasonValidationApi(
                    ids?.length ? ids : [],
                    ids?.length ? showToast : false
                )
            );
        },
        [dispatch]
    );

    useEffect(() => {
        if (!certificateID) {
            exemptionMatrixAPI([]);
        }
    }, [certificateID, exemptionMatrixAPI]);

    useEffect(() => {
        if (!validationData.data && !certificate?.certificateId && certificateID) {
            dispatch(fetchValidationCertificateByIdAPI(certificateID));
        }
    }, [validationData.data, certificateID, dispatch, certificate.certificateId]);

    useEffect(() => {
        if (location?.state?.customer) setCustomers([location.state.customer]);
    }, [location?.state?.customer?.id]); // eslint-disable-line

    useEffect(() => {
        if ("certificateId" in certificate) {
            if (
                certificate?.certificateStatus &&
                !PendingStatuses.includes(certificate?.certificateStatus)
            ) {
                navigate(`/certificate/${certificateID}`);
            }

            // Set the data on the page if we retrieved certificate data from Redux

            // Retrive exposure zones from matrix
            if (certificate.exposureZoneId && certificate.exposureZoneName) {
                setExposureZone([
                    {
                        id: certificate.exposureZoneId,
                        name: certificate.exposureZoneName,
                        certificateId: certificate.certificateId,
                        isSST: certificate.isSst,
                    },
                ]);
                exemptionMatrixAPI([certificate.exposureZoneId]);
            }

            if (certificate.expectedTaxCodeId && certificate.expectedTaxCodeName) {
                setExemptReason(certificate?.expectedTaxCodeName);
                if (certificate?.expectedTaxCodeName === "OTHER/CUSTOM") {
                    setShowExemptReasonDescription(true);
                }
            }
            if (certificate.exemptReasonDescription) {
                setExemptReasonDescription(certificate.exemptReasonDescription);
            }
            if (certificate.isSingle) {
                setIsSingle(true);
            }
            if (certificate.signedDate) {
                setEffectiveDate(certificate.signedDate.slice(0, 10));
                const givenDate = new Date(certificate.signedDate);
                if (givenDate > currentDate) setFutureEffectiveDate(true);
            }
            if (certificate.purchaseOrderNumber) {
                setPoNumber(certificate.purchaseOrderNumber);
            }

            if (certificate.customers && certificate.customers.length) {
                setCustomers(certificate.customers);
            }
            if (certificate.invalidReasons && certificate.invalidReasons.length) {
                setCertificateStatus(prevCertificateStatus => {
                    const cloneStatus = prevCertificateStatus;
                    cloneStatus.status = "Invalid";
                    return cloneStatus;
                });
                setInvalidReasons(
                    certificate.invalidReasons.map(invalidReason => invalidReason.id)
                );
            }

            if (certificate.businessNumberType && certificate.businessNumberType.length > 0) {
                setBusinessTypeData(certificate.businessNumberType);
            }

            if (certificate.expirationDate && certificate.exposureZoneName) {
                updatePostData(
                    certificate.exposureZoneId,
                    "expirationDate",
                    certificate.expirationDate
                );
            }
            if (certificate?.taxNumber && certificate.exposureZoneId) {
                updatePostData(certificate.exposureZoneId, "taxNumber", certificate.taxNumber);
            }

            if (certificate?.taxNumberType && certificate.exposureZoneId) {
                updatePostData(
                    certificate.exposureZoneId,
                    "taxNumberType",
                    certificate.taxNumberType
                );
            }

            if (certificate.certificateTaxIdValidation) {
                updatePostData(certificate.exposureZoneId, "taxValidation", {
                    code: certificate.certificateTaxIdValidation?.taxIdValidationCode,
                    message: certificate.certificateTaxIdValidation?.taxIdValidationMessage,
                });
            }

            if (certificate.certificateStatus === "PENDING" && certificate.avsId) {
                dispatch(getAutoValidationResults(certificate.avsId));
            }
            dispatch(setEscalationLog(certificate.certificateEscalation));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        certificate.avsId,
        certificate.certificateStatus,
        certificate.taxNumber,
        certificate.taxNumberType,
        certificate.exposureZoneId,
        certificate.expirationDate,
        certificate.businessNumberType,
        certificate.invalidReasons,
        certificate.customers,
        certificate.exemptReasonDescription,
        certificate.expectedTaxCodeId,
        certificate.expectedTaxCodeName,
        certificate.exposureZoneName,
        certificate.isSingle,
        certificate.purchaseOrderNumber,
        certificate.signedDate,
        certificate.certificateEscalation,
        certificateID,
        dispatch,
        exemptionMatrixAPI,
        navigate,
        updatePostData,
    ]);

    useEffect(() => {
        dispatch(fetchCertificateRequestsAPI());
    }, []);

    useEffect(() => {
        return () => {
            updateSelectedAttribute(BusinessTypeOptions, "");
            dispatch(clearAutoValidation());
            dispatch(setRegionIds([]));
            dispatch(setIsScanningFVS(false));
            dispatch(setIsInvalidReasonDialogOpen(false));
            dispatch(setSupplementalFiles([]));
            dispatch(setComments([]));
            dispatch(setTaxTemplateTag(""));
            dispatch(setTaxContentForm(""));
            dispatch(setCertificateCommunicationTaxTypes([]));
        };
    }, []); // eslint-disable-line

    useEffect(() => {
        if (!isCertPresent) {
            showToast("error", "Invalid ID. Unable to determine certificate you wish to view.");
            navigate(urlToPageMap["search-certificate"]);
        }
    }, [isCertPresent, navigate]);

    useEffect(() => {
        const sstRegions = exposureZone.filter(x => x?.isSST === true);
        setIsSSTregion(sstRegions?.length > 0);
        return () => {
            setIsSSTregion(false);
        };
    }, [JSON.stringify(exposureZone)]);

    useEffect(() => {
        if (!isRegionValid) {
            setExemptReasonError("error");
            setexemptReasonErrorMessage(regionMessage);
            setSubmitting(false);
        }
    }, [isRegionValid, dispatch, regionMessage]);

    // Set exposure zone and exempt reason from auto validation
    const predictionsHasKeys = Object.keys(autoValidationData.predictions).length;
    const zonePredicted = exposureZones.find(
        x =>
            x?.name?.toUpperCase() === autoValidationData?.predictions?.state?.toUpperCase() ||
            x?.state?.initials.toUpperCase() ===
                autoValidationData?.predictions?.state?.toUpperCase()
    );
    useEffect(() => {
        const regionsPredicted = [];
        if (areZonesPredicted) return;
        if (autoValidationData.isMulti) {
            autoValidationData.predictions?.taxIds?.forEach(taxId => {
                const region = exposureZones.find(
                    x =>
                        x?.state?.initials.toUpperCase() === taxId?.state.toUpperCase() &&
                        !x?.isCustom
                );
                if (region) regionsPredicted.push(region);
            });
        }
        if (autoValidationData.isSinglePurchase) {
            setIsSingle(true);
            if (autoValidationData.predictions?.purchaseOrder)
                setPoNumber(autoValidationData.predictions.purchaseOrder);
        }
        if (predictionsHasKeys && exposureZones.length) {
            if (autoValidationData.isMulti) {
                const regionList = [];
                regionsPredicted?.forEach(region => {
                    regionList.push({
                        id: region.id,
                        name: region.name,
                        initials: region.state.initials,
                        isSST: region.isSst,
                    });
                });
                setExposureZone(regionList);
                setAreZonesPredicted(true);
                exemptionMatrixAPI(regionList.map(x => x.id));
            } else if (autoValidationData.predictions.state) {
                if (zonePredicted) {
                    setExposureZone([
                        {
                            id: zonePredicted.id,
                            name: zonePredicted.name,
                            isSST: zonePredicted.isSst,
                        },
                    ]);
                    setAreZonesPredicted(true);
                    exemptionMatrixAPI([zonePredicted.id]);
                }
            }
            const predictedBusinessType = autoValidationData.predictions?.businessType;
            if (predictedBusinessType && isSSTregion) {
                if (predictedBusinessType.toLowerCase().includes("other")) {
                    setBusinessTypeData("Other", predictedBusinessType.replace(/other /i, ""));
                } else {
                    setBusinessTypeData(predictedBusinessType);
                }
            }
            const predictedReason = autoValidationData.predictions.reason;
            if (predictedReason) {
                if (predictedReason.toLowerCase().includes("other")) {
                    handleReasonChange("OTHER/CUSTOM");
                    setShowExemptReasonDescription(true);
                    setExemptReasonDescription(predictedReason.replace(/other /i, ""));
                } else handleReasonChange(predictedReason.toUpperCase());
            }

            if (autoValidationData.predictions.effectiveDate) {
                setEffectiveDate(autoValidationData.predictions.effectiveDate);
            } else {
                setEffectiveDate("");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        autoValidationData.predictions.reason,
        autoValidationData.predictions.state,
        autoValidationData.predictions.effectiveDate,
        autoValidationData.predictions.businessType,
        exemptionMatrixAPI,
        exposureZones.length,
        handleReasonChange,
        isSSTregion,
        areZonesPredicted,
    ]);

    useEffect(() => {
        if (certificateStatus.status === "Invalid" || certificateStatus.status === "Pending") {
            if (!exposureZone.length) {
                setRegionError("");
            }

            if (!exemptReason) {
                setExemptReasonError("");
            }

            if (!effectiveDate) {
                setEffectiveDateError("");
            }

            if (!exemptReasonDescription) {
                setExemptReasonDescriptionError("");
            }
            if (!businessNumberType) {
                setBusinessTypeError("");
            }
            if (!businessTypeDescription) {
                setBusinessTypeDescriptionError("");
            }
            if (customers.length === 0) {
                setCustomerError("");
            }
        }
    }, [
        certificateStatus.status,
        effectiveDate,
        exposureZone.length,
        exemptReason,
        exemptReasonDescription,
        businessNumberType,
        businessTypeDescription,
        customers.length,
    ]);
    const handleBusinessTypeChange = el => {
        // do not reset or change state if the type was not changed
        if (businessNumberType === el.detail.item.value) {
            return;
        }
        setBusinessTypeError("");
        setBusinessNumber(parseInt(el.detail.item.number, 10));
        setBusinessNumberType(el.detail.item.value);
        setBusinessTypeDescription("");

        if (el.detail.item.value === "Other") {
            setShowBusinessTypeDescription(true);
        } else {
            setShowBusinessTypeDescription(false);
            setBusinessTypeDescriptionError("");
        }
    };

    const handleBusinessTypeRemove = () => {
        setBusinessNumber(null);
        setBusinessNumberType("");
        setBusinessTypeDescription("");
        setShowBusinessTypeDescription(false);
        setBusinessTypeError("");
        setBusinessTypeDescriptionError("");
    };

    const handleChangeInput = setter => {
        return event => {
            if (event.target.value && event.target.className === "error") {
                if (event.target.name === "businessTypeDescription") {
                    setBusinessTypeDescriptionError("");
                }
                if (event.target.name === "exemptReasonDescription") {
                    setExemptReasonDescriptionError("");
                }
            }
            setter(event.target.value);
        };
    };

    const addCustomer = useCallback(value => {
        setCustomerError("");
        setCustomers(prevCustomers => uniqueCustomer(prevCustomers, value));
    }, []);

    const removeCustomer = useCallback(e => {
        setCustomers(prevCustomer => {
            const customersClone = prevCustomer.slice();
            customersClone.splice(
                customersClone.findIndex(ele => ele.id === parseInt(e.value, 10)),
                1
            );
            return customersClone;
        });
    }, []);

    const canCreateCertificates = useCallback(() => {
        if (!certificateID) {
            return true;
        }
        return false;
    }, [certificateID]);

    const handleRegionRemove = useCallback(
        (eventDetail, isDeselectAll) => {
            const { value } = eventDetail;
            if (isDeselectAll) {
                exemptionMatrixAPI([]);
                setExposureZone([]);
                setPostData({});
                dispatch(setTaxContentForm(""));
                if (areZonesPredicted) {
                    setAreZonesPredicted(false);
                }
            } else {
                setExposureZone(prevExposureZone => {
                    const zones = prevExposureZone.filter(zone => zone.id !== value);
                    const ids = zones.map(ele => ele.id);
                    if (!ids.length) {
                        dispatch(setTaxContentForm(""));
                    }
                    exemptionMatrixAPI(ids);
                    return zones;
                });
                setPostData(prevPostData => {
                    const { [value]: tmp, ...rest } = prevPostData;
                    return rest;
                });
            }
            clearExemptReasonDescription();
            dispatch(setIsCertificateUpdated(false));
        },
        [exemptionMatrixAPI, dispatch]
    );

    const clearRegionData = useCallback(() => {
        setRegionError("");
        setExemptReason("");
        setExemptReasonError("");
        clearExemptReasonDescription();
        dispatch(setTaxContentForm(""));
    }, []);

    let regions = [];
    if (regionIds?.length > 0) {
        regions = exposureZones.filter(item => regionIds.some(d => d.regionId === item.id));
    }
    useEffect(() => {
        if (regionIds?.length > 0) {
            clearRegionData();
            const ids = regions?.map(ele => ele.id);
            exemptionMatrixAPI(ids?.length ? ids : []);
            regions.forEach(element => {
                updatePostData(element.id, "taxNumber", "");
            });
            setExposureZone(regions);
            dispatch(setRegionIds([]));
            if (!canCreateCertificates() && exposureZone.length && exposureZone[0]?.id)
                handleRegionRemove(exposureZone[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        regionIds?.length,
        JSON.stringify(regions), // eslint-disable-line
        regions.length,
        canCreateCertificates,
        exemptionMatrixAPI,
        clearRegionData,
        updatePostData,
        handleRegionRemove,
        exposureZone.length,
        exposureZone[0]?.id, // eslint-disable-line
    ]);

    const handleRegionAdd = useCallback(
        event => {
            if (!event.isDataEntrySet) {
                setExposureZone(prevExposureZone => {
                    const exposureZoneId = event.value;
                    const exposureZoneObj = {
                        id: event.value,
                        name: event.label,
                        isSST: event.isSST,
                    };

                    clearRegionData();

                    const ids = [...prevExposureZone.map(ele => ele.id), exposureZoneId];
                    exemptionMatrixAPI(ids);
                    return [
                        ...new Set([
                            ...prevExposureZone,
                            {
                                id: exposureZoneObj.id,
                                name: exposureZoneObj.name,
                                isSST: exposureZoneObj.isSST,
                            },
                        ]),
                    ];
                });
            } else {
                setExposureZone([]);
                dispatch(fetchRegionIdsByDataSetIdAPI(event.value.split("-")[0]));
                dispatch(setTaxTemplateTag(""));
                dispatch(setTaxContentForm(""));
            }
        },
        [exemptionMatrixAPI, clearRegionData, canCreateCertificates, dispatch]
    );

    const getExemptReason = value => {
        const result = exemptionMatrixList.find(
            x => x.exemptReason.name.toUpperCase() === value.toUpperCase()
        );
        return result?.exemptReason;
    };

    const removeExposureZoneById = regionList => {
        setExposureZone(exposureZone.filter(x => !regionList.includes(x.id)));
        exemptionMatrixAPI(exposureZone.filter(x => !regionList.includes(x.id)).map(x => x.id));
        setPostData(prevData => {
            const filteredData = Object.keys(prevData)
                .filter(key => !regionList.includes(key))
                .reduce((acc, key) => {
                    acc[key] = prevData[key];
                    return acc;
                }, {});

            return filteredData;
        });
    };

    const cancelExemptions = () => {
        dispatch(setCertificate([]));
        dispatch(setIsCertificateUpdated(false));
        if (redirectURL) {
            window.location = redirectURL;
        } else if (
            !isEligibleUserValidatedCertificateSaveAsValid &&
            (isAutoValidatingFile || certificateStatus.status === "Pending")
        ) {
            window.location = urlToPageMap["search-pending"];
        } else {
            navigate(urlToPageMap["search-certificate"]);
        }
    };

    const handleReasonRemove = useCallback(() => {
        setExemptReason("");
        setShowExemptReasonDescription(false);
        setExemptReasonDescriptionError("");
        setExemptReasonDescription("");
        setExemptReasonError("");
        dispatch(setIsRegionValid(true));
        dispatch(setIsCertificateUpdated(false));
        dispatch(setTaxContentForm(""));
    }, [dispatch]);

    const avataxIntegrationErrorResponse = response => {
        // Need to work on messages
        if (response?.data[0]?.code === "AvataxIntegrationError") {
            setLogEntry(response?.data[0]?.description);
            setIsSyncErrorDialogOpen(true);
            setSubmitting(false);
        } else if (response?.data?.success && response?.data?.message) {
            showToast(
                "success",
                response.data?.message ? response.data.message : "Successfully added certificate."
            );
            setSubmitting(false);
            cancelExemptions();
        } else {
            setSubmitting(false);
            cancelExemptions();
        }
    };

    const postExemptions = async updatedCertificateStatus => {
        const certStatus = updatedCertificateStatus || certificateStatus;
        const errorfields = document
            .getElementById("left-column-width-enforce")
            .getElementsByClassName("error");
        if (errorfields.length > 0) {
            return;
        }
        setSubmitting(true);
        const expectedTaxCode = getExemptReason(exemptReason);
        let isTaxcodeMapsSucess = isRegionValid;
        if (!isTaxcodeMap && expectedTaxCode?.id && !expectedTaxCode?.isTelecom) {
            await dispatch(getTaxCodeValidation(expectedTaxCode?.id)).then(response => {
                if (!response?.success) {
                    isTaxcodeMapsSucess = false;
                    setSubmitting(false);
                    dispatch(setIsTaxcodeMap(false));
                } else {
                    dispatch(setIsTaxcodeMap(true));
                }
            });
        }
        if (!isTaxcodeMapsSucess) {
            return;
        }
        const exposureZoneIds = [];
        const certificatePostData = { certificates: [] };
        // eslint-disable-next-line no-restricted-syntax
        for (const [exposureZoneId, data] of Object.entries(postData)) {
            exposureZoneIds.push(parseInt(exposureZoneId, 10));
            const certificateData = {
                ...data,
                exposureZoneId: parseInt(exposureZoneId, 10),
                expectedTaxCodeId: expectedTaxCode?.id,
                signedDate: effectiveDate,
                isSingle: single,
                valid: certStatus.status === "Valid",
                certificateStatusId: certStatus.id,
                businessNumberType,
                avsId: autoValidationData.avsId,
                templateTag: taxTemplateTag,
            };
            delete certificateData.taxValidation;
            if (canCreateCertificates()) {
                if (data?.taxValidation?.code !== 0) {
                    certificateData.taxValidation = data.taxValidation;
                }
            } else {
                certificateData.taxValidation = data.taxValidation;
            }

            if (isSSTregion && businessNumberType === "Other") {
                certificateData.businessNumberType = businessTypeDescription;
            }
            if (exemptReason === "OTHER/CUSTOM") {
                certificateData.exemptReasonDescription = exemptReasonDescription;
            }
            certificateData.businessNumber = businessNumber;
            certificatePostData.certificates.push(certificateData);
        }

        if (
            !Object.keys(postData).length &&
            (certStatus.status === "Invalid" || certStatus.status === "Pending")
        ) {
            const certificateData = {
                exposureZoneId: null,
                expirationDate: null,
                taxNumber: null,
                expectedTaxCodeId: expectedTaxCode?.id,
                signedDate: effectiveDate,
                isSingle: single,
                valid: false,
                certificateStatusId: certStatus.id,
                businessNumberType: isSSTregion ? businessNumberType : "Other",
                avsId: autoValidationData.avsId,
            };

            if (isSSTregion && businessNumberType === "Other") {
                certificateData.businessNumberType = businessTypeDescription;
            }

            certificateData.businessNumber = businessNumber;
            if (exemptReason === "OTHER/CUSTOM") {
                certificateData.exemptReasonDescription = exemptReasonDescription;
            }
            certificatePostData.certificates.push(certificateData);
        }

        if (PoNumber) {
            certificatePostData.purchaseOrderNumber = { purchaseOrderNumber: PoNumber };
        }

        if (addedCategories.length > 0 && setting.exemptionCategory) {
            certificatePostData.exemptProductCategoriesAdded = addedCategories;
        }

        if (removedCategories.length > 0 && setting.exemptionCategory) {
            certificatePostData.exemptProductCategoriesRemoved = removedCategories;
        }

        const customerIds = customers.map(customer => customer.id);
        // Calculate database ids added and removed so API doesn't have to do database lookups
        const certificateCustomerIds = certificate.customers
            ? certificate.customers.map(customer => customer.id)
            : [];
        certificatePostData.customerIdsRemoved = certificateCustomerIds.filter(
            c => !customerIds.includes(c)
        );

        const certificateInvalidReasonIds = certificate.invalidReasons
            ? certificate.invalidReasons.map(reason => reason.id)
            : [];
        // Delete all invalid reasons if invalid reasons on file and not saving certificate as invalid
        if (certStatus.status !== "Invalid" && certificateInvalidReasonIds.length) {
            certificatePostData.invalidReasonIdsRemoved = certificateInvalidReasonIds;
        } else {
            certificatePostData.invalidReasonIdsRemoved = certificateInvalidReasonIds.filter(
                r => !invalidReasons.includes(r)
            );
            certificatePostData.invalidReasonIdsAdded = invalidReasons.filter(
                r => !certificateInvalidReasonIds.includes(r)
            );
        }
        certificatePostData.exposureZoneIds = exposureZoneIds;

        // get CertificateRequest setting
        const certificateRequest = dispatch(getCertificateRequest());
        let sendNotification = true;
        if (certificateRequest?.webPortalOptions) {
            sendNotification = certificateRequest?.webPortalOptions?.filter(
                option => option.webportalOptionId === 10
            )[0]?.value;
        }
        certificatePostData.SendNotification =
            sendNotification &&
            matchSubscriptions(
                featureFlag.validatedCertificate.notification.subscription,
                getProductInfo
            );

        if (labelPostData) {
            certificatePostData.certificateCodes = labelPostData;
        }

        let customFieldPostData = await dispatch(getCustomFieldFromSlice());
        customFieldPostData = {
            ...customFieldPostData.certificateAddedCustomFieldValues,
            ...customFieldPostData.certificateExistingCustomFieldValues,
        };
        if (Object.keys(customFieldPostData).length > 0) {
            const transformedCustomFields = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const [key, value] of Object.entries(customFieldPostData)) {
                let fieldValue = value;
                if (Array.isArray(value)) {
                    fieldValue = value.join("|");
                }
                if (fieldValue !== null) transformedCustomFields[key] = fieldValue;
            }
            certificatePostData.CertificateCustomFields = transformedCustomFields;
        }

        if (canCreateCertificates()) {
            certificatePostData.customerIdsAdded = customerIds;
            if (
                validationData.data &&
                (typeof validationData.data === "string" ||
                    validationData.data instanceof String) &&
                validationData.data.indexOf(",") > -1
            ) {
                const [fileType, dataString] = validationData.data.split(",");
                if (fileType.includes("pdf")) {
                    certificatePostData.Pdf = dataString; // eslint-disable-line no-unused-vars
                } else {
                    certificatePostData.Images = [dataString];
                }
            }

            if (certificate?.certificateFilename) {
                certificatePostData.filename = certificate.certificateFilename;
            }
            certificatePostData.FVSAuiUploaderResponse = fvsValidationData;

            if (isSSTregion === false) {
                certificatePostData.businessNumberType = "Other";
                certificatePostData.businessTypeDescription = "Other";
            }
            certificatePostData.CertificateComments = comments;
            certificatePostData.CommentFiles = fileAttachments;

            certificatePostData.CommunicationTaxTypes = certificateCommunicationTaxTypes.map(
                t => t.id
            );

            await axios
                .post(
                    `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/certificates/validation`,
                    certificatePostData,
                    {
                        withCredentials: true,
                    }
                )
                .then(response => {
                    avataxIntegrationErrorResponse(response);
                });
        } else if (certificatePostData?.certificates?.length) {
            // This block will be used when updating a certificate that is completed
            certificatePostData.customerIds = customerIds;
            certificatePostData.customerIdsAdded = customerIds.filter(
                c => !certificateCustomerIds.includes(c)
            );
            certificatePostData.certificates[0].id = certificateID;
            await axios
                .put(
                    `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/certificates/validation`,
                    certificatePostData,
                    {
                        withCredentials: true,
                    }
                )
                .then(response => {
                    avataxIntegrationErrorResponse(response);
                });
        } else {
            cancelExemptions();
        }
    };

    const handleStatusChange = value => {
        const certState = {};
        let status = {};
        if (certificateStatuses && certificateStatuses.length) {
            switch (value) {
                case "Invalid":
                    switch (futureEffectiveDate) {
                        case true:
                            status = certificateStatuses.find(x => x.name === "PENDING-FUTURE");
                            certState.id = status.id;
                            certState.status = "Invalid";
                            break;
                        default:
                            status = certificateStatuses.find(x => x.name === "COMPLETE");
                            certState.id = status.id;
                            certState.status = "Invalid";
                            break;
                    }
                    break;
                case "Pending":
                    status = certificateStatuses.find(x => x.name === "PENDING");
                    certState.id = status.id;
                    certState.status = "Pending";
                    break;
                default:
                    switch (futureEffectiveDate) {
                        case true:
                            status = certificateStatuses.find(x => x.name === "PENDING-FUTURE");
                            certState.id = status.id;
                            certState.status = "Valid";
                            break;
                        default:
                            status = certificateStatuses.find(x => x.name === "COMPLETE");
                            certState.id = status.id;
                            certState.status = "Valid";
                    }
            }
            setCertificateStatus(certState);
        }
        return certState;
    };

    const closeInvalidReasonDialog = useCallback(() => {
        document.getElementById(invalidReasonDialogID).close();
        dispatch(setIsInvalidReasonDialogOpen(false));
    }, []);

    const openInvalidReasonDialog = useCallback(() => {
        document.getElementById(invalidReasonDialogID).showModal();
        dispatch(setIsInvalidReasonDialogOpen(true));
    }, []);

    const validationHandler = value => {
        setSubmitted(true);
        const updatedCertificateStatus = handleStatusChange(value);
        let formValid = true;
        if (
            updatedCertificateStatus.status !== "Invalid" &&
            updatedCertificateStatus.status !== "Pending"
        ) {
            if (isSSTregion && !businessNumberType) {
                setBusinessTypeError("error");
                formValid = false;
            }

            if (customers && customers.length === 0) {
                setCustomerError("error");
                formValid = false;
            }

            if (isSSTregion && businessNumberType === "Other" && businessTypeDescription === "") {
                setBusinessTypeDescriptionError("error");
                formValid = false;
            }

            if (exemptReason === "OTHER/CUSTOM" && exemptReasonDescription === "") {
                setExemptReasonDescriptionError("error");
                formValid = false;
            }

            if (!exposureZone.length) {
                setRegionError("error");
                formValid = false;
            }
            if (!isRegionValid) {
                formValid = false;
            }
            if (!exemptReason || !getExemptReason(exemptReason)?.id) {
                setExemptReason("");
                setExemptReasonError("error");
                setexemptReasonErrorMessage("Enter a reason for the exemption");
                formValid = false;
            }
            if (!effectiveDate) {
                if (invalidEffectiveDate) setEffectiveDateError(invalidDateErrorMsg);
                else setEffectiveDateError(dateErrorMsg);
                formValid = false;
            }

            if (single && !PoNumber) {
                formValid = false;
            }

            if (PoNumber && coverageRef.current.validatePoNumber()) {
                formValid = false;
            }

            Object.values(postData).every(data => {
                if (!data.taxNumber && setting.requireTaxId === true) {
                    formValid = false;
                }
                if (!data.taxNumberType && setting.requireTaxId === true) {
                    formValid = false;
                }
                if (!data.expirationDate) {
                    formValid = false;
                }
                if (data.expirationDate === "manual" || effectiveDate > data.expirationDate) {
                    formValid = false;
                }

                return true;
            });
        }
        if (updatedCertificateStatus.status !== "Pending") {
            if (customerDataValidation) {
                formValid = false;
            }

            if (!formValid) {
                return;
            }
        }

        if (
            matchSubscriptions(
                featureFlag.settings.requireCertificateImage.subscription,
                getProductInfo
            ) &&
            setting.requireCertificateImage === null &&
            !hasCertificateFile
        ) {
            showToast(
                "error",
                "Based on your settings, certificate images are required to be attached to certificate records. Add a certificate image and save again."
            );
            return;
        }

        if (updatedCertificateStatus.status === "Invalid") {
            if (regionMessage?.length && regionMessage !== "Certificate exempt reason exists") {
                formValid = false;
            }
            if (isSSTregion && businessNumberType === "Other" && businessTypeDescription === "") {
                setBusinessTypeDescriptionError("");
            }
            Object.values(postData).every(data => {
                if (data.expirationDate === "manual" || effectiveDate > data.expirationDate) {
                    formValid = false;
                }
                return true;
            });
            if (invalidExpirationDate) {
                formValid = false;
            }
            if (formValid) {
                openInvalidReasonDialog();
                dispatch(setIsInvalidReasonDialogOpen(true));
            } else {
                closeInvalidReasonDialog();
                dispatch(setIsInvalidReasonDialogOpen(false));
            }
        } else {
            postExemptions(updatedCertificateStatus);
        }
    };

    const handleDateChange = e => {
        if (e?.detail?.value) {
            const givenDate = new Date(e.detail.value);
            setEffectiveDate(e.detail.value);
            setEffectiveDateError("");
            if (givenDate > currentDate) {
                setFutureEffectiveDate(true);
            } else {
                setFutureEffectiveDate(false);
            }
        } else if (e?.detail?.invalidInput) {
            setInvalidEffectiveDate(true);
            setEffectiveDate("");
            setEffectiveDateError(invalidDateErrorMsg);
        } else {
            setEffectiveDate("");
            setEffectiveDateError(dateErrorMsg);
        }
    };

    const getTaxNumber = zoneId => {
        if (certificate && certificate.exposureZoneId === zoneId && certificate?.taxNumber) {
            return certificate?.taxNumber;
        }
        return "";
    };

    const getTaxNumberType = zoneId => {
        if (certificate && certificate.exposureZoneId === zoneId && certificate?.taxNumberType) {
            return certificate?.taxNumberType;
        }
        return "";
    };

    const getExpirationDate = zone => {
        if (
            certificate.exposureZoneName &&
            certificate.exposureZoneName === zone &&
            certificate.expirationDate
        ) {
            return getFormattedDate(certificate.expirationDate, true);
        }
        return "";
    };

    const getTaxValidationCode = zoneId => {
        let code = 0;
        if (certificate?.certificateTaxIdValidation && certificate.exposureZoneId === zoneId) {
            code = certificate.certificateTaxIdValidation.taxIdValidationCode;
        }
        return code;
    };
    const getTaxValidationMessage = zoneId => {
        let message = "";
        if (certificate?.certificateTaxIdValidation && certificate.exposureZoneId === zoneId) {
            message = certificate.certificateTaxIdValidation.taxIdValidationMessage;
        }
        return message;
    };

    const handleReasonSelection = event => {
        dispatch(setIsTaxcodeMap(false));
        if (event?.detail?.item?.value) {
            dispatch(setIsRegionValid(true));
            const expectedTaxCode = getExemptReason(event.detail.item.value);
            const expectedTaxCodeId = expectedTaxCode?.id;

            // Check for AvaTax taxCode map only if account does not have the following subscriptions
            // 1. ECMProComms
            // 2. ECMPremiumComms
            if (
                !matchSubscriptions(
                    featureFlag.afcContent.associateTaxTypeData.subscription,
                    dispatch(getStoreValues("session.subscriptions"))
                ) &&
                expectedTaxCodeId &&
                !expectedTaxCode?.isTelecom
            ) {
                dispatch(getTaxCodeValidation(expectedTaxCodeId));
            }

            dispatch(
                fetchTaxContentFormsAsync(expectedTaxCode, exposureZone, null, null, certOnly)
            );
        }
        handleReasonChange(event);
        dispatch(setIsCertificateUpdated(true));
    };

    const getTaxTypeRegionOptionList = useCallback(
        currentRegionName => {
            const optionList = exposureZone.map(element => {
                const selectOption = {
                    label: element.name,
                    value: element.id.toString(),
                    selected: element.name === currentRegionName,
                };
                return selectOption;
            });
            return optionList;
        },
        [exposureZone]
    );

    let ValidationDOM = null;
    if (submitting || isLoading) {
        ValidationDOM = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h1>Loading ...</h1>
                <SLoader loading aria-live="polite" />
            </div>
        );
    } else {
        ValidationDOM = (
            <React.Fragment>
                <Helmet>
                    <title>{dispatch(getPageTitle("Validation"))}</title>
                </Helmet>
                <SRow className="margin-top-xl">
                    <SCol span="5" id="left-column-width-enforce">
                        <SRow>
                            <SCol>
                                <SRow>
                                    <SCol span="auto" className="margin-top-sm">
                                        <CustomerTypeAhead
                                            handleAdd={addCustomer}
                                            handleRemove={removeCustomer}
                                            value={customers}
                                            error={customerError}
                                            certificateId={certificateID}
                                            addNewCustomer
                                            label="Customers"
                                            setCustomerDataValidation={
                                                setCustomerDataValidationValue
                                            }
                                        />
                                    </SCol>
                                </SRow>
                                <SRow>
                                    <SCol span="auto" className="margin-top-sm">
                                        <SelectExposureZone
                                            onAdd={handleRegionAdd}
                                            onRemove={handleRegionRemove}
                                            value={exposureZone}
                                            multiple
                                            exposureZoneDisabled={false}
                                            isDataEntrySet
                                            error={regionError}
                                            ref={clearRef}
                                        />
                                        <div className="input-msg">
                                            <SIcon name="alert-circle-filled" aria-hidden="true" />
                                            <span className="top-xs">
                                                Enter a region covered by this certificate
                                            </span>
                                        </div>
                                    </SCol>
                                    {showRegionRemoveButton && exposureZone.length ? (
                                        <SCol>
                                            <button
                                                id="remove-regions-button"
                                                title="Remove unrelated regions"
                                                onClick={() => {
                                                    setIsRemoveRegionDialogOpen(true);
                                                }}
                                                disabled={false}
                                                className="link inline font-semibold">
                                                <span className="md-left-sm">
                                                    Remove unrelated regions
                                                </span>
                                            </button>
                                        </SCol>
                                    ) : null}
                                </SRow>
                                <SRow>
                                    <SCol span="auto" className="margin-top-sm">
                                        <SelectExemptionReasonBasedOnRegion
                                            onAdd={handleReasonSelection}
                                            onRemove={handleReasonRemove}
                                            error={exemptReasonError}
                                            multiple={false}
                                            value={exemptReason}
                                        />
                                        <div className="input-msg">
                                            <SIcon name="alert-circle-filled" aria-hidden="true" />
                                            <span className="top-xs">
                                                {exemptReasonErrorMessage}
                                            </span>
                                        </div>
                                    </SCol>
                                </SRow>
                                {showExemptReasonDescription ? (
                                    <SRow>
                                        <SCol span="auto" className="margin-top-sm">
                                            <fieldset>
                                                <label
                                                    htmlFor="exemptReasonDescription"
                                                    id="lbl-exemptReasonDescription">
                                                    Description
                                                </label>
                                                <input
                                                    id="exemptReasonDescription"
                                                    name="exemptReasonDescription"
                                                    type="text"
                                                    onChange={handleChangeInput(
                                                        setExemptReasonDescription
                                                    )}
                                                    value={exemptReasonDescription}
                                                    className={
                                                        exemptReasonDescriptionError
                                                            ? errorTag
                                                            : noErrorTag
                                                    }
                                                />
                                                <div className="input-msg">
                                                    <SIcon
                                                        name="alert-circle-filled"
                                                        aria-hidden="true"
                                                    />
                                                    <span className="top-xs">
                                                        Enter description
                                                    </span>
                                                </div>
                                            </fieldset>
                                        </SCol>
                                    </SRow>
                                ) : null}
                                {taxContentForm ? (
                                    <SRow>
                                        <SCol span="auto" className="margin-top-sm">
                                            <label htmlFor={getNextId()}>
                                                Certificate form
                                                <button
                                                    onClick={() =>
                                                        dispatch(setIsContentFormDialogOpen(true))
                                                    }
                                                    className="link small-link margin-left-sm">
                                                    View form options
                                                </button>
                                            </label>
                                            <span className="taxContentForm">{taxContentForm}</span>
                                            <button
                                                onClick={() =>
                                                    dispatch(setIsTaxTypeDialogOpen(true))
                                                }
                                                className="link small-link">
                                                Taxes exempted by this form
                                            </button>
                                        </SCol>
                                    </SRow>
                                ) : null}
                                {isLoadingTaxContentForms ? (
                                    <SLoader className="margin-top-sm" loading />
                                ) : null}
                                {isSSTregion ? (
                                    <SRow>
                                        <SCol span="auto" className="margin-top-sm">
                                            <label htmlFor={getNextId()} id="lbl-businessType">
                                                Business type
                                            </label>
                                            <SSelect
                                                name="businessType"
                                                inputId={getCurrentId()}
                                                selectionOptional
                                                optionsList={BusinessTypeOptions}
                                                onS-select={e => handleBusinessTypeChange(e)}
                                                onS-deselect={e => handleBusinessTypeRemove(e)}
                                                className={
                                                    businessTypeError ? errorTag : noErrorTag
                                                }
                                            />
                                            <div className="input-msg">
                                                <SIcon
                                                    name="alert-circle-filled"
                                                    aria-hidden="true"
                                                />
                                                <span className="top-xs">
                                                    Enter a business type
                                                </span>
                                            </div>
                                        </SCol>
                                    </SRow>
                                ) : null}
                                {showBusinessTypeDescription ? (
                                    <SRow>
                                        <SCol span="auto" className="margin-top-sm">
                                            <fieldset>
                                                <label
                                                    htmlFor="businessTypeDescription"
                                                    id="lbl-businessTypeDescription">
                                                    Description
                                                </label>
                                                <input
                                                    id="businessTypeDescription"
                                                    name="businessTypeDescription"
                                                    onChange={handleChangeInput(
                                                        setBusinessTypeDescription
                                                    )}
                                                    value={businessTypeDescription}
                                                    className={
                                                        businessTypeDescriptionError
                                                            ? errorTag
                                                            : noErrorTag
                                                    }
                                                />
                                                <div className="input-msg">
                                                    <SIcon
                                                        name="alert-circle-filled"
                                                        aria-hidden="true"
                                                    />
                                                    <span className="top-xs">
                                                        Enter description
                                                    </span>
                                                </div>
                                            </fieldset>
                                        </SCol>
                                    </SRow>
                                ) : null}
                                <SRow>
                                    <SCol span="auto" className="margin-top-sm">
                                        <label htmlFor="effective-date-input">Effective</label>
                                        <SRow>
                                            <SCol span="3" className="pad-bottom-none">
                                                <SDatepicker
                                                    inputid="effective-date-input"
                                                    inputname="date"
                                                    className={
                                                        submitted && effectiveDateError
                                                            ? errorTag
                                                            : "effectiveDate"
                                                    }
                                                    onS-select={e => {
                                                        handleDateChange(e);
                                                    }}
                                                    value={effectiveDate}
                                                    onS-error={e => {
                                                        handleDateChange(e);
                                                    }}
                                                    onS-deselect={e => {
                                                        handleDateChange(e);
                                                    }}
                                                />
                                            </SCol>
                                        </SRow>
                                        <SRow>
                                            <SCol>
                                                {submitted && effectiveDateError ? (
                                                    <div className="input-msg effective-date-error">
                                                        <SIcon
                                                            name="alert-circle-filled"
                                                            aria-hidden="true"
                                                        />
                                                        <span className="top-xs">
                                                            {effectiveDateError}
                                                        </span>
                                                    </div>
                                                ) : null}
                                            </SCol>
                                        </SRow>
                                    </SCol>
                                    {exemptReason.length > 0 && exposureZone.length > 1 ? (
                                        <SCol>
                                            <span className="right">
                                                {validatingAllIds ? (
                                                    <span className="text-sm-strong">
                                                        <i>Verifying all ID numbers...</i>
                                                    </span>
                                                ) : (
                                                    <button
                                                        className="link font-semibold"
                                                        onClick={() => setValidateAllIds(true)}>
                                                        Verify all regional ID numbers
                                                    </button>
                                                )}
                                            </span>
                                        </SCol>
                                    ) : null}
                                </SRow>
                                <SRow
                                    hidden={exemptReason.length === 0 || exposureZone.length === 0}>
                                    <SCol span="12" className="margin-top-sm">
                                        <div className="relative-wrap">
                                            {exposureZone.map(zone => {
                                                return (
                                                    <Multi
                                                        key={zone.id}
                                                        zoneName={zone.name}
                                                        zoneId={zone.id}
                                                        avsPredictedZoneInitial={zone.initials}
                                                        certificateId={zone.certificateId}
                                                        taxNumber={getTaxNumber(zone.id)}
                                                        taxNumberType={getTaxNumberType(zone.id)}
                                                        taxValidationCode={getTaxValidationCode(
                                                            zone.id
                                                        )}
                                                        taxValidationMessage={getTaxValidationMessage(
                                                            zone.id
                                                        )}
                                                        exemptReason={exemptReason}
                                                        effectiveDate={effectiveDate}
                                                        existingExpirationDate={getExpirationDate(
                                                            zone.name
                                                        )}
                                                        submitted={submitted}
                                                        updatePostData={updatePostData}
                                                        clearPostData={clearPostData}
                                                        autoValidationPredictionsData={JSON.stringify(
                                                            autoValidationData.predictions
                                                        )}
                                                        isMulti={
                                                            autoValidationData.isMulti ||
                                                            exposureZone.length > 1
                                                        }
                                                        isExistingZone={false}
                                                        existingEffectiveDate={
                                                            certificate.signedDate
                                                        }
                                                        existingExpectedTaxCodeName={
                                                            certificate.expectedTaxCodeName
                                                        }
                                                        existingExposureZoneName={
                                                            certificate.exposureZoneName
                                                        }
                                                        certStatus={certificateStatus.status}
                                                        setInvalidExpirationDate={
                                                            setInvalidExpirationDate
                                                        }
                                                        validateAllIds={validateAllIds}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </SCol>
                                </SRow>
                                <SRow>
                                    <SCol span="10">
                                        <Coverage
                                            single={single}
                                            setIsSingle={setIsSingle}
                                            PoNumber={PoNumber}
                                            setPoNumber={setPoNumber}
                                            submitted={submitted}
                                            certStatus={certificateStatus.status}
                                            ref={coverageRef}
                                        />
                                    </SCol>
                                </SRow>
                                {setting.exemptionCategory ? (
                                    <SRow>
                                        <SCol span="10">
                                            <CertificateProductCategories
                                                newCategories={addedCategories}
                                                addCategory={categoriesHandler}
                                                certificateID={certificateID}
                                                deleteCategory={removeCategoryHandler}
                                                deletedCategory={removedCategories}
                                                allCategory={setRemovedCategories}
                                            />
                                        </SCol>
                                    </SRow>
                                ) : null}

                                <FeatureToggler category="reviewCertificate" id="assignRelease">
                                    {certificateID && escalationLog?.escalationDate ? (
                                        <SRow className="margin-top-sm">
                                            <SCol>
                                                {getFormattedDate(escalationLog?.escalationDate)} by{" "}
                                                {escalationLog?.escalatedBy}
                                            </SCol>
                                            <SCol>
                                                <strong>Escalation note : </strong>
                                                {escalationLog?.escalationMessage}
                                            </SCol>
                                        </SRow>
                                    ) : null}
                                </FeatureToggler>
                                <FeatureToggler category="reviewCertificate" id="comments">
                                    <CertificateComments certificateID={certificateID} onReview />
                                </FeatureToggler>
                                <FeatureToggler category="validatedCertificate" id="customFields">
                                    <CertificateCustomField
                                        certificateID={certificateID}
                                        ref={certificateCustomFieldsRef}
                                    />
                                </FeatureToggler>
                                <FeatureToggler
                                    category="validatedCertificate"
                                    id="certificateLabels">
                                    <CertificateLabel
                                        updateCertificateLabel={updateCertificateLabelPostData}
                                        certificateID={certificateID}
                                    />
                                </FeatureToggler>
                            </SCol>
                        </SRow>
                    </SCol>
                    <SCol span="7">{validationFileColumnComponent}</SCol>
                    <SCol span="12" className="pad-bottom-none">
                        {isScanningFVS && (
                            <SBox className="margin-bottom-md">
                                <SIcon
                                    name="security-check"
                                    className="margin-right-xs"
                                    aria-hidden="true"
                                />
                                <strong>
                                    Just taking a moment to check for vulnerabilities...
                                </strong>
                            </SBox>
                        )}
                        <hr className="margin-all-none" />
                        <div className="margin-top-lg">
                            <FeatureToggler
                                category="validatedCertificate"
                                id="validate"
                                firstChild={
                                    <>
                                        <button
                                            className={saveCertificateButton}
                                            id="save-valid-certificate-btn"
                                            disabled={
                                                isScanningFVS ||
                                                !passedFVS ||
                                                !isEligibleUserReviewCertificateValidate
                                            }
                                            onClick={() => validationHandler("Valid")}>
                                            Save as valid
                                        </button>
                                        <button
                                            className={secondaryButton}
                                            id="save-invalid-certificate-btn"
                                            disabled={
                                                isScanningFVS ||
                                                !passedFVS ||
                                                !isEligibleUserReviewCertificateValidate
                                            }
                                            onClick={() => validationHandler("Invalid")}>
                                            Save as invalid
                                        </button>
                                        <button
                                            className={secondaryButton}
                                            id="add-certificate-btn"
                                            hidden={exposureZone.length > 1}
                                            disabled={
                                                isScanningFVS ||
                                                !passedFVS ||
                                                !isEligibleUserReviewCertificateAdd
                                            }
                                            onClick={() => validationHandler("Pending")}>
                                            Save changes
                                        </button>
                                    </>
                                }
                                secondChild={
                                    <button
                                        className={secondaryButton}
                                        id="add-certificate-btn"
                                        disabled={
                                            isScanningFVS ||
                                            !passedFVS ||
                                            !isEligibleUserReviewCertificateValidate
                                        }
                                        onClick={() => validationHandler("Valid")}>
                                        Save changes
                                    </button>
                                }
                            />
                            <button onClick={cancelExemptions} className={cancelButton}>
                                Cancel
                            </button>
                        </div>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol className="margin-top-md">
                        {certificateID ? (
                            <FeatureToggler category="reviewCertificate" id="delete">
                                <button
                                    className="link inline font-semibold"
                                    onClick={() => setIsDeleteCertificateDialogOpen(true)}
                                    disabled={!isEligibleUserReviewCertificateDelete}>
                                    <span className="margin-left-xs">Delete this certificate</span>
                                </button>
                            </FeatureToggler>
                        ) : null}
                    </SCol>
                </SRow>
                {isSyncErrorDialogOpen ? (
                    <SyncErrorDialog
                        isSyncErrorDialogOpen={isSyncErrorDialogOpen}
                        setIsSyncErrorDialogOpen={setIsSyncErrorDialogOpen}
                        logEntry={logEntry}
                    />
                ) : null}
                {isDeleteCertificateDialogOpen ? (
                    <DeleteCertificateDialog
                        isDeleteCertificateDialogOpen={isDeleteCertificateDialogOpen}
                        setIsDeleteCertificateDialogOpen={setIsDeleteCertificateDialogOpen}
                        certificateIds={certificateID}
                        navigate={() => navigate(urlToPageMap["search-pending"])}
                    />
                ) : null}
                <InvalidReasonDialog
                    postExemptions={postExemptions}
                    invalidReasons={invalidReasons}
                    setInvalidReasons={setInvalidReasons}
                    closeInvalidReasonDialog={closeInvalidReasonDialog}
                    openInvalidReasonDialog={openInvalidReasonDialog}
                />
                {isContentFormDialogOpen ? (
                    <ContentFormDialog exposureZone={exposureZone} exemptReason={exemptReason} />
                ) : null}
                {isTaxTypeDialogOpen ? (
                    <TaxTypeDialog
                        exposureZoneId={parseInt(exposureZone[0].id, 10)}
                        exposureZoneName={exposureZone[0].name}
                        isMulti={exposureZone.length > 1}
                        getTaxTypeRegionOptionList={getTaxTypeRegionOptionList}
                    />
                ) : null}
                {isRemoveRegionDialogOpen ? (
                    <RemoveUnRelatedRegionsDialog
                        isRemoveRegionDialogOpen={isRemoveRegionDialogOpen}
                        setIsRemoveRegionDialogOpen={setIsRemoveRegionDialogOpen}
                        customerIds={JSON.stringify(customers.map(x => x.id))}
                        regionIdName={JSON.stringify(
                            exposureZone.map(zone => ({ id: zone.id, name: zone.name }))
                        )}
                        customerRegionSetting={setting.applyCustomerExposure}
                        companyRegionSetting={setting.removeNonNexusExposure}
                        removeExposureZoneById={removeExposureZoneById}
                    />
                ) : null}
            </React.Fragment>
        );
    }
    return ValidationDOM;
});

export default ValidationDetails;
