import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SLoader, SPagination } from "@avalara/skylab-react";
import { gridHeader, DataGrid } from "@certcapture/react-components";
import {
    fetchCertificateCustomFields,
    selectPage,
    selectIsLoading,
    setPageToNull,
    selectSortColumn,
    setSortColumn,
} from "../../../../app/certificateCustomFieldSlice";
import DeleteCertificatesCustomFieldDialog from "./DeleteCertificatesCustomField";
import AddEditCertificateCustomFieldsDialog from "./AddEditCertificateCustomFieldsDialog";
import featureFlag from "../../../../featureToggler/featureFlag";
import { isEligibleUser } from "../../../../shared/sessionUtility";
import FeatureToggler from "../../../../featureToggler/FeatureToggler";

function CertificatesCustomFieldsGrid(props) {
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const page = useSelector(selectPage, shallowEqual);
    const gridData = page.data;
    const pageData = page.paginate;
    const isLoading = useSelector(selectIsLoading);
    const sortColumn = useSelector(selectSortColumn);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [certificateCustomField, setCertificateCustomField] = useState({});
    const isUserEligibleEditCertificatesCustomField = !dispatch(
        isEligibleUser(featureFlag.settings.editCertificatesCustomField)
    );
    const isUserEligibleDeleteCertificatesCustomField = !dispatch(
        isEligibleUser(featureFlag.settings.deleteCertificatesCustomField)
    );

    useEffect(() => {
        dispatch(fetchCertificateCustomFields());
        return () => {
            dispatch(setPageToNull([]));
        };
    }, [dispatch]);

    async function sortFunction(e) {
        setSubmitting(true);
        const column = e.detail.id;
        const order = sortColumn[0] === column ? !sortColumn[1] : true;
        dispatch(setSortColumn([column, order]));
        await dispatch(fetchCertificateCustomFields(pageData.rowsPerPage, null, pageData));
        setSubmitting(false);
    }

    const columns = [
        gridHeader(
            "Name",
            "fieldName",
            value => value,
            null,
            "",
            null,
            null,
            sortFunction,
            "fieldName"
        ),
        gridHeader("Type", "type", value => value, null, "", null, null, sortFunction, "type"),
        gridHeader("Values", "possibleValues", value => value, "text-center", "text-center", null),
        gridHeader(
            "",
            "isEditable",
            (value, row) =>
                !row.isEditable ? (
                    <FeatureToggler category="settings" id="editCertificatesCustomField">
                        <button
                            id={`edit-${row.id}`}
                            className="link font-bold"
                            disabled={isUserEligibleEditCertificatesCustomField}
                            onClick={() => {
                                props.setAddOrEdit("Edit");
                                setCertificateCustomField(row);
                                props.setOpenAddUpdateDialog(true);
                            }}>
                            Edit
                        </button>
                    </FeatureToggler>
                ) : null,
            "text-center",
            "text-center"
        ),
        gridHeader(
            "",
            "isEditable",
            (value, row) =>
                !row.isEditable ? (
                    <FeatureToggler category="settings" id="deleteCertificatesCustomField">
                        <button
                            id={`delete-${row.id}`}
                            className="link font-bold"
                            disabled={isUserEligibleDeleteCertificatesCustomField}
                            onClick={() => {
                                setCertificateCustomField(row);
                                setIsDeleteDialogOpen(true);
                            }}>
                            Delete
                        </button>
                    </FeatureToggler>
                ) : null,
            "text-center",
            "text-center"
        ),
    ];

    const getRowKey = row => {
        return row.id;
    };

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && pageData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            dispatch(
                fetchCertificateCustomFields(e.detail.rowsPerPage, e.detail.startIndex, e.detail)
            );
            setSubmitting(false);
        }
    };

    const table =
        isLoading || submitting ? (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        ) : (
            <>
                <div className="margin-bottom-xs text-right">
                    {pageData.totalRecords} custom fields for certificates
                </div>
                <span className="clear" />
                <s-table-container>
                    <DataGrid
                        columns={columns}
                        rows={gridData}
                        loading={isLoading}
                        getKey={getRowKey}
                        inContainer
                        sortColumn={sortColumn}
                    />
                </s-table-container>
            </>
        );

    return !isLoading && pageData.totalRecords === 0 ? (
        <div className="text-center flex dl-flex-fill-height dl-flex-center">
            <h1 className="text-color-no-data" id="NoCustomfieldsFound">
                No Custom fields are found
            </h1>
        </div>
    ) : (
        <React.Fragment>
            {table}
            {pageData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={pageData.rowsPerPage}
                    totalRecords={pageData.totalRecords}
                    startIndex={pageData.startIndex}
                    onS-paginate={e => {
                        handlePaginate(e);
                    }}
                />
            ) : null}

            {isDeleteDialogOpen ? (
                <DeleteCertificatesCustomFieldDialog
                    isDeleteDialogOpen
                    setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                    customField={certificateCustomField}
                />
            ) : null}

            {props.openAddUpdateDialog ? (
                <AddEditCertificateCustomFieldsDialog
                    addOrEdit={props.addOrEdit}
                    certificateCustomField={certificateCustomField}
                    openAddUpdateDialog={props.openAddUpdateDialog}
                    setOpenAddUpdateDialog={props.setOpenAddUpdateDialog}
                />
            ) : null}
        </React.Fragment>
    );
}

export default CertificatesCustomFieldsGrid;
