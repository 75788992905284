import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { buildURL, fixSearchStringForPostgres } from "../shared/Utils";
import toast from "../hooks/toast";

const defaultFormState = {
    nameOrCode: "",
    nameOrCodeWhileLoading: "",
};

const defaultRequestFiltersState = {
    title: "",
    customMessage: "",
    attachCertificates: false,
    isCustomerDetails: true,
    isCertificateDetails: false,
    customerRegions: [],
    isMissingcertificates: false,
    missingExposureZones: [],
    isBillToRelation: false,
    isShipToRelation: false,
    isVendor: false,
    customerCodes: [],
    customerCodeError: "",
    customerCreatedDateAfter: "",
    customerCreatedDateBefore: "",
    customerCreatedDateAfterError: "",
    customerCreatedDateBeforeError: "",
    customerModifiedDateAfter: "",
    customerModifiedDateBefore: "",
    customerModifiedDateAfterError: "",
    customerModifiedDateBeforeError: "",
    certificatesLabels: [],
    customersLabels: [],
    certificateStatus: [],
    certificateRegions: [],
    exemptReasons: [],
    isPurchaseOrder: false,
    invoiceNumber: "",
    certificateIds: [],
    certificateIdError: "",
    isCertificateImage: 0,
    certificateCreatedDateAfter: "",
    certificateCreatedDateBefore: "",
    certificateCreatedDateAfterError: "",
    certificateCreatedDateBeforeError: "",
    certificateModifiedDateAfter: "",
    certificateModifiedDateBefore: "",
    certificateModifiedDateAfterError: "",
    certificateModifiedDateBeforeError: "",
    certificateEffectiveDateAfter: "",
    certificateEffectiveDateBefore: "",
    certificateEffectiveDateAfterError: "",
    certificateEffectiveDateBeforeError: "",
    certificateExpirationDateAfter: "",
    certificateExpirationDateBefore: "",
    certificateExpirationDateAfterError: "",
    certificateExpirationDateBeforeError: "",
    savedSearch: [],
    alternateId: "",
    isExportCampaignDialogOpen: false,
    isExportCampaignLoading: false,
    isDuplicateRelation: false,
};

const defaultPageData = {
    data: [],
    paginate: {
        pageNumber: 0,
        startIndex: -1,
        endIndex: 0,
        rowsPerPage: 20,
        totalRecords: 0,
    },
};

const [showToast, hideToast] = toast();

export const requestSlice = createSlice({
    name: "request",
    initialState: {
        formState: defaultFormState,
        requestFiltersState: defaultRequestFiltersState,
        isloading: false,
        isCustomerSearchLoading: false,
        isCustomersWithoutEmailLoading: false,
        isCampaignCustomersLoading: false,
        campaign: [],
        noDataType: "",
        page: defaultPageData,
        customerSearchPage: defaultPageData,
        campaignCustomersPage: defaultPageData,
        sortColumn: ["created", false],
        createSortColumn: ["name", true],
        detailsSortColumn: ["id", true],
        missingCustomerEmailCount: 0,
        isCustomerCountAPICompleted: 0,
        isLoadingCustomerCount: false,
    },
    reducers: {
        setLoading: (state, action) => {
            state.isloading = action.payload;
        },
        setCustomerSearchLoading: (state, action) => {
            state.isCustomerSearchLoading = action.payload;
        },
        setCustomersWithoutEmailLoading: (state, action) => {
            state.isCustomersWithoutEmailLoading = action.payload;
        },
        setCampaignCustomersLoading: (state, action) => {
            state.isCampaignCustomersLoading = action.payload;
        },
        setFormState: (state, action) => {
            state.formState = { ...state.formState, ...action.payload };
        },
        clearFormState: state => {
            state.formState = defaultFormState;
        },
        setRequestFiltersState: (state, action) => {
            const key = Object.keys(action.payload)[0];
            const value = Object.values(action.payload)[0];
            state.requestFiltersState[key] = value;
        },
        clearRequestFiltersState: state => {
            state.requestFiltersState = defaultRequestFiltersState;
        },
        setCampaign: (state, action) => {
            state.campaign = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setCustomerSearchPage: (state, action) => {
            state.customerSearchPage = action.payload;
        },
        setCampaignCustomersPage: (state, action) => {
            state.campaignCustomersPage = action.payload;
        },
        setPageToNull: state => {
            state.page = defaultPageData;
        },
        setCustomerSearchPageToNull: state => {
            state.customerSearchPage = defaultPageData;
        },
        setCampaignCustomersPageToNull: state => {
            state.campaignCustomersPage = defaultPageData;
        },
        setNoDataType: (state, action) => {
            state.noDataType = action.payload;
        },
        setNoDataOnSearch: state => {
            state.noDataType = "onSearch";
        },
        setNoDataOnLand: state => {
            state.noDataType = "onLanding";
        },
        setNoDataToNull: state => {
            state.noDataType = "";
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setCreateSortColumn: (state, action) => {
            state.createSortColumn = action.payload;
        },
        setDetailsSortColumn: (state, action) => {
            state.detailsSortColumn = action.payload;
        },
        setMissingCustomerEmailCount: (state, action) => {
            state.missingCustomerEmailCount = action.payload;
        },
        setSavedSearch: (state, action) => {
            state.requestFiltersState.savedSearch = action.payload;
        },
        setSavedSearchState: (state, action) => {
            state.requestFiltersState = {
                ...defaultRequestFiltersState,
                ...action.payload,
            };
        },
        setCustomerCountAPICompleted: (state, action) => {
            state.isCustomerCountAPICompleted = action.payload;
        },
        incrementCustomerCountAPICompleted: state => {
            state.isCustomerCountAPICompleted += 1;
        },
        decrementCustomerCountAPICompleted: state => {
            state.isCustomerCountAPICompleted -= 1;
        },
        setIsLoadingCustomerCount: (state, action) => {
            state.isLoadingCustomerCount = action.payload;
        },

        setIsExportCampaignDialogOpen: (state, action) => {
            state.isExportCampaignDialogOpen = action.payload;
        },
        setIsLoadingCampaignExport: (state, action) => {
            state.isExportCampaignLoading = action.payload;
        },
    },
});

export const {
    setFormState,
    clearFormState,
    setRequestFiltersState,
    clearRequestFiltersState,
    setLoading,
    setCustomerSearchLoading,
    setCustomersWithoutEmailLoading,
    setCampaignCustomersLoading,
    setCampaign,
    setPage,
    setCustomerSearchPage,
    setCampaignCustomersPage,
    setPageToNull,
    setCustomerSearchPageToNull,
    setCampaignCustomersPageToNull,
    setNoDataType,
    setSortColumn,
    setCreateSortColumn,
    setDetailsSortColumn,
    setMissingCustomerEmailCount,
    setSavedSearch,
    setSavedSearchState,
    setCustomerCountAPICompleted,
    incrementCustomerCountAPICompleted,
    decrementCustomerCountAPICompleted,
    setIsLoadingCustomerCount,
    setIsExportCampaignDialogOpen,
    setIsLoadingCampaignExport,
} = requestSlice.actions;

export const selectIsCustomerCountAPICompleted = state => {
    return state.request.isCustomerCountAPICompleted;
};

export const selectIsLoadingCustomerCount = state => {
    return state.request.isLoadingCustomerCount;
};

export const selectFormState = state => {
    return state.request.formState;
};

export const selectRequestFiltersState = state => {
    return state.request.requestFiltersState;
};

export const selectLoading = state => {
    return state.request.isloading;
};

export const selectCustomerSearchLoading = state => {
    return state.request.isCustomerSearchLoading;
};

export const selectCustomersWithoutEmailLoading = state => {
    return state.request.isCustomersWithoutEmailLoading;
};

export const selectCampaignCustomersLoading = state => {
    return state.request.isCustomerSearchLoading;
};

export const selectPage = state => {
    return state.request.page;
};

export const selectCustomerSearchPage = state => {
    return state.request.customerSearchPage;
};

export const selectCampaignCustomersPage = state => {
    return state.request.campaignCustomersPage;
};

export const selectTotalCustomers = state => {
    return state.request.campaignCustomersPage.paginate.totalRecords;
};

export const selectNoData = state => {
    return state.request.noDataType;
};

export const selectSortColumn = state => {
    return state.request.sortColumn;
};

export const selectCreateSortColumn = state => {
    return state.request.createSortColumn;
};

export const selectDetailsSortColumn = state => {
    return state.request.detailsSortColumn;
};

export const selectCampaign = state => {
    return state.request.campaign;
};

export const selectMissingCustomerEmailCount = state => {
    return state.request.missingCustomerEmailCount;
};
export const selectIsCampaignCustomersLoading = state => {
    return state.request.isCampaignCustomersLoading;
};

export const selectIsExportCampaignDialogOpen = state => {
    return state.request.isExportCampaignDialogOpen;
};

export const selectIsExportCampaignLoading = state => {
    return state.request.isExportCampaignLoading;
};

export const fetchCampaignAPI = (id, filterString, include) => async (dispatch, getState) => {
    const { request } = getState();
    if (request.isloading) {
        return;
    }

    dispatch(setLoading(true));
    const apiURI = buildURL(
        `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/campaigns/${id}`,
        filterString,
        include,
        0
    );

    const response = await axios.get(`${apiURI}`, { withCredentials: true });
    if (response.data === null || response.data === "") {
        dispatch(setNoDataType("noResource"));
    } else {
        dispatch(setNoDataType(""));
    }
    dispatch(setCampaign(response.data));
    dispatch(setLoading(false));
};

export const fetchCampaignsAPI =
    (filterString, include, top, skip, onLanding, paginateDetails) =>
    async (dispatch, getState) => {
        const { isloading, sortColumn } = getState().request;
        if (isloading) {
            return;
        }
        dispatch(setLoading(true));
        const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;

        const apiURI = buildURL(
            `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/campaigns`,
            filterString,
            include,
            top,
            skip,
            sort
        );
        const response = await axios.get(`${apiURI}`, { withCredentials: true });

        if (response.data && response.data.count === 0) {
            onLanding ? dispatch(setNoDataType("onLanding")) : dispatch(setNoDataType("onSearch"));
            dispatch(setPageToNull());
        } else {
            dispatch(setNoDataType(""));
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.currentPage,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response.data.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 20,
                      totalRecords: response.data.count,
                  };
            dispatch(
                setPage({
                    data: response.data.value,
                    paginate: paginateData,
                })
            );
        }
        dispatch(setLoading(false));
    };

export const fetchCampaignCustomersAPI =
    (campaignId, filterString, include, top, skip, onLanding, paginateDetails) =>
    async (dispatch, getState) => {
        const { isCampaignCustomersLoading, detailsSortColumn: sortColumn } = getState().request;
        if (isCampaignCustomersLoading) {
            return;
        }
        dispatch(setCampaignCustomersLoading(true));
        const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;

        const apiURI = buildURL(
            `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/campaigns/${campaignId}/campaign-customers`,
            filterString,
            include,
            top,
            skip,
            sort
        );
        const response = await axios.get(`${apiURI}`, { withCredentials: true });
        if (response.data && response.data.count === 0) {
            dispatch(setCampaignCustomersPageToNull());
        } else {
            dispatch(setNoDataType(""));
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.currentPage,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response.data.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 20,
                      totalRecords: response.data.count,
                  };
            dispatch(
                setCampaignCustomersPage({
                    data: response.data.value,
                    paginate: paginateData,
                })
            );
        }
        dispatch(setCampaignCustomersLoading(false));
    };

export const fetchCampaignDetailsAPI =
    (campaignId, pageSize = 20, pageNumber = 1, onLanding, paginateDetails) =>
    async (dispatch, getState) => {
        const {
            isCampaignCustomersLoading,
            detailsSortColumn: sortColumn,
            formState,
        } = getState().request;

        if (isCampaignCustomersLoading) {
            return;
        }

        const requestBody = {
            pageSize,
            pageNumber,
            orderBy: sortColumn[0],
            orderByDirection: sortColumn[1] ? "DESC" : "ASC",
            nameOrCode: formState.nameOrCode,
        };

        dispatch(setCampaignCustomersLoading(true));
        axios
            .post(
                `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/campaigns/${campaignId}/campaign-details`,
                requestBody,
                { withCredentials: true }
            )
            .then(async response => {
                if (!response.data) {
                    onLanding
                        ? dispatch(setNoDataType("onLanding"))
                        : dispatch(setNoDataType("onCustomerSearch"));
                    dispatch(setCampaignCustomersPageToNull());
                    dispatch(setCampaignCustomersLoading(false));
                } else {
                    dispatch(setNoDataType(""));
                    const paginateData = paginateDetails
                        ? {
                              pageNumber,
                              startIndex: paginateDetails.startIndex,
                              endIndex: paginateDetails.endIndex,
                              rowsPerPage: paginateDetails.rowsPerPage,
                              totalRecords: response.data.count,
                              indeterminate: false,
                          }
                        : {
                              pageNumber,
                              startIndex: 0,
                              endIndex: 19,
                              rowsPerPage: pageSize,
                              totalRecords: response.data.count,
                          };
                    dispatch(
                        setCampaignCustomersPage({
                            data: response.data.value,
                            paginate: paginateData,
                        })
                    );
                    dispatch(setCampaignCustomersLoading(false));
                }
            })
            .catch(() => {
                dispatch(setCampaignCustomersLoading(false));
            });
    };

export function buildSearchParams(getState, pageSize, pageNumber, returnEmptyEmail = null) {
    const { requestFiltersState, createSortColumn, formState } = getState().request;
    const { nameOrCode, nameOrCodeWhileLoading } = formState;
    const searchVal = nameOrCodeWhileLoading !== "" ? nameOrCodeWhileLoading : nameOrCode;
    return {
        pageSize,
        pageNumber,
        orderBy: createSortColumn[0],
        orderByDirection: createSortColumn[1] ? "ASC" : "DESC",
        includes: "",
        nameOrCode: fixSearchStringForPostgres(searchVal),
        purchaseOrderNumber: requestFiltersState.invoiceNumber,
        missing: requestFiltersState.isMissingcertificates,
        missingExposureZones: requestFiltersState.missingExposureZones,
        isBillTo: requestFiltersState.isBillToRelation,
        isShipTo: requestFiltersState.isShipToRelation,
        isVendor: requestFiltersState.isVendor,
        certificateImageExists: requestFiltersState.isCertificateImage,
        returnEmptyEmailRecords:
            returnEmptyEmail == null ? requestFiltersState.isMissingEmailAddress : returnEmptyEmail,
        customerCreatedFrom: requestFiltersState.customerCreatedDateAfter
            ? requestFiltersState.customerCreatedDateAfter
            : null,
        customerCreatedTo: requestFiltersState.customerCreatedDateBefore
            ? requestFiltersState.customerCreatedDateBefore
            : null,
        customerModifiedFrom: requestFiltersState.customerModifiedDateAfter
            ? requestFiltersState.customerModifiedDateAfter
            : null,
        customerModifiedTo: requestFiltersState.customerModifiedDateBefore
            ? requestFiltersState.customerModifiedDateBefore
            : null,
        certificateCreatedFrom: requestFiltersState.certificateCreatedDateAfter
            ? requestFiltersState.certificateCreatedDateAfter
            : null,
        certificateCreatedTo: requestFiltersState.certificateCreatedDateBefore
            ? requestFiltersState.certificateCreatedDateBefore
            : null,
        certificateModifiedFrom: requestFiltersState.certificateModifiedDateAfter
            ? requestFiltersState.certificateModifiedDateAfter
            : null,
        certificateModifiedTo: requestFiltersState.certificateModifiedDateBefore
            ? requestFiltersState.certificateModifiedDateBefore
            : null,
        certificateEffectiveFrom: requestFiltersState.certificateEffectiveDateAfter
            ? requestFiltersState.certificateEffectiveDateAfter
            : null,
        certificateEffectiveTo: requestFiltersState.certificateEffectiveDateBefore
            ? requestFiltersState.certificateEffectiveDateBefore
            : null,
        certificateExpirationFrom: requestFiltersState.certificateExpirationDateAfter
            ? requestFiltersState.certificateExpirationDateAfter
            : null,
        certificateExpirationTo: requestFiltersState.certificateExpirationDateBefore
            ? requestFiltersState.certificateExpirationDateBefore
            : null,
        certificateIds: requestFiltersState.certificateIds.length
            ? requestFiltersState.certificateIds
                  .split("\n")
                  .map(value => fixSearchStringForPostgres(value.trim()))
                  .map(Number)
            : [],
        customerCodes: requestFiltersState.customerCodes?.length
            ? requestFiltersState.customerCodes
                  .split("\n")
                  .map(value => fixSearchStringForPostgres(value.trim()))
            : [],
        status: requestFiltersState.certificateStatus,
        exposureZones: requestFiltersState.certificateRegions,
        customerRegions: requestFiltersState.customerRegions,
        exemptionReasons: requestFiltersState.exemptReasons,
        alternateId: requestFiltersState.alternateId,
        certificatesLabels: requestFiltersState.certificatesLabels,
        customersLabels: requestFiltersState.customersLabels,
        isDuplicate: requestFiltersState.isDuplicateRelation,
    };
}

export const requestSearch =
    (pageSize = 20, pageNumber = 0, paginateDetails) =>
    async (dispatch, getState) => {
        const { isCustomerSearchLoading, formState } = getState().request;
        const { nameOrCode, nameOrCodeWhileLoading } = formState;

        if (isCustomerSearchLoading) {
            dispatch(
                setFormState({
                    ...formState,
                    nameOrCodeWhileLoading: nameOrCode,
                })
            );
            return;
        }

        dispatch(setCustomerSearchLoading(true));
        axios
            .post(
                `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customers/search`,
                buildSearchParams(getState, pageSize, pageNumber),
                { withCredentials: true }
            )
            .then(async response => {
                if (!response.data) {
                    dispatch(setCustomerSearchPageToNull());
                    dispatch(setNoDataType("onCustomerSearch"));
                } else {
                    dispatch(setNoDataType(""));
                    const { page, isCustomerCountAPICompleted } = getState().request;
                    const pageData = { ...page.paginate };
                    const paginateData = paginateDetails
                        ? {
                              pageNumber: paginateDetails.currentPage,
                              startIndex: paginateDetails.startIndex,
                              endIndex: paginateDetails.endIndex,
                              rowsPerPage: paginateDetails.rowsPerPage,
                              totalRecords: pageData.totalRecords,
                              indeterminate: false,
                          }
                        : {
                              pageNumber,
                              startIndex: 0,
                              endIndex: 19,
                              rowsPerPage: pageSize,
                              totalRecords:
                                  isCustomerCountAPICompleted === 0 ? pageData.totalRecords : 20,
                              indeterminate: !(isCustomerCountAPICompleted === 0),
                          };

                    dispatch(
                        setCustomerSearchPage({
                            data: response.data.value,
                            paginate: paginateData,
                        })
                    );
                }
                if (nameOrCodeWhileLoading !== "") {
                    await dispatch(
                        setFormState({
                            ...formState,
                            nameOrCodeWhileLoading: "",
                        })
                    );
                }
                dispatch(setCustomerSearchLoading(false));
                if (nameOrCodeWhileLoading !== "") {
                    await dispatch(
                        setFormState({
                            ...formState,
                            nameOrCodeWhileLoading: "",
                        })
                    );
                }
            });
        if (!paginateDetails) {
            dispatch(setIsLoadingCustomerCount(true));
            dispatch(incrementCustomerCountAPICompleted());
            axios
                .post(
                    `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customers/search-count`,
                    buildSearchParams(getState, pageSize, pageNumber),
                    { withCredentials: true }
                )
                .then(res => {
                    dispatch(decrementCustomerCountAPICompleted());
                    const { page } = getState().request;
                    const pageData = { ...page.paginate };
                    pageData.totalRecords = res?.data;
                    pageData.indeterminate = false;
                    dispatch(
                        setPage({
                            data: page.data,
                            paginate: pageData,
                        })
                    );
                    dispatch(setIsLoadingCustomerCount(false));
                });
        }
    };

export const getCustomersWithoutEmail = () => async (dispatch, getState) => {
    dispatch(setCustomersWithoutEmailLoading(true));
    const response = await axios.post(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customers/search-count`,
        buildSearchParams(getState, 20, 1, true),
        { withCredentials: true }
    );
    if (response && response.data) {
        dispatch(setMissingCustomerEmailCount(response.data));
    }

    dispatch(setCustomersWithoutEmailLoading(false));
};

export const postCampaignExportAPI =
    (pageSize = 20, pageNumber = 0, title) =>
    async (dispatch, getState) => {
        const { isExportCampaignLoading, formState, sortColumn, campaign } = getState().request;
        const { userId, accountId, role } = getState().session;
        const { nameOrCode, nameOrCodeWhileLoading } = formState;
        if (isExportCampaignLoading) {
            return;
        }

        dispatch(setIsLoadingCampaignExport(true));
        const searchVal = nameOrCodeWhileLoading !== "" ? nameOrCodeWhileLoading : nameOrCode;
        const response = await axios.post(
            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/export-campaign?title=${title}`,
            {
                pageSize,
                pageNumber,

                orderBy: sortColumn[0],
                orderByDirection: sortColumn[1] ? "DESC" : "ASC",
                nameOrCode: fixSearchStringForPostgres(searchVal),
                campaignId: campaign.id,
                avalaraUserId: userId,
                avalaraUserRole: role,
                avaTaxAccountId: accountId,
            },
            { withCredentials: true }
        );
        dispatch(setIsExportCampaignDialogOpen(false));
        dispatch(setIsLoadingCampaignExport(false));
        if (response.status === 200) {
            const baseURL = `https://${process.env.REACT_APP_DOCLOCKER_HOST}`;
            window.open(`${baseURL}/mylockers/downloads`, "_blank", "noopener,noreferrer");
            showToast(
                "success",
                "We are working on your export. When it's ready, find it in",
                "export-success",
                true,
                "your downloads",
                () => {
                    hideToast("export-success");
                    window.open(`${baseURL}/mylockers/downloads`, "_blank", "noopener,noreferrer");
                },
                "The time it takes to export data depends on the size of the file and network traffic."
            );
            return;
        }
        showToast("error", `Something went wrong`);
    };

export default requestSlice.reducer;
