import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import {
    AuiSessionTimeout,
    AuiFooter,
    AuiIeBanner,
    AuiHeader,
    AuiSupernav,
    SToasts,
    SLoader,
} from "@avalara/skylab-react";
import { getSessionAsync, selectSession, selectSkylabHeader } from "../../app/sessionSlice";
import { fetchSettingsAPI } from "../../app/settingsSlice";
import { getCertificateStatusesAsync } from "../../app/certificateSlice";
import Navigation from "../navigation/Navigation";
import Provision from "../provision/Provision";
import ErrorBoundary from "../errorHandling/errorBoundary";
import { isNoAccessUser, getSessionAccessToken } from "../../shared/sessionUtility";
import { isOneWebDomainRuntime, retainCriticalData, urlToPageMap } from "../../shared/Utils";
import { subscription } from "../navigation/Subscription";
import zoominWidget from "../../shared/zoominWidget";
import { avataxAPUrls } from "../../shared/constants";

function Application() {
    const dispatch = useDispatch();
    const session = useSelector(selectSession, shallowEqual);
    const header = useSelector(selectSkylabHeader, shallowEqual);
    const [hideNav, setHideNav] = useState(false);
    const isNoAccessUserFlag = dispatch(isNoAccessUser());
    const currentLocation = window.location.href;
    const isRetailView =
        !currentLocation.includes(urlToPageMap.settings) &&
        currentLocation.includes(urlToPageMap.retail);

    useEffect(() => {
        if (!currentLocation.includes("/health-check")) dispatch(getSessionAsync());
        const documentHeader = document.querySelector("aui-header");
        if (documentHeader) {
            document.querySelector("aui-header").addEventListener("click", event => {
                if (event.target.text === "Sign out") {
                    retainCriticalData();
                }
            });
        }
    }, [dispatch]);

    useEffect(() => {
        if (session.activeCompany.id && session.provisionStatus === "Provisioned") {
            dispatch(fetchSettingsAPI());
            dispatch(getCertificateStatusesAsync());
        }
        if (session.activeCompany.name) {
            document.title = `Avatax | ${session.activeCompany.name}`;
        }
    }, [
        dispatch,
        session.activeCompany.id,
        session.activeCompany.name,
        session.provisionStatus,
        session.subscriptions,
    ]);

    const setActiveMenuTab = currentURL => {
        const isMatch = avataxAPUrls.some(url => currentURL.includes(url));
        if (session.subscriptions.includes(subscription.AVATAXAP) && isMatch) {
            document.querySelector("aui-supernav")?.setAttribute("active", "aui-supernav.settings");
        } else {
            document
                .querySelector("aui-supernav")
                ?.setAttribute("active", "aui-supernav.exemptions");
        }
    };

    useEffect(() => {
        setActiveMenuTab(window.location.pathname);
        if (process.env.REACT_APP_ENV !== "local" && session.isAuthenticated) {
            zoominWidget(session);
        }
    }, [session.isAuthenticated]);

    useEffect(() => {
        const ipuaPanel = document.querySelector("ipua-panel");
        if (ipuaPanel && session.accessToken !== null) {
            ipuaPanel.getaccesstoken = getSessionAccessToken;
        }
    }, [session.accessToken]);

    const logout = () => {
        retainCriticalData();
        const baseURL =
            process.env.REACT_APP_ENV === "local"
                ? `http://${process.env.REACT_APP_API_HOST}`
                : `https://${process.env.REACT_APP_API_HOST}`;
        window.location = `${baseURL}/v3/auth/logout`;
    };

    let appDOM = null;
    if (session.isLoading && !session.accountId) {
        appDOM = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h1>Loading ...</h1>
                <SLoader id="page-loader" className="large" aria-live="polite" loading />
            </div>
        );
    } else if (
        !session.isLoading &&
        ((session.accountId && (session.provisionStatus === "Provisioned" || isNoAccessUserFlag)) ||
            currentLocation.includes("health-check"))
    ) {
        appDOM = (
            <div
                className={`flex dl-flex-fill-height ${
                    isRetailView || !hideNav ? "margin-top-lg dl-content" : ""
                }`}>
                <Navigation setHideNav={setHideNav} />
            </div>
        );
    } else if (session.accountId) {
        appDOM = (
            <div
                className={`flex dl-flex-fill-height ${
                    !hideNav ? "margin-top-lg dl-content" : ""
                }`}>
                <Provision />
            </div>
        );
    }

    if (isOneWebDomainRuntime()) {
        return (
            <Router>
                <ErrorBoundary>{appDOM}</ErrorBoundary>
            </Router>
        );
    }

    return (
        <Router>
            <AuiIeBanner />
            <AuiHeader
                appKey={header.appKey}
                user={header.user}
                env={header.env}
                logouturl={header.logoutUrl}
                onS-navigate={e => retainCriticalData(e)}
                integrateHelp={isRetailView ? "false" : "true"}
                requestCurrentAccessToken={getSessionAccessToken}
                className={
                    isRetailView
                        ? "hide-account-products-usage hide-account-support-cases hide-account-billing hide-account-change-password hide-account-id hide-account-my-downloads"
                        : ""
                }
                showchatbutton={!isRetailView}
            />
            {session.accessToken && !hideNav ? (
                <React.Fragment>
                    <AuiSupernav requestCurrentAccessToken={getSessionAccessToken} />
                    <AuiSessionTimeout onSTimeout={() => logout()} />
                </React.Fragment>
            ) : null}
            <ErrorBoundary>{appDOM}</ErrorBoundary>
            <SToasts role="status" />
            <AuiFooter user={header.user} />
            {session.accessToken !== null ? (
                <ipua-panel product="ECM" url={process.env.REACT_APP_IDENTITY_HOST} />
            ) : null}
        </Router>
    );
}

export default React.memo(Application);
