import React, { useEffect, useState } from "react";
import { SSelect, SRow, SCol, SIcon } from "@avalara/skylab-react";
import axios from "../../axios";
import { fixSearchStringForPostgres } from "../../shared/Utils";

const CustomerTabTypeAhead = React.memo(props => {
    const [customers, setCustomers] = useState([]);

    const fetchTypeAhead = async value => {
        const fixedValue = fixSearchStringForPostgres(value);

        const typeAheadURL = !props.sameas
            ? `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customers/typeahead?$filter=name contains '${fixedValue}' or customerNumber contains '${fixedValue}'  &$orderBy=name ASC&$top=10`
            : `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customers/${props.customerId}/not-same-as-customers/${props.sameAsCompany}?$filter=name contains '${fixedValue}' or customerNumber contains '${fixedValue}'  &$orderBy=name ASC&$top=10`;

        const response = await axios.get(typeAheadURL, {
            withCredentials: true,
        });

        if (props.sameas) {
            return response.data.value;
        }
        return response.data;
    };

    useEffect(() => {
        const asyncSelect = document.getElementById("async-customer-tab-select");
        function customerLink(event) {
            if (
                event &&
                event.path &&
                event.path.length >= 1 &&
                event.path[1].nodeName === "S-CHIP"
            ) {
                const idRegex = event.path[1].id.match(/-(.*)-/);
                if (idRegex && idRegex.length >= 1) {
                    const customerId = parseInt(idRegex[1], 10);
                    const idIndex = customers.findIndex(element => element.id === customerId);
                    if (idIndex !== -1) {
                        window.open(`${window.location.origin}/customer/${customerId}`, "_blank");
                    }
                }
            }
        }
        asyncSelect.addEventListener("click", customerLink);
        return () => {
            asyncSelect.removeEventListener("click", customerLink);
        };
    }, [JSON.stringify(customers)]); // eslint-disable-line

    useEffect(() => {
        const asyncSelect = document.getElementById("async-customer-tab-select");
        if (props?.value && (!customers?.length || props?.value?.length > customers?.length)) {
            setCustomers(props.value);
            let selectedResults = {};
            selectedResults = props.value.map(element => {
                return {
                    label: `${element.name ? element.name : element.customerName} : ${
                        element.customerNumber
                    }`,
                    value: `${element.id}`,
                    selected: true,
                };
            });
            asyncSelect.optionsList = selectedResults;
        }
    }, [props.value, customers.length]); // eslint-disable-line

    useEffect(() => {
        const uniqueCustomer = (prevCustomer, newCustomer) => {
            if (!prevCustomer.length) return newCustomer;
            if (!newCustomer.length) return prevCustomer;

            const prevCustomerIds = prevCustomer.map(ele => ele.id);
            return [
                ...prevCustomer,
                ...newCustomer.filter(cust => prevCustomerIds.indexOf(cust.id) === -1),
            ];
        };

        async function typeAhead(e) {
            const asyncSelect = document.getElementById("async-customer-tab-select");
            let typeAheadResults = {};
            if (
                e.detail.inputValue !== "" &&
                e.detail.inputValue.length > 1 &&
                !asyncSelect.getAttribute("loading")
            ) {
                asyncSelect.setAttribute("loading", "");
                const results = await fetchTypeAhead(e.detail.inputValue);

                if (results) {
                    typeAheadResults = results.map(element => {
                        return {
                            label: `${element.name ? element.name : element.customerName} : ${
                                element.customerNumber
                            }`,
                            value: `${element.id}`,
                        };
                    });

                    asyncSelect.optionsList = typeAheadResults;
                    setCustomers(uniqueCustomer(customers, results));
                }
                asyncSelect.removeAttribute("loading");
            }
        }

        const asyncSelect = document.getElementById("async-customer-tab-select");
        asyncSelect.addEventListener("s-input", typeAhead);
        return () => {
            asyncSelect.removeEventListener("s-input", typeAhead);
        };
    }, [JSON.stringify(customers), props.sameAsCompany]); // eslint-disable-line

    return (
        <SRow>
            <SCol>
                <label id="lbl-async-select" htmlFor="async-select-input" className="required">
                    {props?.typeAheadlabel}
                </label>
                <SSelect
                    id="async-customer-tab-select"
                    ref={props?.selectRef}
                    inputid="async-select-input"
                    aria-labelledby="lbl-async-select"
                    className={props.error ? props.error : "text-as-link"}
                    async
                    selectionOptional
                    placeholder="Search customers..."
                    onS-select={e => {
                        props.handleAdd(
                            customers.filter(cust => cust.id == e.detail.item.value)
                        ); /* eslint eqeqeq: 0 */
                    }}
                    onS-deselect={e => {
                        props.handleRemove(e.detail.item);
                    }}
                />
                <div className="input-msg" hidden={!props?.customerError}>
                    <SIcon name="alert-circle-filled" aria-hidden="true" />
                    <span className="top-xs">Enter a customer</span>
                </div>
            </SCol>
        </SRow>
    );
});

export default CustomerTabTypeAhead;
