import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import {
    getCertificateSources,
    selectCertificateSources,
    selectIsLoadingCertificateSources,
    setCertificateSources,
} from "../../app/certificateSlice";

function SelectCertificateSources(props) {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingCertificateSources);
    const optionsList = useSelector(selectCertificateSources, shallowEqual).map(element => {
        const selectOption = {
            label: element.displayName,
            value: element.id,
            selected: props?.value === element.id,
        };
        return selectOption;
    });

    useEffect(() => {
        dispatch(getCertificateSources());
        return () => {
            dispatch(setCertificateSources([]));
        };
    }, []);

    return (
        <React.Fragment>
            <label
                htmlFor="select-certificate-sources"
                className="margin-top-sm"
                id="lbl-certificate-sources">
                Certificate sources
            </label>
            <SSelect
                inputId="select-certificate-sources"
                selectionOptional
                loading={isLoading}
                optionsList={optionsList}
                onS-select={e => {
                    props.onAdd(e.detail.item);
                }}
                onS-deselect={e =>
                    props?.onRemove({ value: parseInt(e.detail.item, 10) }, e.detail.isDeselectAll)
                }
                disabled={props.disabled}
                value={props?.value}
            />
        </React.Fragment>
    );
}

export default SelectCertificateSources;
