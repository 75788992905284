import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { SDialog, SRow, SCol, SIcon, SLoader } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";
import {
    selectIsEmailAddressDialogOpen,
    addFaxEmailAddress,
    setIsEmailAddressDialogOpen,
} from "../../app/certificateSlice";
import { selectSetting, selectIsLoading } from "../../app/settingsSlice";
import toast from "../../hooks/toast";

function EmailAddressDialog(props) {
    const dispatch = useDispatch();
    const isDeletingComment = useSelector(selectIsEmailAddressDialogOpen);
    const settings = useSelector(selectSetting, shallowEqual);
    const isLoading = useSelector(selectIsLoading);
    const [replyAddressesList, setReplyAddressesList] = useState([]);
    const [getCurrentId] = useId();
    const deleteButton = classnames({ primary: true, small: true, loading: false });
    const [showToast] = toast();

    // eslint-disable-next-line no-shadow
    function EmailAddressList(props) {
        const { emails } = props;
        const listItems = emails.map(email => (
            <div>
                <input
                    id={getCurrentId()}
                    type="text"
                    name="email"
                    value={email.value}
                    className="margin-bottom-sm"
                />
            </div>
        ));
        return <ul id="email_list">{listItems}</ul>;
    }
    useEffect(() => {
        let replyAddresses = {};

        replyAddresses = settings.clientsFaxEmails.map(element => {
            const selectOption = {
                label: element.faxEmailAddress,
                value: element.faxEmailAddress,
            };
            return selectOption;
        });
        setReplyAddressesList(replyAddresses);
        // }
    }, [settings]);

    function addEmailInput() {
        const emailList = document.getElementById("email_list");
        const newDiv = document.createElement("div");
        newDiv.innerHTML = '<input id="new_fax_email" type="text" name="email" />';
        newDiv.classList.add("margin-bottom-sm");
        emailList.prepend(newDiv);
        document.getElementById("email_input_add").style.display = "none";
    }

    async function saveEmailReader() {
        const newFaxEmail = document.getElementById("new_fax_email");
        if (newFaxEmail && newFaxEmail.value) {
            const payload = {
                FaxEmailAddress: newFaxEmail.value,
            };
            await dispatch(addFaxEmailAddress(payload, showToast));
        }
        dispatch(setIsEmailAddressDialogOpen(false));
    }

    let certificateRequestDOM = null;
    if (isLoading) {
        certificateRequestDOM = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h1>Loading ...</h1>
                <SLoader loading="true" />
            </div>
        );
    } else {
        certificateRequestDOM = (
            <div>
                <span className="block">
                    Your customers can send certificates to these addresses as email attachments.
                    <br /> ECM retrieves the attached PDF file and uploads it to the system for
                    review or <br /> automatic validation, depending on your company settings
                </span>
                <br />
                <button
                    id="email_input_add"
                    className="top-sm ghost link"
                    onClick={() => addEmailInput()}>
                    <SIcon name="plus" className="pad-right-xs" />
                    Add
                </button>
                <EmailAddressList emails={replyAddressesList} />
            </div>
        );
    }
    return (
        <SDialog
            id="EmailAddressDialog"
            open={isDeletingComment}
            onSDismiss={() => {
                props.setIsEmailAddressDialogOpen(false);
            }}
            aria-labelledby="dialog-title"
            aria-modal="true">
            <div slot="header">Manage certificate uploader addresses</div>
            <div slot="body">
                <SRow>
                    <SCol span="auto">{certificateRequestDOM}</SCol>
                </SRow>
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    disabled={isDeletingComment}
                    onClick={() => props.setIsEmailAddressDialogOpen(false)}>
                    Cancel
                </button>
                <button className={deleteButton} type="button" onClick={() => saveEmailReader()}>
                    Save
                </button>
            </div>
        </SDialog>
    );
}

export default EmailAddressDialog;
