import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SRow, SCol, SIcon, SBox, SLoader } from "@avalara/skylab-react";
import { selectSession } from "../../app/sessionSlice";
import { isEligibleUser } from "../../shared/sessionUtility";
import { getFormattedDate } from "../../shared/Utils";
import {
    getSupplementalFiles,
    selectSupplementalFiles,
    selectIsLoadingSupplementalFiles,
    deleteSupplementalFile,
} from "../../app/certificateSlice";
import PreviewSupplFileDialogue from "../sharedDialogs/PreviewSupplFileDialogue";
import toast from "../../hooks/toast";
import featureFlag from "../../featureToggler/featureFlag";

function SupplementalFiles(props) {
    const dispatch = useDispatch();
    const { certificateID } = props;
    const isLoading = useSelector(selectIsLoadingSupplementalFiles);
    const supplementalFiles = useSelector(selectSupplementalFiles);
    const session = useSelector(selectSession);
    const [hideSupplementalFiles, toggleHideSupplementalFiles] = useState(true);
    const [isPreviewSupplementalDialogOpen, setIsPreviewSupplementalDialogOpen] = useState(false);
    const [currFile, setCurrFile] = useState(null);
    const [deletingFileId, setDeletingFileId] = useState("");
    const [showToast] = toast();
    const isEligibleUserAttachmentsFlag = dispatch(
        isEligibleUser(
            props.onReview
                ? featureFlag.reviewCertificate.attachments
                : featureFlag.validatedCertificate.attachments
        )
    );

    useEffect(() => {
        if (session.activeCompany.id !== null) {
            dispatch(getSupplementalFiles(certificateID));
        }
    }, [dispatch]); // eslint-disable-line

    const toggleSupplementalFiles = () => {
        toggleHideSupplementalFiles(!hideSupplementalFiles);
    };
    const deleteFile = async fileId => {
        setDeletingFileId(fileId);
        await dispatch(deleteSupplementalFile(certificateID, fileId, showToast));
        setDeletingFileId("");
    };
    const openPreviewButton = file => {
        setCurrFile(file);
        setIsPreviewSupplementalDialogOpen(true);
    };
    return (
        <>
            <SRow className="margin-top-md">
                <SCol span="8">
                    {isLoading && (
                        <SCol className="font-semibold">
                            <span className="flex dl-flex-fill-height dl-flex-right">
                                Loading Attachments...
                                <br />
                                <span className="pad-top-none margin-top-none padding-left-sm">
                                    <SLoader loading="true" aria-live="polite" />
                                </span>
                            </span>
                        </SCol>
                    )}
                    {!isLoading && supplementalFiles.length > 0 && (
                        <button
                            className="link font-bold"
                            onClick={() => toggleSupplementalFiles()}>
                            {supplementalFiles.length}{" "}
                            {supplementalFiles.length === 1 ? "attachment" : "attachments"}
                            <SIcon
                                name={!hideSupplementalFiles ? "chevron-up" : "chevron-down"}
                                className="pad-left-xs top-xs"
                                aria-hidden="true"
                            />
                        </button>
                    )}
                </SCol>

                <SCol hidden={hideSupplementalFiles} className="margin-top-sm">
                    {supplementalFiles.length > 0 ? (
                        <SBox className="pad-all-md margin-bottom-lg">
                            <span>
                                {supplementalFiles &&
                                    supplementalFiles.map((file, index) => (
                                        <div key={file.id} className="pad-top-xs">
                                            <SRow>
                                                <SCol span="3">
                                                    {file.entry.length <= 15
                                                        ? file.entry
                                                        : `${file.entry.slice(0, 12)}...`}
                                                </SCol>
                                                <SCol span="5">
                                                    {getFormattedDate(file.created)} by{" "}
                                                    {file.accountName}
                                                </SCol>
                                                <SCol span="4" className="flex justify-content-end">
                                                    {!(deletingFileId === file.id) ? (
                                                        <>
                                                            {!Number.isNaN(certificateID) ? (
                                                                <>
                                                                    <button
                                                                        className="top-sm ghost link"
                                                                        disabled={Number.isNaN(
                                                                            certificateID
                                                                        )}
                                                                        onClick={() =>
                                                                            openPreviewButton(file)
                                                                        }>
                                                                        <SIcon
                                                                            name="zoom-in"
                                                                            aria-label="zoom-in"
                                                                        />
                                                                    </button>
                                                                    <span className="top-sm pad-right-md" />
                                                                    <a
                                                                        className="top-sm pad-left-xs"
                                                                        href={`//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/companies/${session.activeCompany.id}/certificates/${certificateID}/certificate-comments/${file.id}/file`}>
                                                                        <SIcon
                                                                            name="download"
                                                                            aria-label="download"
                                                                        />
                                                                    </a>
                                                                    <span className="top-sm pad-right-md" />
                                                                </>
                                                            ) : null}
                                                            <button
                                                                className="top-sm pad-left-xs ghost link"
                                                                disabled={
                                                                    !isEligibleUserAttachmentsFlag
                                                                }
                                                                onClick={() => deleteFile(file.id)}>
                                                                <SIcon
                                                                    name="trash"
                                                                    aria-label="delete"
                                                                />
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <span className="right pad-right-xl">
                                                            <SLoader
                                                                loading="true"
                                                                aria-live="polite"
                                                            />
                                                        </span>
                                                    )}
                                                </SCol>
                                            </SRow>

                                            {index !== supplementalFiles.length - 1 && (
                                                <hr className="margin-top-xs margin-bottom-xs" />
                                            )}
                                        </div>
                                    ))}
                            </span>
                        </SBox>
                    ) : null}
                </SCol>
            </SRow>
            {currFile && (
                <PreviewSupplFileDialogue
                    isPreviewSupplementalDialogOpen={isPreviewSupplementalDialogOpen}
                    setIsPreviewSupplementalDialogOpen={setIsPreviewSupplementalDialogOpen}
                    currFile={currFile}
                    certificateID={certificateID}
                />
            )}
        </>
    );
}

export default SupplementalFiles;
