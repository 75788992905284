import axios from "../axios";
import { validateEmail } from "./Utils";
import { Canada, UnitedStates } from "./constants";

export const postCustomerAPI = customerData => {
    return axios.post(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customers`,
        customerData,
        {
            withCredentials: true,
        }
    );
};

export const putCustomerAPI = async (customerData, customer) => {
    return axios.put(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customers/${customer.id}`,
        customerData,
        {
            withCredentials: true,
        }
    );
};

export const addCustomersShipToState = async (
    customerId,
    shipStates,
    documentDescription,
    vendorCheck
) => {
    const requestBody = {
        stateIds: shipStates,
        documentTypeName: documentDescription,
        outgoing: vendorCheck,
    };
    return axios.post(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customers/${customerId}/ship-to-states`,
        requestBody,
        {
            withCredentials: true,
        }
    );
};

export const removeCustomersShipToState = async (
    customerId,
    shipStates,
    documentDescription,
    vendorCheck
) => {
    const requestBody = {
        stateIds: shipStates,
        documentTypeName: documentDescription,
        outgoing: vendorCheck,
    };
    return axios.delete(
        `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/customers/${customerId}/ship-to-states`,
        {
            withCredentials: true,
            data: requestBody,
        }
    );
};

export const checkValidation = data => {
    let valid = true;
    const errorFields = {
        email: false,
        customerCode: false,
        customerName: false,
        country: false,
        addressLine1: {
            addressLine1Empty: false,
            addressLine1Length: false,
        },
        city: false,
        state: false,
        zipCode: {
            required: false,
            US: false,
            Canada: false,
        },
    };
    if (data.emailAddress && !validateEmail(data.emailAddress)) {
        errorFields.email = true;
        valid = false;
    }
    if (!data.customerCode || data.customerCode.trim()?.length <= 0) {
        errorFields.customerCode = true;
        valid = false;
    }
    if (!data.customerName || data.customerName.trim()?.length <= 0) {
        errorFields.customerName = true;
        valid = false;
    }
    if (!data.countryId && !data.defaultCountryId) {
        errorFields.country = true;
        valid = false;
    }
    if (!data.addressLine1 || data.addressLine1.trim()?.length <= 0) {
        errorFields.addressLine1.addressLine1Empty = true;
        valid = false;
    } else if (data.addressLine1?.length > 50) {
        errorFields.addressLine1.addressLine1Length = true;
        valid = false;
    }

    if (!data.city || data.city.trim()?.length <= 0) {
        errorFields.city = true;
        valid = false;
    }
    if (!data.state) {
        errorFields.state = true;
        valid = false;
    }
    if (!data.zipCode || data.zipCode.trim()?.length <= 0) {
        errorFields.zipCode.required = true;
        valid = false;
    }
    if (data.zipCode) {
        if (
            data.countryName === UnitedStates &&
            (data.zipCode.length < 5 || data.zipCode.length > 10)
        ) {
            errorFields.zipCode[UnitedStates] = true;
            valid = false;
        }
        if (data.countryName === Canada && (data.zipCode.length < 6 || data.zipCode.length > 10)) {
            errorFields.zipCode[Canada] = true;
            valid = false;
        }
    }
    return { valid, errorFields };
};
