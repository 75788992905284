import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, gridHeader } from "@certcapture/react-components";
import { SLoader, SPagination, SRowActions, SIcon } from "@avalara/skylab-react";
import { v4 as uuidv4 } from "uuid";
import {
    fetchOutgoingCoverLettersByPagingAPI,
    selectPreviewLoading,
    setPageToNull,
    selectPage,
    selectSortColumn,
    setSortColumn,
} from "../../app/coverLetterSlice";
import DeleteCoverLetterDialog from "./dialogs/DeleteCoverLetterDialog";
import PreviewCoverLetterDialog from "./dialogs/PreviewCoverLetterDialog";
import featureFlag from "../../featureToggler/featureFlag";
import { isEligibleUser } from "../../shared/sessionUtility";
import RecordCount from "../search/RecordCount";

function VendorLetterGrid() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector(selectPreviewLoading);
    const [submitting, setSubmitting] = useState(false);
    const page = useSelector(selectPage, shallowEqual);
    const pageData = page?.data;
    const paginateData = page.paginate;
    const [currentRow, setCurrentRow] = useState({});
    const sortColumn = useSelector(selectSortColumn, shallowEqual);
    const [isDeleteCoverLetterDialogOpen, setIsDeleteCoverLetterDialogOpen] = useState(false);
    const [isPreviewCoverLetterDialogOpen, setIsPreviewCoverLetterDialogOpen] = useState(false);
    const isEligibleECMUserCoverLetterFlag = dispatch(
        isEligibleUser(featureFlag.requestCertificates.coverLetter)
    );
    const ellipsesMenuItems = [
        {
            label: "View and edit",
            classList: ["primary", "icon-leading"],
            id: `menu-${uuidv4()}-edit`,
        },
    ];
    useEffect(() => {
        dispatch(fetchOutgoingCoverLettersByPagingAPI());
        return () => {
            dispatch(setPageToNull([]));
        };
    }, [dispatch]);

    async function sortFunction(e) {
        setSubmitting(true);
        const column = e.detail.id;
        const order = sortColumn[0] === column ? !sortColumn[1] : true;
        dispatch(setSortColumn([column, order]));
        dispatch(fetchOutgoingCoverLettersByPagingAPI());
        setSubmitting(false);
    }

    const openCoverLetterPreviewModal = row => {
        setCurrentRow(row);
        setIsPreviewCoverLetterDialogOpen(true);
    };

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            dispatch(
                fetchOutgoingCoverLettersByPagingAPI(
                    e.detail.rowsPerPage,
                    e.detail.startIndex,
                    false,
                    e.detail
                )
            );
            setSubmitting(false);
        }
    };

    const columns = [
        gridHeader(
            "Template name",
            "title",
            (value, row) => (
                <Link className="font-semibold customer-link" to={`/vendor-letter/${row.id}`}>
                    {row.title}
                </Link>
            ),
            null,
            null,
            null,
            null,
            sortFunction,
            "title"
        ),
        gridHeader("Subject line", "subject", value => value, null, "", null),
        gridHeader("Description", "description", value => value, null, "", null),
        gridHeader(
            "Last modified",
            "modified",
            value => (value ? new Date(value).toLocaleDateString() : ""),
            null,
            "",
            null
        ),
        gridHeader(
            "Preview",
            "id",
            (value, row) => (
                <button className="link font-bold" onClick={() => openCoverLetterPreviewModal(row)}>
                    <SIcon name="zoom-in" className="blue-icon" aria-hidden="true" />
                </button>
            ),
            "text-center",
            "text-center",
            null
        ),
        gridHeader(
            "",
            "",
            (value, row) => (
                <SRowActions
                    key={uuidv4()}
                    menuId={`menu-${uuidv4()}`}
                    className="row-actions"
                    actionItems={
                        row.isSystem
                            ? ellipsesMenuItems
                            : [
                                  ...ellipsesMenuItems,
                                  {
                                      label: "Delete the template",
                                      classList: isEligibleECMUserCoverLetterFlag
                                          ? ["primary"]
                                          : ["primary", "hidden"],
                                      id: `menu-${uuidv4()}-delete`,
                                  },
                              ]
                    }
                    collapsed
                    onS-select={e => {
                        setCurrentRow(row);
                        if (e.detail.id.includes("delete")) setIsDeleteCoverLetterDialogOpen(true);
                        if (e.detail.id.includes("edit")) navigate(`/vendor-letter/${row.id}`);
                    }}
                />
            ),
            "text-left",
            "text-left"
        ),
    ];

    const getRowKey = () => {
        return uuidv4();
    };

    const table =
        submitting || loading ? (
            <div className="flex flex-dir-col dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="grid-loader" class="medium" aria-live="polite" loading />
            </div>
        ) : (
            <div className="margin-top-sm">
                <RecordCount
                    selectedCount={0}
                    recordCount={paginateData.totalRecords}
                    recordName="Vendor templates"
                />
                <s-table-container key={uuidv4()}>
                    <DataGrid
                        columns={columns}
                        rows={pageData}
                        getKey={getRowKey}
                        loading={loading}
                        inContainer
                        sortColumn={sortColumn}
                    />
                </s-table-container>
            </div>
        );

    return (
        <React.Fragment>
            {table}
            {paginateData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={paginateData.rowsPerPage}
                    totalRecords={paginateData.totalRecords}
                    startIndex={paginateData.startIndex}
                    onS-paginate={e => {
                        handlePaginate(e);
                    }}
                />
            ) : null}

            {isDeleteCoverLetterDialogOpen ? (
                <DeleteCoverLetterDialog
                    isDeleteCoverLetterDialogOpen={isDeleteCoverLetterDialogOpen}
                    setIsDeleteCoverLetterDialogOpen={setIsDeleteCoverLetterDialogOpen}
                    coverLetter={currentRow}
                    isVendor
                />
            ) : null}
            {isPreviewCoverLetterDialogOpen ? (
                <PreviewCoverLetterDialog
                    isPreviewCoverLetterDialogOpen={isPreviewCoverLetterDialogOpen}
                    setIsPreviewCoverLetterDialogOpen={setIsPreviewCoverLetterDialogOpen}
                    coverLetter={currentRow}
                />
            ) : null}
        </React.Fragment>
    );
}

export default VendorLetterGrid;
