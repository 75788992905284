import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import {
    getCertificateLabels,
    selectCertificateLabels,
    selectIsLoadingCertificateLabels,
    setCertificateLabels,
} from "../../app/certificateSlice";

function SelectCertificateLabels(props) {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingCertificateLabels);
    const optionsList = useSelector(selectCertificateLabels, shallowEqual).map(element => {
        const selectOption = {
            label: element.name,
            value: element.id,
            selected: false,
        };
        return selectOption;
    });

    useEffect(() => {
        dispatch(getCertificateLabels());
        return () => {
            dispatch(setCertificateLabels([]));
        };
    }, []);

    const certificateLabelOptions = optionsList.map(element => {
        if (props.value?.includes(parseInt(element.value, 10))) {
            return { ...element, selected: true };
        }
        return element;
    });

    return (
        <React.Fragment>
            <label
                htmlFor="SelectCertificatelabel"
                className="margin-top-sm"
                id="lbl-certificateLabel">
                Certificate labels
            </label>
            <SSelect
                inputId="SelectCertificatelabel"
                multiple
                selectionOptional
                loading={isLoading}
                optionsList={certificateLabelOptions}
                value={[...props.value.map(certificateLabelId => `${certificateLabelId}`)]}
                onS-select={e => props?.onAdd({ value: parseInt(e.detail.item.value, 10) })}
                onS-deselect={e =>
                    props?.onRemove(
                        { value: parseInt(e.detail.item.value, 10) },
                        e.detail.isDeselectAll
                    )
                }
                onS-close={e => (props.onClose ? props.onClose(e) : null)}
                disabled={props.isLoading}
            />
        </React.Fragment>
    );
}

export default React.forwardRef(SelectCertificateLabels);
