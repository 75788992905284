import React, { useEffect, useState, useRef } from "react";
import { SDialog, SRow, SCol, SSelect, SLoader } from "@avalara/skylab-react";
import classnames from "classnames";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { forIn, includes, isEmpty, findKey, cloneDeep, has } from "lodash";
import { selectIsLoading } from "../../../../app/contentSlice";
import { isEligibleUser } from "../../../../shared/sessionUtility";
import toast from "../../../../hooks/toast";

import {
    fetchCollectionRegionsAPI,
    selectCollectionRegions,
    selectLoadingCollectionRegions,
    selectCollectionInfo,
} from "../../../../app/settingsSlice";
import SelectExemptionReasons from "../../../sharedComponents/SelectExemptionReasons";
import { selectExposureZones, getExposureZonesAsync } from "../../../../app/certificateSlice";
import axios from "../../../../axios";
import featureFlag from "../../../../featureToggler/featureFlag";

function RequestingCollectionDialog(props) {
    const dispatch = useDispatch();
    const loading = useSelector(selectIsLoading);
    const saveClassName = classnames({ primary: true, small: true, loading: false });
    const isCollectionLoading = useSelector(selectLoadingCollectionRegions);
    const collectionRegionList = useSelector(selectCollectionRegions, shallowEqual);
    const availableRegionList = useSelector(selectExposureZones, shallowEqual);
    const collectionInfo = useSelector(selectCollectionInfo, shallowEqual);
    const [deletedRegions, setDeletedRegions] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [regionList, setRegionList] = useState();
    const [exemptReasonName, setExemptReasonName] = useState("");
    const [exemptReasonId, setExemptReasonId] = useState("");
    const [regionSelected, setRegionSelected] = useState(false);
    const mulRef = useRef([]);
    const selRef = useRef([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [showToast] = toast();
    const [isUpdated, setIsUpdated] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const toastShown = useRef(false);

    const isEligibleUserSettingsRequestingCertficateExemptionMatrix = dispatch(
        isEligibleUser(featureFlag.settings.requestingCertficateExemptionMatrix)
    );

    useEffect(() => {
        dispatch(
            getExposureZonesAsync(
                `?$filter=documentType.name eq 'Sales and Use Tax' AND documentType.outgoing eq false`
            )
        );
    }, [dispatch]);

    useEffect(() => {
        if (!isEmpty(selectedRegions) || !isEmpty(deletedRegions)) {
            setIsUpdate(true);
        } else {
            setIsUpdate(false);
        }
    }, [selectedRegions, deletedRegions]);

    const deleteCollectionAsync = regionData => {
        setIsProcessing(true);
        forIn(regionData, async (value, key) => {
            await axios
                .delete(
                    `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/exemption-matrix/delete-collection/${regionData[key].collectionId}`,
                    {
                        withCredentials: true,
                    }
                )
                .then(() => {
                    setIsUpdated(true);
                    setIsProcessing(false);
                });
        });
    };
    const addCollectionAsync = () => {
        setIsProcessing(true);
        forIn(selectedRegions, async (value, key) => {
            await axios
                .post(
                    `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/exemption-matrix/defaults`,
                    {
                        zoneName: selectedRegions[key].regionName,
                        reasonName: exemptReasonName,
                    },
                    {
                        withCredentials: true,
                    }
                )
                .then(async response => {
                    const hasDefaults = !isEmpty(response.data.value);
                    const sendData = {
                        TaxCodeId: exemptReasonId,
                        ExposureZoneId: selectedRegions[key].regionId,
                        TemplateTag: hasDefaults ? response.data.value[0].templateTag : "NONE",
                        TimeLength: hasDefaults ? response.data.value[0].timeLength : "NVR",
                        Question: "",
                        SetToDefault: false,
                    };

                    await axios
                        .post(
                            `//${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION3}/exemption-matrix/add-collection`,
                            sendData,
                            {
                                withCredentials: true,
                            }
                        )
                        .then(() => {
                            setIsUpdated(true);
                            setIsProcessing(false);
                        });
                });
        });
    };

    const updateSelection = () => {
        const container = availableRegionList.map(region => {
            return {
                label: region.name,
                value: region.id,
                selected: includes(collectionRegionList, region.name),
            };
        });
        setRegionList(container);
    };
    const cancelDialog = () => {
        setIsUpdate(false);
        setRegionSelected(false);
        setSelectedRegions([]);
        setDeletedRegions([]);
        setRegionList([]);
        setExemptReasonId(null);
        setExemptReasonName(null);
        selRef.current.deselectAll();
        props.setOpenDialog(false);
    };

    const saveHandler = async () => {
        if (!isEmpty(selectedRegions)) {
            addCollectionAsync();
        }
        if (!isEmpty(deletedRegions)) {
            deleteCollectionAsync(deletedRegions, true);
        }
        cancelDialog();
        props.setOpenDialog(false);
    };

    const handleChangeInput = async setter => {
        toastShown.current = false;
        setExemptReasonId(setter.value);
        setExemptReasonName(setter.label);
        setRegionSelected(true);
        await dispatch(fetchCollectionRegionsAPI(false, setter.label, true));
    };

    useEffect(() => {
        updateSelection();
    }, [collectionRegionList, availableRegionList, regionSelected]);

    useEffect(() => {
        setIsUpdated(isUpdated);
        if (isUpdated && !isProcessing && !toastShown.current) {
            showToast("success", "Exemption matrix has been updated successfully");
            toastShown.current = true;
            props.reload();
        }
    }, [isUpdated]);

    useEffect(() => {
        if (isUpdated) {
            setIsUpdated(false);
        }
    }, [isProcessing]);

    const handleRemove = () => {
        setSelectedRegions([]);
        setRegionSelected(false);
        setIsUpdate(false);
    };

    const handleRemoveRegions = rer => {
        const clone = cloneDeep(selectedRegions);
        if (regionSelected && exemptReasonName) {
            toastShown.current = false;
            forIn(collectionInfo, (val, key) => {
                if (collectionInfo[key].regionName === rer?.item.label) {
                    setDeletedRegions(tvar => [
                        ...tvar,
                        {
                            collectionId: collectionInfo[key].collectionId,
                            regionName: rer?.item.label,
                            reasonName: exemptReasonName,
                        },
                    ]);
                } else if (
                    !isEmpty(selectedRegions) &&
                    has(selectedRegions, { regionName: rer.item.label })
                ) {
                    const idx = findKey(clone, { regionName: rer.item.label });
                    clone.splice(idx, 1);
                    setSelectedRegions(clone);
                }
            });
            if (isEmpty(collectionInfo)) {
                if (rer.isDeselectAll) {
                    setSelectedRegions([]);
                } else {
                    const idx = findKey(clone, { regionName: rer.item.label });
                    clone.splice(idx, 1);
                    setSelectedRegions(clone);
                }
            }
            setIsUpdate(true);
        }
    };
    const handleAddRegions = aer => {
        if (regionSelected && exemptReasonName) {
            toastShown.current = false;
            setIsUpdate(true);
            setSelectedRegions(tvar => [
                ...tvar,
                { regionId: aer?.item.value, regionName: aer?.item.label },
            ]);
        }
    };

    return (
        <div>
            <SDialog
                open={props.diagStatus}
                noDismiss
                id="collections-dialog"
                className="collectionDialog overflow-visible"
                aria-modal="true">
                <div slot="header" id="collections">
                    Define why and where you&lsquo;re exempt
                </div>
                <div slot="body">
                    <SRow className="pad-top-sm margin-bottom-lg">
                        <SCol span="4">
                            <SelectExemptionReasons
                                ref={selRef}
                                name="collectionReason"
                                id="collectionReason"
                                onAdd={e => handleChangeInput(e)}
                                onRemove={e => handleRemove(e)}
                                multiple={false}
                                disabled={false}
                                value={exemptReasonName}
                            />
                        </SCol>

                        <SCol span="8">
                            {isCollectionLoading ? (
                                <div className="flex dl-flex-fill-height dl-flex-center">
                                    <h4>Fetching regions ...</h4>
                                    <SLoader
                                        id="page-loader"
                                        className="medium"
                                        aria-live="polite"
                                        loading
                                    />
                                </div>
                            ) : (
                                <>
                                    <label htmlFor="region-list" id="lbl-region-list">
                                        Regions where you are exempt from paying taxes
                                    </label>

                                    <SSelect
                                        inputId="region-list"
                                        className="matrix-regions"
                                        multiple
                                        ref={mulRef}
                                        optionsList={regionList}
                                        disabled={
                                            loading || isCollectionLoading || !regionSelected
                                                ? "disabled"
                                                : null
                                        }
                                        onS-select={e => handleAddRegions(e.detail)}
                                        onS-deselect={e => handleRemoveRegions(e.detail)}
                                    />
                                </>
                            )}
                        </SCol>
                    </SRow>
                </div>
                <div slot="footer">
                    <button className="secondary small" onClick={cancelDialog}>
                        Cancel
                    </button>
                    <button
                        className={saveClassName}
                        disabled={
                            !isUpdate ||
                            loading ||
                            !isEligibleUserSettingsRequestingCertficateExemptionMatrix
                        }
                        onClick={() => {
                            saveHandler();
                        }}>
                        Save
                    </button>
                </div>
            </SDialog>
        </div>
    );
}

export default RequestingCollectionDialog;
