import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import {
    getCertificateReviewers,
    selectCertificateReviewers,
    selectIsLoadingCertificateReviewers,
    setCertificateReviewers,
} from "../../app/certificateSlice";

function SelectCertificateReviewers(props) {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingCertificateReviewers);
    const [reviewedBy, setReviewedBy] = useState("");
    const optionsList = useSelector(selectCertificateReviewers, shallowEqual).map(element => {
        const selectOption = {
            label: element.uploadAccount,
            value: element.uploadAccount,
            selected: reviewedBy === element.uploadAccount,
        };
        return selectOption;
    });

    useEffect(() => {
        dispatch(getCertificateReviewers());
        return () => {
            dispatch(setCertificateReviewers([]));
        };
    }, []);

    useEffect(() => {
        props.applyFilters();
    }, [reviewedBy]);

    const handleSearch = detail => {
        const selectVal = !detail.isDeselectAll ? detail.item.value : "";
        setReviewedBy(selectVal);
        dispatch(
            props.setFilterState({
                [detail.selectInputId]: selectVal,
            })
        );
    };

    return (
        <React.Fragment>
            <label htmlFor="reviewedBy" id="lbl-reviewedby">
                Certificate reviewed by
            </label>
            <SSelect
                inputId="reviewedBy"
                optionsList={optionsList}
                value={props.value}
                onS-select={e => handleSearch(e.detail)}
                onS-deselect={e => handleSearch(e.detail)}
                selectionOptional
                disabled={isLoading ? "disabled" : null}
            />
        </React.Fragment>
    );
}

export default SelectCertificateReviewers;
