import React, { useCallback, useState } from "react";
import { SRow, SCol, SIcon, SBox, SLoader } from "@avalara/skylab-react";
import { Document, Page, pdfjs } from "react-pdf";
import { useSelector, shallowEqual } from "react-redux";
import { selectFormData, selectIsFormLoading } from "../../../app/contentSlice";

import "../taxContent.css";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

const CertificateFormsContent = React.memo(() => {
    const formData = useSelector(selectFormData, shallowEqual);
    const isFormLoading = useSelector(selectIsFormLoading);
    const [numberPages, setNumberPages] = useState(null);

    const onDocumentLoadSuccess = useCallback(({ numPages }) => {
        setNumberPages(numPages);
    }, []);

    const downloadFile = () => {
        const downloadLink = document.createElement("a");
        downloadLink.href = formData.data;
        downloadLink.download = formData.fileName;
        downloadLink.click();
    };

    const fileLoader = (
        <SBox className="no-border">
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        </SBox>
    );

    return (
        <SRow>
            <SCol
                className="flex justify-content-end button-box margin-top-right-md pad-top-bottom-lg"
                span="12">
                <button
                    id="download-blank-btn"
                    onClick={downloadFile}
                    className="download-blank margin-all-sm secondary"
                    disabled={isFormLoading || formData?.data?.length === 0}>
                    <SIcon name="download" aria-hidden="true" /> Download blank
                </button>
            </SCol>
            <SCol span="12" className="content-border">
                {!isFormLoading ? (
                    <Document
                        file={formData.data}
                        loading={fileLoader}
                        noData={
                            isFormLoading && formData?.data?.length === 0
                                ? fileLoader
                                : "no file found"
                        }
                        onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(new Array(numberPages), (el, index) => (
                            <Page
                                className="pdf-border"
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                scale={1.75}
                            />
                        ))}
                    </Document>
                ) : (
                    fileLoader
                )}
            </SCol>
        </SRow>
    );
});

export default CertificateFormsContent;
