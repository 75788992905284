import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { SRow, SCol, SDialog, SIcon } from "@avalara/skylab-react";
import classnames from "classnames";
import { getStateByCountry, selectStates } from "../../../app/customerSlice";
import SelectCountry from "../../sharedComponents/SelectCountry";
import SelectState from "../../customers/SelectState";
import "./region.css";
import {
    fetchRegionsWithNexusDetailsAPI,
    postRegionAPI,
    putRegionAPI,
} from "../../../app/regionSlice";
import { documentTypeNames } from "../../../shared/constants";

function AddEditRegionDialog(props) {
    const dispatch = useDispatch();
    const statesForCountry = useSelector(selectStates, shallowEqual);
    const [submitting, setSubmitting] = useState(false);
    const [title, setTitle] = useState("");
    const [country, setCountry] = useState(null);
    const [state, setState] = useState(null);
    const [autoShipTo, setAutoShipTo] = useState(false);
    const [isExcludeFromRequest, setIsExcludeFromRequest] = useState(false);
    const [countryError, setCountryError] = useState("valid");
    const saveClassName = classnames({ primary: true, small: true, loading: submitting });
    const [enableSave, setEnableSave] = useState(false);

    async function getRegions() {
        dispatch(fetchRegionsWithNexusDetailsAPI());
    }

    const clearFields = () => {
        setTitle("");
        setCountry("");
        setState(null);
        setAutoShipTo(false);
        setIsExcludeFromRequest(false);
    };

    useEffect(() => {
        if (title && country && state) {
            setEnableSave(true);
        } else if (title && country && statesForCountry.length === 0) {
            setEnableSave(true);
        } else setEnableSave(false);
    }, [JSON.stringify(country), title, JSON.stringify(state)]);

    useEffect(() => {
        if (props.source === "EditARegion") {
            setEnableSave(true);
            setTitle(props?.region?.name);
            setCountry(props?.region?.country?.id);
            setState(props?.region?.state?.id);
            setAutoShipTo(props?.region?.autoShipTo);
            setIsExcludeFromRequest(props?.region?.isExcludeFromRequest);
        } else if (props.source === "AddARegion") {
            clearFields();
        }
    }, [props.source]);

    const handleChangeInput = event => {
        setTitle(event.target.value);
    };

    const handleCountryChange = value => {
        setCountry(value);
        setState(null);
        setCountryError("valid");
        dispatch(getStateByCountry(value));
    };

    const handleStateChange = value => {
        setState(value.value);
    };

    const handleAutoShipToCheckboxChange = () => {
        setAutoShipTo(!autoShipTo);
    };

    const handleExcludeFromRequestCheckboxChange = () => {
        setIsExcludeFromRequest(!isExcludeFromRequest);
    };

    const createRegion = async () => {
        const createregionObj = {
            name: title,
            countryId: country,
            stateId: state,
            documentTypeName: documentTypeNames.salesAndUseTax,
            outgoing: false,
            autoShipTo,
            isExcludeFromRequest,
        };
        await dispatch(postRegionAPI(createregionObj)).then(response => {
            if (response) {
                clearFields();
                setSubmitting(false);
                getRegions();
            }
        });
        props.setIsAddEditRegionDialogOpen(false);
    };

    const updateRegion = async () => {
        const updateregionObj = {
            id: props.region.id,
            name: title,
            countryId: country,
            stateId: state,
            documentTypeName: documentTypeNames.salesAndUseTax,
            autoShipTo,
            isExcludeFromRequest,
        };
        await dispatch(putRegionAPI(updateregionObj)).then(response => {
            if (response) {
                clearFields();
                getRegions();
                props.setIsAddEditRegionDialogOpen(false);
            }
        });
        setSubmitting(false);
    };

    const onSave = async event => {
        event.preventDefault();
        setSubmitting(true);
        if (props?.region?.name) {
            updateRegion();
        } else {
            createRegion();
        }
    };

    const onCancel = () => {
        clearFields();
        props.setIsAddEditRegionDialogOpen(false);
    };

    const handleStateRemove = () => {
        setState(null);
    };
    const handleCountryRemove = () => {
        setCountry(null);
        setState(null);
    };

    return (
        <SDialog
            id="add-edit-reason-dialog"
            open={props.isAddEditRegionDialogOpen}
            onS-dismiss={onCancel}
            aria-modal="true">
            <div slot="header" id="addEditReason">
                {props.source === "AddARegion" ? "Add a region" : "Edit a region"}
            </div>
            <div slot="body">
                <SRow>
                    <SCol>
                        <label htmlFor="title" className="required">
                            Name
                        </label>
                        <input
                            id="title"
                            name="title"
                            type="text"
                            value={title}
                            onChange={e => {
                                handleChangeInput(e);
                            }}
                        />
                    </SCol>
                </SRow>
                <SRow>
                    <SCol span="auto">
                        <SelectCountry
                            onChange={handleCountryChange}
                            onRemove={handleCountryRemove}
                            value={country}
                            all
                            required
                            countryError={countryError}
                        />

                        {countryError.length > 0 && countryError === "error" ? (
                            <div className="input-msg">
                                <SIcon name="alert-circle-filled" aria-hidden="true" />
                                <span className="top-xs">Enter a country</span>
                            </div>
                        ) : null}
                    </SCol>
                </SRow>
                <SRow>
                    <SCol span="auto">
                        <SelectState
                            label="State or province"
                            onChange={handleStateChange}
                            onRemove={handleStateRemove}
                            value={state}
                            required
                            multiple={false}
                            stateError="valid"
                        />
                    </SCol>
                </SRow>
                <SRow>
                    <SCol>
                        <input
                            id="checkAutoShipTo"
                            name="checkAutoShipTo"
                            type="checkbox"
                            aria-label="Include this region when adding the selected state or province to a
                            customer"
                            checked={autoShipTo}
                            onChange={() => handleAutoShipToCheckboxChange()}
                        />
                        <label
                            htmlFor="checkAutoShipTo"
                            className="margin-top-xs margin-bottom-md block">
                            Include this region when adding the selected state or province to a
                            customer
                        </label>
                        <input
                            id="checkExcludeFromRequest"
                            name="checkExcludeFromRequest"
                            type="checkbox"
                            aria-label="Exclude this region from requests"
                            checked={isExcludeFromRequest}
                            onChange={() => handleExcludeFromRequestCheckboxChange()}
                        />
                        <label
                            htmlFor="checkExcludeFromRequest"
                            className="margin-top-xs margin-bottom-md">
                            Exclude this region from requests
                        </label>
                    </SCol>
                </SRow>
            </div>
            <div slot="footer">
                <button className="secondary small" onClick={onCancel}>
                    Cancel
                </button>
                <button
                    disabled={!enableSave}
                    className={saveClassName}
                    id="save-region"
                    type="button"
                    onClick={onSave}>
                    Save
                </button>
            </div>
        </SDialog>
    );
}

export default AddEditRegionDialog;
