import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    SIcon,
    SFiltersPanel,
    SRow,
    SCol,
    SDatepicker,
    SSelect,
    SMenu,
} from "@avalara/skylab-react";
import SelectExposureZone from "../../sharedComponents/SelectExposureZone";
import SelectCertificateSources from "../../sharedComponents/SelectCertificateSources";
import SelectExemptionReasons from "../../sharedComponents/SelectExemptionReasons";
import BulkUploadCertificateDialog from "../../sharedDialogs/BulkUploadCertificateDialog";
import PendingSearchForm from "./PendingSearchForm";
import {
    setPendingFiltersState,
    selectPendingCertificateFiltersState,
    clearPendingFiltersState,
    fetchPendingCertificatesSearchAPI,
    selectLoading,
    selectIsLoadingCertificateCount,
    setRedirectURL,
} from "../../../app/certificateSlice";
import "./pending.css";
import { urlToPageMap } from "../../../shared/Utils";
import { isEligibleUser } from "../../../shared/sessionUtility";
import FeatureToggler from "../../../featureToggler/FeatureToggler";
import featureFlag from "../../../featureToggler/featureFlag";
import { ClaimedByList, EscalatedList } from "../../../shared/constants";
import PendingCertificateColumnsManager from "./PendingCertificateColumnsManager";

const PendingFilter = React.memo(props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pendingFilterState = useSelector(selectPendingCertificateFiltersState);
    const {
        exposureZone,
        certificateCreatedFrom,
        certificateCreatedTo,
        assignedTo,
        escalted,
        isCertificateImage,
        source,
        exemptReasons,
    } = pendingFilterState;
    const [openFilters, setOpenFilters] = useState(false);
    const [startDateError, setStartDateError] = useState("");
    const [endDateError, setEndDateError] = useState("");
    const [isUploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [exposureZonePrevVal, setExposureZonePrevVal] = useState([]);
    const [certificateReasonPrevVal, setCertificateReasonPrevVal] = useState([]);
    const [sourcePrevVal, setSourcePrevVal] = useState([]);
    const selectRegionsRef = useRef(null);
    const isLoading = useSelector(selectLoading);
    const isLoadingCertificateCount = useSelector(selectIsLoadingCertificateCount);
    const applyButton = document.querySelector('button[ref="apply-button"]');
    const resetButton = document.querySelector('button[ref="reset-button"]');
    const isEligibleUserReviewCertificateAssignRelease = dispatch(
        isEligibleUser(featureFlag.reviewCertificate.assignRelease)
    );

    const isEligibleUserValidatedSearchPageBulkDelete = dispatch(
        isEligibleUser(featureFlag.validatedSearchPage.bulkDelete)
    );
    const isEligibleUserReviewCertificateAdd = dispatch(
        isEligibleUser(featureFlag.reviewCertificate.add)
    );

    const certificateImageList = [
        {
            label: "Missing a certificate image",
            value: "1",
            selected: isCertificateImage === "1",
        },
        {
            label: "Has a certificate image",
            value: "2",
            selected: isCertificateImage === "2",
        },
    ];
    const uploadFile = () => {
        setUploadDialogOpen(true);
    };

    const showFilters = () => {
        setOpenFilters(!openFilters);
    };

    const dismissFilters = () => {
        setOpenFilters(false);
    };

    const applyFilters = async () => {
        dispatch(fetchPendingCertificatesSearchAPI());
        props.setSelectedRows([]);
        props.setDisableDelete(true);
        props.setDisableAssignment(true);
        props.setRowCount(0);
    };

    const resetFilters = () => {
        dispatch(clearPendingFiltersState());
        applyFilters();
    };

    const updateFilters = async (id, detail) => {
        await dispatch(
            setPendingFiltersState({
                [id]: detail,
            })
        );
        applyFilters();
    };
    const handleSearch = (id, value) => {
        dispatch(
            setPendingFiltersState({
                [id]: [...pendingFilterState[`${id}`], value.value],
            })
        );
    };

    const handleRemove = async (selectKey, eventDetail, isDeselectAll) => {
        if (isDeselectAll) {
            dispatch(setPendingFiltersState({ [`${selectKey}`]: [] }));
        } else {
            dispatch(
                setPendingFiltersState({
                    [`${selectKey}`]: pendingFilterState[`${selectKey}`]?.filter(
                        option => option !== eventDetail?.value
                    ),
                })
            );
        }
        const timeoutId = setTimeout(() => {
            applyFilters();
        }, 400);
        return () => clearTimeout(timeoutId);
    };
    const handleSelectClose = async (fieldId, e) => {
        switch (fieldId) {
            case "exposureZone": {
                setExposureZonePrevVal(e.target.value);
                if (
                    JSON.stringify(exposureZonePrevVal) !== JSON.stringify(e.target.value) &&
                    exposureZone?.length > 0
                )
                    await applyFilters();
                break;
            }
            case "source": {
                setSourcePrevVal(e.target.value);
                if (
                    JSON.stringify(sourcePrevVal) !== JSON.stringify(e.target.value) &&
                    source?.length > 0
                )
                    await applyFilters();
                break;
            }
            case "exemptReasons": {
                setCertificateReasonPrevVal(e.target.value);
                if (
                    JSON.stringify(certificateReasonPrevVal) !== JSON.stringify(e.target.value) &&
                    exemptReasons.length > 0
                )
                    await applyFilters();
                break;
            }
            default:
                break;
        }
    };

    useEffect(() => {
        if (applyButton !== null) {
            if (isLoading && isLoadingCertificateCount) {
                applyButton.setAttribute("disabled", "disabled");
                resetButton.setAttribute("disabled", "disabled");
            } else {
                applyButton.removeAttribute("disabled");
                resetButton.removeAttribute("disabled");
            }
        }
    }, [applyButton, isLoading, isLoadingCertificateCount, resetButton]);
    return (
        <React.Fragment>
            <SRow className="pad-top-md pad-bottom-md align-items-end">
                <SCol span="xl-8 6">
                    <SRow className="margin-left-none align-items-end">
                        <button
                            type="button"
                            id="review-filter"
                            className="secondary"
                            onClick={showFilters}>
                            <SIcon name="filter" className="margin-right-xs" aria-hidden="true" />
                            Filters
                        </button>

                        <PendingSearchForm
                            setSelectedRows={props.setSelectedRows}
                            setRowCount={props.setRowCount}
                            setDisableDelete={props.setDisableDelete}
                        />
                        <PendingCertificateColumnsManager />
                    </SRow>
                </SCol>
                <SCol span="4" className="flex justify-content-end">
                    <span className="flex justify-content-start">
                        <SMenu>
                            <button
                                slot="menu-trigger"
                                className="secondary icon"
                                aria-controls="menu1-id">
                                <SIcon name="more" aria-label="more" />
                            </button>
                            <ul slot="menu-items" id="menu1-id">
                                <FeatureToggler category="reviewCertificate" id="assignRelease">
                                    <li>
                                        <button
                                            id="assign-certificate-btn"
                                            onClick={e => props.assignCertificate(e)}
                                            disabled={
                                                props.disableAssignment ||
                                                !isEligibleUserReviewCertificateAssignRelease
                                            }>
                                            Assign certificates to me
                                        </button>
                                    </li>

                                    <li>
                                        <button
                                            id="release-certificate-btn"
                                            onClick={e => props.assignCertificate(e)}
                                            disabled={
                                                props.disableAssignment ||
                                                !isEligibleUserReviewCertificateAssignRelease
                                            }>
                                            Release Certificates
                                        </button>
                                    </li>
                                </FeatureToggler>
                                <FeatureToggler category="reviewSearchPage" id="bulkDelete">
                                    <li>
                                        <button
                                            onClick={() =>
                                                props.setIsDeleteCertificateDialogOpen(true)
                                            }
                                            type="button"
                                            id="delete-certificate-btn"
                                            disabled={
                                                props.disableDelete ||
                                                !isEligibleUserValidatedSearchPageBulkDelete
                                            }>
                                            Delete
                                        </button>
                                    </li>
                                </FeatureToggler>
                                <li>
                                    <button
                                        type="button"
                                        id="accept-certificate-btn"
                                        className="secondary inline hidden"
                                        disabled={props.disableAccept}>
                                        Accept
                                    </button>
                                </li>
                            </ul>
                        </SMenu>
                    </span>
                    <span className="flex justify-content-end">
                        <FeatureToggler category="reviewSearchPage" id="zipUploadCertificate">
                            <button
                                className="secondary right margin-left-sm"
                                disabled={
                                    !dispatch(
                                        isEligibleUser(
                                            featureFlag.reviewSearchPage.zipUploadCertificate
                                        )
                                    )
                                }
                                onClick={uploadFile}>
                                <SIcon name="upload" className="pad-right-xs" aria-hidden="true" />
                                Upload a ZIP file
                            </button>
                        </FeatureToggler>
                        <button
                            id="create-certificate"
                            className="primary inline right margin-left-sm"
                            disabled={!isEligibleUserReviewCertificateAdd}
                            onClick={() => {
                                dispatch(setRedirectURL(urlToPageMap["search-pending"]));
                                navigate(urlToPageMap.review);
                            }}>
                            <SIcon
                                name="plus"
                                className="margin-right-xs"
                                aria-label="Add a certificate"
                            />
                            Add a certificate
                        </button>
                    </span>
                </SCol>
            </SRow>
            <SFiltersPanel
                open={openFilters}
                id="certificate-filters-panel"
                onS-dismiss={dismissFilters}
                onS-reset={resetFilters}
                onS-apply={applyFilters}
                className="margin-right-lg margin-top-xl">
                <div slot="filters-panel-content">
                    <SRow>
                        <SCol span="6" className="pad-right-xs pad-bottom-sm">
                            <label htmlFor="expiration-start-date">Imported starting on</label>
                            <SDatepicker
                                inputid="expiration-start-date"
                                className="width-auto"
                                onS-select={e => {
                                    updateFilters("certificateCreatedFrom", e.detail.value);
                                }}
                                value={certificateCreatedFrom}
                                onS-error={e => {
                                    setStartDateError(e.detail.message);
                                    updateFilters("certificateCreatedFrom", "");
                                    e.target.className = "error";
                                }}
                                onS-deselect={() => {
                                    updateFilters("certificateCreatedFrom", "");
                                }}
                                disabled={isLoading || isLoadingCertificateCount}
                            />
                            <div className="input-msg">
                                <SIcon name="alert-circle-filled" aria-hidden="true" />
                                {startDateError}
                            </div>
                        </SCol>
                        <SCol span="6" className="pad-left-xs pad-bottom-sm">
                            <label htmlFor="expiration-end-date">...and through</label>
                            <SDatepicker
                                inputid="expiration-end-date"
                                className="width-auto"
                                onS-select={e => {
                                    updateFilters("certificateCreatedTo", e.detail.value);
                                }}
                                value={certificateCreatedTo}
                                onS-error={e => {
                                    setEndDateError(e.detail.message);
                                    updateFilters("certificateCreatedTo", "");
                                    e.target.className = "error";
                                }}
                                onS-deselect={() => {
                                    updateFilters("certificateCreatedTo", "");
                                }}
                                disabled={isLoading || isLoadingCertificateCount}
                            />
                            <div className="input-msg">
                                <SIcon name="alert-circle-filled" aria-hidden="true" />
                                {endDateError}
                            </div>
                        </SCol>
                    </SRow>
                    <SRow>
                        <SCol span="12" className="pad-bottom-sm">
                            <SelectExposureZone
                                value={exposureZone}
                                onAdd={e => handleSearch("exposureZone", e)}
                                onRemove={(eventDetail, isDeselectAll) =>
                                    handleRemove("exposureZone", eventDetail, isDeselectAll)
                                }
                                onClose={e => handleSelectClose("exposureZone", e)}
                                exposureZoneDisabled={false}
                                multiple
                                ref={selectRegionsRef}
                                disabled={isLoading || isLoadingCertificateCount}
                            />
                        </SCol>
                    </SRow>
                    <SRow>
                        <SCol span="12" className="pad-bottom-sm">
                            <SelectExemptionReasons
                                name="exemptReasons"
                                id="exemptReasons"
                                onAdd={e => handleSearch("exemptReasons", e)}
                                onRemove={(eventDetail, isDeselectAll) =>
                                    handleRemove("exemptReasons", eventDetail, isDeselectAll)
                                }
                                onClose={e => handleSelectClose("exemptReasons", e)}
                                value={JSON.stringify(exemptReasons)}
                                multiple
                                disabled={isLoading}
                            />
                        </SCol>
                    </SRow>
                    <SRow>
                        <SCol span="12" className="pad-bottom-sm">
                            <SelectCertificateSources
                                value={source}
                                onAdd={e => updateFilters("source", e.value)}
                                onRemove={() => updateFilters("source", 0)}
                                disabled={
                                    isLoading || isLoadingCertificateCount ? "disabled" : null
                                }
                            />
                        </SCol>
                    </SRow>

                    <FeatureToggler category="reviewCertificate" id="assignRelease">
                        <SRow>
                            <SCol span="12" className="pad-bottom-sm">
                                <label htmlFor="assigned-to" id="lbl-assigned-to">
                                    Assigned to
                                </label>
                                <SSelect
                                    inputId="assigned-to"
                                    name="assigned-to"
                                    optionsList={ClaimedByList}
                                    onS-select={e =>
                                        updateFilters("assignedTo", e.detail.item.value)
                                    }
                                    onS-deselect={() => updateFilters("assignedTo", 0)}
                                    value={assignedTo}
                                    selectionOptional
                                    disabled={
                                        isLoading || isLoadingCertificateCount ? "disabled" : null
                                    }
                                />
                            </SCol>
                        </SRow>

                        <SRow>
                            <SCol span="12" className="pad-bottom-sm">
                                <label htmlFor="escalation" id="lbl-escalated">
                                    Escalation
                                </label>
                                <SSelect
                                    inputId="escalated"
                                    name="escalated"
                                    optionsList={EscalatedList}
                                    onS-select={e =>
                                        updateFilters("escalated", e.detail.item.value)
                                    }
                                    onS-deselect={() => updateFilters("escalated", 0)}
                                    value={escalted}
                                    selectionOptional
                                    disabled={
                                        isLoading || isLoadingCertificateCount ? "disabled" : null
                                    }
                                />
                            </SCol>
                        </SRow>
                        <SRow>
                            <FeatureToggler
                                category="certificateFilters"
                                id="certificateImageFilter">
                                <SCol span="12" className="pad-bottom-sm">
                                    <label htmlFor="certificateImage" id="lbl-certificateImage">
                                        Certificate image is present
                                    </label>
                                    <SSelect
                                        inputId="certificateImage"
                                        name="certificateImage"
                                        optionsList={certificateImageList}
                                        onS-select={e =>
                                            updateFilters("isCertificateImage", e.detail.item.value)
                                        }
                                        onS-deselect={() => updateFilters("isCertificateImage", 0)}
                                        value={isCertificateImage}
                                        selectionOptional
                                        disabled={
                                            isLoading || isLoadingCertificateCount
                                                ? "disabled"
                                                : null
                                        }
                                        nosearch
                                    />
                                </SCol>
                            </FeatureToggler>
                        </SRow>
                    </FeatureToggler>
                </div>
            </SFiltersPanel>
            <BulkUploadCertificateDialog
                isBulkUploadZipDialogOpen={isUploadDialogOpen}
                closeUploadDialog={setUploadDialogOpen}
                uploadedFromPending
            />
        </React.Fragment>
    );
});

export default PendingFilter;
