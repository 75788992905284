import React, { useState } from "react";
import { STabs } from "@avalara/skylab-react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isEligibleUser } from "../../../../shared/sessionUtility";

import {
    setRequestCertificateReasons,
    setRequestCertificateRegions,
    setIsRequestingAvalaraDefault,
    setSendingCertificateReasons,
    setSendingCertificateRegions,
    setIsSendingAvalaraDefault,
} from "../../../../app/settingsSlice";
import featureFlag from "../../../../featureToggler/featureFlag";

const CertificateRequestForms = React.memo(() => {
    const location = useLocation();
    const locationPath = location.pathname.toString();
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const vendorOnly = useState(false);
    const hideTab = !(
        dispatch(isEligibleUser(featureFlag.settings.requestingCertficateExemptionMatrix, true)) &&
        dispatch(isEligibleUser(featureFlag.settings.sendingCertficateExemptionMatrix, true))
    );

    const tabItems = [
        {
            id: "tabpanel-settings-default-exemption-forms-incoming",
            path: "/settings/default-exemption-forms/incoming",
            label: "Requesting certificates",
            tabPanelId: "tabpanel-settings-default-exemption-forms-incoming",
            selected: locationPath.includes("default-exemption-forms/incoming"),
        },
        {
            id: "tabpanel-settings-default-exemption-forms-outgoing",
            path: "/settings/default-exemption-forms/outgoing",
            label: "Sending certificates",
            tabPanelId: "tabpanel-settings-default-exemption-forms-outgoing",
            selected: locationPath.includes("default-exemption-forms/outgoing"),
        },
    ];

    // TODO these if conditions needs to be tweaked once we have the subs available for vendors
    let readText = (
        <div>
            Avalara defaults to these forms when you send requests. There may be a reason you want
            to use a different form, override the expiration timeframe, or add an <br /> additional
            &quot;yes or no&quot; question to a form
        </div>
    );

    if (process.env.REACT_APP_ENV !== "prd" && process.env.REACT_APP_ENV !== "sbx") {
        readText = (
            <div>
                Avalara defaults to these forms when you request or send an exemption certificate.
                There may be a reason you want to use <br /> a different form, override the
                expiration timeframe, or change the &quot;yes or no&quot; question on a form
            </div>
        );
    } else if (vendorOnly) {
        readText = (
            <div>
                Avalara defaults to these forms when you request or send an exemption certificate.
                There may be a reason you want to use a <br /> different form, override the
                expiration timeframe, or change the &quot;yes or no &quot; question on a form
            </div>
        );
    }

    const navigateTo = to => {
        const currentHistory = locationPath;
        if (
            to.includes("tabpanel-settings-default-exemption-forms-incoming") &&
            !currentHistory.includes("default-exemption-forms/incoming")
        ) {
            dispatch(setSendingCertificateReasons({ reasonId: "", reasonName: "" }));
            dispatch(setSendingCertificateRegions({ regionId: "", regionName: "" }));
            dispatch(setIsSendingAvalaraDefault(false));
            navigate(`/settings/default-exemption-forms/incoming`);
        } else if (
            to.includes("tabpanel-settings-default-exemption-forms-outgoing") &&
            !currentHistory.includes("default-exemption-forms/outgoing")
        ) {
            dispatch(setRequestCertificateReasons({ reasonId: "", reasonName: "" }));
            dispatch(setRequestCertificateRegions({ regionId: "", regionName: "" }));
            dispatch(setIsRequestingAvalaraDefault(false));
            navigate(`/settings/default-exemption-forms/outgoing`);
        }
    };

    const DOMelement = (
        // TODO add functionality for alternate text below
        <React.Fragment>
            <div className="pad-top-sm margin-bottom-lg">{readText}</div>
            <STabs tabItems={tabItems} onS-select={e => navigateTo(e.detail.id)} hidden={hideTab} />
            <Outlet />
        </React.Fragment>
    );
    return <React.Fragment> {DOMelement} </React.Fragment>;
});

export default CertificateRequestForms;
