import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SPagination, SLoader, SIcon, STag, SRow, SRowActions } from "@avalara/skylab-react";
import { v4 as uuidv4 } from "uuid";
import RecordCount from "../../search/RecordCount";
import {
    selectPage,
    setPageToNull,
    fetchCategoriesAsync,
    selectNoData,
    selectIsLoading,
    productCategoryCertificateCountAsync,
} from "../../../app/productCategoriesSlice";
import EmptyBox from "./images/EmptyBox.svg";
import EditProductCategoryDialog from "./EditProductCategoryDialog";
import DeleteProductCategories from "./DeleteProductCategories";

const ProductCategoriesGrid = React.memo(props => {
    const dispatch = useDispatch();
    const loading = useSelector(selectIsLoading);
    const noDataType = useSelector(selectNoData);
    const [submitting, setSubmitting] = useState(false);
    const page = useSelector(selectPage, shallowEqual);
    const pageData = page?.data;
    const paginateData = page.paginate;
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [certCount, setCertCount] = useState(0);
    const [categoryName, setCategoryName] = useState();
    const [taxcodes, setTaxcodes] = useState();

    useEffect(() => {
        dispatch(fetchCategoriesAsync(10, 0));
        return () => {
            dispatch(setPageToNull([]));
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (pageData.length < 1) {
            props.emptyGrid(true);
        } else {
            props.emptyGrid(false);
        }
    }, [pageData]); // eslint-disable-line react-hooks/exhaustive-deps

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            dispatch(
                fetchCategoriesAsync(e.detail.rowsPerPage, e.detail.startIndex, false, e.detail)
            );
            setSubmitting(false);
        }
    };
    const tableContents = data => {
        const rowData = data?.map(dt => {
            return (
                <tr key={`cat-row-${uuidv4()}`}>
                    <td className="category-row"> {dt.category} </td>
                    <td>
                        <SRow className="tax-code-row">
                            {dt.taxCodes.map(tc => {
                                return (
                                    <STag
                                        className="category-code"
                                        key={`${tc.taxCode}-${uuidv4()}`}>
                                        {tc.taxCode}
                                    </STag>
                                );
                            })}
                        </SRow>
                    </td>
                    <td className="action-item-row">
                        <SRowActions
                            menuId={`menu-${uuidv4()}`}
                            collapsed
                            key={uuidv4()}
                            actionItems={[
                                {
                                    label: "Edit",
                                    classList: ["primary", "icon-leading"],
                                    id: `menu-${uuidv4()}-edit`,
                                },
                                {
                                    label: "Delete",
                                    classList: ["primary", "icon-leading"],
                                    id: `menu-${uuidv4()}-delete`,
                                },
                            ]}
                            onS-select={async e => {
                                if (e.detail.id.includes("edit")) {
                                    setCategoryName(dt.category);
                                    setTaxcodes(dt.taxCodes);
                                    setIsEditDialogOpen(true);
                                }
                                if (e.detail.id.includes("delete")) {
                                    setCertCount(
                                        await dispatch(
                                            productCategoryCertificateCountAsync(dt.category)
                                        )
                                    );
                                    setCategoryName(dt.category);
                                    setIsDeleteDialogOpen(true);
                                }
                            }}
                        />
                    </td>
                </tr>
            );
        });

        return (
            <>
                <thead>
                    <tr>
                        <th> Category</th>
                        <th className="codes-header-row"> Tax codes </th>
                        <th className="action-item-row justify-content-center"> </th>
                    </tr>
                </thead>
                <tbody>{rowData}</tbody>
            </>
        );
    };

    let table = "";

    if (submitting || loading) {
        table = (
            <div className="flex flex-dir-col dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="grid-loader" className="medium" aria-live="polite" loading />
            </div>
        );
    } else if (noDataType) {
        table = (
            <div className="margin-top-none pad-top-none">
                <hr className="margin-bottom-lg" />
                <div>
                    <SRow className="align-content-center justify-content-center text-center">
                        <div>
                            <img className="box-image-empty" src={EmptyBox} alt="empty box" />
                        </div>
                    </SRow>
                    <SRow className="align-content-center justify-content-center text-center">
                        <span className="text-lg  text-center">
                            Map exempt products to tax codes
                        </span>
                    </SRow>
                    <SRow className="align-content-center justify-content-center text-center">
                        <span className="text-default font-normal margin-bottom-xs">
                            Create categories tied to your product&apos;s tax code mappings
                        </span>
                    </SRow>
                    <SRow className="align-content-center justify-content-center text-center">
                        <a href="/resources/exempt-entities" className="text-underline">
                            Learn more about exempt product categories and tax codes
                        </a>
                    </SRow>
                    <SRow className="align-content-center justify-content-center text-center margin-bottom-lg">
                        <button className="primary icon-leading margin-top-lg" disabled>
                            <SIcon name="plus" />
                            Add a category
                        </button>
                    </SRow>
                </div>
            </div>
        );
    } else
        table = (
            <div className="margin-top-sm">
                <RecordCount
                    selectedCount={0}
                    recordCount={paginateData.totalRecords}
                    recordName="product categories"
                />
                <s-table-container key={uuidv4()} className="margin-top-none">
                    <table>{tableContents(pageData)}</table>
                </s-table-container>
                {paginateData.totalRecords > 0 ? (
                    <SPagination
                        className="margin-top-md"
                        rowsPerPage={paginateData.rowsPerPage}
                        totalRecords={paginateData.totalRecords}
                        startIndex={paginateData.startIndex}
                        onS-paginate={e => {
                            handlePaginate(e);
                        }}
                    />
                ) : null}
                {isDeleteDialogOpen ? (
                    <DeleteProductCategories
                        isDeleteDialogOpen
                        setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                        certCount={certCount}
                        categoryName={categoryName}
                    />
                ) : null}
                {isEditDialogOpen ? (
                    <EditProductCategoryDialog
                        isEditDialogOpen
                        setIsEditDialogOpen={setIsEditDialogOpen}
                        oldCategoryName={categoryName}
                        oldTaxcodes={taxcodes}
                    />
                ) : null}
            </div>
        );

    return table;
});

export default ProductCategoriesGrid;
